import React, { useRef, useState } from "react";
import { audioIcon } from "../../Images/images";
const GCChatlistAudioPlayPause = ({ media, baseUrlMedia, stage }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingAudio, setCurrentPlayingAudio] = useState();
  const handlePlayPause = () => {
    if (audioRef?.current?.paused) {
      setCurrentPlayingAudio(audioRef.current.src);
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };
  return (
    <li>
      <audio
        ref={audioRef}
        src={`${
          stage === "save" || stage === "mentor"
            ? baseUrlMedia
            : baseUrlMedia?.mediaUrl
        }${media?.StoragePath}${media?.FileName}`}
      />

      <div
        onClick={() => {
          handlePlayPause(
            `${baseUrlMedia?.mediaUrl}${media?.StoragePath}${media?.FileName}`
          );
        }}
        data-toggle="modal"
        data-target="#myModal"
        className="audio_play_icon"
      >
        <img src={audioIcon} alt="play" />
      </div>
      <div className="file-flex d-flex justify-content-between">
        <div className="file-name">{media?.FileName}</div>
        <span className="file-size" variant="body2">
          {Math.round(media?.FileSize / 100) / 10 > 1000
            ? `${(Math.round(media?.FileSize / 100) / 10000).toFixed(1)} mb`
            : `${(Math.round(media?.FileSize / 100) / 10).toFixed(1)} kb`}
        </span>
      </div>
      {currentPlayingAudio && (
        <div
          className="modal fade video_show_popup"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          onClick={() => setCurrentPlayingAudio("")}
        >
          <div
            className="modal-dialog"
            role="document"
            onClick={() => setCurrentPlayingAudio("")}
          >
            <div className="modal-content">
              <div className="modal-header pb-1 pt-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                <audio src={currentPlayingAudio} controls={true} />
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default GCChatlistAudioPlayPause;
