

export const CountryData = 
[
  {
  "name": "Afghanistan",
  "dialCode": "+93",
  "isoCode": "AF",
  "flag": "https://www.countryflags.io/AF/flat/64.png"
  },
  {
  "name": "Aland Islands",
  "dialCode": "+358",
  "isoCode": "AX",
  "flag": "https://www.countryflags.io/AX/flat/64.png"
  },
  {
  "name": "Albania",
  "dialCode": "+355",
  "isoCode": "AL",
  "flag": "https://www.countryflags.io/AL/flat/64.png"
  },
  {
  "name": "Algeria",
  "dialCode": "+213",
  "isoCode": "DZ",
  "flag": "https://www.countryflags.io/DZ/flat/64.png"
  },
  {
  "name": "AmericanSamoa",
  "dialCode": "+1684",
  "isoCode": "AS",
  "flag": "https://www.countryflags.io/AS/flat/64.png"
  },
  {
  "name": "Andorra",
  "dialCode": "+376",
  "isoCode": "AD",
  "flag": "https://www.countryflags.io/AD/flat/64.png"
  },
  {
  "name": "Angola",
  "dialCode": "+244",
  "isoCode": "AO",
  "flag": "https://www.countryflags.io/AO/flat/64.png"
  },
  {
  "name": "Anguilla",
  "dialCode": "+1264",
  "isoCode": "AI",
  "flag": "https://www.countryflags.io/AI/flat/64.png"
  },
  {
  "name": "Antarctica",
  "dialCode": "+672",
  "isoCode": "AQ",
  "flag": "https://www.countryflags.io/AQ/flat/64.png"
  },
  {
  "name": "Antigua and Barbuda",
  "dialCode": "+1268",
  "isoCode": "AG",
  "flag": "https://www.countryflags.io/AG/flat/64.png"
  },
  {
  "name": "Argentina",
  "dialCode": "+54",
  "isoCode": "AR",
  "flag": "https://www.countryflags.io/AR/flat/64.png"
  },
  {
  "name": "Armenia",
  "dialCode": "+374",
  "isoCode": "AM",
  "flag": "https://www.countryflags.io/AM/flat/64.png"
  },
  {
  "name": "Aruba",
  "dialCode": "+297",
  "isoCode": "AW",
  "flag": "https://www.countryflags.io/AW/flat/64.png"
  },
  {
  "name": "Ascension Island",
  "dialCode": "+247",
  "isoCode": "AC",
  "flag": "https://www.countryflags.io/AC/flat/64.png"
  },
  {
  "name": "Australia",
  "dialCode": "+61",
  "isoCode": "AU",
  "flag": "https://www.countryflags.io/AU/flat/64.png"
  },
  {
  "name": "Austria",
  "dialCode": "+43",
  "isoCode": "AT",
  "flag": "https://www.countryflags.io/AT/flat/64.png"
  },
  {
  "name": "Azerbaijan",
  "dialCode": "+994",
  "isoCode": "AZ",
  "flag": "https://www.countryflags.io/AZ/flat/64.png"
  },
  {
  "name": "Bahamas",
  "dialCode": "+1242",
  "isoCode": "BS",
  "flag": "https://www.countryflags.io/BS/flat/64.png"
  },
  {
  "name": "Bahrain",
  "dialCode": "+973",
  "isoCode": "BH",
  "flag": "https://www.countryflags.io/BH/flat/64.png"
  },
  {
  "name": "Bangladesh",
  "dialCode": "+880",
  "isoCode": "BD",
  "flag": "https://www.countryflags.io/BD/flat/64.png"
  },
  {
  "name": "Barbados",
  "dialCode": "+1246",
  "isoCode": "BB",
  "flag": "https://www.countryflags.io/BB/flat/64.png"
  },
  {
  "name": "Belarus",
  "dialCode": "+375",
  "isoCode": "BY",
  "flag": "https://www.countryflags.io/BY/flat/64.png"
  },
  {
  "name": "Belgium",
  "dialCode": "+32",
  "isoCode": "BE",
  "flag": "https://www.countryflags.io/BE/flat/64.png"
  },
  {
  "name": "Belize",
  "dialCode": "+501",
  "isoCode": "BZ",
  "flag": "https://www.countryflags.io/BZ/flat/64.png"
  },
  {
  "name": "Benin",
  "dialCode": "+229",
  "isoCode": "BJ",
  "flag": "https://www.countryflags.io/BJ/flat/64.png"
  },
  {
  "name": "Bermuda",
  "dialCode": "+1441",
  "isoCode": "BM",
  "flag": "https://www.countryflags.io/BM/flat/64.png"
  },
  {
  "name": "Bhutan",
  "dialCode": "+975",
  "isoCode": "BT",
  "flag": "https://www.countryflags.io/BT/flat/64.png"
  },
  {
  "name": "Bolivia",
  "dialCode": "+591",
  "isoCode": "BO",
  "flag": "https://www.countryflags.io/BO/flat/64.png"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dialCode": "+387",
  "isoCode": "BA",
  "flag": "https://www.countryflags.io/BA/flat/64.png"
  },
  {
  "name": "Botswana",
  "dialCode": "+267",
  "isoCode": "BW",
  "flag": "https://www.countryflags.io/BW/flat/64.png"
  },
  {
  "name": "Brazil",
  "dialCode": "+55",
  "isoCode": "BR",
  "flag": "https://www.countryflags.io/BR/flat/64.png"
  },
  {
  "name": "British Indian Ocean Territory",
  "dialCode": "+246",
  "isoCode": "IO",
  "flag": "https://www.countryflags.io/IO/flat/64.png"
  },
  {
  "name": "Brunei Darussalam",
  "dialCode": "+673",
  "isoCode": "BN",
  "flag": "https://www.countryflags.io/BN/flat/64.png"
  },
  {
  "name": "Bulgaria",
  "dialCode": "+359",
  "isoCode": "BG",
  "flag": "https://www.countryflags.io/BG/flat/64.png"
  },
  {
  "name": "Burkina Faso",
  "dialCode": "+226",
  "isoCode": "BF",
  "flag": "https://www.countryflags.io/BF/flat/64.png"
  },
  {
  "name": "Burundi",
  "dialCode": "+257",
  "isoCode": "BI",
  "flag": "https://www.countryflags.io/BI/flat/64.png"
  },
  {
  "name": "Cambodia",
  "dialCode": "+855",
  "isoCode": "KH",
  "flag": "https://www.countryflags.io/KH/flat/64.png"
  },
  {
  "name": "Cameroon",
  "dialCode": "+237",
  "isoCode": "CM",
  "flag": "https://www.countryflags.io/CM/flat/64.png"
  },
  {
  "name": "Canada",
  "dialCode": "+1",
  "isoCode": "CA",
  "flag": "https://www.countryflags.io/CA/flat/64.png"
  },
  {
  "name": "Cape Verde",
  "dialCode": "+238",
  "isoCode": "CV",
  "flag": "https://www.countryflags.io/CV/flat/64.png"
  },
  {
  "name": "Cayman Islands",
  "dialCode": "+1345",
  "isoCode": "KY",
  "flag": "https://www.countryflags.io/KY/flat/64.png"
  },
  {
  "name": "Central African Republic",
  "dialCode": "+236",
  "isoCode": "CF",
  "flag": "https://www.countryflags.io/CF/flat/64.png"
  },
  {
  "name": "Chad",
  "dialCode": "+235",
  "isoCode": "TD",
  "flag": "https://www.countryflags.io/TD/flat/64.png"
  },
  {
  "name": "Chile",
  "dialCode": "+56",
  "isoCode": "CL",
  "flag": "https://www.countryflags.io/CL/flat/64.png"
  },
  {
  "name": "China",
  "dialCode": "+86",
  "isoCode": "CN",
  "flag": "https://www.countryflags.io/CN/flat/64.png"
  },
  {
  "name": "Christmas Island",
  "dialCode": "+61",
  "isoCode": "CX",
  "flag": "https://www.countryflags.io/CX/flat/64.png"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dialCode": "+61",
  "isoCode": "CC",
  "flag": "https://www.countryflags.io/CC/flat/64.png"
  },
  {
  "name": "Colombia",
  "dialCode": "+57",
  "isoCode": "CO",
  "flag": "https://www.countryflags.io/CO/flat/64.png"
  },
  {
  "name": "Comoros",
  "dialCode": "+269",
  "isoCode": "KM",
  "flag": "https://www.countryflags.io/KM/flat/64.png"
  },
  {
  "name": "Congo",
  "dialCode": "+242",
  "isoCode": "CG",
  "flag": "https://www.countryflags.io/CG/flat/64.png"
  },
  {
  "name": "Cook Islands",
  "dialCode": "+682",
  "isoCode": "CK",
  "flag": "https://www.countryflags.io/CK/flat/64.png"
  },
  {
  "name": "Costa Rica",
  "dialCode": "+506",
  "isoCode": "CR",
  "flag": "https://www.countryflags.io/CR/flat/64.png"
  },
  {
  "name": "Croatia",
  "dialCode": "+385",
  "isoCode": "HR",
  "flag": "https://www.countryflags.io/HR/flat/64.png"
  },
  {
  "name": "Cuba",
  "dialCode": "+53",
  "isoCode": "CU",
  "flag": "https://www.countryflags.io/CU/flat/64.png"
  },
  {
  "name": "Cyprus",
  "dialCode": "+357",
  "isoCode": "CY",
  "flag": "https://www.countryflags.io/CY/flat/64.png"
  },
  {
  "name": "Czech Republic",
  "dialCode": "+420",
  "isoCode": "CZ",
  "flag": "https://www.countryflags.io/CZ/flat/64.png"
  },
  {
  "name": "Democratic Republic of the Congo",
  "dialCode": "+243",
  "isoCode": "CD",
  "flag": "https://www.countryflags.io/CD/flat/64.png"
  },
  {
  "name": "Denmark",
  "dialCode": "+45",
  "isoCode": "DK",
  "flag": "https://www.countryflags.io/DK/flat/64.png"
  },
  {
  "name": "Djibouti",
  "dialCode": "+253",
  "isoCode": "DJ",
  "flag": "https://www.countryflags.io/DJ/flat/64.png"
  },
  {
  "name": "Dominica",
  "dialCode": "+1767",
  "isoCode": "DM",
  "flag": "https://www.countryflags.io/DM/flat/64.png"
  },
  {
  "name": "Dominican Republic",
  "dialCode": "+1849",
  "isoCode": "DO",
  "flag": "https://www.countryflags.io/DO/flat/64.png"
  },
  {
  "name": "Ecuador",
  "dialCode": "+593",
  "isoCode": "EC",
  "flag": "https://www.countryflags.io/EC/flat/64.png"
  },
  {
  "name": "Egypt",
  "dialCode": "+20",
  "isoCode": "EG",
  "flag": "https://www.countryflags.io/EG/flat/64.png"
  },
  {
  "name": "El Salvador",
  "dialCode": "+503",
  "isoCode": "SV",
  "flag": "https://www.countryflags.io/SV/flat/64.png"
  },
  {
  "name": "Equatorial Guinea",
  "dialCode": "+240",
  "isoCode": "GQ",
  "flag": "https://www.countryflags.io/GQ/flat/64.png"
  },
  {
  "name": "Eritrea",
  "dialCode": "+291",
  "isoCode": "ER",
  "flag": "https://www.countryflags.io/ER/flat/64.png"
  },
  {
  "name": "Estonia",
  "dialCode": "+372",
  "isoCode": "EE",
  "flag": "https://www.countryflags.io/EE/flat/64.png"
  },
  {
  "name": "Eswatini",
  "dialCode": "+268",
  "isoCode": "SZ",
  "flag": "https://www.countryflags.io/SZ/flat/64.png"
  },
  {
  "name": "Ethiopia",
  "dialCode": "+251",
  "isoCode": "ET",
  "flag": "https://www.countryflags.io/ET/flat/64.png"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dialCode": "+500",
  "isoCode": "FK",
  "flag": "https://www.countryflags.io/FK/flat/64.png"
  },
  {
  "name": "Faroe Islands",
  "dialCode": "+298",
  "isoCode": "FO",
  "flag": "https://www.countryflags.io/FO/flat/64.png"
  },
  {
  "name": "Fiji",
  "dialCode": "+679",
  "isoCode": "FJ",
  "flag": "https://www.countryflags.io/FJ/flat/64.png"
  },
  {
  "name": "Finland",
  "dialCode": "+358",
  "isoCode": "FI",
  "flag": "https://www.countryflags.io/FI/flat/64.png"
  },
  {
  "name": "France",
  "dialCode": "+33",
  "isoCode": "FR",
  "flag": "https://www.countryflags.io/FR/flat/64.png"
  },
  {
  "name": "French Guiana",
  "dialCode": "+594",
  "isoCode": "GF",
  "flag": "https://www.countryflags.io/GF/flat/64.png"
  },
  {
  "name": "French Polynesia",
  "dialCode": "+689",
  "isoCode": "PF",
  "flag": "https://www.countryflags.io/PF/flat/64.png"
  },
  {
  "name": "Gabon",
  "dialCode": "+241",
  "isoCode": "GA",
  "flag": "https://www.countryflags.io/GA/flat/64.png"
  },
  {
  "name": "Gambia",
  "dialCode": "+220",
  "isoCode": "GM",
  "flag": "https://www.countryflags.io/GM/flat/64.png"
  },
  {
  "name": "Georgia",
  "dialCode": "+995",
  "isoCode": "GE",
  "flag": "https://www.countryflags.io/GE/flat/64.png"
  },
  {
  "name": "Germany",
  "dialCode": "+49",
  "isoCode": "DE",
  "flag": "https://www.countryflags.io/DE/flat/64.png"
  },
  {
  "name": "Ghana",
  "dialCode": "+233",
  "isoCode": "GH",
  "flag": "https://www.countryflags.io/GH/flat/64.png"
  },
  {
  "name": "Gibraltar",
  "dialCode": "+350",
  "isoCode": "GI",
  "flag": "https://www.countryflags.io/GI/flat/64.png"
  },
  {
  "name": "Greece",
  "dialCode": "+30",
  "isoCode": "GR",
  "flag": "https://www.countryflags.io/GR/flat/64.png"
  },
  {
  "name": "Greenland",
  "dialCode": "+299",
  "isoCode": "GL",
  "flag": "https://www.countryflags.io/GL/flat/64.png"
  },
  {
  "name": "Grenada",
  "dialCode": "+1473",
  "isoCode": "GD",
  "flag": "https://www.countryflags.io/GD/flat/64.png"
  },
  {
  "name": "Guadeloupe",
  "dialCode": "+590",
  "isoCode": "GP",
  "flag": "https://www.countryflags.io/GP/flat/64.png"
  },
  {
  "name": "Guam",
  "dialCode": "+1671",
  "isoCode": "GU",
  "flag": "https://www.countryflags.io/GU/flat/64.png"
  },
  {
  "name": "Guatemala",
  "dialCode": "+502",
  "isoCode": "GT",
  "flag": "https://www.countryflags.io/GT/flat/64.png"
  },
  {
  "name": "Guernsey",
  "dialCode": "+44",
  "isoCode": "GG",
  "flag": "https://www.countryflags.io/GG/flat/64.png"
  },
  {
  "name": "Guinea",
  "dialCode": "+224",
  "isoCode": "GN",
  "flag": "https://www.countryflags.io/GN/flat/64.png"
  },
  {
  "name": "Guinea-Bissau",
  "dialCode": "+245",
  "isoCode": "GW",
  "flag": "https://www.countryflags.io/GW/flat/64.png"
  },
  {
  "name": "Guyana",
  "dialCode": "+592",
  "isoCode": "GY",
  "flag": "https://www.countryflags.io/GY/flat/64.png"
  },
  {
  "name": "Haiti",
  "dialCode": "+509",
  "isoCode": "HT",
  "flag": "https://www.countryflags.io/HT/flat/64.png"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dialCode": "+379",
  "isoCode": "VA",
  "flag": "https://www.countryflags.io/VA/flat/64.png"
  },
  {
  "name": "Honduras",
  "dialCode": "+504",
  "isoCode": "HN",
  "flag": "https://www.countryflags.io/HN/flat/64.png"
  },
  {
  "name": "Hong Kong",
  "dialCode": "+852",
  "isoCode": "HK",
  "flag": "https://www.countryflags.io/HK/flat/64.png"
  },
  {
  "name": "Hungary",
  "dialCode": "+36",
  "isoCode": "HU",
  "flag": "https://www.countryflags.io/HU/flat/64.png"
  },
  {
  "name": "Iceland",
  "dialCode": "+354",
  "isoCode": "IS",
  "flag": "https://www.countryflags.io/IS/flat/64.png"
  },
  {
  "name": "India",
  "dialCode": "+91",
  "isoCode": "IN",
  "flag": "https://www.countryflags.io/IN/flat/64.png"
  },
  {
  "name": "Indonesia",
  "dialCode": "+62",
  "isoCode": "ID",
  "flag": "https://www.countryflags.io/ID/flat/64.png"
  },
  {
  "name": "Iran",
  "dialCode": "+98",
  "isoCode": "IR",
  "flag": "https://www.countryflags.io/IR/flat/64.png"
  },
  {
  "name": "Iraq",
  "dialCode": "+964",
  "isoCode": "IQ",
  "flag": "https://www.countryflags.io/IQ/flat/64.png"
  },
  {
  "name": "Ireland",
  "dialCode": "+353",
  "isoCode": "IE",
  "flag": "https://www.countryflags.io/IE/flat/64.png"
  },
  {
  "name": "Isle of Man",
  "dialCode": "+44",
  "isoCode": "IM",
  "flag": "https://www.countryflags.io/IM/flat/64.png"
  },
  {
  "name": "Italy",
  "dialCode": "+39",
  "isoCode": "IT",
  "flag": "https://www.countryflags.io/IT/flat/64.png"
  },
  {
  "name": "Ivory Coast / Cote d'Ivoire",
  "dialCode": "+225",
  "isoCode": "CI",
  "flag": "https://www.countryflags.io/CI/flat/64.png"
  },
  {
  "name": "Jamaica",
  "dialCode": "+1876",
  "isoCode": "JM",
  "flag": "https://www.countryflags.io/JM/flat/64.png"
  },
  {
  "name": "Japan",
  "dialCode": "+81",
  "isoCode": "JP",
  "flag": "https://www.countryflags.io/JP/flat/64.png"
  },
  {
  "name": "Jersey",
  "dialCode": "+44",
  "isoCode": "JE",
  "flag": "https://www.countryflags.io/JE/flat/64.png"
  },
  {
  "name": "Jordan",
  "dialCode": "+962",
  "isoCode": "JO",
  "flag": "https://www.countryflags.io/JO/flat/64.png"
  },
  {
  "name": "Kazakhstan",
  "dialCode": "+77",
  "isoCode": "KZ",
  "flag": "https://www.countryflags.io/KZ/flat/64.png"
  },
  {
  "name": "Kenya",
  "dialCode": "+254",
  "isoCode": "KE",
  "flag": "https://www.countryflags.io/KE/flat/64.png"
  },
  {
  "name": "Kiribati",
  "dialCode": "+686",
  "isoCode": "KI",
  "flag": "https://www.countryflags.io/KI/flat/64.png"
  },
  {
  "name": "Korea, Democratic People's Republic of Korea",
  "dialCode": "+850",
  "isoCode": "KP",
  "flag": "https://www.countryflags.io/KP/flat/64.png"
  },
  {
  "name": "Korea, Republic of South Korea",
  "dialCode": "+82",
  "isoCode": "KR",
  "flag": "https://www.countryflags.io/KR/flat/64.png"
  },
  {
  "name": "Kosovo",
  "dialCode": "+383",
  "isoCode": "XK",
  "flag": "https://www.countryflags.io/XK/flat/64.png"
  },
  {
  "name": "Kuwait",
  "dialCode": "+965",
  "isoCode": "KW",
  "flag": "https://www.countryflags.io/KW/flat/64.png"
  },
  {
  "name": "Kyrgyzstan",
  "dialCode": "+996",
  "isoCode": "KG",
  "flag": "https://www.countryflags.io/KG/flat/64.png"
  },
  {
  "name": "Laos",
  "dialCode": "+856",
  "isoCode": "LA",
  "flag": "https://www.countryflags.io/LA/flat/64.png"
  },
  {
  "name": "Latvia",
  "dialCode": "+371",
  "isoCode": "LV",
  "flag": "https://www.countryflags.io/LV/flat/64.png"
  },
  {
  "name": "Lebanon",
  "dialCode": "+961",
  "isoCode": "LB",
  "flag": "https://www.countryflags.io/LB/flat/64.png"
  },
  {
  "name": "Lesotho",
  "dialCode": "+266",
  "isoCode": "LS",
  "flag": "https://www.countryflags.io/LS/flat/64.png"
  },
  {
  "name": "Liberia",
  "dialCode": "+231",
  "isoCode": "LR",
  "flag": "https://www.countryflags.io/LR/flat/64.png"
  },
  {
  "name": "Libya",
  "dialCode": "+218",
  "isoCode": "LY",
  "flag": "https://www.countryflags.io/LY/flat/64.png"
  },
  {
  "name": "Liechtenstein",
  "dialCode": "+423",
  "isoCode": "LI",
  "flag": "https://www.countryflags.io/LI/flat/64.png"
  },
  {
  "name": "Lithuania",
  "dialCode": "+370",
  "isoCode": "LT",
  "flag": "https://www.countryflags.io/LT/flat/64.png"
  },
  {
  "name": "Luxembourg",
  "dialCode": "+352",
  "isoCode": "LU",
  "flag": "https://www.countryflags.io/LU/flat/64.png"
  },
  {
  "name": "Macau",
  "dialCode": "+853",
  "isoCode": "MO",
  "flag": "https://www.countryflags.io/MO/flat/64.png"
  },
  {
  "name": "Madagascar",
  "dialCode": "+261",
  "isoCode": "MG",
  "flag": "https://www.countryflags.io/MG/flat/64.png"
  },
  {
  "name": "Malawi",
  "dialCode": "+265",
  "isoCode": "MW",
  "flag": "https://www.countryflags.io/MW/flat/64.png"
  },
  {
  "name": "Malaysia",
  "dialCode": "+60",
  "isoCode": "MY",
  "flag": "https://www.countryflags.io/MY/flat/64.png"
  },
  {
  "name": "Maldives",
  "dialCode": "+960",
  "isoCode": "MV",
  "flag": "https://www.countryflags.io/MV/flat/64.png"
  },
  {
  "name": "Mali",
  "dialCode": "+223",
  "isoCode": "ML",
  "flag": "https://www.countryflags.io/ML/flat/64.png"
  },
  {
  "name": "Malta",
  "dialCode": "+356",
  "isoCode": "MT",
  "flag": "https://www.countryflags.io/MT/flat/64.png"
  },
  {
  "name": "Marshall Islands",
  "dialCode": "+692",
  "isoCode": "MH",
  "flag": "https://www.countryflags.io/MH/flat/64.png"
  },
  {
  "name": "Martinique",
  "dialCode": "+596",
  "isoCode": "MQ",
  "flag": "https://www.countryflags.io/MQ/flat/64.png"
  },
  {
  "name": "Mauritania",
  "dialCode": "+222",
  "isoCode": "MR",
  "flag": "https://www.countryflags.io/MR/flat/64.png"
  },
  {
  "name": "Mauritius",
  "dialCode": "+230",
  "isoCode": "MU",
  "flag": "https://www.countryflags.io/MU/flat/64.png"
  },
  {
  "name": "Mayotte",
  "dialCode": "+262",
  "isoCode": "YT",
  "flag": "https://www.countryflags.io/YT/flat/64.png"
  },
  {
  "name": "Mexico",
  "dialCode": "+52",
  "isoCode": "MX",
  "flag": "https://www.countryflags.io/MX/flat/64.png"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dialCode": "+691",
  "isoCode": "FM",
  "flag": "https://www.countryflags.io/FM/flat/64.png"
  },
  {
  "name": "Moldova",
  "dialCode": "+373",
  "isoCode": "MD",
  "flag": "https://www.countryflags.io/MD/flat/64.png"
  },
  {
  "name": "Monaco",
  "dialCode": "+377",
  "isoCode": "MC",
  "flag": "https://www.countryflags.io/MC/flat/64.png"
  },
  {
  "name": "Mongolia",
  "dialCode": "+976",
  "isoCode": "MN",
  "flag": "https://www.countryflags.io/MN/flat/64.png"
  },
  {
  "name": "Montenegro",
  "dialCode": "+382",
  "isoCode": "ME",
  "flag": "https://www.countryflags.io/ME/flat/64.png"
  },
  {
  "name": "Montserrat",
  "dialCode": "+1664",
  "isoCode": "MS",
  "flag": "https://www.countryflags.io/MS/flat/64.png"
  },
  {
  "name": "Morocco",
  "dialCode": "+212",
  "isoCode": "MA",
  "flag": "https://www.countryflags.io/MA/flat/64.png"
  },
  {
  "name": "Mozambique",
  "dialCode": "+258",
  "isoCode": "MZ",
  "flag": "https://www.countryflags.io/MZ/flat/64.png"
  },
  {
  "name": "Myanmar",
  "dialCode": "+95",
  "isoCode": "MM",
  "flag": "https://www.countryflags.io/MM/flat/64.png"
  },
  {
  "name": "Namibia",
  "dialCode": "+264",
  "isoCode": "NA",
  "flag": "https://www.countryflags.io/NA/flat/64.png"
  },
  {
  "name": "Nauru",
  "dialCode": "+674",
  "isoCode": "NR",
  "flag": "https://www.countryflags.io/NR/flat/64.png"
  },
  {
  "name": "Nepal",
  "dialCode": "+977",
  "isoCode": "NP",
  "flag": "https://www.countryflags.io/NP/flat/64.png"
  },
  {
  "name": "Netherlands",
  "dialCode": "+31",
  "isoCode": "NL",
  "flag": "https://www.countryflags.io/NL/flat/64.png"
  },
  {
  "name": "Netherlands Antilles",
  "dialCode": "+599",
  "isoCode": "AN",
  "flag": "https://www.countryflags.io/AN/flat/64.png"
  },
  {
  "name": "New Caledonia",
  "dialCode": "+687",
  "isoCode": "NC",
  "flag": "https://www.countryflags.io/NC/flat/64.png"
  },
  {
  "name": "New Zealand",
  "dialCode": "+64",
  "isoCode": "NZ",
  "flag": "https://www.countryflags.io/NZ/flat/64.png"
  },
  {
  "name": "Nicaragua",
  "dialCode": "+505",
  "isoCode": "NI",
  "flag": "https://www.countryflags.io/NI/flat/64.png"
  },
  {
  "name": "Niger",
  "dialCode": "+227",
  "isoCode": "NE",
  "flag": "https://www.countryflags.io/NE/flat/64.png"
  },
  {
  "name": "Nigeria",
  "dialCode": "+234",
  "isoCode": "NG",
  "flag": "https://www.countryflags.io/NG/flat/64.png"
  },
  {
  "name": "Niue",
  "dialCode": "+683",
  "isoCode": "NU",
  "flag": "https://www.countryflags.io/NU/flat/64.png"
  },
  {
  "name": "Norfolk Island",
  "dialCode": "+672",
  "isoCode": "NF",
  "flag": "https://www.countryflags.io/NF/flat/64.png"
  },
  {
  "name": "North Macedonia",
  "dialCode": "+389",
  "isoCode": "MK",
  "flag": "https://www.countryflags.io/MK/flat/64.png"
  },
  {
  "name": "Northern Mariana Islands",
  "dialCode": "+1670",
  "isoCode": "MP",
  "flag": "https://www.countryflags.io/MP/flat/64.png"
  },
  {
  "name": "Norway",
  "dialCode": "+47",
  "isoCode": "NO",
  "flag": "https://www.countryflags.io/NO/flat/64.png"
  },
  {
  "name": "Oman",
  "dialCode": "+968",
  "isoCode": "OM",
  "flag": "https://www.countryflags.io/OM/flat/64.png"
  },
  {
  "name": "Pakistan",
  "dialCode": "+92",
  "isoCode": "PK",
  "flag": "https://www.countryflags.io/PK/flat/64.png"
  },
  {
  "name": "Palau",
  "dialCode": "+680",
  "isoCode": "PW",
  "flag": "https://www.countryflags.io/PW/flat/64.png"
  },
  {
  "name": "Palestine",
  "dialCode": "+970",
  "isoCode": "PS",
  "flag": "https://www.countryflags.io/PS/flat/64.png"
  },
  {
  "name": "Panama",
  "dialCode": "+507",
  "isoCode": "PA",
  "flag": "https://www.countryflags.io/PA/flat/64.png"
  },
  {
  "name": "Papua New Guinea",
  "dialCode": "+675",
  "isoCode": "PG",
  "flag": "https://www.countryflags.io/PG/flat/64.png"
  },
  {
  "name": "Paraguay",
  "dialCode": "+595",
  "isoCode": "PY",
  "flag": "https://www.countryflags.io/PY/flat/64.png"
  },
  {
  "name": "Peru",
  "dialCode": "+51",
  "isoCode": "PE",
  "flag": "https://www.countryflags.io/PE/flat/64.png"
  },
  {
  "name": "Philippines",
  "dialCode": "+63",
  "isoCode": "PH",
  "flag": "https://www.countryflags.io/PH/flat/64.png"
  },
  {
  "name": "Pitcairn",
  "dialCode": "+872",
  "isoCode": "PN",
  "flag": "https://www.countryflags.io/PN/flat/64.png"
  },
  {
  "name": "Poland",
  "dialCode": "+48",
  "isoCode": "PL",
  "flag": "https://www.countryflags.io/PL/flat/64.png"
  },
  {
  "name": "Portugal",
  "dialCode": "+351",
  "isoCode": "PT",
  "flag": "https://www.countryflags.io/PT/flat/64.png"
  },
  {
  "name": "Puerto Rico",
  "dialCode": "+1939",
  "isoCode": "PR",
  "flag": "https://www.countryflags.io/PR/flat/64.png"
  },
  {
  "name": "Qatar",
  "dialCode": "+974",
  "isoCode": "QA",
  "flag": "https://www.countryflags.io/QA/flat/64.png"
  },
  {
  "name": "Reunion",
  "dialCode": "+262",
  "isoCode": "RE",
  "flag": "https://www.countryflags.io/RE/flat/64.png"
  },
  {
  "name": "Romania",
  "dialCode": "+40",
  "isoCode": "RO",
  "flag": "https://www.countryflags.io/RO/flat/64.png"
  },
  {
  "name": "Russia",
  "dialCode": "+7",
  "isoCode": "RU",
  "flag": "https://www.countryflags.io/RU/flat/64.png"
  },
  {
  "name": "Rwanda",
  "dialCode": "+250",
  "isoCode": "RW",
  "flag": "https://www.countryflags.io/RW/flat/64.png"
  },
  {
  "name": "Saint Barthelemy",
  "dialCode": "+590",
  "isoCode": "BL",
  "flag": "https://www.countryflags.io/BL/flat/64.png"
  },
  {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "dialCode": "+290",
  "isoCode": "SH",
  "flag": "https://www.countryflags.io/SH/flat/64.png"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dialCode": "+1869",
  "isoCode": "KN",
  "flag": "https://www.countryflags.io/KN/flat/64.png"
  },
  {
  "name": "Saint Lucia",
  "dialCode": "+1758",
  "isoCode": "LC",
  "flag": "https://www.countryflags.io/LC/flat/64.png"
  },
  {
  "name": "Saint Martin",
  "dialCode": "+590",
  "isoCode": "MF",
  "flag": "https://www.countryflags.io/MF/flat/64.png"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dialCode": "+508",
  "isoCode": "PM",
  "flag": "https://www.countryflags.io/PM/flat/64.png"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dialCode": "+1784",
  "isoCode": "VC",
  "flag": "https://www.countryflags.io/VC/flat/64.png"
  },
  {
  "name": "Samoa",
  "dialCode": "+685",
  "isoCode": "WS",
  "flag": "https://www.countryflags.io/WS/flat/64.png"
  },
  {
  "name": "San Marino",
  "dialCode": "+378",
  "isoCode": "SM",
  "flag": "https://www.countryflags.io/SM/flat/64.png"
  },
  {
  "name": "Sao Tome and Principe",
  "dialCode": "+239",
  "isoCode": "ST",
  "flag": "https://www.countryflags.io/ST/flat/64.png"
  },
  {
  "name": "Saudi Arabia",
  "dialCode": "+966",
  "isoCode": "SA",
  "flag": "https://www.countryflags.io/SA/flat/64.png"
  },
  {
  "name": "Senegal",
  "dialCode": "+221",
  "isoCode": "SN",
  "flag": "https://www.countryflags.io/SN/flat/64.png"
  },
  {
  "name": "Serbia",
  "dialCode": "+381",
  "isoCode": "RS",
  "flag": "https://www.countryflags.io/RS/flat/64.png"
  },
  {
  "name": "Seychelles",
  "dialCode": "+248",
  "isoCode": "SC",
  "flag": "https://www.countryflags.io/SC/flat/64.png"
  },
  {
  "name": "Sierra Leone",
  "dialCode": "+232",
  "isoCode": "SL",
  "flag": "https://www.countryflags.io/SL/flat/64.png"
  },
  {
  "name": "Singapore",
  "dialCode": "+65",
  "isoCode": "SG",
  "flag": "https://www.countryflags.io/SG/flat/64.png"
  },
  {
  "name": "Sint Maarten",
  "dialCode": "+1721",
  "isoCode": "SX",
  "flag": "https://www.countryflags.io/SX/flat/64.png"
  },
  {
  "name": "Slovakia",
  "dialCode": "+421",
  "isoCode": "SK",
  "flag": "https://www.countryflags.io/SK/flat/64.png"
  },
  {
  "name": "Slovenia",
  "dialCode": "+386",
  "isoCode": "SI",
  "flag": "https://www.countryflags.io/SI/flat/64.png"
  },
  {
  "name": "Solomon Islands",
  "dialCode": "+677",
  "isoCode": "SB",
  "flag": "https://www.countryflags.io/SB/flat/64.png"
  },
  {
  "name": "Somalia",
  "dialCode": "+252",
  "isoCode": "SO",
  "flag": "https://www.countryflags.io/SO/flat/64.png"
  },
  {
  "name": "South Africa",
  "dialCode": "+27",
  "isoCode": "ZA",
  "flag": "https://www.countryflags.io/ZA/flat/64.png"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dialCode": "+500",
  "isoCode": "GS",
  "flag": "https://www.countryflags.io/GS/flat/64.png"
  },
  {
  "name": "South Sudan",
  "dialCode": "+211",
  "isoCode": "SS",
  "flag": "https://www.countryflags.io/SS/flat/64.png"
  },
  {
  "name": "Spain",
  "dialCode": "+34",
  "isoCode": "ES",
  "flag": "https://www.countryflags.io/ES/flat/64.png"
  },
  {
  "name": "Sri Lanka",
  "dialCode": "+94",
  "isoCode": "LK",
  "flag": "https://www.countryflags.io/LK/flat/64.png"
  },
  {
  "name": "Sudan",
  "dialCode": "+249",
  "isoCode": "SD",
  "flag": "https://www.countryflags.io/SD/flat/64.png"
  },
  {
  "name": "Suriname",
  "dialCode": "+597",
  "isoCode": "SR",
  "flag": "https://www.countryflags.io/SR/flat/64.png"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dialCode": "+47",
  "isoCode": "SJ",
  "flag": "https://www.countryflags.io/SJ/flat/64.png"
  },
  {
  "name": "Sweden",
  "dialCode": "+46",
  "isoCode": "SE",
  "flag": "https://www.countryflags.io/SE/flat/64.png"
  },
  {
  "name": "Switzerland",
  "dialCode": "+41",
  "isoCode": "CH",
  "flag": "https://www.countryflags.io/CH/flat/64.png"
  },
  {
  "name": "Syrian Arab Republic",
  "dialCode": "+963",
  "isoCode": "SY",
  "flag": "https://www.countryflags.io/SY/flat/64.png"
  },
  {
  "name": "Taiwan",
  "dialCode": "+886",
  "isoCode": "TW",
  "flag": "https://www.countryflags.io/TW/flat/64.png"
  },
  {
  "name": "Tajikistan",
  "dialCode": "+992",
  "isoCode": "TJ",
  "flag": "https://www.countryflags.io/TJ/flat/64.png"
  },
  {
  "name": "Tanzania, United Republic of Tanzania",
  "dialCode": "+255",
  "isoCode": "TZ",
  "flag": "https://www.countryflags.io/TZ/flat/64.png"
  },
  {
  "name": "Thailand",
  "dialCode": "+66",
  "isoCode": "TH",
  "flag": "https://www.countryflags.io/TH/flat/64.png"
  },
  {
  "name": "Timor-Leste",
  "dialCode": "+670",
  "isoCode": "TL",
  "flag": "https://www.countryflags.io/TL/flat/64.png"
  },
  {
  "name": "Togo",
  "dialCode": "+228",
  "isoCode": "TG",
  "flag": "https://www.countryflags.io/TG/flat/64.png"
  },
  {
  "name": "Tokelau",
  "dialCode": "+690",
  "isoCode": "TK",
  "flag": "https://www.countryflags.io/TK/flat/64.png"
  },
  {
  "name": "Tonga",
  "dialCode": "+676",
  "isoCode": "TO",
  "flag": "https://www.countryflags.io/TO/flat/64.png"
  },
  {
  "name": "Trinidad and Tobago",
  "dialCode": "+1868",
  "isoCode": "TT",
  "flag": "https://www.countryflags.io/TT/flat/64.png"
  },
  {
  "name": "Tunisia",
  "dialCode": "+216",
  "isoCode": "TN",
  "flag": "https://www.countryflags.io/TN/flat/64.png"
  },
  {
  "name": "Turkey",
  "dialCode": "+90",
  "isoCode": "TR",
  "flag": "https://www.countryflags.io/TR/flat/64.png"
  },
  {
  "name": "Turkmenistan",
  "dialCode": "+993",
  "isoCode": "TM",
  "flag": "https://www.countryflags.io/TM/flat/64.png"
  },
  {
  "name": "Turks and Caicos Islands",
  "dialCode": "+1649",
  "isoCode": "TC",
  "flag": "https://www.countryflags.io/TC/flat/64.png"
  },
  {
  "name": "Tuvalu",
  "dialCode": "+688",
  "isoCode": "TV",
  "flag": "https://www.countryflags.io/TV/flat/64.png"
  },
  {
  "name": "Uganda",
  "dialCode": "+256",
  "isoCode": "UG",
  "flag": "https://www.countryflags.io/UG/flat/64.png"
  },
  {
  "name": "Ukraine",
  "dialCode": "+380",
  "isoCode": "UA",
  "flag": "https://www.countryflags.io/UA/flat/64.png"
  },
  {
  "name": "United Arab Emirates",
  "dialCode": "+971",
  "isoCode": "AE",
  "flag": "https://www.countryflags.io/AE/flat/64.png"
  },
  {
  "name": "United Kingdom",
  "dialCode": "+44",
  "isoCode": "GB",
  "flag": "https://www.countryflags.io/GB/flat/64.png"
  },
  {
  "name": "United States",
  "dialCode": "+1",
  "isoCode": "US",
  "flag": "https://www.countryflags.io/US/flat/64.png"
  },
  {
  "name": "Uruguay",
  "dialCode": "+598",
  "isoCode": "UY",
  "flag": "https://www.countryflags.io/UY/flat/64.png"
  },
  {
  "name": "Uzbekistan",
  "dialCode": "+998",
  "isoCode": "UZ",
  "flag": "https://www.countryflags.io/UZ/flat/64.png"
  },
  {
  "name": "Vanuatu",
  "dialCode": "+678",
  "isoCode": "VU",
  "flag": "https://www.countryflags.io/VU/flat/64.png"
  },
  {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "dialCode": "+58",
  "isoCode": "VE",
  "flag": "https://www.countryflags.io/VE/flat/64.png"
  },
  {
  "name": "Vietnam",
  "dialCode": "+84",
  "isoCode": "VN",
  "flag": "https://www.countryflags.io/VN/flat/64.png"
  },
  {
  "name": "Virgin Islands, British",
  "dialCode": "+1284",
  "isoCode": "VG",
  "flag": "https://www.countryflags.io/VG/flat/64.png"
  },
  {
  "name": "Virgin Islands, U.S.",
  "dialCode": "+1340",
  "isoCode": "VI",
  "flag": "https://www.countryflags.io/VI/flat/64.png"
  },
  {
  "name": "Wallis and Futuna",
  "dialCode": "+681",
  "isoCode": "WF",
  "flag": "https://www.countryflags.io/WF/flat/64.png"
  },
  {
  "name": "Yemen",
  "dialCode": "+967",
  "isoCode": "YE",
  "flag": "https://www.countryflags.io/YE/flat/64.png"
  },
  {
  "name": "Zambia",
  "dialCode": "+260",
  "isoCode": "ZM",
  "flag": "https://www.countryflags.io/ZM/flat/64.png"
  },
  {
  "name": "Zimbabwe",
  "dialCode": "+263",
  "isoCode": "ZW",
  "flag": "https://www.countryflags.io/ZW/flat/64.png"
  }
  ]

