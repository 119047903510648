import React, { useEffect, useRef, useState, memo, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  administrator,
  blocklist,
  channel,
  deletes,
  discussion,
  manipulate,
  permission,
  reactions,
  sign,
  subscribers,
  user1,
  closeModel,
  downloading,
  searchVector,
  bookmark,
  videoPlay,
  searchIcon,
  images5,
} from "../../Images/images";
import ChatboxInner from "./ChatboxInner";
import { socket } from "../../store/SocketIo";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Moment from "react-moment";
import moment from "moment";
import {
  allPinMessages,
  allPinMessagess,
  removePinMessages,
  unPinAllMessages,
} from "../../reducer/PinMessage";
import {
  Description,
  FullChat,
  GroupHeading,
  getGroupChannelId,
  GCShowMediaFiles,
  LikeUpdate,
  showPreviewImage,
  SubscriptionCount,
  initialShow,
  UpdatedList,
  GCChatlistData,
} from "../../reducer/GetChat";
import { updateGroupInfo } from "../../reducer/EditGroupInfo";
import { saveAs } from "file-saver";
import { updateGroupChannelPermission } from "../../reducer/GcPermissionUpdate";
import { GCDelete, updateGcSetting } from "../../reducer/GcSetting";
import {
  GetTotalGCReaction,
  UpdateReactionList,
  getSelectedEmojiList,
} from "../../reducer/GCReaction";
import Slider from "rc-slider";
import {
  GCBlockMemberList,
  GroupChannelUserBlockMemberID,
  globalBlockListUnblockMemberStatus,
  updateMemberStatus,
  updateUnblockMemberStatus,
} from "../../reducer/GCMemberBlockUnblock";
import {
  reportUserGCUserInfoMediaApi,
  userAddReport,
  userReportListData,
} from "../../reducer/ReportUser";
import {
  CallAPI,
  debounce,
  emmitToSocket,
  extractUsernameAndText,
  highlightUsernames,
} from "../../common";
import { getDummyUser } from "../../reducer/DummyUserReducer";
import GCUserVideoPlayPause from "../VideoPlayPause/GCUserVideoPlayPause";
import GCTotalVideoPlayPause from "../VideoPlayPause/GCTotalVideoPlayPause";
import { ClipLoader } from "react-spinners";
import { Bars } from "react-loader-spinner";
import {
  DetailsChannelGroupApi,
  groupChannelDetailsData,
} from "../../reducer/DetailsChannelGroup";
import { getUserUpdateProfileDetails } from "../../reducer/EditProfileReducer/UpdateProfile";
import {
  chatList,
  checkUserRoleAddCondition,
  mentorDetails,
  pinMessageList,
  setConversationID,
  setMediaURL,
  storeMenoteChatInitialData,
  storeProfilePicUrl,
} from "../../reducer/MentorReducer";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import { TagUser } from "../../common/TagUser";
import { getGroupChannelID, getGroupChannelType } from "../GlobalLocalStorage";
import { GCUserInfoMediaApi } from "../../reducer/GCUserInfoMedia";
const ChatContent = ({
  title,
  member,
  show,
  setShow,
  setPage,
  page,
  stage,
  setPinMesssageState,
  PinMessageState,
  showCheckbox,
  setShowCheckbox,
  selectedMessage,
  setSelectedMessage,
  setUpdateReactionListed,
  updateReactionListed,
  showChatContent,
  savedMessageState,
  setSavedMesssageState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const userProfileRef = useRef(null);
  const openProfileRef = useRef(null);
  const openSettingRef = useRef(null);
  const openCommentRef = useRef(null);
  const containerRef = useRef(null);
  const [singleFilterData, setSingleFilterData] = useState();
  const [editValue, setEditValue] = useState();
  const [editValueClick, setEditValueClick] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [reply, setReply] = useState(false);
  const [gcSearchMessageList, setGCSearchMessageList] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [forwardList, setForwardList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [userOpenComment, setUserOpenComment] = useState(false);
  const [userOpenSetting, setUserOpenSetting] = useState(false);
  const [userOpenProfile, setUserOpenProfile] = useState(false);
  const [reportUserUpdateStatus, setReportUserUpdateStatus] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [reportReasonID, setReportReasonID] = useState();
  const [blockUserStatusUpdate, setUserBlockStatusUpdate] = useState(false);
  const [updateSettingGC, setUpdateSettingGC] = useState(false);
  const [menterListData, setMenterListData] = useState(false);
  const [
    allowDisscussionUpdatePermission,
    setAllowDisscussionUpdatePermission,
  ] = useState();
  const [selectMode, setSelectMode] = useState(false);
  const [selectedMentMessages, setSelectedMentMessages] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [quote, setQuote] = useState(null);
  const [profileUrl, setProfileUrl] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [updatedGCListAfterDeleteGC, setUpdatedGCListAfterDeleteGC] =
    useState(false);
  const [
    commentAllowDiscussionPermission,
    setCommentAllowDiscussionPermission,
  ] = useState();
  const [showMessageForwordPopup, setShowMessageForwordPopup] = useState(false);
  const [selectedDummyUser, setSelectedDummyUser] = useState({});
  const [chatHistoryStatus, setChatHistoryStatus] = useState(0);

  // Forword saved message condition
  const [savedMessageForwordConditon, setSavedMessageForwordConditon] =
    useState(false);

  // Group/Channel setting update

  const { statusGCSettingUpdateLoader } = useSelector(
    (state) => state?.GcPermissionUpdate
  );

  // For Notification

  const urlSearchParamsData = new URLSearchParams(window.location.search);
  const notificationData = urlSearchParamsData.get("notificationData");

  // GC info check permission
  const gcSettingPermissionData = JSON.parse(
    localStorage.getItem("GCPermissonCustmValue")
  );
  const userRoleSlugSettingPermission = localStorage.getItem("user_role_slug");
  const [permissionInnerShowPopup, setPermissionInnerShowPopup] =
    useState(false);
  const [permissionGroupInnerShowPopup, setPermissionGroupInnerShowPopup] =
    useState(false);
  const [permissionChannelInnerShowPopup, setPermissionChannelInnerShowPopup] =
    useState(false);
  const [commentList, setCommentList] = useState([]);
  const chat = useSelector((state) => state?.GetChat?.Chats);
  const [loadMore, setLoadMore] = useState(false);
  const selectedEmojiList = useSelector(
    (state) => state?.GCReactionList?.selectedEmojiList
  );

  // Mentor sec profile pic url
  const mentorProfilePicUrl = useSelector(
    (state) => state?.MentorList?.mentorProfilePicUrl
  );
  // Dummy User get firstname and last name  only get first character

  const getFirstCharacter =
    Object.keys(selectedDummyUser)?.length === 0
      ? "U"
      : `${selectedDummyUser?.Firstname?.charAt(
          0
        )}${selectedDummyUser?.Lastname?.charAt(0)}`;

  //forward list
  const mentors = useSelector((state) => state?.MentorList?.mentors);
  const [allEmoiisList, setAllEmoijisList] = useState([]);
  const handleEmojiChange = (item) => {
    let out = updateReactionListed?.find(
      (data) => data?.ReactionID == item?.ReactionID
    );
    if (out == undefined) {
      setUpdateReactionListed([...updateReactionListed, item]);
    } else {
      let d = updateReactionListed?.filter(
        (data) => data?.ReactionID !== item?.ReactionID
      );
      setUpdateReactionListed(d);
    }
  };
  const GtfConnectId = localStorage?.getItem("gtfconnectid");
  const UserID = localStorage?.getItem("UserID");
  const roleID = localStorage?.getItem("roleID");

  const LoginUser = useSelector(state => state?.UserData?.user)
  const GCUserInfoMedia = useSelector(
    (state) => state?.GCUserInfoMedia?.GetGCUserInfoMedia
  );

  const getGroupHeadingFromExclusive = useSelector(
    (state) => state?.GetChat?.GroupHeading
  );
  const getDataFromExclusive = useSelector(
    (state) => state?.GetChat?.chatlistData
  );
  const dummyUsers = useSelector((state) => state?.DummyUserReducer?.dummyUser);
  const GCuserBlockMemberID = useSelector(
    (state) => state?.GCBlockUser?.GcBlockUserMemberID
  );
  const gcID = useSelector((state) => state?.GetChat?.channelgroupid);
  const getGCBlockMemberList = useSelector(
    (state) => state?.GCBlockUser?.GCBlockUserList?.data
  );

  const subscriberAPI = useSelector(
    (state) => state?.SubscriberReducer?.subscriberList
  );

  //chatLeftData
  const chatLeftShowList = useSelector(
    (state) => state?.GetChat?.upDatedlistData
  );

  // Group and channel base url for preview image
  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);

  // Mentor image url for preview image
  const mentorMediaURL = useSelector((state) => state?.MentorList?.mediaURL);

  // Saved message url for preview image
  const savedMessageMediaFilesBaseUrl = useSelector(
    (state) => state?.SavedMessagess?.storeBaseUrl?.base_url
  );

  // Preview image get path and storage
  const showPreviewImagess = useSelector(
    (state) => state?.GetChat?.showZoomImage
  );

  const imgSrc = `${mentorMediaURL ? mentorMediaURL : baseUrlMedia?.mediaUrl}${
    showPreviewImagess?.StoragePath
  }${showPreviewImagess?.FileName}`;

  const showGCMediaFiles = useSelector(
    (state) => state?.GetChat?.GCShowMediaFilesData?.data
  );
  const reportUserListData = useSelector(
    (state) => state?.ReportUser?.reportUserList?.data
  );
  const channelGroupID = useSelector((state) => state?.GetChat?.channelgroupid);
  const GCReactionList = useSelector((state) => state?.GCReactionList);
  useEffect(() => {
    setAllEmoijisList(GCReactionList?.totalReactionList);
  }, [GCReactionList?.totalReactionList]);

  const [channelName, setChannelName] = useState("");
  const [channelDescription, setChannelDescription] = useState();
  const groupHeading = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData?.gc_info
  );
  const GCMemberIDUserReport = useSelector(
    (state) => state?.GCBlockUser?.GcBlockUserMemberID
  );
  const gc_setting = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
  );
  const manipulatePercent = gc_setting?.ManipulateViewsPercent;
  const [manipulateValue, setManupulateValue] = useState(manipulatePercent);
  const Type = useSelector(
    (state) => state?.GetChat?.groupinformation?.group?.Type
  );

  // mentor details
  const mentDetails = useSelector((state) => state?.MentorList?.details);
  const chatConversationID = useSelector(
    (state) => state?.MentorList?.ConversationID
  );
  const channelAccessType = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_info?.AccessType
  );
  const channelAllowDisscussion = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.AllowDiscussion
  );
  const sharingContent = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.RestrictSharingContent
  );
  // Mentor list profile pic url

  const mentorListProfilePicUrl = useSelector(
    (state) => state?.GetChat?.upDatedlistData
  );

  const Reaction = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.EnableReactions
  );
  const GC_AdminList = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData?.gc_admin
  );
  const GC_AdminListCheckUrl = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData
  );

  const mentorChats = useSelector((state) => state?.MentorList?.mentorChatList);

  const groupGCScroll = useRef();

  const [gcReactionListData, setGcReactionListData] = useState(1);
  function handleScroll(e) {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setGcReactionListData(gcReactionListData + 1);
    }
  }

  const GC_permission = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData?.gc_permission
  );

  const PinMessagechannel = GC_permission?.PinMessage;
  const EmbedLinkschannel = GC_permission?.EmbedLinks;
  const SendStickerGIFchannel = GC_permission?.SendStickerGIF;
  const SendMediachannel = GC_permission?.SendMedia;
  const SendMessagechannel = GC_permission?.SendMessage;
  const SlowModechannel = GC_permission?.SlowMode;
  const [channelAccess, setChannelAccess] = useState();
  const [groupAccess, setGroupAccess] = useState();
  const [channelDisscussion, setChannelDisscussion] = useState(
    channelAllowDisscussion
  );

  // comment sec tag user

  // const [tagUsersComment, setTagUsersComment] = useState([]);
  // const [filteredUsers, setFilterdUsers] = useState(tagUsersComment);
  // const [showTagUsers, setShowTagUsers] = useState(false);
  // const [tagUserImgUrl, settagUserImgUrl] = useState();

  const [channelSignedMsg, setChannelSignedMsg] = useState();
  const [channelEnableManipulateViews, setChannelEnableManipulateViews] =
    useState();
  const [channelSendMessage, setChannelSendMessage] = useState();
  const [channelEmbedLinks, setChannelEmbedLinks] = useState();
  const [channelPinMessage, setChannelPinMessage] = useState();
  const [channelSendStickerGIF, setChannelSendStickerGIF] = useState();
  const [channelSendMedia, setChannelSendMedia] = useState();
  const [channelSlowMode, setChannelSlowMode] = useState();
  const [channelReaction, setChannelReaction] = useState();
  const [channelSharingContent, setChannelSharingContent] = useState();
  const [groupSendMessage, setGroupSendMessage] = useState();
  const [groupEmbedLinks, setGroupEmbedLinks] = useState();
  const [groupPinMessage, setGroupPinMessage] = useState();
  const [groupSharingContent, setGroupSharingContent] = useState();
  const [groupSendStickerGIF, setGroupSendStickerGIF] = useState();
  const [groupSendMedia, setGroupSendMedia] = useState();
  const [groupSlowMode, setGroupSlowMode] = useState();
  const [groupReaction, setGroupReaction] = useState();
  const [searchMessage, setSearchMessage] = useState({});
  const [selectMessageId, setSelectMessageId] = useState([]);
  const groupChannelDetails = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData
  );
  const groupChannelDetailsStatus = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.status
  );
  const [imageName, setimageName] = useState("");
  const [groupImage, setGroupImage] = useState();
  const [
    checkSubscriptionPlanValueInDays,
    setCheckSubscriptionPlanValueInDays,
  ] = useState();
  const [storeGCSubscriptionPlanID, setStoreGCSubscriptionPlanID] = useState();

  // Getting Tag user data
  const {
    commentData,
    setCommentData,
    setTagUsersComment,
    filteredUsers,
    showTagUsers,
    tagUserImgUrl,
    settagUserImgUrl,
    handleChangeComment,
    handleUserClick,
    formatUserMentions,
  } = TagUser();

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#2fb463",
  };

  // Handle comment section large message
  const COMMENT_CHARACTER_LIMIT = 100;
  function LargeComment({ comment }) {
    const [showFullComment, setShowFullComment] = useState(false);
    // Truncate or show the full comment based on the state.
    const truncatedComment = showFullComment
      ? comment
      : comment.slice(0, COMMENT_CHARACTER_LIMIT);

    return (
      <p>
        {/* {truncatedComment} */}
        {highlightUsernames(truncatedComment)}

        {comment.length > COMMENT_CHARACTER_LIMIT && (
          <span
            style={{
              paddingLeft: "10px",
              color: "green",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setShowFullComment(!showFullComment)}
          >
            {showFullComment ? "Read Less" : "Read More"}
          </span>
        )}
      </p>
    );
  }

  // //.

  useEffect(() => {
    if (groupReaction == 1 || channelReaction == 1) {
      dispatch(GetTotalGCReaction({ gcReactionListData, channelGroupID }));
    }
  }, [groupReaction, channelReaction, gcReactionListData]);

  useEffect(() => {
    groupChannelDetails?.gc_subscription_plan &&
      groupChannelDetails.gc_subscription_plan.forEach((i) => {
        setCheckSubscriptionPlanValueInDays(i?.subscription_plan);
        setStoreGCSubscriptionPlanID(i);
      });
  }, [groupChannelDetails]);

  // download image for group channel mentor
  const downloadImage = () => {
    let imageUrl = imgSrc;
    let fileName = showPreviewImagess?.FileName;
    saveAs(imageUrl, fileName);
  };

  // download image for saved message
  const savedMessageDownloadImage = () => {
    let imageUrl = `${savedMessageMediaFilesBaseUrl}${showPreviewImagess?.StoragePath}${showPreviewImagess?.FileName}`;
    let fileName = showPreviewImagess?.FileName;
    saveAs(imageUrl, fileName);
  };

  // download mentor preview image
  const mentorMessageDownloadImage = () => {
    let imageUrl = `${mentorMediaURL}${showPreviewImagess?.StoragePath}${showPreviewImagess?.FileName}`;
    let fileName = showPreviewImagess?.FileName;
    saveAs(imageUrl, fileName);
  };

  // handle GC delete confirm popup
  const getCheckGcType = localStorage.getItem("paymentGCType");
  const handleDeleteGC = () => {
    if (getCheckGcType === "channel") {
      dispatch(
        GCDelete({
          channelGroupID: channelGroupID || getGroupChannelID,
          setUpdatedGCListAfterDeleteGC,
        })
      );
    } else if (getCheckGcType === "group") {
      dispatch(
        GCDelete({
          channelGroupID: channelGroupID || getGroupChannelID,
          setUpdatedGCListAfterDeleteGC,
        })
      );
    }
    setOpenSetting(!openSetting);
  };

  const scrollBottom = useRef();
  const showCommentRef = useRef();
  const inputref = useRef();
  const commentDatas = useSelector((state) => state?.GetChat?.Chats);
  const inHome = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.inHome
  );

  useEffect(() => {
    socket.on("commentListData", (res) => {
      setCommentList(res.comment);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("commentted", (res) => {
      let vary = res?.data.chatDetail?.commentData;
      setCommentList(vary);
    });
  }, [socket]);

  const newTimeChatComment = new Date(
    singleFilterData?.CreatedAt
  ).toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  // show comment
  const ShowComment = (singleID) => {
    setSingleFilterData(singleID);
    socket.emit("commentList", {
      GroupChannelID: singleID.GroupChannelID,
      GroupChatID: singleID?.GroupChatID,
    });
    let closeSettingId = document.getElementById("gtf_inner");
    let CloseshowProfile = document.getElementById("showProfile");
    closeSettingId?.classList.add("width_calc");
    CloseshowProfile?.classList.add("open-comment");
    setGCSearchMessageList(false);
    setOpenProfile(false);
    setOpenSetting(false);
    setOpenComment(true);
    setUserOpenProfile(false);
    setUserOpenSetting(false);
    setUserOpenComment(true);
    setShowSearchPopup(false);
  };

  // close comment
  const CloseComment = () => {
    let closeSettingId = document.getElementById("gtf_inner");
    let CloseshowProfile = document.getElementById("showProfile");
    closeSettingId?.classList.remove("width_calc");
    CloseshowProfile?.classList.remove("open-comment");
    setOpenComment(false);
    setUserOpenComment(false);
    setShowSearchPopup(false);
  };

  // submit comment
  const submitComment = (e) => {
    e.preventDefault();
    if (commentData === "") {
      toast.error("Please Enter Some Comment");
    }

    if (editValueClick) {
      socket.emit("commentEdit", {
        CommentID: editValue?.CommentID,
        UserID: GtfConnectId,
        GroupChannelID: editValue?.GroupChannelID,
        GroupChatID: editValue?.GroupChatID,
        comment: formatUserMentions(commentData),
      });
    } else if (Object.keys(selectedDummyUser)) {
      socket.emit("comment", {
        DummyUserID: selectedDummyUser?.DummyUserID,
        UserID: GtfConnectId,
        GroupChannelID: singleFilterData?.GroupChannelID,
        GCMemberID: singleFilterData?.GCMemberID,
        GroupChatID: singleFilterData?.GroupChatID,
        comment: formatUserMentions(commentData),
      });
      socket.emit(
        "chatList",
        {
          userId: GtfConnectId,
          GroupChannelID: channelGroupID,
          page: 1,
        },
        (response) => {
          console.log("response",response);
          dispatch(FullChat(response?.data?.chatData?.rows));
        }
      );
    } else {
      socket.emit("comment", {
        UserID: GtfConnectId,
        GroupChannelID: singleFilterData?.GroupChannelID,
        GCMemberID: singleFilterData?.GCMemberID,
        GroupChatID: singleFilterData?.GroupChatID,
        comment: formatUserMentions(commentData),
      });
      socket.emit(
        "chatList",
        {
          userId: GtfConnectId,
          GroupChannelID: channelGroupID,
          page: 1,
        },
        (response) => {
          dispatch(FullChat(response?.data?.chatData?.rows));
        }
      );
    }
    setEditValueClick(false);
    setCommentData("");
  };

  useEffect(() => {
    scrollBottom.current &&
      scrollBottom.current?.scrollIntoView({ behavior: "smooth" });
  }, [commentList]);
  const editComment = (data) => {
    setEditValue(data);
    setCommentData(data?.comment);
    setEditValueClick(true);
  };

  // Delete comment
  const deleteComment = (data) => {
    socket.emit("commentDelete", {
      CommentID: data?.CommentID,
      UserID: data?.UserID,
      GroupChannelID: data?.GroupChannelID,
      GroupChatID: data?.GroupChatID,
    });
    // }
    toast.success("Comment deleted successfully..");
    socket.emit(
      "chatList",
      {
        userId: GtfConnectId,
        GroupChannelID: channelGroupID,
        page: 1,
      },
      (response) => {
        dispatch(FullChat(response?.data?.chatData?.rows));
      }
    );
  };

  useEffect(() => {
    socket.on("commentEditData", (res) => {
      let findIndex = chat?.findIndex(
        (item) =>
          parseInt(item?.GroupChatID) === res?.data?.commentList[0]?.GroupChatID
      );
      let newArr = [...chat];
      newArr[findIndex] = {
        ...newArr[findIndex],
        commentData: res?.data?.commentList,
      };
      dispatch(LikeUpdate(newArr));
      setCommentList(res?.data?.commentList);
    });
    socket.on("commentDeleteData", (res) => {
      setCommentList(res?.data?.commentList);
    });

    return () => {
      socket.off("commentEditData");
      socket.off("commentDeleteData");
    };
  }, [socket, chat]);

  const removePinMessage = (data) => {
    dispatch(removePinMessages(data, channelGroupID));
  };

  const unPinAllMessage = () => {
    dispatch(unPinAllMessages(channelGroupID));
  };

  useEffect(() => {
    const handler = (e) => {
      if (
        showCommentRef?.current &&
        !showCommentRef?.current?.contains(e.target)
      ) {
        setOpenComment(false);
        setOpenSetting(false);
        setOpenProfile(false);
        setGCSearchMessageList(false);
        setUserOpenComment(false);
        setUserOpenSetting(false);
        setUserOpenProfile(false);
        setShowEmoji(false);
        setReply(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const hiddenFileInput = useRef();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onFileSelect = (event) => {
    if (!event.target.files[0]?.type?.startsWith("image")) {
      toast.error("The profile image must be an image.");
    } else {
      setGroupImage(URL.createObjectURL(event.target.files[0]));
      setimageName(event.target.files[0]);
    }
  };

  // saved message forword
  const savedMessageForword = async () => {
    let data = {};
    mentDetails
      ? (data = {
          Action: "IndividualChat",
          IndividualChatID: selectedMentMessages?.map(
            (item) => item?.IndividualChatID
          ),
        })
      : (data = {
          GroupChannelID: selectedMessage?.[0]?.GroupChannelID,
          GroupChatID: selectedMessage?.map((item) => item?.GroupChatID),
          Action: "WithGcChat",
        });
    const response = await CallAPI("user/saved-message", "POST", {}, data);
    if (response.status === 200) {
      toast.success(response.message);
    }
    setSelectedMessage([]);
    setSelectedMentMessages([]);
    setSelectMode(false);
    setShowCheckbox(false);
  };

  // Handle popup when outside click pupup
  const getDynamicRef = () => {
    if (showSearchPopup) {
      return popupRef;
    } else if (openProfile) {
      return openProfileRef;
    } else if (openSetting) {
      return openSettingRef;
    } else if (openComment) {
      return openCommentRef;
    } else {
      return containerRef;
    }
  };

  // handle Message Chatlist right popup
  const handleOutsideClick = (event) => {
    const handleShowSearchPopup =
      showSearchPopup &&
      popupRef.current &&
      !popupRef.current.contains(event.target);
    const handleUserOpenProfilePopup =
      userOpenProfile &&
      userProfileRef.current &&
      !userProfileRef.current.contains(event.target);
    const handleOpenProfilePopup =
      openProfileRef &&
      openProfileRef.current &&
      !openProfileRef.current.contains(event.target);
    const handleOpenSettingPopup =
      openSettingRef &&
      openSettingRef.current &&
      !openSettingRef.current.contains(event.target);
    const handleOpenCommentPopup =
      openCommentRef &&
      openCommentRef.current &&
      !openCommentRef.current.contains(event.target);
    if (handleShowSearchPopup) {
      setShowSearchPopup(false);
      setSearchKey("");
      setMessageList([]);
    } else if (handleUserOpenProfilePopup) {
      setUserOpenProfile(false);
    } else if (handleOpenProfilePopup) {
      setOpenProfile(false);
    } else if (handleOpenSettingPopup) {
      setOpenSetting(false);
    } else if (handleOpenCommentPopup) {
      setOpenComment(false);
    }
  };
  // handle Message Chatlist right popup
  useEffect(() => {
    const popupElement = popupRef.current;
    const handlePopupClick = (event) => {
      event.stopPropagation();
    };
    document.addEventListener("mousedown", handleOutsideClick);
    if (popupElement) {
      popupElement.addEventListener("mousedown", handlePopupClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      if (popupElement) {
        popupElement.removeEventListener("mousedown", handlePopupClick);
      }
    };
  }, [showSearchPopup, userOpenProfile, openProfile, openSetting, openComment]);

  // *****************************Slider Css for manipulate Views***********************

  const dotStyle = {
    borderColor: "#2fb463",
    borderWidth: "2px",
    backgroundColor: "#fff",
    width: "20px",
    height: "20px",
    marginBottom: "-7px",
  };

  const activeDotStyle = {
    borderColor: "#2fb463",
    borderWidth: "2px",
    backgroundColor: "#2fb463",
    width: "20px",
    height: "20px",
  };

  const trackStyle = {
    backgroundColor: "#2fb463",
    height: "5px",
  };

  const marks = {
    10: {
      label: "10%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    20: {
      label: "20%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    30: {
      label: "30%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    40: {
      label: "40%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    50: {
      label: "50%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    60: {
      label: "60%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    70: {
      label: "70%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    80: {
      label: "80%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    90: {
      label: "90%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
    100: {
      label: "100%",
      style: { fontSize: "17px", top: "3px", color: "black" },
    },
  };

  // ....

  useEffect(() => {
    if (mentors?.length > 0) {
      setForwardList(mentors);
    }
  }, [mentors]);

  const markStyle = {
    fontSize: "30px",
    backgroundColor: "#2fb463",
    textAlign: "center",
    top: "20px",
  };

  const handleStyle = {
    marginTop: "-6.5px",
    marginBottom: "7px",
    fontSize: "30px",
    backgroundColor: "#2fb463",
    width: "20px",
    height: "20px",
  };
  const handleSliderChange = (value) => {
    setManupulateValue(value);
  };

  const showGCDocumentFiles = () => {
    socket.emit(
      "mediaListing",
      { GroupChannelID: gcID, filter: "document" },
      (res) => {
        dispatch(GCShowMediaFiles(res?.data));
      }
    );
  };
  const showGroupChannelMediaFiles = () => {
    socket.emit(
      "mediaListing",
      { GroupChannelID: gcID, filter: "file" },
      (res) => {
        dispatch(GCShowMediaFiles(res?.data));
      }
    );
  };
  const userInfoDocumentList = GCUserInfoMedia?.document?.map(
    (media, index) => {
      if (
        media?.MimeType?.startsWith("application") ||
        media?.MimeType?.startsWith("application/msword")
      ) {
        const onButtonClick = () => {
          fetch(
            `${GCUserInfoMedia?.base_url_node}${media?.StoragePath}${media?.FileName}`
          ).then((response) => {
            response.blob().then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = media?.FileName;
              alink.click();
            });
          });
        };
        return (
          <li key={index}>
            <div className="docs_content" onClick={onButtonClick}>
              <span>
                <i className="fa-regular fa-file" />
              </span>
              {media?.FileName}
              <small>
                {Math.round(media?.FileSize / 100) / 10 > 1000
                  ? `${(Math.round(media?.FileSize / 100) / 10000).toFixed(
                      1
                    )} mb`
                  : `${(Math.round(media?.FileSize / 100) / 10).toFixed(1)} kb`}
              </small>
            </div>
          </li>
        );
      }
    }
  );

  const showGCDocumentList =
    showGCMediaFiles?.mediaData?.rows &&
    showGCMediaFiles?.mediaData?.rows?.map((media, index) => {
      if (
        media?.MimeType?.startsWith("application") ||
        media?.MimeType?.startsWith("application/msword")
      ) {
        const onButtonClick = () => {
          fetch(
            `${showGCMediaFiles?.mediaUrl}${media?.StoragePath}${media?.FileName}`
          ).then((response) => {
            response.blob().then((blob) => {
              const fileURL = window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = media?.FileName;
              alink.click();
            });
          });
        };
        return (
          <li key={index}>
            <div className="docs_content" onClick={onButtonClick}>
              <span>
                <i className="fa-regular fa-file" />
              </span>
              {media?.FileName}
              <small>
                {Math.round(media?.FileSize / 100) / 10 > 1000
                  ? `${(Math.round(media?.FileSize / 100) / 10000).toFixed(
                      1
                    )} mb`
                  : `${(Math.round(media?.FileSize / 100) / 10).toFixed(1)} kb`}
              </small>
            </div>
          </li>
        );
      }
    });

  //search list in chat starts here
  const handleSearchResponse = (response) => {
    setSearchLoading(false);
    setMessageList(response?.data?.data?.chatData);
    setProfileUrl(response?.data?.data?.baseUrl);
  };
  const handleFindeMessage = (message) => {
    let find = mentDetails
      ? mentorChats?.find(
          (item) => item?.IndividualChatID === message?.IndividualChatID
        )
      : chat?.find((item) => item?.GroupChatID === message?.GroupChatID);
    if (find) {
      // alert("Hello")
      mentDetails ? setQuote(find) : setSearchMessage(find);
    } else {
      toast.error("Message Not Found !!!");
    }
  };

  // Handle notification blink message
  const [blinkChatID, setBlinkChatID] = useState(null);
  useEffect(() => {
    const storedBlinkChatID = localStorage.getItem("blinkChatID");
    try {
      const parsedBlinkChatID = storedBlinkChatID
        ? JSON.parse(storedBlinkChatID)
        : null;
      setBlinkChatID(parsedBlinkChatID);
    } catch (error) {
      console.error("Error parsing JSON for blinkChatID:", error);
      setBlinkChatID(null);
    }
  }, []);
  // //. Handle blink message

  useEffect(() => {
    let find = chat?.find((item) => item?.GroupChatID === blinkChatID);
    setTimeout(() => {
      if (find) {
        setSearchMessage(find);
      }
    }, 2000);
  }, [chat]);

  const fetchSearchList = (value) => {
    let data = {
      GroupChannelID: gcID,
      searchKey: value,
      userId: GtfConnectId,
      page: page,
    };
    emmitToSocket("searchingChat", data, handleSearchResponse);
  };

  // search in mentor chat list
  const handleMentorSearchResponse = (res) => {
    setMessageList(res?.data?.dataMentor);
    setProfileUrl(res?.data?.profile_image_url);
    setSearchLoading(false);
  };
  const fetchMentorChatListData = (value) => {
    let body = {
      ConversationID: chatConversationID,
      searchKey: value,
    };

    emmitToSocket("mentorChatList", body, handleMentorSearchResponse);
  };

  // Debounced version of the handleSearch function
  const debouncedHandleSearch = debounce(
    mentDetails ? fetchMentorChatListData : fetchSearchList,
    500
  );

  // search chatlist
  const handleSearch = (e) => {
    const value = e.target.value;
    if (value === "") {
      setMessageList([]);
      setSearchKey("");
      setSearchLoading(false);
      return;
    }
    setSearchLoading(true);
    setSearchKey(value);
    // Call the debounced function instead of handleSearch
    debouncedHandleSearch(value);
  };

  //fetch group & channel data
  const getGroupChannelData = (data, res) => {
    const { UserID, GroupChannelID } = data;
    if (
      data?.group_channel?.Type === "group" ||
      data?.group_channel?.Type === "channel"
    ) {
      dispatch(mentorDetails(null));
      dispatch(chatList([]));
      dispatch(pinMessageList([]));
    }
    emmitToSocket(
      "chatList",
      { userId: UserID, GroupChannelID, page: 1 },
      (chatRes) => {
        if (chatRes?.success) {
          dispatch(Description(res?.data?.responses?.groupData?.Description));
          dispatch(GroupHeading(res?.data?.responses?.groupData?.Name));
          dispatch(FullChat(chatRes?.data?.chatData?.rows));
          dispatch(SubscriptionCount(chatRes?.data?.subscriptionCount));
          dispatch(getGroupChannelId(data?.group_channel));
          dispatch(initialShow(true));
          setShow(true);
          dispatch(allPinMessagess(GroupChannelID));
          dispatch(DetailsChannelGroupApi(GroupChannelID));
          dispatch(getUserUpdateProfileDetails());
          dispatch(getSelectedEmojiList(GroupChannelID));
        }
      }
    );
  };

  //fetch mentor & student data
  const getIndividualUserData = (res, role) => {
    if (role === "GC") {
      dispatch(Description(""));
      dispatch(GroupHeading(""));
      dispatch(FullChat([]));
      dispatch(SubscriptionCount(""));
      dispatch(getGroupChannelId(""));
      dispatch(allPinMessages([]));
      dispatch(GCChatlistData(""));
      dispatch(groupChannelDetailsData(""));
    }
    emmitToSocket(
      "mentorChatList",
      { ConversationID: res?.data?.responses?.ConversationID },
      (mentRes) => {
        if (mentRes?.success) {
          //get pin messages
          emmitToSocket(
            "allPinMentorMessage",
            {
              ConversationID: res?.data?.responses?.ConversationID,
              UserID: GtfConnectId,
            },
            (pinRes) => {
              dispatch(pinMessageList(pinRes?.data?.data?.message));
            }
          );
          dispatch(mentorDetails(res?.data?.responses?.UserData));
          dispatch(setConversationID(res?.data?.responses?.ConversationID));
          dispatch(chatList(mentRes?.data?.dataMentor));
          dispatch(setMediaURL(mentRes?.data?.mediaUrl));
          if (role === "User") {
            let leftBody = {
              userId: localStorage?.getItem("UserID"),
              filter: "mentor",
            };
            if (stage === "student") {
              leftBody = {
                ...leftBody,
                roleId: localStorage.getItem("roleID"),
              };
            }
            emmitToSocket("auth-user", leftBody, (leftRes) => {
              dispatch(UpdatedList(leftRes?.data?.gcData));
            });
          }
          setShow(true);
        }
      }
    );
  };

  //mentor forward message
  const mentorForwardMessage = (data, role) => {
    const { UserID, GroupChannelID, GCMemberID } = data;
    let body = {
      UserID: UserID,
      isSentToMentor: 2,
      isForwardFromGC: 1,
      // isSavedMessage: 2,
      isForwardFromSavedMessage: 2,
    };
    role !== "User"
      ? data?.RoleSlug === "mentor" || data?.RoleSlug === "user"
        ? (body = {
            ...body,
            UserID: GtfConnectId,
            GroupChatIDs: selectedMessage?.map((item) => item?.GroupChatID),
            ChatRecipientID: UserID,
            isSentToMentor: 1,
          })
        : (body = {
            ...body,
            GroupChannelID: GroupChannelID,
            GCMemberID: GCMemberID,
            GroupChatIDs: selectedMessage?.map((item) => item?.GroupChatID),
          })
      : savedMessageState
      ? (body = {
          ...body,
          UserID: GtfConnectId,
          ChatRecipientID: UserID,
          SavedMessageIDs: selectedMessage?.map((item) => item?.GroupChatID),
          isSentToMentor: 1,
          isForwardFromGC: 2,
          isForwardFromSavedMessage: 1,
          isForwardFromMentor: 2,
          // isSavedMessage: 1,
          isSentToGC: 2,
        })
      : data?.group_channel?.Type === "group" ||
        data?.group_channel?.Type === "channel"
      ? (body = {
          ...body,
          UserID: GtfConnectId,
          isForwardFromGC: 2,
          isForwardFromMentor: 1,
          isSentToGC: 1,
          GCMemberID: GCMemberID,
          GroupChannelID: GroupChannelID,
          IndividualChatIDs: selectedMentMessages?.map(
            (item) => item?.IndividualChatID
          ),
        })
      : (body = {
          ...body,
          UserID: GtfConnectId,
          isForwardFromGC: 2,
          isSentToMentor: 1,
          isForwardFromMentor: 1,
          isSentToGC: 2,
          ConversationID: selectedMentMessages[0]?.ConversationID,
          IndividualChatIDs: selectedMentMessages?.map(
            (item) => item?.IndividualChatID
          ),
          ChatRecipientID: UserID,
        });
    emmitToSocket("forwardMessage", body, (res) => {
      if (res?.success) {
        role !== "User"
          ? data?.RoleSlug === "mentor" || data?.RoleSlug === "user"
            ? getIndividualUserData(res, role)
            : getGroupChannelData(data, res)
          : data?.group_channel?.Type === "group" ||
            data?.group_channel?.Type === "channel"
          ? getGroupChannelData(data, res)
          : getIndividualUserData(res, role);
      }
      setSavedMesssageState(false);
      setSelectedMessage([]);
      setShowCheckbox(false);
      setSelectedMessage([]);
      setSelectedMentMessages([]);
      setSelectMode(false);
      setShowCheckbox(false);
    });
    // toast.success("message forwarded to mentor");
  };

  //forward list filter
  const handleFilterForwardList = (e) => {
    if (e.target.value === "") {
      setForwardList(mentors);
      setSearchValue("");
    } else {
      let filteredData = mentors?.filter((item) =>
        item?.data_type === "gc_member"
          ? item?.data_gc_member?.group_channel?.Name?.toLowerCase()?.includes(
              e.target.value.toLowerCase()
            )
          : item?.data_user?.Firstname?.toLowerCase()?.includes(
              e.target.value?.toLowerCase()
            ) ||
            item?.data_user?.Lastname?.toLowerCase()?.includes(
              e.target.value?.toLowerCase()
            )
      );

      setForwardList(filteredData);
      setSearchValue(e.target.value);
    }
  };

  // {
  //   console.log("statusGCSettingUpdateLoader", statusGCSettingUpdateLoader);
  // }

  //initiate mentor conversaion
  const initiateMentorConversation = (ChatRecipientID) => {
    let body = {
      UserID: GtfConnectId,
      ChatRecipientID,
    };
    emmitToSocket("initiateMentorChat", body, (res) => {
      // setaddClassInMentorList(res?.data?.UserData?.GTFUserID);
      if (res?.success) {
        fetchMentorChats(res?.data, "initiate");
        emmitToSocket(
          "auth-user",
          { userId: UserID, filter: "mentor" },
          (res) => {
            dispatch(storeProfilePicUrl(res?.data));
          }
          );
          setShow(true)
          // navigate("/users")
      }
    });
  };

  //fetch mentor's chat
  const fetchMentorChats = (data) => {
    const [UserChatDetails] = menterListData?.filter(item => item?.ChatRecipientID === data?.UserID)

    localStorage.removeItem("GCID");
    // navigate("/user")
    dispatch(checkUserRoleAddCondition(true));
    // store role

    if(UserChatDetails){
      dispatch(storeMenoteChatInitialData(UserChatDetails));
      const { ConversationID } = UserChatDetails;
      let body = {
        ConversationID,
      };
      // Getting mentor list
      emmitToSocket("mentorChatList", body, (res) => {
        if (res.success) {
          dispatch(Description(""));
          dispatch(GroupHeading(""));
          dispatch(FullChat([]));
          dispatch(SubscriptionCount(""));
          dispatch(getGroupChannelId(""));
          dispatch(GCChatlistData(""));
          dispatch(groupChannelDetailsData(""));
          dispatch(allPinMessages([]));
          emmitToSocket(
            "allPinMentorMessage",
            { ConversationID, UserID: GtfConnectId },
            (res) => {
              dispatch(pinMessageList(res?.data?.data?.message));
            }
          );
            // dispatch mentor section mentor data
            dispatch(
              mentorDetails(
                   UserChatDetails?.ChatInitiatedByID == GtfConnectId
                    ? UserChatDetails?.ChatRecipientData
                    : UserChatDetails?.ChatInitiatedUserData
              )
            );
  
            // read/unread message for mentor section
            emmitToSocket(
              "readMentorMessage",
              {
                ConversationID: ConversationID,
                UserID: GtfConnectId,
                RoleID: roleID,
              },
              (res) => {
                if (res.success) {
                  emmitToSocket(
                    "auth-user",
                    { userId: UserID, filter: "mentor" },
                    (res) => {
                      dispatch(UpdatedList(res?.data?.gcData));
                      // dispatch(mentorDetails(res?.data?.responses?.UserData));
                    }
                  );
                }
              }
            );
  
          // Dispatch other mentor/home data
          dispatch(setConversationID(ConversationID));
          dispatch(chatList(res?.data?.dataMentor));
          dispatch(setMediaURL(res?.data?.mediaUrl));
          
        }
      });
    }else{
      initiateMentorConversation(data?.UserID)
    }

    setPinMesssageState(false);
    setSavedMesssageState(false);
    setShow(true)
    // navigate("/users")
  };


  useEffect(() => {
    emmitToSocket("auth-user", { userId: UserID, filter: "mentor" }, (res) => {
      setMenterListData(res?.data?.gcData)
    });
  },[])
  return (
    <>
      {!updateSettingGC && groupChannelDetailsStatus === "loading" ? (
        <Bars
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperClass="loader_style"
          visible={true}
          wrapperStyle={{ left: "30%" }}
        />
      ) : (
        <div className="chatbox-area">
          <div className="chatbox-right-bar" ref={showCommentRef}>
            <ChatboxInner
              ShowComment={ShowComment}
              setTagUsersComment={setTagUsersComment}
              commentDatas={commentDatas}
              title={title}
              member={member}
              gcReactionListData={gcReactionListData}
              show={show}
              setShow={setShow}
              setPage={setPage}
              page={page}
              stage={stage}
              commentList={commentList}
              openComment={openComment}
              openSetting={openSetting}
              setOpenSetting={setOpenSetting}
              setOpenProfile={setOpenProfile}
              setGCSearchMessageList={setGCSearchMessageList}
              setOpenComment={setOpenComment}
              userOpenProfile={userOpenProfile}
              userOpenSetting={userOpenSetting}
              userOpenComment={userOpenComment}
              setUserOpenSetting={setUserOpenSetting}
              setUserOpenProfile={setUserOpenProfile}
              setUserOpenComment={setUserOpenComment}
              setChannelAccess={setChannelAccess}
              setGroupAccess={setGroupAccess}
              setChannelDisscussion={setChannelDisscussion}
              setChannelSignedMsg={setChannelSignedMsg}
              setChannelEnableManipulateViews={setChannelEnableManipulateViews}
              setChatHistoryStatus={setChatHistoryStatus}
              openProfile={openProfile}
              setChannelSharingContent={setChannelSharingContent}
              showCommentRef={showCommentRef}
              setPinMesssageState={setPinMesssageState}
              PinMessageState={PinMessageState}
              showCheckbox={showCheckbox}
              setShowCheckbox={setShowCheckbox}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
              showChatContent={showChatContent}
              setShowMessageForwordPopup={setShowMessageForwordPopup}
              showMessageForwordPopup={showMessageForwordPopup}
              setSelectMessageId={setSelectMessageId}
              savedMessageState={savedMessageState}
              setShowSearchPopup={setShowSearchPopup}
              showSearchPopup={showSearchPopup}
              searchMessage={searchMessage}
              setSearchMessage={setSearchMessage}
              setSavedMesssageState={setSavedMesssageState}
              setSelectedMentMessages={setSelectedMentMessages}
              selectedMentMessages={selectedMentMessages}
              setQuote={setQuote}
              quote={quote}
              setSelectMode={setSelectMode}
              selectMode={selectMode}
              setCommentAllowDiscussionPermission={
                setCommentAllowDiscussionPermission
              }
              updatedGCListAfterDeleteGC={updatedGCListAfterDeleteGC}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              setSavedMessageForwordConditon={setSavedMessageForwordConditon}
              handleFindeMessage={handleFindeMessage}
              allowDisscussionUpdatePermission={
                allowDisscussionUpdatePermission
              }
              setAllowDisscussionUpdatePermission={
                setAllowDisscussionUpdatePermission
              }
              selectedDummyUser={selectedDummyUser}
              setSelectedDummyUser={setSelectedDummyUser}
              setUpdateSettingGC={setUpdateSettingGC}
              settagUserImgUrl={settagUserImgUrl}
              tagUserImgUrl={tagUserImgUrl}
              setReply={setReply}
              setShowEmoji={setShowEmoji}
              showEmoji={showEmoji}
              reply={reply}
            />
            <div
              className={`toggle-side-bar ${
                openComment
                  ? "open-comment"
                  : openSetting
                  ? "open-setting"
                  : openProfile
                  ? "open-profile"
                  : showSearchPopup
                  ? "open-search-bar"
                  : ""
              }`}
              id="showProfile"
              ref={getDynamicRef()}
            >
              <div className="toggle-side-inner">
                <div className="toggle-profile ">
                  <div className="toggle-profile-inner">
                    <span
                      className="profile-close same-close-bar"
                      onClick={() => {
                        setOpenProfile(!openProfile);
                      }}
                    >
                      <i className="fa-solid fa-xmark" />
                    </span>
                    <div className="profile-header-toggle">
                      <div className="user-img-profile">
                        {/* <figure>
                          <img
                            src={
                              groupChannelDetails?.gc_info?.ProfileImage == null
                                ? user1
                                : !mentDetails
                                  ? groupChannelDetails?.gc_info?.ProfileImage
                                  : user1
                            }
                            alt="img"
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </figure> */}
                        <figure>
                          <img
                            src={
                              mentorProfilePicUrl
                                ? mentDetails?.ProfileImage !== null &&
                                  mentDetails?.ProfileImage !== undefined
                                  ? `${mentorProfilePicUrl?.user_profile_image_url}${mentDetails.ProfileImage}`
                                  : groupChannelDetails?.gc_info
                                      ?.ProfileImage == null
                                  ? user1
                                  : groupChannelDetails?.gc_info?.ProfileImage
                                : mentDetails?.ProfileImage !== null &&
                                  mentDetails?.ProfileImage !== undefined
                                ? `${mentorListProfilePicUrl?.user_profile_image_url}${mentDetails.ProfileImage}`
                                : groupChannelDetails?.gc_info?.ProfileImage ==
                                  null
                                ? user1
                                : groupChannelDetails?.gc_info?.ProfileImage
                            }
                            alt="logo"
                          />
                        </figure>
                        {!mentDetails &&
                          (userRoleSlugSettingPermission === "super-admin" ||
                            gcSettingPermissionData?.full_permission === "1" ||
                            gcSettingPermissionData?.gc_edit_profile ===
                              "1") && (
                            <Link
                              className="edit-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#EditModal"
                              onClick={() => {
                                setGroupImage(
                                  groupChannelDetails?.gc_info?.ProfileImage
                                );
                                setChannelName(groupHeading?.Name);
                                setChannelDescription(
                                  groupHeading?.Description?.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  )
                                );
                              }}
                            >
                              Edit
                            </Link>
                          )}
                        <div className="user-content-profile">
                          <h3>
                            {mentDetails
                              ? `${mentDetails?.Firstname} ${
                                  mentDetails?.Lastname === null
                                    ? ""
                                    : mentDetails?.Lastname
                                }`
                              : groupHeading?.Name ||
                                getGroupHeadingFromExclusive}
                          </h3>
                          {!mentDetails ? (
                            <span>
                              {`${
                                groupChannelDetails?.gc_info?.MemberCount ||
                                getDataFromExclusive?.subscriptionCount
                              } ${Type == "group" ? "Members" : "Subscribers"}`}
                            </span>
                          ) : (
                            <span>
                              {mentDetails?.usrRole?.role?.Name}
                              {/* mentDetails?.usrRole?.role?.Name */}
                            </span>
                          )}

                          {!mentDetails && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: groupHeading?.Description,
                              }}
                            ></p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="profile-body-toggle">
                      <div className="profile-body-inner">
                        <div className="profile-body-menu">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#tab1GC"
                                onClick={showGroupChannelMediaFiles}
                              >
                                Media
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tab2GC"
                                onClick={showGCDocumentFiles}
                              >
                                Docs
                              </a>
                            </li>
                          </ul>
                        </div>
                        {!mentDetails && (
                          <div className="profile-body-content">
                            <div className="tab-content">
                              <div
                                id="tab1GC"
                                className="tab-pane fade show active"
                              >
                                <div className="media_upload_img">
                                  <ul>
                                    {showGCMediaFiles?.mediaData?.rows &&
                                    showGCMediaFiles?.mediaData?.rows?.length >
                                      0 ? (
                                      showGCMediaFiles?.mediaData?.rows?.map(
                                        (media, index) => {
                                          return (
                                            <>
                                              {media?.MimeType?.startsWith(
                                                "image"
                                              ) && (
                                                <li>
                                                  <a
                                                    href=""
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#imagesmodalimg"
                                                    onClick={() => {
                                                      dispatch(
                                                        showPreviewImage(media)
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={`${showGCMediaFiles?.mediaUrl}${media?.StoragePath}${media?.FileName}`}
                                                      alt="img"
                                                      style={{
                                                        height: "100%",
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </a>
                                                </li>
                                              )}
                                              {media?.MimeType?.startsWith(
                                                "video"
                                              ) && (
                                                <GCTotalVideoPlayPause
                                                  baseUrlMedia={
                                                    showGCMediaFiles
                                                  }
                                                  media={media}
                                                  index={index}
                                                  videoPlay={videoPlay}
                                                />
                                              )}
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="text-white m-auto ">
                                        No Media Files..
                                      </p>
                                    )}
                                  </ul>
                                </div>
                              </div>

                              <div
                                id="tab2GC"
                                className="tab-pane fade showuser_media"
                              >
                                <div className="docs_file">
                                  <ul>
                                    {showGCMediaFiles?.mediaData?.rows &&
                                    showGCMediaFiles?.mediaData?.rows?.length >
                                      0 ? (
                                      showGCDocumentList
                                    ) : (
                                      <p className="text-white d-flex justify-content-center">
                                        No Document..
                                      </p>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="modal fade edit-profile"
                      id="EditModal"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="EditModalLabel"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="fa-solid fa-xmark" />
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="upload_img">
                              <figure onClick={handleClick}>
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={groupImage || user1}
                                />
                                <input
                                  ref={hiddenFileInput}
                                  type="file"
                                  onChange={onFileSelect}
                                  style={{ display: "none" }}
                                />
                              </figure>

                              <div className="upload-input">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={`${Type} Name*`}
                                  onChange={(e) =>
                                    setChannelName(e.target.value)
                                  }
                                  value={channelName}
                                />
                                <br />
                                <textarea
                                  type="textarea"
                                  id="autoresizing"
                                  className="form-control"
                                  placeholder={`${Type} Description*`}
                                  onChange={(e) => {
                                    setChannelDescription(e.target.value);
                                  }}
                                  value={channelDescription}
                                />
                              </div>

                              <div className="upload-content">
                                <button
                                  className="btn btn-inline"
                                  type="button"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    dispatch(
                                      updateGroupInfo({
                                        channelGroupID,
                                        channelName,
                                        Type: groupHeading?.Type,
                                        Description: channelDescription,
                                        channelAccessType,
                                        imageName,
                                        setOpenProfile,
                                        inHome,
                                        chatLeftShowList,
                                      })
                                    );
                                    setGroupImage(
                                      groupChannelDetails?.gc_info?.ProfileImage
                                    );
                                  }}
                                >
                                  Update Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ Setting Group Permission *********************************************** */}
                <div className="modal fade edit-profile" id="PermissionModel">
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Permission Setting</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() =>
                            setPermissionGroupInnerShowPopup(false)
                          }
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Messages</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="channelcheck"
                                        checked={
                                          groupSendMedia == 1
                                            ? true
                                            : groupSendMessage
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setGroupSendMessage(
                                            groupSendMedia == 1
                                              ? 1
                                              : groupSendMessage
                                              ? 0
                                              : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                groupSendStickerGIF,
                                              channelPinMessage:
                                                groupPinMessage,
                                              channelEmbedLinks:
                                                groupEmbedLinks,
                                              channelSendMessage:
                                                groupSendMessage ? 0 : 1,
                                              channelSendMedia: groupSendMedia,
                                              channelSlowMode: groupSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="channelcheck">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Media</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="sendmedia"
                                        checked={groupSendMedia ? true : false}
                                        onChange={() => {
                                          setGroupSendMedia(
                                            groupSendMedia ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                groupSendStickerGIF,
                                              channelPinMessage:
                                                groupPinMessage,
                                              channelEmbedLinks:
                                                groupEmbedLinks,
                                              channelSendMessage:
                                                groupSendMessage,
                                              channelSendMedia: groupSendMedia
                                                ? 0
                                                : 1,
                                              channelSlowMode: groupSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="sendmedia">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {/* <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Stickers and GIFs</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="stickers"
                                        checked={
                                          groupSendStickerGIF ? true : false
                                        }
                                        onChange={() => {
                                          setGroupSendStickerGIF(
                                            groupSendStickerGIF ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                groupSendStickerGIF ? 0 : 1,
                                              channelPinMessage:
                                                groupPinMessage,
                                              channelEmbedLinks:
                                                groupEmbedLinks,
                                              channelSendMessage:
                                                groupSendMessage,
                                              channelSendMedia: groupSendMedia,
                                              channelSlowMode: groupSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="stickers">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                              {/* <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Embed Links</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="embed links"
                                        checked={groupEmbedLinks ? true : false}
                                        onChange={() => {
                                          setGroupEmbedLinks(
                                            groupEmbedLinks ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                groupSendStickerGIF,
                                              channelPinMessage:
                                                groupPinMessage,
                                              channelEmbedLinks: groupEmbedLinks
                                                ? 0
                                                : 1,
                                              channelSendMessage:
                                                groupSendMessage,
                                              channelSendMedia: groupSendMedia,
                                              channelSlowMode: groupSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="embed links">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Pin Messages</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="pinmessages"
                                        checked={groupPinMessage ? true : false}
                                        onChange={() => {
                                          setGroupPinMessage(
                                            groupPinMessage ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                groupSendStickerGIF,
                                              channelPinMessage: groupPinMessage
                                                ? 0
                                                : 1,
                                              channelEmbedLinks:
                                                groupEmbedLinks,
                                              channelSendMessage:
                                                groupSendMessage,
                                              channelSendMedia: groupSendMedia,
                                              channelSlowMode: groupSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="pinmessages">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Sharing Content</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="sharing links"
                                        checked={
                                          groupSharingContent ? true : false
                                        }
                                        onChange={() => {
                                          setGroupSharingContent(
                                            groupSharingContent ? 0 : 1
                                          );
                                          dispatch(
                                            updateGcSetting({
                                              channelGroupID,
                                              Type,
                                              channelDisscussion,
                                              channelSignedMsg,
                                              channelEnableManipulateViews,
                                              channelSharingContent:
                                                groupSharingContent ? 0 : 1,
                                              channelReaction: Reaction,
                                              manipulateValue: manipulateValue,
                                              chatHistoryStatus
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="sharing links">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <br />
                              <li
                                onClick={() => {
                                  setPermissionGroupInnerShowPopup(
                                    !permissionGroupInnerShowPopup
                                  );
                                  setGroupSlowMode(SlowModechannel);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Slow Mode</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <span style={{ color: "#FCC706" }}>
                                        {SlowModechannel == 60
                                          ? `60 Sec`
                                          : SlowModechannel == 0
                                          ? "Never"
                                          : `${SlowModechannel} Sec`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <hr className="group_setting_hr" />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Setting Group Permission *********************************************** */}
                {/* ************************************************ Reaction setting  *********************************************** */}
                <div
                  className="modal fade edit-profile group_setting_reaction"
                  id="ReactionModel"
                  ref={groupGCScroll}
                  onScroll={handleScroll}
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar mb-0">
                          <h3>Reactions</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <hr className="group_setting_hr" />

                            <ul className="channel-list">
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Reactions</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label ">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="groupcheckreaction"
                                        checked={groupReaction ? true : false}
                                        onChange={() => {
                                          setGroupReaction(
                                            groupReaction ? 0 : 1
                                          );
                                          dispatch(
                                            updateGcSetting({
                                              channelGroupID,
                                              Type,
                                              channelDisscussion:
                                                gc_setting?.AllowDiscussion,
                                              channelSignedMsg:
                                                gc_setting?.SignedMsg,
                                              channelEnableManipulateViews:
                                                gc_setting?.EnableManipulateViews,
                                              channelSharingContent:
                                                gc_setting?.RestrictSharingContent,
                                              channelReaction: groupReaction
                                                ? 0
                                                : 1,
                                              manipulateValue: manipulateValue,
                                              chatHistoryStatus
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="groupcheckreaction">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <hr className="group_setting_hr" />
                              {groupReaction && (
                                <span className="reaction_header mt-2">
                                  Please checkmark emoji’s to show in chat
                                </span>
                              )}
                              {groupReaction && (
                                <div className="group-list-reaction">
                                  <ul>
                                    {GCReactionList?.totalReactionList?.map(
                                      (i, index) => {
                                        return (
                                          <li key={index}>
                                            <div className="reaction_emoji">
                                              <div className="check-setting">
                                                <div className="setting-check-label round">
                                                  <input
                                                    type="checkbox"
                                                    id={i?.ReactionID}
                                                    name="smile"
                                                    value={i?.ReactionID}
                                                    className="checkbox-round"
                                                    defaultChecked={
                                                      i?.gcReactionStatus ==
                                                      "Active"
                                                    }
                                                    onClick={() => {
                                                      handleEmojiChange(i);
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={i?.ReactionID}
                                                  ></label>
                                                  <br />
                                                </div>
                                              </div>
                                              <div className="setting-list-blog">
                                                <span className="emoji_icon">
                                                  {i?.EmojiCode}
                                                </span>

                                                <div className="setting-content">
                                                  <strong>
                                                    <Link to="#">
                                                      {i?.Name}
                                                    </Link>
                                                  </strong>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                    {GCReactionList?.status === "loading" && (
                                      <span className="text-white d-flex justify-content-center mb-2">
                                        Loading...
                                      </span>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </ul>
                          </div>
                          {groupReaction && (
                            <div className="reaction_button">
                              <button
                                className="btn btn-inline"
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    UpdateReactionList({
                                      updateReactionListed,
                                      channelGroupID,
                                    })
                                  );
                                }}
                              >
                                Update Reactions
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Reaction setting  *********************************************** */}
                {/* ************************************************ Members setting  *********************************************** */}
                <div className="modal fade edit-profile" id="MemberModel">
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Members</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />

                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {subscriberAPI?.list?.length > 0 ? (
                                subscriberAPI?.list?.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="members_group_setting">
                                        <div className="check-setting">
                                          <div className="setting-check-label" onClick={() => {
                                            // Dispatch actions and update state
                                            // dispatch(
                                            //   GroupChannelUserBlockMemberID(item?.GCMemberID)
                                            // );
                                            dispatch(
                                              GCUserInfoMediaApi({
                                                GCMemberID: item?.GCMemberID,
                                                getGroupChannelId: item?.GroupChannelID,
                                              })
                                            );
                                            setUserOpenProfile(!userOpenProfile);
                    
                                            // You can use if statements for more complex conditional behavior
                                            if (userOpenProfile) {
                                              setUserOpenSetting(false);
                                              setUserOpenComment(false);
                                            }
                                          }}>
                                            <figure className="members_img">
                                              <img
                                                src={
                                                  item?.user?.ProfileImage ===
                                                  null
                                                    ? user1
                                                    : `${subscriberAPI?.profile_base_url}${item?.user?.ProfileImage}`
                                                }
                                                alt="img"
                                              />
                                              <span />
                                            </figure>
                                            <br />
                                          </div>
                                        </div>
                                        <div className="setting-list-blog ">
                                          <div className="setting-content">
                                            <strong>
                                              <Link to="#" className="heading">
                                                {item?.user?.Firstname} {` `}
                                                {item?.user?.Lastname}
                                              </Link>
                                            </strong>
                                          </div>
                                        </div>

                                        {item?.UserID !== LoginUser?.UserID && <div className="setting-list-blog member_setting_group" onClick={() => fetchMentorChats(item)}>

                                          <div className="setting-content">
                                            <strong>
                                              <i className="far fa-comment" />
                                            </strong>
                                          </div>
                                        </div>}
                                      </div>
                                    </li>
                                  );
                                })
                              ) : (
                                <p className="d-flex justify-content-center text-white">
                                  No Subscriber User.
                                </p>
                              )}
                            </ul>

                            {loadMore && (
                              <ul className="channel-list">
                                <li>
                                  <div className="members_group_setting">
                                    <div className="check-setting">
                                      <div className="setting-check-label">
                                        <figure className="members_img">
                                          <img src={user1} alt="img" />
                                          <span />
                                        </figure>
                                        <br />
                                      </div>
                                    </div>
                                    <div className="setting-list-blog ">
                                      <div className="setting-content">
                                        <strong>
                                          <Link to="#" className="heading">
                                            Darlene Robertson
                                          </Link>
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="setting-list-blog member_setting_group">
                                      <div className="setting-content">
                                        <strong>
                                          <i className="far fa-comment" />
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="members_group_setting">
                                    <div className="check-setting">
                                      <div className="setting-check-label">
                                        <figure className="members_img">
                                          <img src={user1} alt="img" />
                                          <span />
                                        </figure>
                                        <br />
                                      </div>
                                    </div>
                                    <div className="setting-list-blog ">
                                      <div className="setting-content">
                                        <strong>
                                          <Link to="#" className="heading">
                                            Darlene Robertson
                                          </Link>
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="setting-list-blog member_setting_group">
                                      <div className="setting-content">
                                        <strong>
                                          <i className="far fa-comment" />
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="members_group_setting">
                                    <div className="check-setting">
                                      <div className="setting-check-label">
                                        <figure className="members_img">
                                          <img src={user1} alt="img" />
                                          <span />
                                        </figure>
                                        <br />
                                      </div>
                                    </div>
                                    <div className="setting-list-blog ">
                                      <div className="setting-content">
                                        <strong>
                                          <Link to="#" className="heading">
                                            Darlene Robertson
                                          </Link>
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="setting-list-blog member_setting_group">
                                      <div className="setting-content">
                                        <strong>
                                          <i className="far fa-comment" />
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Members setting  *********************************************** */}
                {/* ************************************************ Addminestration setting  *********************************************** */}
                <div
                  className="modal fade edit-profile"
                  id="AdministratorModel"
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Administrator</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {GC_AdminList?.list?.length > 0 ? (
                                GC_AdminList?.list?.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="members_group_setting">
                                        <div className="check-setting">
                                          <div className="setting-check-label">
                                            <figure className="members_img">
                                              <img
                                                src={
                                                  item?.user?.ProfileImage ===
                                                  null
                                                    ? user1
                                                    : `${GC_AdminListCheckUrl?.profile_base_url}${item?.user?.ProfileImage}`
                                                }
                                                alt="img"
                                              />
                                              <span />
                                            </figure>
                                            <br />
                                          </div>
                                        </div>
                                        <div className="setting-list-blog">
                                          <div className="setting-content administorator_group">
                                            <strong>
                                              <Link
                                                to="#"
                                                className="administor_heading"
                                              >
                                                {`${item?.user?.Firstname} ${item?.user?.Lastname}`}
                                              </Link>
                                            </strong>
                                            <span>Admin</span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              ) : (
                                <p className="d-flex justify-content-center text-white">
                                  No Admin .
                                </p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Addminestration setting  *********************************************** */}
                {/* ************************************************ Blocklist Group setting  *********************************************** */}
                <div className="modal fade edit-profile" id="BlockListModel">
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Block List</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {getGCBlockMemberList?.length <= 0 ? (
                                <p className="text-white d-flex justify-content-center mt-5">
                                  No Blocked user
                                </p>
                              ) : (
                                getGCBlockMemberList?.map((list, index) => {
                                  return (
                                    <div key={index}>
                                      <li>
                                        <div className="adnubustratir_group_setting">
                                          <div className="check-setting">
                                            <div className="setting-check-label">
                                              <figure className="members_img">
                                                <img
                                                  src={
                                                    list?.user?.ProfileImage ==
                                                    ""
                                                      ? user1
                                                      : list?.user?.ProfileImage
                                                  }
                                                  alt="img"
                                                />
                                                <span />
                                              </figure>
                                              <br />
                                            </div>
                                          </div>
                                          <div className="setting-list-blog fullwidth">
                                            <div className="setting-content gc-blocklist-item fullwidth">
                                              <strong>
                                                <p className="administor_heading" style={{margin : 0}}>
                                                  {list?.user?.Firstname + " "}
                                                  {list?.user?.Lastname}
                                                </p>
                                              </strong>
                                              <span
                                                className="unblock_subtitle"
                                                onClick={() =>
                                                  updateUnblockMemberStatus({
                                                    GCuserBlockMemberID: list?.GCMemberID,
                                                    getGroupChannelId: list?.GroupChannelID,
                                                  })
                                                }
                                              >
                                                Unblock
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <hr className="group_setting_hr" />
                                    </div>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Blocklist Group setting  *********************************************** */}
                {/* ******************************************************CHANNEL SETTING************************** */}

                {/*
                 **********************permissionInnerShowPopup *************************** */}

                {permissionInnerShowPopup && (
                  <div className="permissonInnerpopup">
                    <div className="permissonInnerpopup-list ">
                      <strong>Slow Mode</strong>
                      <div className="permissonInnerpopup-checkgfgf ">
                        <div className="permissonInnerpopup-box">
                          <input
                            type="radio"
                            className="checknotification"
                            id="userone"
                            name="dummyuser"
                          />
                          <label htmlFor="userone">
                            <span />
                            Kristin Watson
                          </label>
                        </div>
                        <div className="permissonInnerpopup-box">
                          <input
                            type="radio"
                            className="checknotification"
                            id="usertwo"
                            name="dummyuser"
                          />
                          <label htmlFor="usertwo">
                            <span />
                            Floyd Miles
                          </label>
                        </div>
                        <div className="permissonInnerpopup-box">
                          <input
                            type="radio"
                            className="checknotification"
                            id="userthree"
                            name="dummyuser"
                          />
                          <label htmlFor="userthree">
                            <span />
                            Darlene Robertson
                          </label>
                        </div>
                        <div className="permissonInnerpopup-box">
                          <input
                            type="radio"
                            className="checknotification"
                            id="userfour"
                            name="dummyuser"
                          />
                          <label htmlFor="userfour">
                            <span />
                            Ronald Richards
                          </label>
                        </div>
                        <div className="permissonInnerpopup-box">
                          <input
                            type="radio"
                            className="checknotification"
                            id="userfive"
                            name="dummyuser"
                          />
                          <label htmlFor="userfive">
                            <span />
                            Raiph Edwards
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*
                 **********************permissionInnerShowPopup *************************** */}
                {/*
                 **********************permissionInnerShowPopup Group *************************** */}

                {permissionGroupInnerShowPopup && (
                  <div className="permissonInnerpopup radio-button-popup">
                    <div className="permissonInnerpopup-list">
                      <strong>Slow Mode</strong>
                      <hr className="group_setting_hr" />
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="grouponeminute"
                            name="permissiongroup"
                            value="10"
                            className="checkpermission"
                            checked={groupSlowMode == 10}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="grouponeminute">
                            <span></span>After 10 Sec
                          </label>
                        </div>
                      </div>
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="grouptwominute"
                            name="permissiongroup"
                            value="30"
                            className="checkpermission"
                            checked={groupSlowMode == 30}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="grouptwominute">
                            <span></span>After 30 Sec
                          </label>
                        </div>
                      </div>
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="groupfiveminutes"
                            name="permissiongroup"
                            value="60 "
                            className="checkpermission"
                            checked={groupSlowMode == 60}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="groupfiveminutes">
                            <span></span>After 1 Min
                          </label>
                        </div>
                      </div>
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="grouptenminutes"
                            name="permissiongroup"
                            value="300"
                            className="checkpermission"
                            checked={groupSlowMode == 300}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="grouptenminutes">
                            <span></span>After 5 Min
                          </label>
                        </div>
                      </div>
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="grouponehour"
                            name="permissiongroup"
                            value="86400"
                            className="checkpermission"
                            checked={groupSlowMode == 86400}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="grouponehour">
                            <span></span>After 24 Hours
                          </label>
                        </div>
                      </div>
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="groupNever"
                            name="permissiongroup"
                            value="0"
                            className="checkpermission"
                            checked={groupSlowMode == 0}
                            onChange={(e) => {
                              setGroupSlowMode(e.target.value);
                            }}
                          />
                          <label for="groupNever">
                            <span></span>Never
                          </label>
                        </div>
                      </div>
                      <div
                        className="group_setting_hr"
                        style={{ marginTop: "10px" }}
                      />
                      <div className="innner_button_style">
                        <span
                          onClick={() => {
                            setPermissionGroupInnerShowPopup(false);
                          }}
                        >
                          Cancel
                        </span>
                        <span
                          onClick={() => {
                            dispatch(
                              updateGroupChannelPermission({
                                channelGroupID,
                                channelSendStickerGIF: groupSendStickerGIF,
                                channelPinMessage: groupPinMessage,
                                channelEmbedLinks: groupEmbedLinks,
                                channelSendMessage: groupSendMessage,
                                channelSendMedia: groupSendMedia,
                                channelSlowMode: groupSlowMode,
                              })
                            );
                            setPermissionGroupInnerShowPopup(false);
                          }}
                        >
                          Save
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {/*
                 **********************permissionInnerShowPopup Group *************************** */}
                {/*
                 **********************permissionInnerShowPopup Channel *************************** */}

                {permissionChannelInnerShowPopup && (
                  <div className="permissonInnerpopup radio-button-popup">
                    <div className="permissonInnerpopup-list ">
                      <strong>Slow Mode</strong>
                      <hr className="group_setting_hr" />
                      <div className="permissonInnerpopup-check notification-check">
                        <div className="permissonInnerpopup-box check-noification-box">
                          <input
                            type="radio"
                            id="oneminute"
                            name="permissionchannel"
                            value="10"
                            className="checkpermission"
                            checked={channelSlowMode == 10}
                            onChange={(e) => {
                              setChannelSlowMode(e.target.value);
                            }}
                          />
                          <label for="oneminute">
                            {" "}
                            <span></span>After 10 Sec
                          </label>
                        </div>
                        <div className="permissonInnerpopup-check notification-check">
                          <div className="permissonInnerpopup-box check-noification-box">
                            <input
                              type="radio"
                              id="twominute"
                              name="permissionchannel"
                              value="30"
                              checked={channelSlowMode == 30}
                              className="checkpermission"
                              onChange={(e) => {
                                setChannelSlowMode(e.target.value);
                              }}
                            />
                            <label for="twominute">
                              {" "}
                              <span></span>After 30 Sec
                            </label>
                          </div>
                        </div>
                        <div className="permissonInnerpopup-check notification-check">
                          <div className="permissonInnerpopup-box check-noification-box">
                            <input
                              type="radio"
                              id="fiveminutes"
                              name="permissionchannel"
                              value="60"
                              checked={channelSlowMode == 60}
                              className="checkpermission"
                              onChange={(e) => {
                                setChannelSlowMode(e.target.value);
                              }}
                            />
                            <label for="fiveminutes">
                              <span></span>After 1 Min
                            </label>
                          </div>
                        </div>
                        <div className="permissonInnerpopup-check notification-check">
                          <div className="permissonInnerpopup-box check-noification-box">
                            <input
                              type="radio"
                              id="tenminutes"
                              name="permissionchannel"
                              value="300"
                              checked={channelSlowMode == 300}
                              className="checkpermission"
                              onChange={(e) => {
                                setChannelSlowMode(e.target.value);
                              }}
                            />
                            <label for="tenminutes">
                              <span></span> After 5 Min
                            </label>
                          </div>
                        </div>
                        <div className="permissonInnerpopup-check notification-check">
                          <div className="permissonInnerpopup-box check-noification-box">
                            <input
                              type="radio"
                              id="onehour"
                              name="permissionchannel"
                              value="86400"
                              checked={channelSlowMode == 86400}
                              className="checkpermission"
                              onChange={(e) => {
                                setChannelSlowMode(e.target.value);
                              }}
                            />
                            <label for="onehour">
                              <span></span>After 24 Hours
                            </label>
                          </div>
                        </div>
                        <div className="permissonInnerpopup-check notification-check">
                          <div className="permissonInnerpopup-box check-noification-box">
                            <input
                              type="radio"
                              id="Never"
                              name="permissionchannel"
                              value="0"
                              checked={channelSlowMode == "0"}
                              className="checkpermission"
                              onChange={(e) => {
                                setChannelSlowMode(e.target.value);
                              }}
                            />
                            <label for="Never">
                              <span></span>Never
                            </label>
                          </div>
                        </div>
                        <hr
                          className="group_setting_hr"
                          style={{ marginTop: "10px" }}
                        />
                        <div className="innner_button_style">
                          <span
                            onClick={() => {
                              setPermissionChannelInnerShowPopup(false);
                            }}
                          >
                            Cancel
                          </span>
                          <span
                            onClick={() => {
                              dispatch(
                                updateGroupChannelPermission({
                                  channelGroupID,
                                  channelSendStickerGIF,
                                  channelPinMessage,
                                  channelEmbedLinks,
                                  channelSendMessage,
                                  channelSendMedia,
                                  channelSlowMode,
                                })
                              );
                              setPermissionChannelInnerShowPopup(false);
                            }}
                          >
                            Save
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*
                 **********************permissionInnerShowPopup Channel *************************** */}

                {/* ****************************************Channel permission Setting****************************************** */}
                <div
                  className="modal fade edit-profile"
                  id="ChannelPermissionModel"
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Permission Setting</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() =>
                            setPermissionChannelInnerShowPopup(false)
                          }
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Messages</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="SendmessageSetting"
                                        checked={
                                          channelSendMedia == 1
                                            ? true
                                            : channelSendMessage
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          setChannelSendMessage(
                                            channelSendMedia == 1
                                              ? 1
                                              : channelSendMessage
                                              ? 0
                                              : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF,
                                              channelPinMessage,
                                              channelEmbedLinks,
                                              channelSendMessage:
                                                channelSendMessage ? 0 : 1,
                                              channelSendMedia,
                                              channelSlowMode,
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="SendmessageSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Media</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="sendmediaSetting"
                                        onChange={() => {
                                          setChannelSendMedia(
                                            channelSendMedia ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF,
                                              channelPinMessage,
                                              channelEmbedLinks,
                                              channelSendMessage,
                                              channelSendMedia: channelSendMedia
                                                ? 0
                                                : 1,
                                              channelSlowMode,
                                            })
                                          );
                                        }}
                                        checked={
                                          channelSendMedia ? true : false
                                        }
                                      />
                                      <label htmlFor="sendmediaSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {/* <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Send Stickers and GIFs</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="stickersSetting"
                                        onChange={() => {
                                          setChannelSendStickerGIF(
                                            channelSendStickerGIF ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF:
                                                channelSendStickerGIF ? 0 : 1,
                                              channelPinMessage,
                                              channelEmbedLinks,
                                              channelSendMessage,
                                              channelSendMedia,
                                              channelSlowMode,
                                            })
                                          );
                                        }}
                                        checked={
                                          channelSendStickerGIF ? true : false
                                        }
                                      />
                                      <label htmlFor="stickersSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Embed Links</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="embed linksSetting"
                                        onChange={() => {
                                          setChannelEmbedLinks(
                                            channelEmbedLinks ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF,
                                              channelPinMessage,
                                              channelEmbedLinks:
                                                channelEmbedLinks ? 0 : 1,
                                              channelSendMessage,
                                              channelSendMedia,
                                              channelSlowMode,
                                            })
                                          );
                                        }}
                                        checked={
                                          channelEmbedLinks ? true : false
                                        }
                                      />
                                      <label htmlFor="embed linksSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Pin Messages</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="pinmessagesSetting"
                                        onChange={() => {
                                          setChannelPinMessage(
                                            channelPinMessage ? 0 : 1
                                          );
                                          dispatch(
                                            updateGroupChannelPermission({
                                              channelGroupID,
                                              channelSendStickerGIF,
                                              channelPinMessage:
                                                channelPinMessage ? 0 : 1,
                                              channelEmbedLinks,
                                              channelSendMessage,
                                              channelSendMedia,
                                              channelSlowMode,
                                            })
                                          );
                                        }}
                                        checked={
                                          channelPinMessage ? true : false
                                        }
                                      />
                                      <label htmlFor="pinmessagesSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Sharing Content</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="sharingContentSetting"
                                        checked={
                                          channelSharingContent ? true : false
                                        }
                                        onChange={() => {
                                          setChannelSharingContent(
                                            channelSharingContent ? 0 : 1
                                          );

                                          dispatch(
                                            updateGcSetting({
                                              channelGroupID,
                                              Type,
                                              channelDisscussion,
                                              channelSignedMsg,
                                              channelEnableManipulateViews,
                                              channelSharingContent:
                                                channelSharingContent ? 0 : 1,
                                              channelReaction: Reaction,
                                              manipulateValue: manipulateValue,
                                              chatHistoryStatus
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="sharingContentSetting">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <br />
                              <li
                                onClick={() => {
                                  setPermissionChannelInnerShowPopup(
                                    !permissionChannelInnerShowPopup
                                  );
                                  setChannelSlowMode(SlowModechannel);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link>Slow Mode</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label">
                                      <span style={{ color: "#FCC706" }}>
                                        {SlowModechannel == 60
                                          ? `1 Min`
                                          : SlowModechannel == 0
                                          ? "Never"
                                          : `${SlowModechannel} Sec`}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <hr className="group_setting_hr" />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* **************************************** //. Channel permission Setting********************************* */}
                {/* ************************************************ Channel Reaction setting  *********************************************** */}
                <div
                  className="modal fade edit-profile"
                  id="ChannelReactionModel"
                  onScroll={handleScroll}
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar mb-0">
                          <h3>Reactions</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <hr className="group_setting_hr" />
                            <ul className="channel-list">
                              <li>
                                <div className="setting-list-blog">
                                  <div className="setting-content">
                                    <strong>
                                      <Link to="#">Reaction</Link>
                                    </strong>
                                  </div>
                                  <div className="check-setting">
                                    <div className="setting-check-label ">
                                      <input
                                        type="checkbox"
                                        className="check-toggle-setting"
                                        id="channelreaction"
                                        checked={channelReaction ? true : false}
                                        onChange={() => {
                                          setChannelReaction(
                                            channelReaction ? 0 : 1
                                          );
                                          dispatch(
                                            updateGcSetting({
                                              channelGroupID,
                                              Type,
                                              channelDisscussion,
                                              channelSignedMsg,
                                              channelEnableManipulateViews,
                                              channelSharingContent,
                                              channelReaction: channelReaction
                                                ? 0
                                                : 1,
                                              manipulateValue: manipulateValue,
                                              chatHistoryStatus
                                            })
                                          );
                                        }}
                                      />
                                      <label htmlFor="channelreaction">
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <hr className="group_setting_hr" />
                              {channelReaction && (
                                <span className="reaction_header mt-2">
                                  Please checkmark emoji’s to show in chat
                                </span>
                              )}
                              {channelReaction && (
                                <div className="group-list-reaction">
                                  <ul>
                                    {allEmoiisList?.map((i, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="reaction_emoji">
                                            <div className="check-setting">
                                              <div className="setting-check-label round">
                                                <input
                                                  type="checkbox"
                                                  id={i?.ReactionID}
                                                  name="smile"
                                                  value={i.ReactionID}
                                                  defaultChecked={
                                                    i?.gcReactionStatus ==
                                                    "Active"
                                                  }
                                                  className="checkbox-round"
                                                  onClick={() => {
                                                    handleEmojiChange(i);
                                                  }}
                                                />
                                                <label
                                                  htmlFor={i?.ReactionID}
                                                ></label>
                                                <br />
                                              </div>
                                            </div>
                                            <div className="setting-list-blog">
                                              <span className="emoji_icon">
                                                {i?.EmojiCode}
                                              </span>

                                              <div className="setting-content">
                                                <strong>
                                                  <Link to="#">{i?.Name}</Link>
                                                </strong>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                    {GCReactionList?.status === "loading" && (
                                      <span className="text-white d-flex justify-content-center mb-2">
                                        Loading...
                                      </span>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </ul>
                          </div>
                          {channelReaction && (
                            <div className="reaction_button">
                              <button
                                className="btn btn-inline"
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    UpdateReactionList({
                                      updateReactionListed,
                                      channelGroupID,
                                    })
                                  );
                                }}
                              >
                                Update Reactions
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************************ //. Channel Reaction setting  *********************************************** */}

                {/*
                 **********************Channel Administrator Setting *************************** */}
                <div
                  className="modal fade edit-profile"
                  id="AdministratorChannelModel"
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Administrator</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {GC_AdminList?.list?.length > 0 ? (
                                GC_AdminList?.list?.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="members_group_setting">
                                        <div className="check-setting">
                                          <div className="setting-check-label">
                                            <figure className="members_img">
                                              <img
                                                src={
                                                  item?.user?.ProfileImage ==
                                                  null
                                                    ? user1
                                                    : `${GC_AdminListCheckUrl?.profile_base_url}${item?.user?.ProfileImage}`
                                                }
                                                alt="img"
                                              />
                                              <span />
                                            </figure>
                                            <br />
                                          </div>
                                        </div>
                                        <div className="setting-list-blog">
                                          <div className="setting-content administorator_group">
                                            <strong>
                                              <Link
                                                to="#"
                                                className="administor_heading"
                                              >
                                                {`${item?.user?.Firstname} ${item?.user?.Lastname}`}
                                              </Link>
                                            </strong>
                                            <span>Admin</span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })
                              ) : (
                                <p className="d-flex justify-content-center text-white">
                                  No Admin
                                </p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* **********************  // . Channel Setting Administrator***************** */}
                {/* ************************************** Channel Subscriber Setting ****************************** */}
                <div className="modal fade edit-profile" id="SubscriberModel">
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Subscribers</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />

                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {subscriberAPI?.list?.map((item) => (
                                  <li>
                                    <div className="members_group_setting">
                                      <div className="check-setting">
                                        <div className="setting-check-label" onClick={() => {
                                            // Dispatch actions and update state
                                            dispatch(
                                              GroupChannelUserBlockMemberID(item?.GCMemberID)
                                            );
                                            dispatch(
                                              GCUserInfoMediaApi({
                                                GCMemberID: item?.GCMemberID,
                                                getGroupChannelId: item?.GroupChannelID,
                                              })
                                            );
                                            setUserOpenProfile(!userOpenProfile);
                    
                                            // You can use if statements for more complex conditional behavior
                                            if (userOpenProfile) {
                                              setUserOpenSetting(false);
                                              setUserOpenComment(false);
                                            }
                                          }}>
                                          <figure className="members_img">
                                            <img
                                              src={
                                                item?.user?.ProfileImage ===
                                                null
                                                  ? user1
                                                  : `${subscriberAPI?.profile_base_url}${item?.user?.ProfileImage}`
                                              }
                                              alt="img"
                                            />
                                            <span />
                                          </figure>
                                          <br />
                                        </div>
                                      </div>
                                      <div className="setting-list-blog ">
                                        <div className="setting-content">
                                          <strong>
                                            <Link to="#" className="heading">
                                              {item?.user?.Firstname} {` `}
                                              {item?.user?.Lastname}
                                            </Link>
                                          </strong>
                                        </div>
                                      </div>

                                      {item?.UserID !== LoginUser?.UserID && <div className="setting-list-blog member_setting_group" onClick={() => fetchMentorChats(item)}>

                                        <div className="setting-content">
                                          <strong>
                                            <i className="far fa-comment" />
                                          </strong>
                                        </div>
                                      </div>}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ************************************** //. Channel Subscriber Setting ****************************** */}
                {/* *****************************************Channel Block Setting********************* */}

                <div
                  className="modal fade edit-profile"
                  id="ChannelBlockListModel"
                >
                  <div className="modal-dialog">
                    <div className="modal-content permissionStyle">
                      <div className="modal-header">
                        <div className="setting-header-bar">
                          <h3>Block List</h3>
                        </div>
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                      <hr className="group_setting_hr" />
                      <div className="modal-body">
                        <div className="toggle-setting-body">
                          <div className="setting-body-inner">
                            <ul className="channel-list">
                              {getGCBlockMemberList?.length <= 0 ? (
                                <p className="text-white d-flex justify-content-center">
                                  No Blocked user
                                </p>
                              ) : (
                                getGCBlockMemberList?.map((list, index) => {
                                  return (
                                    <div key={index}>
                                      <li>
                                        <div className="adnubustratir_group_setting">
                                          <div className="check-setting">
                                            <div className="setting-check-label">
                                              <figure className="members_img">
                                                <img
                                                  src={
                                                    list?.user?.ProfileImage ==
                                                    ""
                                                      ? user1
                                                      : list?.user?.ProfileImage
                                                  }
                                                  alt="img"
                                                />
                                                <span />
                                              </figure>
                                              <br />
                                            </div>
                                          </div>
                                          <div className="setting-list-blog fullwidth">
                                            <div className="setting-content gc-blocklist-item fullwidth" >
                                              <strong>
                                                <p className="administor_heading" style={{margin : 0}}>
                                                  {list?.user?.Firstname + " "}
                                                  {list?.user?.Lastname}
                                                </p>
                                              </strong>
                                              <span
                                                className="unblock_subtitle"
                                                onClick={() => {
                                                  dispatch(
                                                    updateUnblockMemberStatus({
                                                      GCuserBlockMemberID: list?.GCMemberID,
                                                      getGroupChannelId: list?.GroupChannelID,
                                                    })
                                                  )
                                                  setOpenSetting(false)
                                                  setUserBlockStatusUpdate(false)
                                                }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#ChannelBlockListModel"
                                              >
                                                Unblock
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <hr className="group_setting_hr" />
                                    </div>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ***************************************** // Channel Block Setting********************* */}
                <div className="toggle-setting">
                  {Type == "channel" ? (
                    <div className="toggle-setting-inner">
                      <div className="setting-header-bar">
                        <h3>Channel Settings</h3>
                        <span
                          className="toggle-setting-close same-close-bar"
                          onClick={() => setOpenSetting(!openSetting)}
                        >
                          <i className="fa-solid fa-xmark" />
                        </span>
                      </div>
                      <div className="toggle-setting-body">
                        <div className="setting-body-inner">
                          <ul className="channel-list">
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={channel} alt="channel" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link to="#">Channel Type</Link>
                                  </strong>
                                  <small>{channelAccess}</small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="channelcheckSetting"
                                      checked={
                                        channelAccess == "public" ? false : true
                                      }
                                      onChange={() => {
                                        setChannelAccess(
                                          channelAccess === "public"
                                            ? "private"
                                            : "public"
                                        );

                                        dispatch(
                                          updateGroupInfo({
                                            channelGroupID,
                                            channelName: groupHeading?.Name,
                                            Description:
                                              groupHeading?.Description,
                                            Type: groupHeading?.Type,
                                            channelAccessType:
                                              channelAccess == "public"
                                                ? "private"
                                                : "public",
                                            imageName,
                                            setOpenProfile,
                                            inHome,
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="channelcheckSetting">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={permission} alt="permission" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ChannelPermissionModel"
                                      onClick={() => {
                                        setChannelSendStickerGIF(
                                          SendStickerGIFchannel
                                        );
                                        setChannelPinMessage(PinMessagechannel);
                                        setChannelEmbedLinks(EmbedLinkschannel);
                                        setChannelSendMessage(
                                          SendMessagechannel
                                        );
                                        setChannelSendMedia(SendMediachannel);
                                        setChannelSlowMode(SlowModechannel);
                                        setChannelSharingContent(
                                          sharingContent
                                        );
                                        setChannelReaction(Reaction);
                                      }}
                                    >
                                      Permission
                                    </Link>
                                  </strong>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={discussion} alt="Discussion" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link to="#">Discussion</Link>
                                  </strong>
                                  <small>
                                    {channelDisscussion ? "On" : "Off"}
                                  </small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="discussioncheck"
                                      checked={
                                        channelDisscussion ? true : false
                                      }
                                      onChange={() => {
                                        setChannelDisscussion(
                                          channelDisscussion ? 0 : 1
                                        );
                                        dispatch(
                                          updateGcSetting({
                                            channelGroupID,
                                            Type,
                                            channelDisscussion:
                                              channelDisscussion ? 0 : 1,
                                            channelSignedMsg,
                                            channelEnableManipulateViews,
                                            channelSharingContent,
                                            channelReaction: Reaction,
                                            manipulateValue: manipulateValue,
                                            chatHistoryStatus,
                                            isSetting: true,
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="discussioncheck">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div
                                className="setting-list-blog"
                                onClick={() => {
                                  setUpdateReactionListed(selectedEmojiList);
                                  setAllEmoijisList(
                                    GCReactionList?.totalReactionList
                                  );
                                }}
                              >
                                <figure>
                                  <img src={reactions} alt="reactions" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ChannelReactionModel"
                                    >
                                      Reactions
                                    </Link>
                                  </strong>
                                  <small>
                                    {selectedEmojiList?.length} Reactions
                                  </small>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul className="massage-list ul-border">
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={sign} alt="sign" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link to="#">Sign Message</Link>
                                  </strong>
                                  <small>
                                    {channelSignedMsg ? "On" : "Off"}
                                  </small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="signcheck"
                                      checked={channelSignedMsg ? true : false}
                                      onChange={() => {
                                        setChannelSignedMsg(
                                          channelSignedMsg ? 0 : 1
                                        );
                                        dispatch(
                                          updateGcSetting({
                                            channelGroupID,
                                            channelDisscussion,
                                            Type,
                                            channelSignedMsg: channelSignedMsg
                                              ? 0
                                              : 1,
                                            channelEnableManipulateViews,
                                            channelSharingContent,
                                            channelReaction: Reaction,
                                            manipulateValue: manipulateValue,
                                            chatHistoryStatus
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="signcheck">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul className="administrator-list">
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img
                                    src={administrator}
                                    alt="administrator"
                                  />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#AdministratorChannelModel"
                                    >
                                      Administrator
                                    </Link>
                                  </strong>
                                  <small>
                                    {GC_AdminList?.list?.length > 0
                                      ? GC_AdminList?.list?.length
                                      : 0}
                                  </small>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={subscribers} alt="subscribers" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#SubscriberModel"
                                    >
                                      Subscribers
                                    </Link>
                                  </strong>
                                  <small>{subscriberAPI?.list?.length}</small>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={blocklist} alt="blocklist" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ChannelBlockListModel"
                                    >
                                      Block List
                                    </Link>
                                  </strong>
                                  <small>{getGCBlockMemberList?.length}</small>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={manipulate} alt="manipulate" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#Manipulate"
                                      onClick={() => {
                                        setManupulateValue(manipulatePercent);
                                      }}
                                    >
                                      Manipulate Views
                                    </Link>
                                  </strong>
                                  <small>
                                    {channelEnableManipulateViews
                                      ? "On"
                                      : "Off"}
                                  </small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="manipulatecheck"
                                      checked={
                                        channelEnableManipulateViews
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setChannelEnableManipulateViews(
                                          channelEnableManipulateViews ? 0 : 1
                                        );
                                        dispatch(
                                          updateGcSetting({
                                            channelGroupID,
                                            Type,
                                            channelDisscussion,
                                            channelSignedMsg,
                                            channelEnableManipulateViews:
                                              channelEnableManipulateViews
                                                ? 0
                                                : 1,
                                            channelSharingContent,
                                            channelReaction: Reaction,
                                            manipulateValue: manipulateValue,
                                            chatHistoryStatus
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="manipulatecheck">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={manipulate} alt="chathistory" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link to="#">
                                      View Chat History
                                    </Link>
                                  </strong>
                                  <small>
                                    {chatHistoryStatus
                                      ? "On"
                                      : "Off"}
                                  </small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="chathistorytoggle"
                                      checked={
                                        chatHistoryStatus
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        setChatHistoryStatus(
                                          chatHistoryStatus ? 0 : 1
                                        );
                                        dispatch(
                                          updateGcSetting({
                                            channelGroupID,
                                            Type,
                                            channelDisscussion,
                                            channelSignedMsg,
                                            channelEnableManipulateViews,
                                            channelSharingContent,
                                            channelReaction: Reaction,
                                            manipulateValue: manipulateValue,
                                            chatHistoryStatus : chatHistoryStatus ? 0 : 1,
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="chathistorytoggle">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul className="ul-border delete-list">
                            {gcSettingPermissionData?.gc_delete == 1 ||
                            userRoleSlugSettingPermission == "super-admin" ||
                            gcSettingPermissionData?.full_permission == 1 ? (
                              <li>
                                <div className="setting-list-blog">
                                  <figure>
                                    <img src={deletes} alt="delete" />
                                  </figure>
                                  <div className="setting-content">
                                    <strong>
                                      <Link
                                        to="#"
                                        // onClick={() => {
                                        //   dispatch(
                                        //     GCDelete({
                                        //       channelGroupID: channelGroupID,
                                        //       setUpdatedGCListAfterDeleteGC,
                                        //     })
                                        //   );
                                        //   setOpenSetting(!openSetting);
                                        // }}
                                        onClick={() => {
                                          ConfirmationDialog({
                                            title: "Delete Channel",
                                            message:
                                              "Are you sure you want to delete this Channel?",
                                            onConfirm: handleDeleteGC,
                                          });
                                        }}
                                      >
                                        Delete Channel
                                      </Link>
                                    </strong>
                                  </div>
                                </div>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="toggle-setting-inner">
                      <div className="setting-header-bar">
                        <h3>Group Settings</h3>
                        <span
                          className="toggle-setting-close same-close-bar"
                          onClick={() => setOpenSetting(!openSetting)}
                        >
                          <i className="fa-solid fa-xmark" />
                        </span>
                      </div>
                      <div className="toggle-setting-body">
                        <div className="setting-body-inner">
                          <ul className="channel-list">
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={channel} alt="channel" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link to="#">Group Type</Link>
                                  </strong>
                                  <small>{groupAccess}</small>
                                </div>
                                <div className="check-setting">
                                  <div className="setting-check-label">
                                    <input
                                      type="checkbox"
                                      className="check-toggle-setting"
                                      id="groupcheck"
                                      checked={
                                        groupAccess == "public" ? false : true
                                      }
                                      onChange={() => {
                                        setGroupAccess(
                                          groupAccess === "public"
                                            ? "private"
                                            : "public"
                                        );

                                        dispatch(
                                          updateGroupInfo({
                                            channelGroupID,
                                            channelName: groupHeading?.Name,
                                            Description:
                                              groupHeading?.Description,
                                            Type: groupHeading?.Type,
                                            channelAccessType:
                                              groupAccess == "public"
                                                ? "private"
                                                : "public",
                                            imageName,
                                            setOpenProfile,
                                            inHome,
                                            isSetting: true,
                                          })
                                        );
                                      }}
                                    />
                                    <label htmlFor="groupcheck">
                                      <span />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={permission} alt="permission" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#PermissionModel"
                                      onClick={() => {
                                        setGroupSendStickerGIF(
                                          SendStickerGIFchannel
                                        );
                                        setGroupPinMessage(PinMessagechannel);
                                        setGroupEmbedLinks(EmbedLinkschannel);
                                        setGroupSendMessage(SendMessagechannel);
                                        setGroupSendMedia(SendMediachannel);
                                        setGroupSlowMode(SlowModechannel);
                                        setGroupReaction(Reaction);
                                      }}
                                    >
                                      Permission
                                    </Link>
                                  </strong>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div
                                className="setting-list-blog"
                                onClick={() => {
                                  setUpdateReactionListed(selectedEmojiList);
                                  setAllEmoijisList(
                                    GCReactionList?.totalReactionList
                                  );
                                }}
                              >
                                <figure>
                                  <img src={reactions} alt="reactions" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#ReactionModel"
                                    >
                                      Reactions
                                    </Link>
                                  </strong>
                                  <small>
                                    {selectedEmojiList?.length} Reactions
                                  </small>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={subscribers} alt="Members" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#MemberModel"
                                    >
                                      Members
                                    </Link>
                                  </strong>

                                  {/* <small>{groupHeading?.MemberCount}</small> */}
                                  <small>{subscriberAPI?.list?.length}</small>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul className="massage-list ul-border"></ul>
                          <ul className="administrator-list">
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img
                                    src={administrator}
                                    alt="administrator"
                                  />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#AdministratorModel"
                                    >
                                      Administrator
                                    </Link>
                                  </strong>
                                  <small>
                                    {GC_AdminList?.list?.length > 0
                                      ? GC_AdminList?.list?.length
                                      : 0}
                                  </small>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="setting-list-blog">
                                <figure>
                                  <img src={blocklist} alt="blocklist" />
                                </figure>
                                <div className="setting-content">
                                  <strong>
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#BlockListModel"
                                      onClick={() =>
                                        dispatch(
                                          GCBlockMemberList({
                                            gcID,
                                          })
                                        )
                                      }
                                    >
                                      Block List
                                    </Link>
                                  </strong>
                                  <small>{getGCBlockMemberList?.length}</small>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul className="ul-border delete-list">
                            {gcSettingPermissionData?.gc_delete == 1 ||
                            userRoleSlugSettingPermission == "super-admin" ||
                            gcSettingPermissionData?.full_permission == 1 ? (
                              <li>
                                <div className="setting-list-blog">
                                  <figure>
                                    <img src={deletes} alt="delete" />
                                  </figure>
                                  <div className="setting-content">
                                    <strong>
                                      <Link
                                        to="#"
                                        // onClick={() => {
                                        //   dispatch(
                                        //     GCDelete({
                                        //       channelGroupID: channelGroupID,
                                        //       setUpdatedGCListAfterDeleteGC,
                                        //     })
                                        //   );
                                        //   setOpenSetting(!openSetting);
                                        // }}
                                        onClick={() => {
                                          ConfirmationDialog({
                                            title: "Delete Group",
                                            message:
                                              "Are you sure you want to delete this Group?",
                                            onConfirm: handleDeleteGC,
                                          });
                                        }}
                                      >
                                        Delete Group
                                      </Link>
                                    </strong>
                                  </div>
                                </div>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="toggle_comment">
                  <div className="toggle_body_inner">
                    <div className="toggle_comment_header">
                      <div className="toggle_header_title">
                        <figure>
                          <img
                            src={
                              singleFilterData?.dummyUserData === null
                                ? singleFilterData?.user?.ProfileImage === null
                                  ? user1
                                  : `${baseUrlMedia?.baseUrl}${singleFilterData?.user?.ProfileImage}`
                                : singleFilterData?.dummyUserData
                                    ?.ProfilePic === null
                                ? user1
                                : `${baseUrlMedia?.dummyUserBaseUrl}${singleFilterData?.dummyUserData?.ProfilePic}`
                            }
                            alt="img"
                          />
                        </figure>
                        <h2>
                          {singleFilterData?.dummyUserData === null
                            ? `${singleFilterData?.user?.Firstname} ${
                                singleFilterData?.user?.Lastname === null
                                  ? ""
                                  : singleFilterData?.user?.Lastname
                              }`
                            : `${singleFilterData?.dummyUserData?.Firstname} ${
                                singleFilterData?.dummyUserData?.Lastname ===
                                null
                                  ? ""
                                  : singleFilterData?.dummyUserData?.Lastname
                              }`}
                        </h2>
                        <small>
                          <Moment fromNow>
                            {moment(newTimeChatComment).add(1, "hours")}
                          </Moment>
                        </small>
                        <span
                          className="toggle-comment-close same-close-bar"
                          onClick={CloseComment}
                        >
                          <i className="fa-solid fa-xmark" />
                        </span>
                      </div>
                      <p>
                        {/* {extractUsernameAndText(
                          singleFilterData?.Message?.length > 200
                            ? singleFilterData?.Message.slice(0, 200) + "..."
                            : singleFilterData?.Message
                        )} */}
                        {highlightUsernames(
                          singleFilterData?.Message?.slice(0, 200)
                        )}
                        {singleFilterData?.Message?.length > 200 ? "..." : ""}
                      </p>
                    </div>
                    <div className="comment_show_list">
                      <h3>Comments</h3>
                      <span>( {commentList?.length} )</span>
                    </div>
                    <div className="toggle_comment_bodylist">
                      <ul>
                        {commentList?.map((data, index) => {
                          const filterId = data?.UserID == GtfConnectId;
                          const newTimeChat = new Date(
                            data?.CreatedAt
                          ).toLocaleString("en-US", {
                            timeZone: "Asia/Kolkata",
                          });
                          return (
                            <li key={index} ref={scrollBottom}>
                              <div className="toggle_comment_blog">
                                <figure>
                                  <img
                                    src={
                                      data?.dummyUserData === null
                                        ? data?.user?.ProfileImage === null
                                          ? user1
                                          : `${baseUrlMedia?.baseUrl}${data?.user?.ProfileImage}`
                                        : data?.dummyUserData?.ProfilePic ===
                                          null
                                        ? user1
                                        : `${baseUrlMedia?.dummyUserBaseUrl}${data?.dummyUserData?.ProfilePic}`
                                    }
                                    alt="img"
                                  />
                                </figure>
                                <div className="toggle_comment_content">
                                  <h4>
                                    {data?.dummyUserData === null
                                      ? `${data?.user?.Firstname} ${
                                          data?.user?.Lastname === null
                                            ? ""
                                            : data?.user?.Lastname
                                        }`
                                      : `${data?.dummyUserData?.Firstname} ${
                                          data?.dummyUserData?.Lastname === null
                                            ? ""
                                            : data?.dummyUserData?.Lastname
                                        }`}
                                    {filterId && ` ( you ) `}
                                  </h4>
                                  <small>
                                    <Moment fromNow>
                                      {moment(newTimeChat).add(0, "hours")}
                                    </Moment>
                                  </small>

                                  <LargeComment comment={data?.comment} />
                                  {/* <LargeComment comment={data?.comment} /> */}

                                  {filterId && (
                                    <div className="comment_edit_link">
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          inputref.current.focus();
                                          editComment(data);
                                        }}
                                      >
                                        Edit
                                      </Link>
                                      <Link
                                        to="#"
                                        className="delete-link"
                                        onClick={() => {
                                          ConfirmationDialog({
                                            title: "Delete Comment",
                                            message:
                                              "Do you really want to delete this Comment?",
                                            onConfirm: () =>
                                              deleteComment(data),
                                          });
                                          setShowMenu(false);
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {userRoleSlugSettingPermission === "super-admin" ||
                    gcSettingPermissionData?.full_permission == 1 ||
                    allowDisscussionUpdatePermission?.AllowDiscussion == 1 ? (
                      <div className="toggle_comment_footer">
                        <div className="toggle_form_footer d-flex justify-content-center">
                          {userRoleSlugSettingPermission === "super-admin" ||
                          gcSettingPermissionData?.full_permission == 1 ||
                          gcSettingPermissionData?.dummy_message == 1
                            ? getGroupChannelType() === "group" && (
                                <span
                                  className="dummy_user dummy_user_commented"
                                  id="commentBtn"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() => {
                                    dispatch(getDummyUser(channelGroupID));
                                    // setSelectedDummyUser({});
                                  }}
                                >
                                  {getFirstCharacter}
                                </span>
                              )
                            : ""}
                          <div className="commentbox-footer">
                            {getGroupChannelType() === "group" &&
                              showTagUsers && (
                                <div className="taguser_list">
                                  <div className="taguser_heading">
                                    <h2>Suggestions</h2>
                                  </div>
                                  <ul>
                                    {filteredUsers?.map((user, index) => (
                                      <li key={index}>
                                        <div
                                          className="taguser_group"
                                          onClick={() => handleUserClick(user)}
                                        >
                                          <figure>
                                            <img
                                              src={
                                                user?.IsDummyUser === true
                                                  ? user?.ProfileImage
                                                    ? `${tagUserImgUrl?.dummyUserBaseUrl}${user?.ProfileImage}`
                                                    : images5
                                                  : user?.ProfileImage
                                                  ? `${tagUserImgUrl?.baseUrl}${user?.ProfileImage}`
                                                  : images5
                                              }
                                              alt="img"
                                            />
                                          </figure>
                                          <strong>
                                            <span>{user.Firstname}</span>{" "}
                                            {user?.Lastname}
                                          </strong>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                          <div
                            className="dropdown-menu border-0-p comment-dropdwon-padding"
                            aria-labelledby="commentBtn"
                          >
                            <div className="notification-list_dummyuser">
                              <strong>Choose a user</strong>
                              <div className="notification-check">
                                {dummyUsers?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="check-noification-box"
                                    >
                                      <input
                                        type="radio"
                                        className="checknotification"
                                        id={item?.Firstname}
                                        name="dummyusercomment"
                                        // onChange={() =>
                                        //   setDummyUserObjComment(item)
                                        // }
                                        onChange={() =>
                                          setSelectedDummyUser(item)
                                        }
                                        checked={selectedDummyUser === item}
                                      />
                                      <label htmlFor={item?.Firstname}>
                                        <span />
                                        {item?.Firstname} {item?.Lastname}
                                      </label>
                                    </div>
                                  );
                                })}
                                <div className="check-noification-box">
                                  <input
                                    type="radio"
                                    className="checknotification"
                                    id="Unmute-1"
                                    value="Unmute"
                                    name="dummyusercomment"
                                    onChange={() => setSelectedDummyUser({})}
                                  />
                                  <label htmlFor="Unmute-1">
                                    <span />
                                    You
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form>
                            <div className="flex-inline">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Comment"
                                value={commentData}
                                ref={inputref}
                                onChange={handleChangeComment}
                              />
                              <button
                                type="submit"
                                className="send-btn"
                                onClick={submitComment}
                              >
                                Send
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <p className="text-white justify-content-center d-flex">
                        Discussion not allowed
                      </p>
                    )}
                  </div>
                  <div className="toggle_delete_popup">
                    <div className="toggle_delete_inner">
                      <div className="delete_modal">
                        <div className="delete_body">
                          <h2>Are you sure ?</h2>
                          <p>You want to delete this comment</p>
                          <div className="delete_cancel_btn">
                            <Link to="#" className="cancel-btn">
                              Cancel
                            </Link>
                            <Link to="#" className="delete-btn">
                              Delete
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* search message popup */}
                <div className="search_toggle_massage">
                  <div className="search-inner-massage">
                    <div className="search-header-bar">
                      <h3>Search </h3>
                      <span
                        className="toggle-setting-close same-close-bar"
                        onClick={() => {
                          setMessageList([]);
                          setSearchKey("");
                          setSearchMessage({});
                          setShowSearchPopup(false);
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <div className="search-massage-input">
                      <div className="form-group-input">
                        <button className="saearch-icon">
                          <img src={searchIcon} alt="img" />
                        </button>
                        <input
                          type="Search"
                          value={searchKey}
                          className="search-massage-form"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="search-massage-found">
                      {searchLoading && (
                        <ClipLoader color="#2fb463" cssOverride={override} />
                      )}
                      <ul>
                        {messageList?.length > 0
                          ? messageList?.map((message, index) => (
                              <li key={index}>
                                <div
                                  className="vc_search-list"
                                  onClick={() => handleFindeMessage(message)}
                                >
                                  <div className="vc-img">
                                    <img
                                      src={
                                        stage === "mentor" ||
                                        stage === "student"
                                          ? message?.SenderId ===
                                            message?.senderData?.UserID
                                            ? profileUrl +
                                              message?.senderData?.ProfileImage
                                            : profileUrl +
                                              message?.recipientData
                                                ?.ProfileImage
                                          : message?.user?.ProfileImage === null
                                          ? images5
                                          : profileUrl +
                                              message?.user?.ProfileImage ||
                                            images5
                                      }
                                      alt="img"
                                    />
                                  </div>
                                  <div className="vc-content-search">
                                    <strong>
                                      {mentDetails
                                        ? highlightUsernames(
                                            message?.Content?.slice(0, 200)
                                          )
                                        : highlightUsernames(
                                            message?.Message?.slice(0, 200)
                                          )}
                                      {message?.Message?.length > 200
                                        ? "..."
                                        : ""}
                                      {message?.Content?.length > 200
                                        ? "..."
                                        : ""}
                                    </strong>
                                    <p className="chatlist_search_name">
                                      {mentDetails
                                        ? message?.SenderId ===
                                          message?.senderData?.UserID
                                          ? `${
                                              message?.senderData?.Firstname
                                            } ${
                                              message?.senderData?.Lastname ===
                                              null
                                                ? ""
                                                : message?.senderData?.Lastname
                                            }`
                                          : `${
                                              message?.recipientData?.Firstname
                                            } ${
                                              message?.recipientData
                                                ?.Lastname === null
                                                ? ""
                                                : message?.recipientData
                                                    ?.Lastname
                                            }`
                                        : message?.dummyUserData === null
                                        ? `${message?.user?.Firstname} ${
                                            message?.user?.Lastname === null
                                              ? ""
                                              : message?.user?.Lastname
                                          }`
                                        : `${
                                            message?.dummyUserData?.Firstname
                                          } ${
                                            message?.dummyUserData?.Lastname ===
                                            null
                                              ? ""
                                              : message?.dummyUserData?.Lastname
                                          }`}
                                      <span></span>
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))
                          : !searchLoading && (
                              <p
                                style={{
                                  color: "white",

                                  textAlign: "center",
                                }}
                              >
                                No Message Found!!
                              </p>
                            )}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* //. search message popup */}
              </div>
            </div>

            {/* ******************************* User profile show popup ******************************* */}

            <div
              className={`toggle-side-bar ${
                userOpenSetting
                  ? "open-setting"
                  : "" || userOpenProfile
                  ? "open-profile"
                  : ""
              }`}
              id="userShowProfile"
              ref={userProfileRef}
            >
              <div className="toggle-side-inner">
                <div className="toggle-profile ">
                  <div className="toggle-profile-inner">
                    <span
                      className="profile-close same-close-bar"
                      onClick={() => {
                        setUserOpenProfile(!userOpenProfile);
                      }}
                    >
                      <i className="fa-solid fa-xmark" />
                    </span>
                    <div className="profile-header-toggle">
                      <div className="user-img-profile">
                        <figure>
                          <img
                            src={
                              GCUserInfoMedia?.userInfo?.ProfileImage.endsWith(
                                "user_profile/"
                              )
                                ? user1
                                : GCUserInfoMedia?.userInfo?.ProfileImage
                            }
                            alt="img"
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </figure>
                        <div className="user-content-profile">
                          <h3>{`${GCUserInfoMedia?.userInfo?.Firstname} ${
                            GCUserInfoMedia?.userInfo?.Lastname === null
                              ? ""
                              : GCUserInfoMedia?.userInfo?.Lastname
                          }`}</h3>
                          {/* <span>Online</span> */}
                        </div>
                      </div>
                    </div>
                    <div className="profile-body-toggle">
                      <div className="profile-body-inner">
                        <div className="profile-body-menu">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#tab1"
                              >
                                Media
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tab2"
                              >
                                Docs
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="profile-body-content">
                          <div className="tab-content">
                            <div
                              id="tab1"
                              className="tab-pane fade show active"
                            >
                              <div className="media_upload_img">
                                <ul>
                                  {GCUserInfoMedia?.media &&
                                  GCUserInfoMedia?.media?.length > 0 ? (
                                    GCUserInfoMedia?.media?.map(
                                      (media, index) => {
                                        return (
                                          <>
                                            {media?.MimeType?.startsWith(
                                              "image"
                                            ) && (
                                              <li>
                                                <a
                                                  href=""
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#imagesmodalimg"
                                                  onClick={() => {
                                                    dispatch(
                                                      showPreviewImage(media)
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src={`${GCUserInfoMedia?.base_url_node}${media?.StoragePath}${media?.FileName}`}
                                                    alt="img"
                                                    style={{
                                                      height: "100%",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </a>
                                              </li>
                                            )}
                                            {media?.MimeType?.startsWith(
                                              "video"
                                            ) && (
                                              <GCUserVideoPlayPause
                                                baseUrlMedia={GCUserInfoMedia}
                                                media={media}
                                                index={index}
                                                videoPlay={videoPlay}
                                              />
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p className="text-white m-auto ">
                                      No Media Files..
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div
                              id="tab2"
                              className="tab-pane fade showuser_media"
                            >
                              <div className="docs_file">
                                <ul>
                                  {GCUserInfoMedia?.document &&
                                  GCUserInfoMedia?.document?.length > 0 ? (
                                    userInfoDocumentList
                                  ) : (
                                    <p className="text-white d-flex justify-content-center">
                                      No Document..
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(GCUserInfoMedia?.userInfo?.UserID !== LoginUser?.UserID) && <div className="userblock_report">
                  {(gcSettingPermissionData?.block_unblock_gc_member == 1 ||
                    gcSettingPermissionData?.full_permission == 1 ||
                    userRoleSlugSettingPermission === "super-admin" ) ? (
                      <span
                        className="user_block"
                        onClick={() => {
                          GCUserInfoMedia?.userInfo?.gc_member?.Status ===
                            "Blocked" || blockUserStatusUpdate === true
                            ? dispatch(
                                globalBlockListUnblockMemberStatus({
                                  GCMemberID: GCuserBlockMemberID,
                                  GroupChannelID: groupChannelDetails?.gc_info?.GroupChannelID,
                                  setUserBlockStatusUpdate
                                })
                              )
                            : dispatch(
                                updateMemberStatus({
                                  GCuserBlockMemberID,
                                  getGroupChannelId: groupChannelDetails?.gc_info?.GroupChannelID,
                                  setUserBlockStatusUpdate,
                                })
                              );
                            setUserOpenProfile(false)
                        }}
                      >
                        {GCUserInfoMedia?.userInfo?.gc_member?.Status ===
                          "Blocked" || blockUserStatusUpdate === true
                          ? "Unblock"
                          : "Block"}
                      </span>
                    ) : null}

                  {GCUserInfoMedia?.userInfo?.user_report === null &&
                  reportUserUpdateStatus !== true ? (
                    <span
                      className="user_report"
                      data-bs-toggle="modal"
                      data-bs-target="#userReport"
                      onClick={() => dispatch(userReportListData())}
                    >
                      Report User
                    </span>
                  ) : (
                    <span className="user_report">Already Reported</span>
                  )}
                </div>}
              </div>
            </div>

            {/* *********************************** //. User Profile Show Popup End *************************************** */}

            {/* ******************************************* GC Search Message ************************************************ */}

            {/* <div
              className={`toggle-side-bar ${
                userOpenSetting
                  ? "open-setting"
                  : "" || userOpenProfile
                  ? "open-profile"
                  : ""
              }`}
              id="GCSearchMessageShowPopup"
            >
              <div className="toggle-side-inner">
                <div className="toggle-profile ">
                  <div className="toggle-profile-inner">
                    <span className="profile-close same-close-bar">
                      <i className="fa-solid fa-xmark" />
                    </span>

                    <div className="profile-header-toggle">
                      <div className="user-img-profile">
                        <figure>
                          <img
                            src={user1}
                            alt="img"
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </figure>
                        <div className="user-content-profile">
                          <h3>Hello</h3>
                          <span>Online</span>
                        </div>
                      </div>
                    </div>
                    <div className="profile-body-toggle">
                      <div className="profile-body-inner">
                        <div className="profile-body-menu">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#tab1"
                              >
                                Media
                              </a>
                            </li>
                            <li className="nav-item" role="presentation">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tab2"
                              >
                                Docs
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="profile-body-content">
                          <div className="tab-content">
                            <div
                              id="tab1"
                              className="tab-pane fade show active"
                            >
                              <div className="media_upload_img">
                                <ul>
                                  {GCUserInfoMedia?.media &&
                                  GCUserInfoMedia?.media?.length > 0 ? (
                                    GCUserInfoMedia?.media?.map(
                                      (media, indix) => {
                                        return (
                                          <>
                                            {media?.MimeType?.startsWith(
                                              "image"
                                            ) && (
                                              <li>
                                                <figure>
                                                  <img
                                                    src={`${GCUserInfoMedia?.base_url_node}${media?.StoragePath}${media?.FileName}`}
                                                    alt="img"
                                                    style={{ height: "100%" }}
                                                  />
                                                </figure>
                                              </li>
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p className="text-white m-auto ">
                                      No Media Files..
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div
                              id="tab2"
                              className="tab-pane fade showuser_media"
                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="userblock_report">
                  <span className="user_block"></span>
                </div>
              </div>
            </div> */}

            {/* ******************************************* //. GC Search Message ************************************************ */}

            {/* ********************************************* user block report show popup ********** **************************************** */}

            <div
              className="modal fade edit-profile group_setting_reaction userreportdesign"
              id="userReport"
            >
              <div className="modal-dialog">
                <div className="modal-content userreportshowpopup">
                  <div className="modal-header">
                    <div className="setting-header-bar mb-0 user_report_setting_header_bar">
                      <div className="back_btn">
                        <i
                          className="fa-solid fa-arrow-left"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></i>
                      </div>
                      <h3 className="text-white">Report User</h3>
                      <div className="user_report_button">
                        <button
                          type="button"
                          className="btn-close-permisssion"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="toggle-setting-body">
                      <div className="setting-body-inner user_report_inner">
                        <hr className="group_setting_hr" />

                        <ul className="channel-list report_User_List_show">
                          <hr className="group_setting_hr" />
                          {reportUserListData?.list?.map((list, index) => {
                            return (
                              <li key={index}>
                                <input
                                  type="radio"
                                  id={list?.ReportReasonID}
                                  name="report_user"
                                  value={list?.ReasonCode}
                                  onClick={() =>
                                    setReportReasonID(list?.ReportReasonID)
                                  }
                                />
                                <label
                                  className="report_user_label"
                                  for={list?.ReasonCode}
                                >
                                  {list?.ReasonText}
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <button
                        className="report_user_button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          dispatch(
                            userAddReport({
                              getGroupChannelId,
                              GCMemberIDUserReport,
                              reportReasonID,
                            })
                          );
                          dispatch(
                            reportUserGCUserInfoMediaApi({
                              getGroupChannelId: getGroupChannelId,
                              GCMemberID: GCMemberIDUserReport,
                              setReportUserUpdateStatus:
                                setReportUserUpdateStatus,
                            })
                          );
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ********************************************* user block report show popup ********** **************************************** */}

            {/* ******************************* User profile show popup ******************************* */}
          </div>
        </div>
      )}

      {/* Forward Modal popup for save and forword message */}
      <div
        className="modal fade forward-modal modal-backdrop-overlay"
        id="Forward"
        tabIndex={-1}
        aria-labelledby="ForwardLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Forward To
              </h1>
              <button
                type="button"
                className="btn-close-c"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setSearchValue("");
                  setForwardList(mentors);
                }}
              >
                Close
              </button>
            </div>
            <div className="modal-body">
              <div className="forward-list">
                <div className="forward-search">
                  <div className="search-group">
                    <input
                      type="text"
                      value={searchValue}
                      placeholder="Search Mentor"
                      className="form-control"
                      onChange={handleFilterForwardList}
                    />
                    <button type="button">
                      <img src={searchVector} alt="search vector" />
                    </button>
                  </div>
                </div>
                <div className="forward-inner">
                  {!savedMessageForwordConditon && (
                    <div
                      className="forward-blog cursor-pointer"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={savedMessageForword}
                    >
                      <figure>
                        <img src={bookmark} alt="" style={{
                          "width": "32px" ,
                          'height': "32px"  ,
                          "marginTop": '4px',
                          "marginLeft": "2px",
                        }}/>
                      </figure>
                      Save Message
                    </div>
                  )}
                  {forwardList?.map((mentor, index) => {
                    const messageData =
                      mentor?.data_type === "gc_member"
                        ? mentor?.data_gc_member
                        : mentor?.data_user;
                    const role =
                      stage === "mentor" || stage === " user" ? "User" : "GC";
                    return (
                      <div
                        className="forward-blog"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        key={index}
                        onClick={() => mentorForwardMessage(messageData, role)}
                      >
                        <figure>
                          {mentor?.data_type === "gc_member" ? (
                            <img
                              src={
                                mentor?.data_gc_member?.group_channel
                                  ?.ProfileImage === null
                                  ? user1
                                  : mentor?.data_gc_member?.group_channel
                                      ?.ProfileImage
                              }
                              alt="img"
                            />
                          ) : mentor?.data_type === "user" ? (
                            <img
                              src={
                                mentor?.data_user?.ProfileImage.includes(
                                  "jpeg"
                                ) ||
                                mentor?.data_user?.ProfileImage.includes(
                                  "jpg"
                                ) ||
                                mentor?.data_user?.ProfileImage.includes("png")
                                  ? mentor?.data_user?.ProfileImage
                                  : user1
                              }
                              alt="img"
                            />
                          ) : (
                            <img
                              src={
                                mentor?.data_user?.ProfileImage === null
                                  ? user1
                                  : mentor?.data_user?.ProfileImage
                              }
                              alt="img"
                            />
                          )}
                        </figure>
                        {mentor?.data_type === "gc_member"
                          ? `${mentor?.data_gc_member?.group_channel?.Name}`
                          : (!!mentor?.data_user?.Firstname && !!mentor?.data_user?.Lastname) ? 
                          `${mentor?.data_user?.Firstname} ${
                            mentor?.data_user?.Lastname === null
                              ? ""
                              : mentor?.data_user?.Lastname
                          }`:((mentor?.data_user?.Email.length > 20) ? mentor?.data_user?.Email.slice(0,20) + "..." : mentor?.data_user?.Email)
                          }
                        {/* <br /> ({mentor?.data_user?.RoleSlug}) */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* *******************************Manupulate view****************************** */}

      <div
        className="modal fade manipulate-modal modal-backdrop-overlay"
        id="Manipulate"
        tabIndex={-1}
        aria-labelledby="CopyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog manipulateModal">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Manipulate Views
              </h1>
            </div>
            <div className="modal-body">
              <Slider
                step={10}
                min={10}
                max={100}
                style={{ width: "100%", height: "20px", color: "green" }}
                dots={true}
                dotStyle={dotStyle}
                trackStyle={trackStyle}
                activeDotStyle={activeDotStyle}
                marks={marks}
                markStyle={markStyle}
                value={manipulateValue}
                handleStyle={handleStyle}
                onChange={(e) => {
                  handleSliderChange(e);
                }}
              />
            </div>
            <div className="modal-footer">
              <button type="button" data-bs-dismiss="modal">
                Cancel
              </button>

              <button
                type="button"
                className="btn-primary"
                data-bs-dismiss="modal"
                onClick={() =>
                  dispatch(
                    updateGcSetting({
                      channelGroupID,
                      Type,
                      channelDisscussion,
                      channelSignedMsg,
                      channelEnableManipulateViews,
                      channelSharingContent: channelSharingContent,
                      channelReaction: Reaction,
                      manipulateValue: manipulateValue,
                      chatHistoryStatus
                    })
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* *********************end manupulate view********************************* */}
      {/* ------------------------------ Pin Messsage--------------------------------  */}
      <div
        className="modal fade pin-modal modal-backdrop-overlay"
        id="Pin"
        tabIndex={-1}
        aria-labelledby="PinLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------- Image Chat preview image ------------------------------ */}
      <div
        className="modal fade images-chat-modal modal-backdrop-overlay"
        id="imagesmodalimg"
        tabIndex={-1}
        aria-labelledby="imagesLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close-c"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeModel} alt="close-modal" />
              </button>
              <button
                type="button"
                className="btn-close-c download-btn"
                value="download"
              >
                <img src={downloading} alt="img" onClick={downloadImage} />
              </button>
            </div>
            <div className="modal-body">
              <figure>
                <img src={imgSrc} alt="img" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      {/* Saved chat preview image */}
      <div
        className="modal fade images-chat-modal modal-backdrop-overlay"
        id="imagesmodalsavedimg"
        tabIndex={-1}
        aria-labelledby="imagesLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close-c"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeModel} alt="close-modal" />
              </button>
              <button
                type="button"
                className="btn-close-c download-btn"
                value="download"
              >
                <img
                  src={downloading}
                  alt="img"
                  onClick={savedMessageDownloadImage}
                />
              </button>
            </div>
            <div className="modal-body">
              <figure>
                <img
                  src={`${savedMessageMediaFilesBaseUrl}${showPreviewImagess?.StoragePath}${showPreviewImagess?.FileName}`}
                  alt="img"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChatContent);
