import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: undefined
};

export const CurrentChatBoxInfo = createSlice({
  name: "currentChatBoxInfo",
  initialState,
  reducers: {
    updateChatBoxData: (state, action) => {
      state.data = action?.payload
    },
  }
});

export const {updateChatBoxData} = CurrentChatBoxInfo.actions;

export default CurrentChatBoxInfo.reducer;
