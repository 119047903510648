import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrlMain } from "../../store/axios";
import { tokenExpireCheckStatus, userID } from "../../Component/GlobalLocalStorage";
// const userID = JSON.parse(localStorage.getItem("gtfconnectid"));

const initialState = {
  allPinMessage: [],
  pinMessage: "",
};

export const PinMessage = createSlice({
  name: "pinMessage",
  initialState,
  reducers: {
    allPinMessages: (state, action) => {
      state.allPinMessage = action.payload;
    },
    pinMessages: (state, action) => {
      state.pinMessage = action.payload;
    },
  },
});

export const { allPinMessages, pinMessages, removeOnePinMessages } =
  PinMessage.actions;

export default PinMessage.reducer;

export const addPinMessage = (payload) => {
  const { GCMemberID, GroupChannelID, GroupChatID } = payload;
  return async (dispatch) => {
    try {
      await baseUrlMain
        .get(
          `pinMessage?GCMemberID=${GCMemberID}&GroupChannelID=${GroupChannelID}&UserID=${userID()}&GroupChatID=${GroupChatID}`
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(pinMessages(res?.data));
            toast.success(res?.data?.message);
            baseUrlMain
              .get(
                `allPinMessage?GroupChannelID=${GroupChannelID}&UserID=${userID()}`
              )
              .then((res) => {
                if (res?.data?.status === 200) {
                  dispatch(allPinMessages(res?.data));
                }
              });
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.error("error :", error);
    }
  };
};

export const removePinMessages = (payload) => {
  const { pinmessagesID, channelGroupID, setPinMesssageState } = payload;
  return async (dispatch) => {
    try {
      await baseUrlMain
        .get(
          `/removePinMessage?UserID=${userID()}&pinmessagesID=${pinmessagesID}`
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(pinMessages(res?.data));
            toast.success(res?.data?.message);
            baseUrlMain
              .get(
                `allPinMessage?GroupChannelID=${channelGroupID}&UserID=${userID()}`
              )
              .then((res) => {
                if (res?.data?.status === 200) {
                  dispatch(allPinMessages(res?.data));
                  res?.data?.data?.allPinData?.length === 0 &&
                    setPinMesssageState(false);
                } else if (res?.data?.status === 401) {
                  tokenExpireCheckStatus(false, res?.data?.message);
                }
              });
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.error("error :", error);
    }
  };
};
export const allPinMessagess = (payload) => {
  return async (dispatch) => {
    try {
      await baseUrlMain
        .get(`allPinMessage?GroupChannelID=${payload}&UserID=${userID()}`)
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(allPinMessages(res?.data));
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.error("error :", error);
    }
  };
};
export const unPinAllMessages = (payload) => {
  const { GroupChannelID, setPinMesssageState } = payload;
  return async (dispatch) => {
    try {
      await baseUrlMain
        .get(
          `/removeAllPinMessage?GroupChannelID=${GroupChannelID}&UserID=${userID()}`
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(pinMessages(res?.data));
            toast.success(res?.data?.message);
            // setPinMesssageState(false);
            baseUrlMain
              .get(
                `allPinMessage?GroupChannelID=${GroupChannelID}&UserID=${userID()}`
              )
              .then((res) => {
                if (res?.data?.status === 200) {
                  dispatch(allPinMessages(res?.data));
                } else if (res?.data?.status === 401) {
                  tokenExpireCheckStatus(false, res?.data?.message);
                }
              });
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.error("unPinAllMessages error :", error);
    }
  };
};
