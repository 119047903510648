import React from "react";
import GCChatlistVideoPlayPause from "../VideoPlayPause/GCChatlistVideoPlayPause";

const MediaChatComponent = ({
  mediaToShow,
  allItems,
  baseUrlMedia,
  dispatch,
  showPreviewImage,
  setGallary,
  setShowGallary,
  checkGlobalPermission,
  videoPlay,
  stage,
}) => {
  const showMediaInChat = mediaToShow?.map((media, index) => {
    let bool = index === 3 ? true : false;
    let count = allItems?.length;
    if (media?.MimeType?.startsWith("image")) {
      return (
        <div className="images_view" key={index} id={index}>
          <a
            href=""
            data-bs-toggle="modal"
            data-bs-target={ stage === "save" ? "#imagesmodalsavedimg" :"#imagesmodalimg"}
            onClick={() => dispatch(showPreviewImage(media))}
          >
            <img
              width={38}
              height={38}
              alt={media?.name}
              key={index}
              src={`${
                stage === "save" ? baseUrlMedia : baseUrlMedia?.mediaUrl
              }${media?.StoragePath}${media?.FileName}`}
            />
          </a>
          {bool && (
            <div
              className="image-counter"
              onClick={() => {
                setGallary(allItems);
                setShowGallary(true);
              }}
            >
              +{count}
            </div>
          )}
        </div>
      );
    } else if (media?.MimeType?.startsWith("video")) {
      return (
        <GCChatlistVideoPlayPause
          key={index}
          media={media}
          baseUrlMedia={baseUrlMedia}
          checkGlobalPermission={checkGlobalPermission}
          videoPlay={videoPlay}
          index={index}
          bool={bool}
          count={count}
          allItems={allItems}
          setShowGallary={setShowGallary}
          setGallary={setGallary}
          stage={stage}
        />
      );
    }
  });

  return <>{showMediaInChat}</>;
};

export default React.memo(MediaChatComponent);
