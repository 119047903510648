import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Timer from "otp-timer";
import {
  app_store,
  google_play,
  loginleftbox_image_1,
  loginleftbox_image_2,
  loginleftbox_image_3,
  loginleftbox_image_4,
  loginleftbox_image_5,
  loginleftbox_image_6,
  paymentLogo,
  otpVerificationLogo,
} from "../../Images/images";
import { useDispatch } from "react-redux";
import { fetchOtpverification } from "../../reducer/userAuth/OtpVerification";
import { LoadingButton } from "@mui/lab";
import { fetchResendOTP } from "../../reducer/userAuth/resendOtp";

// Otp Verification
const OtpVerification = () => {
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);
  const getAuthToken = localStorage.getItem("token");
  const getUserEmail = localStorage.getItem("email");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Handle Protect Routes
  useEffect(() => {
    getAuthToken && navigate("/");
  }, []);

  const getemail = localStorage.getItem("email");

  // Otp verification Handler
  const OtpVerificationHandler = (values) => {
    dispatch(
      fetchOtpverification({
        email: getemail,
        otp: otpDigits.join(""),
        navigate,
        setLoading,
      })
    );
  };

  //  Handle OTP Change Event
  const handleOtpChange = (index, event) => {
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = event.target.value;

    if (event.target.value === "") {
      // If the input is empty, focus on the previous input
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (index < otpDigits.length - 1) {
      // If the input is not empty and we're not at the last input, focus on the next input
      inputRefs.current[index + 1].focus();
    }
    setOtpDigits(newOtpDigits);
  };

  // resend OTP
  const resendOTP = () => {
    dispatch(fetchResendOTP({ email: getemail, setLoading }));
  };

  return (
    <>
      <section className="login register">
        <div className="container">
          <div className="row loginrow">
            <div className="col-md-6 loginleft">
              <div className="loginleftbox">
                <div className="loginleftbox_image">
                  <div className="loginleftbox_image_1">
                    <img
                      src={loginleftbox_image_1}
                      alt="loginleftbox_image_1"
                    />
                  </div>
                  <div className="loginleftbox_image_2">
                    <img
                      src={loginleftbox_image_2}
                      alt="loginleftbox_image_2"
                    />
                  </div>
                  <div className="loginleftbox_image_3">
                    <img
                      src={loginleftbox_image_3}
                      alt="loginleftbox_image_3"
                    />
                  </div>
                  <div className="loginleftbox_image_4">
                    <img
                      src={loginleftbox_image_4}
                      alt="loginleftbox_image_4"
                    />
                  </div>
                  <div className="loginleftbox_image_5">
                    <img
                      src={loginleftbox_image_5}
                      alt="loginleftbox_image_5"
                    />
                  </div>
                  <div className="loginleftbox_image_6">
                    <img
                      src={loginleftbox_image_6}
                      alt="loginleftbox_image_6"
                    />
                  </div>
                </div>
                <div className="loginleftbox_app d-flex justify-content-center flex-wrap align-items-center">
                  <Link to="#">
                    <img src={app_store} alt="app-store" />
                  </Link>
                  <Link to="#">
                    <img src={google_play} alt="google-play" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 loginright">
              <div className="loginrightbox otp_verification">
                <div className="loginrightbox_logo">
                  <Link to="#">
                    <img src={paymentLogo} alt="img" />
                  </Link>
                </div>
                <div className="loginrightbox_form">
                  <h3>Please Check Your Mail</h3>
                  <div className="otp_form_group">
                    <div className="otp_head">
                      <img src={otpVerificationLogo} alt="otp" />
                      <strong>Enter OTP</strong>
                    </div>
                    <div className="flex_input">
                      {otpDigits.map((digit, index) => (
                        <input
                          key={index}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          type="text"
                          value={digit}
                          maxLength={1}
                          onChange={(event) => handleOtpChange(index, event)}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="otp_text">
                    <p>
                      We Have Send An Mail To <Link>{getUserEmail}</Link>
                    </p>
                    <span className="text-green"></span>
                    <p>To Verify Your Account</p>
                  </div>

                  <div className="form-submit">
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      className="btn btn-login"
                      onClick={OtpVerificationHandler}
                    >
                      Submit
                    </LoadingButton>
                  </div>
                  <div className="back-to-login p-0 pb-3">
                    <Timer
                      seconds={60}
                      ButtonText={
                        <div className="text-otp">
                          <p>
                            {/* Otp not received yet ? */}
                            <span style={{ color: "#2FB463" }}> Resend</span>
                          </p>
                        </div>
                      }
                      resend={resendOTP}
                      buttonColor={"#dc3747"}
                      background={"none"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpVerification;
