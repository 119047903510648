import React, { useState } from "react";
import SettingChat from "../../Component/chatLeft/SettingChat";
import Sidebar from "../../Component/sidebar";
import ContactUsContainer from "./ContactUsContainer";

const ContactUs = () => {
  const [savedMessageState, setSavedMesssageState] = useState(false);
  return (
    <div className="d-flex main_body_inner">
      <Sidebar
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <SettingChat
        title="Settings"
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
    <ContactUsContainer />
    </div>
  );
};

export default ContactUs;
