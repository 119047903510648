import { createSlice } from "@reduxjs/toolkit";
import { baseUrlMain } from "../store/axios";
import { socket } from "../store/SocketIo";
import { tokenExpireCheckStatus, userID } from "../Component/GlobalLocalStorage";
import { toast } from "react-toastify";
// const userID = localStorage.getItem("gtfconnectid");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  sendFile: [],
  progressBar: "",
  status: STATUSES.IDLE,
};
export const FileUploaded = createSlice({
  name: "FileUploaded",
  initialState,
  reducers: {
    fileUpload: (state, action) => {
      state.sendFile = action.payload;
    },
    fileUploadProgressBar: (state, action) => {
      state.progressBar = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});
export const { fileUpload, fileUploadProgressBar, setStatus } =
  FileUploaded.actions;
export default FileUploaded.reducer;

export const FileUploadApi = (payload) => {
  const { GCMemberID, GroupChannelID, Files,audioChunks , Message , DummyUserID} = payload;
  const fileLoad = new FormData();
  fileLoad.append("GCMemberID", GCMemberID);
  fileLoad.append("GroupChannelID", GroupChannelID);
  // for (let i = 0; i < Files?.length; i++) {
  //   fileLoad.append("files", Files[i],'voice.m4a');
  // }
  audioChunks
  ? fileLoad.append('files', audioChunks, 'voice.m4a')
  : Files?.forEach((file) => fileLoad.append('files', file));
  fileLoad.append("UserID", userID());
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlMain
        .post(`/uploadFile`, fileLoad, {
          onUploadProgress: (data) => {
            dispatch(
              fileUploadProgressBar(
                Math.round((data.loaded / data.total) * 100)
              )
            );
          },
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(fileUpload(res?.data?.data));
            const storeData = [];
            res?.data?.data?.forEach((data) =>
              storeData.push(data?.GroupChatMediaID)
            );
            let data = {
              DummyUserID: DummyUserID, 
              UserID: userID(),
              GCMemberID: GCMemberID,
              GroupChannelID: GroupChannelID,
              Message: Message ? Message :"",
              ChatType: "file",
              MediaIds: storeData.toString(),
            };
            socket.emit("sendMessage", data);
            dispatch(setStatus(STATUSES.IDLE));
          }  else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);      
          } else if(res?.data?.status == 413){
            toast.error(res?.data?.message);
            dispatch(setStatus(STATUSES.ERROR)); 
          } else if(res?.data?.status == 400){
            toast.error(res?.data?.message);
            dispatch(setStatus(STATUSES.ERROR)); 
            }
        });
    } catch (error) {
      console.log("error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
