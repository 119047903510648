import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";
import { setUser, setUserRole } from "../UserReducer";
import { fetchMentors } from "../MentorReducer";
import { BrowserPermission } from "../../Component/BrowserPermission";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialUserState = {
  userLoginData: [],
  otherDeviceLogInMessage: "",
  status: STATUSES.IDLE,
};

const userLoginSlice = createSlice({
  name: "user",
  initialState: initialUserState,

  reducers: {
    fetchUserLogin(state, action) {
      state.userLoginData = action.payload;
    },
    fetchUserEmailVerification(state, action) {
      state.emailVerification = action.payload;
    },
    otherDeviceMessageStore(state, action) {
      state.otherDeviceLogInMessage = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { fetchUserLogin, otherDeviceMessageStore, setStatus } =
  userLoginSlice.actions;
export default userLoginSlice.reducer;

export const fetchUserLogins = (payload) => {
  const {
    setLoading,
    Email,
    Password,
    navigate,
    otherDeviceLogOut,
    setUserCrediancialStatus,
  } = payload;
  const formData = new FormData();
  formData.append("Email", Email);
  formData.append("Password", Password);
  formData.append("IsLogging", 1);
  formData.append("logout", 0);
  formData.append("other_device_logout", otherDeviceLogOut);
  formData.append("is_gtf_connect", 1);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      setLoading(true);
      await baseUrlAuth.post("login", formData
      ).then((response) => {
        if (response?.data?.status == 200) {
          localStorage.setItem("UserID", response?.data?.data?.GTFUserID);
          localStorage.setItem("email", response?.data?.data?.Email);
          localStorage.setItem("token", response?.data?.data?.api_token);
          localStorage.setItem("api_token_gtf", response?.data?.data?.api_token_gtf);
          localStorage.setItem("gtfconnectid", response?.data?.data?.UserID);
          localStorage.setItem("user_role_slug", response?.data?.data?.user_role?.primary?.role?.Slug);
          localStorage.setItem("roleID", response?.data?.data?.user_role?.primary?.role?.RoleID);
          dispatch(setUser(response?.data?.data));
          dispatch(setUserRole(response?.data?.data?.user_role));
          navigate("/");
          // toast.success(response?.data?.message);
          dispatch(fetchUserLogin(response?.data));
          dispatch(setStatus(STATUSES.IDLE));
          dispatch(fetchMentors())
          CheckUserDeviceInfo(response?.data?.data?.user_device?.Browser)
        } else if (response?.data?.status === 401) {
          dispatch(otherDeviceMessageStore(response?.data));
          setUserCrediancialStatus(true);
        } else {
          toast.error(response?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log("error :", error);
      dispatch(setStatus(STATUSES.ERROR));
      setLoading(false);
    }
  };
};

const CheckUserDeviceInfo = async(device_info) => {
  // check browser support desktop notification or not
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
    return;
  }

  // save dummy token in local
  localStorage.setItem('device_token_status', device_info?.IsDeviceTokenSaved)
  localStorage.setItem('device_token', device_info?.DeviceToken)

  // If browser permission is not granted
  if (Notification?.permission !== "granted") {
    BrowserPermission(device_info) 
  }  
}




