import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Chats: [],
  prevData: "",
  subscribePerson: 0,
  GroupChannelID: "",
  GCMemberID: "",
  userIsTyping: {},
  lastmessage: "",
  channelgroupid: "",
  getCommentData: "",
  show: false,
  lastMessageObject: {},
  Description: "",
  upDatedlistData: [],
  fileUploadedData: [],
  fileUploadedBaseUrls: "",
  groupinformation: {},
  deleteChatPost: "",
  showZoomImage: "",
  chatlistData: "",
  GCSetttingShowMode: "",
  GCShowMediaFilesData: "",
  storeExclusiveSecGCType: "",
  paymentStatus: "",
  exclusiveFreeGCJoin: "",
};

export const GetChat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    allgroup: (state, action) => {
      state.allGroup = action.payload;
    },
    FullChat: (state, action) => {
      state.Chats = action.payload?.reverse();
    },
    LikeUpdate: (state, action) => {
      state.Chats = action.payload;
    },
  
    // PrevChatData: (state, action) => {
    //   state.Chats = [...action.payload?.reverse(), ...state.Chats];
    // },
    PrevChatData: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.Chats = [...action.payload.reverse(), ...state?.Chats];
      }
    },
    SubscriptionCount: (state, action) => {
      state.subscribePerson = action.payload;
    },
    FileUploadedDatas: (state, action) => {
      state.fileUploadedData = action.payload;
    },
    getchannelmemberId: (state, action) => {
      state.GCMemberID = action.payload;
      state.GroupChannelID = action.payload.GroupChannelID;
    },
    whichUserTyping: (state, action) => {
      state.userIsTyping = action.payload;
    },
    lastMessage: (state, action) => {
      state.lastmessage = action.payload;
    },
    addmessage: (state, action) => {
      const filterChannelID =
        state.groupinformation?.GroupChannelID ===
        action.payload?.GroupChannelID;
      // if (filterChannelID) {
        // Ensure that state.Chats is an array (or initialize it as an empty array)
        state.Chats = [...(state?.Chats || []), action.payload];
      // }
    },
    getGroupChannelId: (state, action) => {
      state.channelgroupid = action.payload?.GroupChannelID;
      state.groupinformation = action.payload;
    },
    getCommentData: (state, action) => {
      state.getCommentData = action.payload;
    },
    initialShow: (state, action) => {
      state.show = action.payload;
    },
    lastMessageData: (state, action) => {
      state.lastMessageObject = action.payload;
    },
    Description: (state, action) => {
      state.Description = action.payload;
    },
    groupChannelID: (state, action) => {
      state.Description = action.payload;
    },
    UpdatedList: (state, action) => {
      state.upDatedlistData = action.payload;
    },
    fileUploadedBaseUrl: (state, action) => {
      state.fileUploadedBaseUrls = action.payload;
    },
    showPreviewImage: (state, action) => {
      state.showZoomImage = action.payload;
    },
    chatlistDeletePost: (state, action) => {
      const updatedChats = state.Chats?.filter(
        (data) => action.payload?.GroupChatID !== data?.GroupChatID
      );

      return {
        ...state,
        deleteChatPost: action.payload,
        Chats: updatedChats,
      };
    },
    GroupHeading: (state, action) => {
      state.GroupHeading = action.payload;
    },
    GCChatlistData: (state, action) => {
      state.chatlistData = action.payload;
    },
    GCSettingShowModeCheckMessage: (state, action) => {
      state.GCSetttingShowMode = action.payload;
    },
    GCShowMediaFiles: (state, action) => {
      state.GCShowMediaFilesData = action.payload;
    },
    storeExclusiveSecGCTypeFormat: (state, action) => {
      state.storeExclusiveSecGCType = action.payload;
    },
    checkPyamentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    exclusiveFreeGCJoinStatus: (state, action) => {
      state.exclusiveFreeGCJoin = action.payload;
    },
  
  },
});

export const {
  FullChat,
  GroupHeading,
  SubscriptionCount,
  getchannelmemberId,
  whichUserTyping,
  GCMemberID,
  LikeUpdate,
  lastMessage,
  addmessage,
  getGroupChannelId,
  getCommentData,
  initialShow,
  PrevChatData,
  lastMessageData,
  Description,
  UpdatedList,
  FileUploadedDatas,
  fileUploadedBaseUrl,
  chatlistDeletePost,
  showPreviewImage,
  GCChatlistData,
  GCSettingShowModeCheckMessage,
  GCShowMediaFiles,
  storeExclusiveSecGCTypeFormat,
  checkPyamentStatus,
  exclusiveFreeGCJoinStatus
} = GetChat.actions;

export default GetChat.reducer;
