import React, { useState } from "react";
import SettingChat from "../../Component/chatLeft/SettingChat";
import Sidebar from "../../Component/sidebar";
import EditProfileContent from "./EditProfileContent";

const EditProfile = () => {
  const [savedMessageState, setSavedMesssageState] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  return (
    <div className="d-flex main_body_inner">
      <Sidebar
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <SettingChat
        title="Settings"
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <EditProfileContent savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
        showCheckbox={showCheckbox}
        setShowCheckbox={setShowCheckbox}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
      />
    </div>
  );
};

export default EditProfile;
