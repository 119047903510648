import { createSlice } from "@reduxjs/toolkit";

const UserData = createSlice({
  name: "user",
  initialState: {
    user: null,
    userRole: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUser, setUserRole } = UserData.actions;

export default UserData.reducer;
