import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { toast } from "react-toastify";
import { CallAPI } from "../../common";
import {
  globalLocalStrogeToken,
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  getSavedMessage: "",
  postSavedMessage: "",
  deletedSavedMessage: "",
  savedGCID: [],
  storeBaseUrl: "",
  status: STATUSES.IDLE,
};
export const SavedMessage = createSlice({
  name: "savedMessage",
  initialState,
  reducers: {
    getSavedMessage: (state, action) => {
      state.getSavedMessage = action.payload.list;
    },
    postSavedMessageList: (state, action) => {
      state.postSavedMessage = action.payload;
    },
    storeSavedMessageBaseUrl: (state, action) => {
      state.storeBaseUrl = action.payload;
    },
    deletedSavedMessagess: (state, action) => {
      state.deletedSavedMessage = action.payload;
      state.getSavedMessage = state.getSavedMessage?.filter((item) => {
        return item?.SavedMessageID !== action?.payload;
      });
    },
    storeSavedMessageGCID: (state, action) => {
      state.savedGCID = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const {
  getSavedMessage,
  postSavedMessageList,
  deletedSavedMessagess,
  storeSavedMessageGCID,
  storeSavedMessageBaseUrl,
  setStatus,
} = SavedMessage.actions;

export default SavedMessage.reducer;

export const fetchGetSavedMessage = () => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    const url = "user/saved-message";
    const method = "GET";
    const params = {
      per_page: 200,
      page: 1,
    };
    const responseData = await CallAPI(url, method, params);
    if (responseData.status === 200) {
      dispatch(storeSavedMessageBaseUrl(responseData?.data));
      dispatch(getSavedMessage(responseData?.data));
      dispatch(setStatus(STATUSES.IDLE));
    } else {
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

export const sendSavedMessages = (payload) => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    const formData = new FormData();
    formData.append("Content", payload);
    formData.append("Action", "Manually");
    try {
      await baseUrlEdit
        .post(`user/saved-message?per_page=50&page=1`, formData)
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(postSavedMessageList(response?.data?.data));
            dispatch(fetchGetSavedMessage());
            dispatch(setStatus(STATUSES.IDLE));
          }
        });
    } catch (error) {
      console.log("error", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

export const sendMediaFilesSavedMessages = (payload) => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    const formData = new FormData();
    for (let i = 0; i < payload?.length; i++) {
      formData.append("File[]", payload[i]);
    }
    formData.append("Action", "Manually");
    try {
      await baseUrlEdit
        .post(`user/saved-message`, formData, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(postSavedMessageList(response?.data?.data));
            dispatch(fetchGetSavedMessage());
            dispatch(setStatus(STATUSES.IDLE));
          }
        });
    } catch (error) {
      console.log("error", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
export const deleteSavedMessageeess = (payload) => {
  const url = `${process.env.REACT_APP_BASE_URL_EDIT}user/saved-message/${payload?.savedMessgedGetData?.item?.SavedMessageID}/delete`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "delete",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${globalLocalStrogeToken()}`,
          "Content-Type": "application/json",
        },
      });

      const text = await response.json();
      if (text?.status === 200) {
        dispatch(
          deletedSavedMessagess(
            payload?.savedMessgedGetData?.item?.SavedMessageID
          )
        );
        toast.success(text?.message);
        payload.setShowMenu(false);
      } else if (text?.status === 401) {
        tokenExpireCheckStatus(false, text?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
};
