import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Description,
  FullChat,
  GCChatlistData,
  GroupHeading,
  SubscriptionCount,
  UpdatedList,
  getGroupChannelId,
  storeNotificationData,
} from "../../reducer/GetChat";
import {
  chatList,
  checkUserRoleAddCondition,
  mentorDetails,
  pinMessageList,
  setConversationID,
  setMediaURL,
  storeMenoteChatInitialData,
} from "../../reducer/MentorReducer";
import { socket } from "../../store/SocketIo";
import {
  DetailsChannelGroupApi,
  groupChannelDetailsData,
} from "../../reducer/DetailsChannelGroup";
import { Bars } from "react-loader-spinner";
import { emmitToSocket } from "../../common";
import { allPinMessages } from "../../reducer/PinMessage";
import {
  setShowGlobalSearchProductPopup,
  setSubscriptionPlanShowPopupData,
  setShowGlobalSearchSubscriptionPopup,
} from "../../reducer/notificationReducer";
import { toast } from "react-toastify";

const NotificationRoute = () => {

  const navigate = useNavigate();
  const urlSearchParamsData = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const notificationData = urlSearchParamsData.get("notificationData");
  const decodedData = decodeURIComponent(notificationData);
     console.log("Hello Check Notification2", JSON.parse(decodedData));
  const connectid = localStorage.getItem("gtfconnectid");
  const mentDetails = useSelector((state) => state?.MentorList?.details);
  const UserID = localStorage.getItem("UserID");
  const roleID = localStorage.getItem("roleID");
  // useEffect(() => {
  //   if (notificationData !== null && notificationData !== undefined) {
  //     const decodedData = decodeURIComponent(notificationData);
  //     const notificationDataObject = JSON.parse(decodedData);
  //     console.log("Hello Check Notification", notificationDataObject);
  //     navigate("/");
  //     localStorage.setItem(
  //       "GroupChannelID",
  //       notificationDataObject?.data?.gcId
  //     );
  //     localStorage.setItem(
  //       "blinkChatID",
  //       notificationDataObject?.data?.chatID
  //       );
  //       // localStorage.setItem(
  //       //   "storeNotificationData",
  //       //   JSON.stringify(notificationDataObject?.data)
  //       //   );
  //    if(notificationDataObject?.data?.eventType === "gc_create"){
  //       localStorage.setItem("checkNotification", false);
  //       selectedChat(notificationDataObject);
  //     }
  //      else {
  //       // localStorage.setItem(
  //       //   "GroupChannelID",
  //       //   notificationDataObject?.data?.gcId
  //       // );
  //       // localStorage.setItem(
  //       //   "blinkChatID",
  //       //   notificationDataObject?.data?.chatID
  //       //   );
  //       //   localStorage.setItem(
  //       //     "storeNotificationData",
  //       //     JSON.stringify(notificationDataObject?.data)
  //       //     );
  //           selectedChat(notificationDataObject);
  //           localStorage.setItem("checkNotification", 1);

  //       localStorage.setItem("GCID", notificationDataObject?.data?.gcId);
  //     }
  //     // fetchMentorChats(notificationDataObject?.data,"home");
  //   } else {
  //     console.log("No notificationData parameter found in the URL.");
  //   }
  // }, [notificationData]);
  // console.log(dataTesting())

  // InitialChat for notification
  useEffect(() => {
    if (!notificationData) {
      console.log("No notificationData parameter found in the URL.");
      return;
    }
  
    const decodedData = decodeURIComponent(notificationData);
    const notificationDataObject = JSON.parse(decodedData);
    console.log(" Check Notification", notificationDataObject);
  
    navigate("/");
    localStorage.setItem("GroupChannelID", notificationDataObject?.data?.gcId);
    // localStorage.setItem("blinkChatID", notificationDataObject?.data?.chatID);
  
    if (notificationDataObject?.data?.type === "MentorMessage") {
      // Handle MentorMessage case
    } else if (notificationDataObject?.data?.eventType === "gc_create") {
      localStorage.setItem("checkNotification", false);
      selectedChat(notificationDataObject);
    } else {
      selectedChat(notificationDataObject);
      localStorage.setItem("checkNotification", 1);
      localStorage.setItem("GCID", notificationDataObject?.data?.gcId);
    }
  }, [notificationData]);
  
  
  
  const selectedChat = (list) => {
    dispatch(mentorDetails(null));
    dispatch(chatList([]));
    dispatch(pinMessageList([]));
    dispatch(getGroupChannelId(list));
    dispatch(checkUserRoleAddCondition(false));
    // else {

    dispatch(DetailsChannelGroupApi(list?.data?.gcId)).then((res) => {
      dispatch(setSubscriptionPlanShowPopupData(res));
      if (list?.data?.eventType === "gc_create" && res?.gc_info !== null) {
        // localStorage.setItem("checkNotification", false);
        dispatch(setShowGlobalSearchSubscriptionPopup(true));
      } else if (res?.gc_info === null) {
        // localStorage.setItem("checkNotification", false);
        dispatch(setShowGlobalSearchSubscriptionPopup(false));
        toast.error("GC Not Found !!");
      } else {
        socket.emit(
          "chatList",
          {
            userId: connectid,
            GroupChannelID: res?.gc_member_info?.GroupChannelID,
            page: 1,
          },
          (response) => {
            dispatch(FullChat(response?.data?.chatData?.rows));
            dispatch(SubscriptionCount(response?.data?.subscriptionCount));
            dispatch(GCChatlistData(response?.data));
          }
        );
      }
    });
    // }
  };

  //initiate mentor conversaion

  // const initiateMentorConversation = (details) => {
  //   let body = {
  //     UserID: localStorage?.getItem("gtfconnectid"),
  //     ChatRecipientID: details?.ConnectUserID || details?.UserID,
  //   };
  //   emmitToSocket("initiateMentorChat", body, (res) => {
  //     setaddClassInMentorList(res?.data?.UserData?.GTFUserID);
  //     if (res?.success) {
  //       fetchMentorChats(res?.data, "initiate");
  //       emmitToSocket(
  //         "auth-user",
  //         { userId: UserID, filter: "mentor" },
  //         (res) => {
  //           dispatch(storeProfilePicUrl(res?.data));
  //         }
  //       );
  //     }
  //   });
  // };

  //fetch mentor's chat
  //    const fetchMentorChats = (data, type) => {

  //     console.log("checkFetchMentorChats",data,type)
  //     // localStorage.removeItem("GCID");
  //   //  if (data?.SenderId === mentDetails?.UserID) {
  //   //    setHomeSecMentorAddClass(true);
  //   //  } else if (data?.SenderId === mentDetails?.UserID) {
  //   //    setHomeSecMentorAddClass(true);
  //   //  }
  //    dispatch(checkUserRoleAddCondition(true));
  //    // store role
  //    dispatch(storeMenoteChatInitialData(data));
  //    const { ConversationID } = data;
  //    let body = {
  //      ConversationID,
  //    };
  //    // Getting mentor list
  //    emmitToSocket("mentorChatList", body, (res) => {
  //      if (res.success) {
  //        dispatch(Description(""));
  //        dispatch(GroupHeading(""));
  //        dispatch(FullChat([]));
  //        dispatch(SubscriptionCount(""));
  //        dispatch(getGroupChannelId(""));
  //        dispatch(GCChatlistData(""));
  //        dispatch(groupChannelDetailsData(""));
  //        dispatch(allPinMessages([]));
  //        emmitToSocket(
  //          "allPinMentorMessage",
  //          { ConversationID, UserID: localStorage?.getItem("gtfconnectid") },
  //          (res) => {
  //            dispatch(pinMessageList(res?.data?.data?.message));
  //          }
  //        );

  //        // mentor section home and mentor data dispatch and read/unread count
  //        if (type === "home") {
  //          // dispatch home recent tab mentor data
  //          dispatch(
  //            mentorDetails(
  //              connectid !== data?.SenderId
  //                ? data?.recipientData
  //                : data?.senderData
  //            )
  //          );

  //          // read/unread count home recent tab for mentor
  //          emmitToSocket(
  //            "readMentorMessage",
  //            {
  //              ConversationID: ConversationID,
  //              UserID: connectid,
  //              RoleID: roleID,
  //            },
  //            (res) => {
  //              if (res.success) {
  //                emmitToSocket("auth-user", { userId: UserID }, (res) => {
  //                  dispatch(UpdatedList(res?.data));
  //                });
  //              }
  //            }
  //          );
  //        } else {
  //          // dispatch mentor section mentor data
  //         //  dispatch(
  //         //    mentorDetails(
  //         //      stage === "mentor" && type !== "initiate"
  //         //        ? data?.ChatRecipientData?.UserID === connectid
  //         //          ? data?.ChatInitiatedUserData
  //         //          : data?.ChatRecipientData
  //         //        : stage === "student" && type !== "initiate"
  //         //        ? data?.ChatInitiatedUserData
  //         //        : data?.UserData
  //         //    )
  //         //  );

  //          // read/unread message for mentor section
  //          emmitToSocket(
  //            "readMentorMessage",
  //            {
  //              ConversationID: ConversationID,
  //              UserID: connectid,
  //              RoleID: roleID,
  //            },
  //            (res) => {
  //              if (res.success) {
  //                emmitToSocket(
  //                  "auth-user",
  //                  { userId: UserID, filter: "mentor" },
  //                  (res) => {
  //                    dispatch(UpdatedList(res?.data?.gcData));
  //                  }
  //                );
  //              }
  //            }
  //          );
  //        }

  //        // Dispatch other mentor/home data
  //        dispatch(setConversationID(ConversationID));
  //        dispatch(chatList(res?.data?.dataMentor));
  //        dispatch(setMediaURL(res?.data?.mediaUrl));
  //      }
  //     //  setShow(true);
  //      // setPage(1);
  //    });

  //  };
  return (
    <Bars
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="bars-loading"
      wrapperClass="loader_style"
      visible={true}
      wrapperStyle={{ left: "30%" }}
    />
  );
};

export default NotificationRoute;
