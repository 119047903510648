import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialUserState = {
  resendOtp: "",
  status: STATUSES.IDLE,
};

const resendOTP = createSlice({
  name: "resendOTP",
  initialState: initialUserState,

  reducers: {
    resendOtpSend(state, action) {
      state.resendOtp = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { resendOtpSend, setStatus } = resendOTP.actions;
export default resendOTP.reducer;

export const fetchResendOTP = (payload) => {
  const { email, setLoading } = payload;
  const formData = new FormData();
  formData.append("email", email);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      setLoading(true);
      baseUrlAuth.post("resend-email-otp", formData).then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          dispatch(setStatus(STATUSES.IDLE));
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log("Resend OTP error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
