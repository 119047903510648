import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  doubleTick,
  heart,
  like,
  quoteIcon,
  replyIcon,
  replyLeft,
  sendMessageReply,
  user1,
  videoPlay,
  viewIcon,
} from "../../Images/images";
import { socket } from "../../store/SocketIo";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { chatlistDeletePost, showPreviewImage } from "../../reducer/GetChat";
import { storeSavedMessageGCID } from "../../reducer/SavedMessagess";
import { GCUserInfoMediaApi } from "../../reducer/GCUserInfoMedia";
import { GroupChannelUserBlockMemberID } from "../../reducer/GCMemberBlockUnblock";
import { useRef } from "react";
import { convertToLinksAndHighlightUsernames, highlightUsernames, scrollToBottom } from "../../common";
import MediaFileComponent from "./MediaFileContent";
import MediaChatComponent from "./MediaChatComponent";
import GallerySlider from "./GallerySlider";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import { removePinMessages } from "../../reducer/PinMessage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const PinMessage = ({
  item,index,
  ShowComment,
  name,
  setPosition,
  setShowMenu,
  setEditData,
  selectedMessage,
  setSelectedMessage,
  showCheckbox,
  setShowCheckbox,
  handleFindeMessage,
  setPinMesssageState,
  setSearchMessage,
  setFirstChecked,
  userOpenComment,
  userOpenSetting,
  userOpenProfile,
  setUserOpenProfile,
  setUserOpenSetting,
  setUserOpenComment,
  setgetPinMessageID,
  setPositionXX,
  maxX,
  maxY,
  getLastMessageAddClassNamePinMessage,
  setAddLastMessageClassMouseRightClickShowPopup,
}) => {
  const [checked, setChecked] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [pinGallery, setPinGallery] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const endRef = useRef(null);
  const getGroupChannelId = useSelector(
    (state) => state?.GetChat?.channelgroupid
  );
  const checkGlobalPermission = useSelector(
    (state) =>
      state?.UpdateProfileReducer?.userProfileUpdatedetails?.data?.user_setting
  );
  const mentDetails = useSelector((state) => state?.MentorList?.details);

  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);
  const chat = useSelector((state) => state?.GetChat?.Chats);
  const navigate = useNavigate();
  const connectid = localStorage.getItem("gtfconnectid");
  const dispatch = useDispatch();
  const buttons = {
    left: 0,
    right: 2,
  };
  const handleChangeSingleMessage = (e) => {
    if (e.button === buttons.left) {
      setShowMenu(false);
    } else if (e.button === buttons.right) {
      setShowMenu(true);
    }
  };
  const handleMouseScroll = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    dispatch(storeSavedMessageGCID(selectedMessage));
  }, [selectedMessage]);

  useEffect(() => {
    scrollToBottom(endRef);
  }, []);

  // handle context menu

  // const handleContextMenu = (e, data) => {
  //   e.preventDefault();
  //   const adjustedX = e.clientX > maxX ? maxX : e.clientX;
  //   const adjustedY = e.clientY > maxY ? maxY : e.clientY;
  //   setPositionXX({ adjustedX, adjustedY });
  //   setPosition({ x: e.clientX, y: e.clientY });
  //   setEditData({ item: item?.chat, SavedHandelFirstSelect });
  //   setgetPinMessageID({
  //     pinmessagesID: item?.pinmessagesID,
  //     GroupChannelID: item?.GroupChannelID,
  //   });
  //   if (
  //     data?.GroupChatID === getLastMessageAddClassNamePinMessage?.GroupChatID
  //   ) {
  //     setAddLastMessageClassMouseRightClickShowPopup(true);
  //   } else {
  //     setAddLastMessageClassMouseRightClickShowPopup(false);
  //   }
  // };

  // handle select box
  const handleSelectbox = (item) => {
    setChecked(!checked);
    if (!checked) {
      setSelectedMessage([...selectedMessage, item]);
      setShowCheckbox(true);
    } else {
      if (selectedMessage?.length === 1) {
        setShowCheckbox(false);
      }
      setSelectedMessage(
        selectedMessage?.filter(
          (data) => data?.GroupChatID !== item?.GroupChatID
        )
      );
    }
  };

  // Saved message handle first select
  const SavedHandelFirstSelect = () => {
    setChecked(true);
    setSelectedMessage([...selectedMessage, item?.chat]);
  };

  const mediaToShow = item?.chat?.media
    ?.filter(
      (item) =>
        item?.MimeType?.startsWith("image") ||
        item?.MimeType?.startsWith("video")
    )
    .slice(0, 4);

  let allItems = item?.chat?.media?.filter(
    (mediaItem) =>
      mediaItem?.MimeType?.startsWith("image") ||
      mediaItem?.MimeType?.startsWith("video")
  );

  const pinMessageMedia = item?.chat?.media?.map((media, index) => (
    <MediaFileComponent
      key={index}
      media={media}
      baseUrlMedia={baseUrlMedia}
      checkGlobalPermission={checkGlobalPermission}
    />
  ));

  const newTimeChat = new Date(item?.chat?.CreatedAt)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Asia/Kolkata",
    })
    .split("/")
    .join("-");

  useEffect(() => {
    socket.on("postDeleteData", (res) => {
      dispatch(chatlistDeletePost(res));
    });
  }, [socket]);

  // handle long message add read more read less button
  const contentAll = item?.chat?.Message?.split(" ");
  const chunkSize = 100;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    const chunksArray = [];
    for (let i = 0; i < contentAll.length; i += chunkSize) {
      chunksArray.push(contentAll.slice(i, i + chunkSize));
    }
    setChunks(chunksArray);
  }, [item]);

  useEffect(() => {
    setDisplayedWords(chunks.slice(0, currentIndex + 1).flat());
  }, [chunks, currentIndex]);

  const showNextChunk = () => {
    if (currentIndex < chunks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPrevChunk = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const hasMoreContent = currentIndex < chunks.length - 1;
  // ...............

  // Pin message add user tag message

  const userTagPinMessage = displayedWords.join(" ");

  // Delete single pin message

  const handleDeletePinMessage = async (data) => {
    dispatch(
      removePinMessages({
        pinmessagesID: data?.pinmessagesID,
        channelGroupID: data?.GroupChannelID,
        setPinMesssageState,
      })
    );
  };

  // Delete all Pin message
  // const unPinAllMessageGC = (data)=>{
  //   dispatch(unPinAllMessages(data?.GroupChannelID))
  // }

  // Handle find pin message
  // const handleFindeMessage = (message) => {
  //   console.log("Pin message",message)
  //   let findMessage = chat?.find((item) => item?.GroupChatID === message?.GroupChatID);
  //   if(findMessage){
  //     setSearchMessage(findMessage)
  //   }else{
  //     toast.error("Message Not Found !!!");
  //   }
  // let find = mentDetails
  //   ? mentorChats?.find(
  //       (item) => item?.IndividualChatID === message?.IndividualChatID
  //     )
  //   : chat?.find((item) => item?.GroupChatID === message?.GroupChatID);
  // if (find) {
  //   mentDetails ? setQuote(find) : setSearchMessage(find);
  // } else {
  //   toast.error("Message Not Found !!!");
  // }
  // };

  return (
    <div key={index}>
      <li
        className="massage_recived"
        // onContextMenu={(e) => handleContextMenu(e, item)}
        // onMouseDown={handleChangeSingleMessage}
        // onWheel={handleMouseScroll}
      >
        <div className="massage_inner chat_live">
          {showCheckbox && (
            <input
              type="checkbox"
              name="select"
              value={checked}
              checked={checked}
              className="select_message_design"
              onChange={() => {
                handleSelectbox(item?.chat);
              }}
            />
          )}

          <div
            className="massageer_name massage_content openright-bar"
            data-apply_classname="open_profile_sidebar"
          >
            <strong className="orange">
              {item?.chat?.dummyUserData === null
                ? ` ${item?.chat.user?.Firstname} ${
                    item?.chat.user?.Lastname === null
                      ? ""
                      : item?.chat?.user?.Lastname
                  } `
                : ` ${item?.chat.dummyUserData?.Firstname} ${
                    item?.chat.dummyUserData?.Lastname === null
                      ? ""
                      : item?.chat?.dummyUserData?.Lastname
                  } `}
              <span className="pin_message_date pl-2">{newTimeChat} </span>
            </strong>
          </div>
          <div className="massage_content">
            {item?.chat?.GroupChatRefID !== null ? (
              <div className="massage_inner massage_inner_unsetborder massage-quote-inner">
                <div className="massage_content">
                  <div className="massage_quote_text">
                    <p>{convertToLinksAndHighlightUsernames(item?.chat?.Message)}</p>
                    <div className="time_se">
                      <small className="time-text"></small>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <p>
                  {convertToLinksAndHighlightUsernames(userTagPinMessage)}
                  {hasMoreContent && "..."}
                  {currentIndex < chunks.length - 1 && (
                    <span
                      onClick={showNextChunk}
                      style={{
                        paddingLeft: "10px",
                        color: "green",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Read More
                    </span>
                  )}
                  {currentIndex > 0 && (
                    <span
                      onClick={showPrevChunk}
                      style={{
                        paddingLeft: "10px",
                        color: "green",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Read Previous
                    </span>
                  )}
                </p>
                <div className="imgcontent-group">
                  <div
                    className={`goup-row row vodeo_row_play_pause ${
                      item?.chat?.media?.length == 1 ? "no_column" : ""
                    }`}
                  >
                    <MediaChatComponent
                      mediaToShow={mediaToShow}
                      allItems={allItems}
                      baseUrlMedia={baseUrlMedia}
                      checkGlobalPermission={checkGlobalPermission}
                      videoPlay={videoPlay}
                      dispatch={dispatch}
                      showPreviewImage={showPreviewImage}
                      setGallary={setGalleryData}
                      setShowGallary={setPinGallery}
                    />
                  </div>
                  <div className="goup-row row vodeo_row_play_pause">
                    {pinMessageMedia}
                  </div>
                </div>
              </>
            )}
            <>
              <div className="time_se">
                <small
                  className="pin_message_goto_main_msg"
                  onClick={() => {
                    setSearchMessage(item?.chat);
                    setPinMesssageState(false);
                  }}
                >
                  Go To Main Message 
                </small>
                <small
                  className="pin_message_remove_pin ml-2"
                  onClick={() => {
                    ConfirmationDialog({
                      title: "Unpin Message",
                      message: "Are you sure you want to Unpin this message?",
                      onConfirm: () =>
                        handleDeletePinMessage({
                          pinmessagesID: item?.pinmessagesID,
                          GroupChannelID: item?.chat?.GroupChannelID,
                        }),
                    });
                    setShowMenu(false);
                  }}
                >
                  Remove
                </small>
              </div>
            </>
          </div>
        </div>
      </li>

      <div ref={endRef}></div>
      <GallerySlider
        setGallary={setGalleryData}
        setShowGallary={setPinGallery}
        gallary={galleryData}
        showGallary={pinGallery}
        baseUrlMedia={baseUrlMedia}
      />
    </div>
  );
};

export default React.memo(PinMessage);
