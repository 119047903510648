import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

// Get Localstorage Token
export const globalLocalStrogeToken = () => {
  return localStorage.getItem("token");
};

// Get firebase token
export const getFirebaseToken = () => {
  return localStorage.getItem("firebaseToken");
};

// Get device token
export const getLocalDeviceToken = () => {
  return localStorage.getItem("device_token");
};

// Get auth token
export const getGlobalAuthToken = () => {
  return localStorage.getItem("api_token_gtf");
};

// Get Group/Channel Type
export const getGroupChannelType = ()=>{
 return localStorage.getItem("paymentGCType"); 
}
// Get User ID
export const userID = ()=>{
 return localStorage.getItem("gtfconnectid");
}
// Get User ID
export const checkNotification = ()=>{
 return localStorage.getItem("checkNotification");

}

// Get group channel ID

export const getGroupChannelID = ()=>{
  return localStorage.getItem("GroupChannelID")
}

// Token Expire check status
export const tokenExpireCheckStatus = (error, message) => {
  if (error === false) {
    confirmAlert({
      title: message,
      overlayClassName: "confirm_box_logout",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("api_token_gtf");
            localStorage.removeItem("device_token");
            localStorage.removeItem("device_token_status");
            window.location.replace("/login");
          },
        },
      ],
    });
  }
};

// window.addEventListener("beforeunload", () => {
//   tokenExpireCheckStatus();
// });


// user role slug
// export const storeUserRoleInfo = (user_role_slug) => {
//   return localStorage.setItem("user_role_slug", user_role_slug);
// };

// Dummy Token Generator function
// export const generateDummyToken = () => {
//   const timestamp = Date.now().toString(36);
//   const randomChars = Math.random().toString(36).substring(2, 8);
//   const token = timestamp + randomChars;
//   return token;
// }