import React, { useEffect } from "react";
import { videoPlay } from "../../Images/images";
import moment from "moment-timezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showPreviewImage } from "../../reducer/GetChat";
import SavedMessageVideoPlayPause from "../VideoPlayPause/SavedMessageVideoPlayPause";
import { Bars } from "react-loader-spinner";
import { useRef } from "react";
import { convertToLinksAndHighlightUsernames, highlightUsernames, scrollToBottom } from "../../common";
import MediaFileComponent from "./MediaFileContent";
import MediaChatComponent from "./MediaChatComponent";
import GallerySlider from "./GallerySlider";

const SavedMessage = ({
  newTimeChat,
  index,
  item,
  setShowMenu,
  setPosition,
  setSavedMessgedGetData,
  showCheckbox,
  setSelectedMessage,
  selectedMessage,
  setShowCheckbox,
  setPositionXX,
  maxX,
  maxY,
}) => {
  const [checked, setChecked] = useState(false);
  const [saveGallery, setSaveGallery] = useState(false);
  const [saveGalleryData, setSaveGalleryData] = useState([]);
  const endRef = useRef();
  const savedMessageMediaFilesBaseUrl = useSelector(
    (state) => state?.SavedMessagess?.storeBaseUrl
  );
  const fetchSavedMessageDataStatus = useSelector(
    (state) => state?.SavedMessagess?.status
  );
  const checkGlobalPermission = useSelector(
    (state) =>
      state?.UpdateProfileReducer?.userProfileUpdatedetails?.data?.user_setting
  );
  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);
  const getUserId = localStorage.getItem("gtfconnectid");
  const savedMessageUserID = item?.user?.UserID;
  const dispatch = useDispatch();
  const buttons = {
    left: 0,
    right: 2,
  };
  const handleChangeSingleMessage = (e) => {
    if (e.button === buttons.left) {
      setShowMenu(false);
    } else if (e.button === buttons.right) {
      setShowMenu(true);
    }
  };
  const handleMouseScroll = () => setShowMenu(false);
  const handleContextMenu = (e) => {
    e.preventDefault();
    const adjustedX = e.clientX > maxX ? maxX : e.clientX;
    const adjustedY = e.clientY > maxY ? maxY : e.clientY;
    setPositionXX({ adjustedX, adjustedY });
    setPosition({ x: e.clientX, y: e.clientY });
    setSavedMessgedGetData({ item, SavedHandelFirstSelect });
  };

  const handleSelectbox = (item) => {
    setChecked(!checked);
    if (!checked) {
      setSelectedMessage([...selectedMessage, item]);
    } else {
      if (selectedMessage?.length === 1) {
        setShowCheckbox(false);
      }
      setSelectedMessage(
        selectedMessage?.filter(
          (data) => data?.SavedMessageID !== item?.SavedMessageID
        )
      );
    }
  };
  const SavedHandelFirstSelect = () => {
    setChecked(true);
    setSelectedMessage([...selectedMessage, item]);
  };

  // handle long message add read more read less button
  const contentAll = item?.Content?.split(" ");
  const chunkSize = 100;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    const chunksArray = [];
    for (let i = 0; i < contentAll.length; i += chunkSize) {
      chunksArray.push(contentAll.slice(i, i + chunkSize));
    }
    setChunks(chunksArray);
  }, [item]);

  useEffect(() => {
    setDisplayedWords(chunks.slice(0, currentIndex + 1).flat());
  }, [chunks, currentIndex]);

  const showNextChunk = () => {
    if (currentIndex < chunks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPrevChunk = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const hasMoreContent = currentIndex < chunks.length - 1;

  // ......

  const savedMessageText = displayedWords.join(" ");

  const mediaToShow = item?.saved_message_media
    ?.filter(
      (media) =>
        media?.MimeType?.startsWith("image") ||
        media?.MimeType?.startsWith("video")
    )
    .slice(0, 4);

  let allItems = item?.saved_message_media?.filter(
    (mediaItem) =>
      mediaItem?.MimeType?.startsWith("image") ||
      mediaItem?.MimeType?.startsWith("video")
  );

  const savedMessageMediaFiles = item?.saved_message_media?.map(
    (media, index) => (
      <MediaFileComponent
        key={index}
        media={media}
        stage={"save"}
        baseUrlMedia={savedMessageMediaFilesBaseUrl?.base_url}
        checkGlobalPermission={checkGlobalPermission}
      />
    )
  );

  // useEffect(() => {
  //   scrollToBottom(endRef);
  // }, []);

  // handle scrolling last message

  useEffect(() => {
    if (endRef && endRef.current) {
      setTimeout(() => {
        endRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }, 100);
    }
  }, [item, endRef]);


  return fetchSavedMessageDataStatus === "loading" ? (
    <Bars
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="bars-loading"
      wrapperClass="loader_style"
      visible={true}
    />
  ) : (
    <div key={index}>
      <li
        className={
          getUserId == savedMessageUserID ? `massage_send` : `massage_recived`
        }
        key={index}
        onContextMenu={handleContextMenu}
        onMouseDown={handleChangeSingleMessage}
        onWheel={handleMouseScroll}
        ref={endRef}
      >
        <div className="massage_inner massage-text">
          <div className="massageer_name ">
            <strong className="orange">
              {getUserId == savedMessageUserID
                ? ""
                : item?.dummy_user === null
                ? ` ${item?.user?.Firstname} ${
                    item?.user?.Lastname === null ? "" : item?.user?.Lastname
                  } `
                : ` ${item.dummy_user?.Firstname} ${
                    item?.dummy_user?.Lastname === null
                      ? ""
                      : item?.dummy_user?.Lastname
                  } `}
            </strong>
          </div>
          {showCheckbox && (
            <input
              type="checkbox"
              name="select"
              value={checked}
              checked={checked}
              onChange={() => {
                handleSelectbox(item);
              }}
            />
          )}
          <div className="massage_content">
            <p>
              {convertToLinksAndHighlightUsernames(savedMessageText)}
              {hasMoreContent && "..."}
              {currentIndex < chunks.length - 1 && (
                <span
                  onClick={showNextChunk}
                  style={{
                    paddingLeft: "10px",
                    color: "green",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Read More
                </span>
              )}
              {currentIndex > 0 && (
                <span
                  onClick={showPrevChunk}
                  style={{
                    paddingLeft: "10px",
                    color: "green",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Read Previous
                </span>
              )}
            </p>
            <div className="imgcontent-group">
              <div
                className={`goup-row row vodeo_row_play_pause ${
                  item?.saved_message_media?.length == 1 ? "no_column" : ""
                }`}
              >
                <MediaChatComponent
                  mediaToShow={mediaToShow}
                  allItems={allItems}
                  baseUrlMedia={savedMessageMediaFilesBaseUrl?.base_url}
                  checkGlobalPermission={checkGlobalPermission}
                  videoPlay={videoPlay}
                  stage={"save"}
                  dispatch={dispatch}
                  showPreviewImage={showPreviewImage}
                  setGallary={setSaveGalleryData}
                  setShowGallary={setSaveGallery}
                />
              </div>
              <div className="goup-row row vodeo_row_play_pause ">
                {savedMessageMediaFiles}
              </div>
            </div>

            <div className="time_se">
              <small className="time-text">
                {getUserId == savedMessageUserID
                  ? item?.dummy_user ?
                    `${item?.dummy_user?.Firstname} ${
                      item?.dummy_user?.Lastname
                    } / ${moment(newTimeChat).add("hours").format("hh:mm A")}`
                   : `${moment(newTimeChat).add("hours").format("hh:mm A")}`
                  : `${moment(newTimeChat).add("hours").format("hh:mm A")}`}
              </small>
            </div>
          </div>
        </div>
      </li>
      {/* <div ref={endRef}></div> */}
      <GallerySlider
        setGallary={setSaveGalleryData}
        setShowGallary={setSaveGallery}
        gallary={saveGalleryData}
        showGallary={saveGallery}
        stage={"save"}
        baseUrlMedia={savedMessageMediaFilesBaseUrl?.base_url}
      />
    </div>
  );
};

export default React.memo(SavedMessage);
