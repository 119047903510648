import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  app_store,
  google_play,
  loginleftbox_image_1,
  loginleftbox_image_2,
  loginleftbox_image_3,
  loginleftbox_image_4,
  loginleftbox_image_5,
  loginleftbox_image_6,
  vector_icon1,
  vector_icon10,
  vector_icon12,
  vector_icon13,
  vector_icon2,
  vector_icon3,
  vector_icon5,
  Weblogo,
  hide_icon,
  paymentLogo,
} from "../../Images/images";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useDispatch, useSelector } from "react-redux";

import "react-phone-number-input/style.css";
import { CountryData } from "../../CountryData";
import { useEffect } from "react";
import {
  fetchCountryData,
  fetchFindUSData,
  fetchSelectCitys,
  fetchSelectStates,
  handleUserRegistration,
} from "../../reducer/userAuth/userRegister";
import { LoadingButton } from "@mui/lab";
const Register = () => {
  const [registrationStepFirst, setRegistrationFirst] = useState(true);
  const [registrationStepThree, setRegistrationThree] = useState(false);
  const [hidepassword, setHidePassword] = useState(false);
  const [cPasshidepassword, setPasstHidePassword] = useState(false);
  const [findUsDataFetched, setFindUsDataFetched] = useState(false);
  const [countryDataFetched, setCountryDataFetched] = useState(false);
  const [countryDataStatus, setCountryDataStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registrationStep1, setRegistrationStep1] = useState();
  const [storeStateID, setStoreStateID] = useState();
  const [storeCityID, setStoreCityID] = useState();

  const redirect = useNavigate();
  const dispatch = useDispatch();
  const loginGetData = localStorage.getItem("token");
  const userRegisterFetchApiData = useSelector((state) => state?.userRegister);

  useEffect(() => {
    loginGetData && redirect("/");
  }, [loginGetData]);

  // handle Find Us select Box Trigger the API call only once
  const handleFindUsSelect = () => {
    if (!findUsDataFetched) {
      dispatch(fetchFindUSData());
      setFindUsDataFetched(true);
    }
  };

  // handle CountryData
  const handleCountryData = (values) => {
    if (!countryDataFetched) {
      dispatch(fetchCountryData({ setCountryDataStatus }));
      setCountryDataFetched(true);
    }
    countryDataStatus && setStoreStateID(values);
  };

  // fetch state data
  useEffect(() => {
    if (storeStateID) {
      dispatch(fetchSelectStates({ CountryID: storeStateID }));
    }
  }, [storeStateID]);

  // handle city
  const handleCityList = (values) => {
    setStoreCityID(values);
    setCountryDataFetched(true);
  };

  // Get city list
  useEffect(() => {
    if (storeCityID) {
      dispatch(fetchSelectCitys(storeCityID));
    }
  }, [storeCityID]);

  // First step verification
  const FirstStepRegister = (values) => {
    setRegistrationStep1(values);
    setRegistrationFirst(false);
    setRegistrationThree(true);
  };

  // Fist step verification click back button data
  const BackregistrationStepFirst = () => {
    setRegistrationThree(false);
    setRegistrationFirst(true);
  };

  // get First and lastname
  const getFullName = registrationStep1?.fullname;
  const getFirstName = getFullName?.split(" ")[0];
  const getLastName = getFullName?.split(" ").slice(1).join(" ");

  // user register submit data
  const UserRegistration = (values) => {
    const countryCodes =
      registrationStep1?.countryCode === undefined
        ? "+91"
        : registrationStep1?.countryCode;

    dispatch(
      handleUserRegistration({
        FirstName: getFirstName,
        LastName: getLastName,
        Email: registrationStep1?.email,
        Password: registrationStep1?.password,
        PhoneCode: countryCodes,
        Phone: registrationStep1?.phoneNumber,
        CountryID: values?.country,
        StateID: values?.state,
        CityID: values?.city,
        findUs: registrationStep1?.findUs,
        navigate: redirect,
        setLoading,
      })
    );
  };

  // Visible password
  const VisiblePassword = () => {
    setHidePassword(!hidepassword);
    const PasswordId = document.getElementById("password");
    if (PasswordId.type === "password") {
      return (PasswordId.type = "text");
    } else {
      return (PasswordId.type = "password");
    }
  };

  // Confirm Password
  const ConfirmVisiblePassword = () => {
    setPasstHidePassword(!cPasshidepassword);
    const PasswordId = document.getElementById("Cpassword");
    if (PasswordId.type === "password") {
      return (PasswordId.type = "text");
    } else {
      return (PasswordId.type = "password");
    }
  };

  // Form validation First Step
  const SignupSchemaFirst = Yup.object().shape({
    fullname: Yup.string().matches(/^\S/, 'No white space allowed').required("Full name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    findUs: Yup.string().required("Choose field is Required"),
    phoneNumber: Yup.string()
      .transform(value => (value ? String(value) : value)) 
      .min(6, "Min 6 number is Required")
      .max(15, "Too Long!")
      .required("Phone number is Required"),
    password: Yup.string()
      .min(6, "Min 6 number is Required")
      .required("Password is Required"),
    ConfirmPassword: Yup.string()
      .required("Please ReEnter your password")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  // Form validation Second step
  const SignupSchemaThird = Yup.object().shape({
    country: Yup.string().required("Country is Required"),
    state: Yup.string().required("State is Required"),
    city: Yup.string().required("City is Required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });

  return (
    <>
      {/*-------------------------------------------------------------------------- Registratioin Step First ------------------------------------------- */}

      {registrationStepFirst && (
        <section className="login register">
          <div className="container">
            <div className="row loginrow">
              <div className="col-md-6 loginleft">
                <div className="loginleftbox">
                  <div className="loginleftbox_image">
                    <div className="loginleftbox_image_1">
                      <img
                        src={loginleftbox_image_1}
                        alt="loginleftbox_image_1"
                      />
                    </div>
                    <div className="loginleftbox_image_2">
                      <img
                        src={loginleftbox_image_2}
                        alt="loginleftbox_image_2"
                      />
                    </div>
                    <div className="loginleftbox_image_3">
                      <img
                        src={loginleftbox_image_3}
                        alt="loginleftbox_image_3"
                      />
                    </div>
                    <div className="loginleftbox_image_4">
                      <img
                        src={loginleftbox_image_4}
                        alt="loginleftbox_image_4"
                      />
                    </div>
                    <div className="loginleftbox_image_5">
                      <img
                        src={loginleftbox_image_5}
                        alt="loginleftbox_image_5"
                      />
                    </div>
                    <div className="loginleftbox_image_6">
                      <img
                        src={loginleftbox_image_6}
                        alt="loginleftbox_image_6"
                      />
                    </div>
                  </div>
                  <div className="loginleftbox_app d-flex justify-content-center flex-wrap align-items-center">
                    <Link to="#">
                      <img src={app_store} alt="app-store" />
                    </Link>
                    <Link to="#">
                      <img src={google_play} alt="google-play" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 loginright">
                <div className="loginrightbox">
                  <div className="loginrightbox_logo">
                    <Link to="#">
                      <img src={paymentLogo} alt="img" />
                    </Link>
                  </div>
                  <div className="loginrightbox_form">
                    <h3>Register</h3>

                    <Formik
                      initialValues={{
                        fullname: registrationStep1?.fullname,
                        email: registrationStep1?.email,
                        gender: registrationStep1?.gender,
                        dob: registrationStep1?.dob,
                        countryCode: registrationStep1?.countryCode,
                        phoneNumber: registrationStep1?.phoneNumber,
                        findUs: registrationStep1?.findUs,
                        password: registrationStep1?.password,
                        ConfirmPassword: registrationStep1?.ConfirmPassword,
                      }}
                      validationSchema={SignupSchemaFirst}
                      onSubmit={(values) => {
                        FirstStepRegister(values);
                      }}
                    >
                      {({ errors, touched, values, handleChange }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-input">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Full Name"
                                  name="fullname"
                                />
                                <span className="form-input-icon">
                                  <img src={vector_icon1} alt="vector_icon1" />
                                </span>
                                {errors.fullname && touched.fullname ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.fullname}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="form-input">
                            <Field
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                            />
                            <span className="form-input-icon">
                              <img src={vector_icon5} alt="vector_icon5" />
                            </span>
                            {errors.email && touched.email ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-input form-flgselect">
                            <select
                              className="form-select"
                              aria-label="+91"
                              name="countryCode"
                              value={values?.countryCode}
                              onChange={handleChange}
                              defaultValue={+91}
                              style={{ width: "130px", padding: "15px 10px" }}
                            >
                              <option selected value="">
                                +91
                              </option>
                              {CountryData &&
                                CountryData?.map((data, index) => (
                                  <option value={data?.dialCode} key={index}>
                                    {` ${data?.dialCode}`}
                                  </option>
                                ))}
                            </select>
                            <Field
                              type="number"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              name="phoneNumber"
                              style={{ padding: "15px 10px" }}
                            />
                          </div>

                          {errors.phoneNumber && touched.phoneNumber ? (
                            <div className="text-danger d-flex mt-2 mb-2">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                          <div className="form-input">
                            <select
                              className="form-select"
                              aria-label="findUs"
                              name="findUs"
                              value={values.findUs}
                              onChange={handleChange}
                              onClick={handleFindUsSelect} // Trigger the API call only once
                            >
                              <option selected value="">
                                How Did You FInd Us
                              </option>
                              {userRegisterFetchApiData?.status ===
                              "loading" ? (
                                <option disabled>
                                  <h1>Loading..</h1>
                                </option>
                              ) : (
                                userRegisterFetchApiData?.findUSData &&
                                userRegisterFetchApiData?.findUSData?.map(
                                  (data) => (
                                    <option
                                      value={data?.Key}
                                      key={data?.FindUsID}
                                    >
                                      {data?.Name}
                                    </option>
                                  )
                                )
                              )}
                            </select>
                            <span className="form-input-icon">
                              <img src={vector_icon10} alt="vector_icon10" />
                            </span>
                            {errors.findUs && touched.findUs ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.findUs}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-input">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              id="password"
                              name="password"
                            />
                            <span className="form-input-icon">
                              <img src={vector_icon2} alt="vector_icon2" />
                            </span>
                            {errors.password && touched.password ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.password}
                              </div>
                            ) : null}
                            <span className="form-input-eye">
                              {!hidepassword ? (
                                <img
                                  className="oneye"
                                  src={vector_icon3}
                                  alt="vector_icon3"
                                  onClick={VisiblePassword}
                                />
                              ) : (
                                <img
                                  className="oneye"
                                  src={hide_icon}
                                  alt="hide_icon"
                                  onClick={VisiblePassword}
                                />
                              )}
                            </span>
                          </div>
                          <div className="form-input">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              id="Cpassword"
                              name="ConfirmPassword"
                            />
                            <span className="form-input-icon">
                              <img src={vector_icon2} alt="vector_icon2" />
                            </span>
                            {errors.ConfirmPassword &&
                            touched.ConfirmPassword ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.ConfirmPassword}
                              </div>
                            ) : null}
                            <span className="form-input-eye">
                              {!cPasshidepassword ? (
                                <img
                                  className="oneye"
                                  src={vector_icon3}
                                  alt="vector_icon3"
                                  onClick={ConfirmVisiblePassword}
                                />
                              ) : (
                                <img
                                  className="oneye"
                                  src={hide_icon}
                                  alt="hide_icon"
                                  onClick={ConfirmVisiblePassword}
                                />
                              )}
                            </span>
                          </div>

                          <div className="form-submit">
                            <button type="submit" className="btn btn-login">
                              Next
                            </button>
                          </div>
                          <div className="step_number">
                            <div>
                              <span>1</span>/2
                            </div>
                          </div>
                          <div className="back-to-login">
                            <span className="text-black">
                              Already have an Account
                            </span>
                            <Link to="/login">Login Now</Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/*//. --------------------------------------------------------------------- Registratioin Step First End -------------------------------------------------- */}

      {/*-------------------------------------------------------------------- Registratioin Step Three --------------------------------------- */}

      {registrationStepThree && (
        <section className="login register">
          <div className="container">
            <div className="row loginrow">
              <div className="col-md-6 loginleft">
                <div className="loginleftbox">
                  <div className="loginleftbox_image">
                    <div className="loginleftbox_image_1">
                      <img
                        src={loginleftbox_image_1}
                        alt="loginleftbox_image_1"
                      />
                    </div>
                    <div className="loginleftbox_image_2">
                      <img
                        src={loginleftbox_image_2}
                        alt="loginleftbox_image_2"
                      />
                    </div>
                    <div className="loginleftbox_image_3">
                      <img
                        src={loginleftbox_image_3}
                        alt="loginleftbox_image_3"
                      />
                    </div>
                    <div className="loginleftbox_image_4">
                      <img
                        src={loginleftbox_image_4}
                        alt="loginleftbox_image_4"
                      />
                    </div>
                    <div className="loginleftbox_image_5">
                      <img
                        src={loginleftbox_image_5}
                        alt="loginleftbox_image_5"
                      />
                    </div>
                    <div className="loginleftbox_image_6">
                      <img
                        src={loginleftbox_image_6}
                        alt="loginleftbox_image_6"
                      />
                    </div>
                  </div>
                  <div className="loginleftbox_app d-flex justify-content-center flex-wrap align-items-center">
                    <Link to="#">
                      <img src={app_store} alt="app-store" />
                    </Link>
                    <Link to="#">
                      <img src={google_play} alt="google-play" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 loginright">
                <div className="loginrightbox">
                  <div className="loginrightbox_logo">
                    <Link to="#">
                      <img src={Weblogo} alt="img" />
                    </Link>
                  </div>
                  <div className="loginrightbox_form">
                    <h3>Register</h3>
                    <Formik
                      initialValues={{
                        country: "",
                        state: "",
                        city: "",

                        termsAndConditions: false,
                      }}
                      validationSchema={SignupSchemaThird}
                      onSubmit={(values) => {
                        UserRegistration(values);
                      }}
                    >
                      {({ errors, touched, handleChange, values }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-input">
                                <select
                                  className="form-select"
                                  aria-label="Country"
                                  name="country"
                                  value={values.country}
                                  onChange={handleChange}
                                  onClick={() =>
                                    handleCountryData(values.country)
                                  }
                                >
                                  <option selected value="">
                                    Country
                                  </option>
                                  {!countryDataStatus ? (
                                    <option disabled>
                                      <h1>Loading..</h1>
                                    </option>
                                  ) : (
                                    userRegisterFetchApiData?.selectCountry
                                      ?.data &&
                                    userRegisterFetchApiData?.selectCountry?.data?.map(
                                      (c) => (
                                        <option value={c?.CountryID}>
                                          {c?.name}
                                        </option>
                                      )
                                    )
                                  )}
                                </select>
                                <span className="form-input-icon">
                                  <img
                                    src={vector_icon12}
                                    alt="vector_icon12"
                                  />
                                </span>
                                {errors.country && touched.country ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.country}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-input">
                                <select
                                  className="form-select"
                                  aria-label="State"
                                  name="state"
                                  value={values.state}
                                  onChange={handleChange}
                                  onClick={() => handleCityList(values.state)}
                                  disabled={!storeStateID ? true : false}
                                >
                                  <option selected value="">
                                    State
                                  </option>
                                  {userRegisterFetchApiData?.userSelectState
                                    ?.data &&
                                    userRegisterFetchApiData?.userSelectState?.data?.map(
                                      (state) => (
                                        <option value={state?.StateID}>
                                          {state?.name}
                                        </option>
                                      )
                                    )}
                                </select>
                                <span className="form-input-icon">
                                  <img
                                    src={vector_icon12}
                                    alt="vector_icon12"
                                  />
                                </span>
                                {errors.state && touched.state ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.state}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-input">
                                <select
                                  className="form-select"
                                  aria-label="City"
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                  disabled={
                                    !storeStateID || !storeCityID ? true : false
                                  }
                                >
                                  <option selected value="">
                                    City
                                  </option>
                                  {userRegisterFetchApiData?.userSelectCity
                                    ?.data &&
                                    userRegisterFetchApiData?.userSelectCity?.data?.map(
                                      (city) => (
                                        <option value={city?.CityID}>
                                          {city?.name}
                                        </option>
                                      )
                                    )}
                                </select>
                                <span className="form-input-icon">
                                  <img
                                    src={vector_icon13}
                                    alt="vector_icon13"
                                  />
                                </span>
                                {errors.city && touched.city ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.city}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="form-check form-agree">
                            <Field
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              name="termsAndConditions"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                            
                              I agree to{" "}
                              <Link
                                to={process.env.REACT_APP_GTF_PRIVACY_URL}
                                target="_blank"
                              >
                                Privacy Policy{" "}
                              </Link>
                              and{" "}
                              <Link
                                to={process.env.REACT_APP_GTF_TERMS_URL}
                                target="_blank"
                              >
                                Terms
                              </Link>
                            </label>
                          </div>
                          {errors.termsAndConditions && (
                            <p className="text-danger d-flex mt-2">
                              {errors.termsAndConditions}
                            </p>
                          )}

                          <div className="form-submit">
                            {/* <button type="submit" className="btn btn-login">
                              Submit
                            </button> */}

                            <LoadingButton
                              loading={loading}
                              type="submit"
                              className="btn btn-login"
                            >
                              Submit
                            </LoadingButton>
                          </div>
                          <div className="step_number">
                            <div>
                              1/<span>2</span>
                            </div>
                          </div>
                          <div className="form-submit">
                            <button
                              className="btn btn-back back_arrow"
                              onClick={BackregistrationStepFirst}
                            >
                              Back
                            </button>
                          </div>
                          <div className="back-to-login">
                            <span className="text-black">
                              Already have an Account
                            </span>
                            <Link to="/login">Login Now</Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/*//. ---------------------------------------------------------------- Registratioin Step Three End---------------------------------------- */}
    </>
  );
};

export default Register;
