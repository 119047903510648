import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../../store/axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  Student,
  bookmark,
  chat,
  home,
  logout,
  setting,
  tvLine,
  user,
} from "../../Images/images";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserRole } from "../../reducer/UserReducer";
import { useState } from "react";
const Sidebar = ({ setSavedMesssageState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state?.UserData);
  // const { SubscriptionPlanShowPopupData ,showGlobalSearchSubscriptionPopup} = useSelector(
  //   (state) => state?.notificationReducer
  // );

  // Notification value false
  useEffect(() => {
    localStorage.setItem("checkNotification", false);
    // localStorage.removeItem("GCID");
    setTimeout(() => {
      localStorage.removeItem("blinkChatID");
    }, 2000);
  }, []);

  useEffect(() => {
    if(window?.location?.pathname === "/saved-message"){
      setSavedMesssageState(true)
    }else{
      setSavedMesssageState(false)
    }
  },[])

  const UserLogOut = () => {
    confirmAlert({
      title: "Confirm to Logout",
      message: "Are you sure to do this.",
      overlayClassName: "confirm_box_logout_add_zindex",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              baseUrlEdit
                .post("logout")
                .then((res) => {
                  if (res?.data?.status === 200) {
                    dispatch(setUser(null));
                    dispatch(setUserRole(null));
                    navigate("/login");
                    toast.success(res?.data?.message);
                    localStorage.removeItem("token");
                    localStorage.removeItem("api_token_gtf");
                    localStorage.removeItem("firebaseToken");
                    localStorage.removeItem("device_token");
                    localStorage.removeItem("device_token_status");
                    localStorage.removeItem("GCID");
                    // localStorage.removeItem("user_role_slug");
                  } else {
                    toast.error(res?.data?.message);
                  }
                })
                .catch((error) => console.log(error));
            } catch (error) {
              console.log(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <div className="message-area">
        <div className="chat-area">
          <div className="chat-menu-area">
            <div className="menuchat-bar">
              <ul className="nav nav-tabs menutab" id="myTab" role="tablist">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    onClick={() => {
                      localStorage.setItem("checkNotification", false);
                      localStorage.removeItem("storeNotificationData");
                      localStorage.removeItem("GCID");

                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip title="Home" placement="right-start">
                      <IconButton>
                        <img src={home} alt="hometag" />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>
               {userRole?.primary?.role?.Name === "Super Admin" ? <li className="nav-item">
                  <NavLink
                    to={"/users"}
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip
                      title={"Users"}
                      placement="right-start"
                    >
                      <IconButton>
                        <img
                          src={chat}
                          alt="mentoricon"
                        />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li> : <li className="nav-item">
                  <NavLink
                    to={
                      userRole?.primary?.role?.Name === "Mentor"
                        ? "/student"
                        : "/mentor"
                    }
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip
                      title={
                        userRole?.primary?.role?.Name === "Mentor"
                          ? "Student"
                          : "Mentor"
                      }
                      placement="right-start"
                    >
                      <IconButton>
                        <img
                          src={
                            userRole?.primary?.role?.Name === "Mentor"
                              ? Student
                              : chat
                          }
                          alt="mentoricon"
                        />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>}
                <li className="nav-item">
                  <NavLink
                    to="/group"
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip title="Group" placement="right-start">
                      <IconButton>
                        <img src={user} alt="groupicon" />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/channel"
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip title="Channel" placement="right-start">
                      <IconButton>
                        <img src={tvLine} alt="channel" />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/setting"
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(false);
                    }}
                  >
                    <Tooltip title="Setting" placement="right-start">
                      <IconButton>
                        <img
                          src={setting}
                          alt="setting icon"
                          style={{ marginTop: "-20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/saved-message"
                    className="nav-link"
                    onClick={() => {
                      setSavedMesssageState(true);
                    }}
                  >
                    <Tooltip title="Saved Message" placement="right-start">
                      <IconButton>
                        <img src={bookmark} alt="bookmark" />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </li>
                <li className="nav-item" onClick={UserLogOut}>
                  <Tooltip title="Log Out" placement="right-start">
                    <IconButton>
                      <span className="nav-link Logout_sytle ml-1">
                        <img src={logout} alt="hometag" />
                      </span>
                    </IconButton>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
