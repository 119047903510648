import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAvUhHjRdFCt_XgqoQbuoljRyjh66xBkII",
  authDomain: "gtf-connect-c042f.firebaseapp.com",
  projectId: "gtf-connect-c042f",
  storageBucket: "gtf-connect-c042f.appspot.com",
  messagingSenderId: "120982913303",
  appId: "1:120982913303:web:9b1a796b3a82cad4bdb752",
  measurementId: "G-V42XH0HJTP",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const messaging = app.messaging(); // Use app.messaging() instead of firebase.messaging()

export { messaging };
