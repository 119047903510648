import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { globalLocalStrogeToken } from "../../Component/GlobalLocalStorage";
import MaintenanceModeDailog from "../../common/MaintenanceModeDailog";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  exclusiveGcList: [],
  status: STATUSES.IDLE,
};

export const ExcluciveGcReducer = createSlice({
  name: "SubscriberReducer",
  initialState,
  reducers: {
    exclusiceGc: (state, action) => {
      state.exclusiveGcList = action?.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});
export const { exclusiceGc, setStatus } = ExcluciveGcReducer.actions;
export default ExcluciveGcReducer.reducer;

export const getExclusiveGc = () => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlEdit
        .get(`group_channels/exclusive-group-channel?per_page=100&page=1`, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(exclusiceGc(response?.data));
            dispatch(setStatus(STATUSES.IDLE));
          }else if(response?.data?.status === 503){
            MaintenanceModeDailog({message : response?.data?.message})
          }
          // else if (response?.data?.status === 401) {
          //   tokenExpireCheckStatus(false, response?.data?.message);
          //   }
        });
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
