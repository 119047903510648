import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  doubleTick,
  heart,
  like,
  quoteIcon,
  replyIcon,
  replyLeft,
  sendMessageReply,
  user1,
  videoPlay,
  viewIcon,
} from "../../Images/images";
import { socket } from "../../store/SocketIo";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { showPreviewImage } from "../../reducer/GetChat";
import { storeSavedMessageGCID } from "../../reducer/SavedMessagess";
import { GCUserInfoMediaApi } from "../../reducer/GCUserInfoMedia";
import { GroupChannelUserBlockMemberID } from "../../reducer/GCMemberBlockUnblock";
import {
  convertToLinksAndHighlightUsernames,
  emmitToSocket,
  highlightUsernames,
  scrollToBottom,
} from "../../common";
import MediaChatComponent from "./MediaChatComponent";
import MediaFileComponent from "./MediaFileContent";
import { useNavigate, useParams } from "react-router-dom";
import { userID } from "../GlobalLocalStorage";

const SingleMessage = ({
  item,
  ShowComment,
  name,
  setPosition,
  setShowMenu,
  setEditData,
  selectedMessage,
  setSelectedMessage,
  showCheckbox,
  setShowCheckbox,
  userOpenComment,
  searchMessage,
  userOpenSetting,
  setGallary,
  setShowGallary,
  userOpenProfile,
  setUserOpenProfile,
  setUserOpenSetting,
  setUserOpenComment,
  setCommentAllowDiscussionPermission,
  setGCLikeUnlike,
  setTagUsers,
  setTagUsersComment,
  setLikeItem,
  setEmojijList,
  setSearchMessage,
  setShowReactionCountPopup,
  Type,
  setPositionXX,
  maxX,
  maxY,
  getLastMessageAddClassName,
  PinMessageState,
  setAddLastMessageClassMouseRightClickShowPopup,
  handleFindeMessage,
  setAllowDisscussionUpdatePermission,
  settagUserImgUrl,
  focusElement
}) => {
  const [checked, setChecked] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [showAllMedia, setShowAllMedia] = useState(false);
  const [showDummyOriginalView, setShowDummyOriginalView] = useState(false);
  const [readMoreReadLessMsg, setReadMoreReadLessMsg] = useState(false);
  const myRef = useRef(null);
  const liRef = useRef(null);
  const endRef = useRef(null);
  const [likeValue, setLikeValue] = useState();
  const userRoleSlugSettingPermission = localStorage.getItem("user_role_slug");

  // Get group / Channel full Permission
  const gcSettingPermissionData = JSON.parse(
    localStorage.getItem("GCPermissonCustmValue")
  );

  const checkGlobalPermission = useSelector(
    (state) =>
      state?.UpdateProfileReducer?.userProfileUpdatedetails?.data?.user_setting
  );
  const groupChannelDetails = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData
  );

  const gcPermissionSetting = groupChannelDetails?.gc_setting;
  const getGroupChannelId = useSelector(
    (state) => state?.GetChat?.channelgroupid
  );
  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);
  const connectid = localStorage.getItem("gtfconnectid");
  const dispatch = useDispatch();
  const buttons = {
    left: 0,
    right: 2,
  };
  const handleChangeSingleMessage = (e) => {
    if (e.button === buttons.left) {
      setShowMenu(false);
      setShowReactionCountPopup(false);
      setEmojijList([]);
    } else if (e.button === buttons.right) {
      setShowMenu(true);
      // setShowReactionCountPopup(false)
    }
  };

  const handleMouseScroll = () => {
    setShowMenu(false);
    setShowReactionCountPopup(false);
  };
  useEffect(() => {
    setGCLikeUnlike(likeValue);
  }, [likeValue]);
  useEffect(() => {
    dispatch(storeSavedMessageGCID(selectedMessage));
  }, [selectedMessage]);

  // handle context menu like group/channel mouse right click show popup
  const handleContextMenu = (e, value) => {
    e.preventDefault();
    const adjustedX = e.clientX > maxX ? maxX : e.clientX;
    const adjustedY = e.clientY > maxY ? maxY : e.clientY;
    setPositionXX({ adjustedX, adjustedY });
    setPosition({ x: e.clientX, y: e.clientY });
    setEditData({ item, SavedHandelFirstSelect });
    setCommentAllowDiscussionPermission({ item });
    setLikeItem(item);

    if (value?.GroupChatID === getLastMessageAddClassName?.GroupChatID) {
      setAddLastMessageClassMouseRightClickShowPopup(true);
    } else {
      setAddLastMessageClassMouseRightClickShowPopup(false);
    }
  };

  // handle saved message select box
  const handleSelectbox = (item) => {
    setChecked(!checked);
    if (!checked) {
      setSelectedMessage([...selectedMessage, item]);
      setShowCheckbox(true);
    } else {
      if (selectedMessage?.length === 1) {
        setShowCheckbox(false);
      }
      setSelectedMessage(
        selectedMessage?.filter(
          (data) => data?.GroupChatID !== item?.GroupChatID
        )
      );
    }
  };
  // show read more and read less content
  //Read Less and More
  const contentAll = item?.Message?.split(" ");
  const chunkSize = 100;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    const chunksArray = [];
    for (let i = 0; i < contentAll.length; i += chunkSize) {
      chunksArray.push(contentAll.slice(i, i + chunkSize));
    }
    setChunks(chunksArray);
  }, [item]);

  useEffect(() => {
    setDisplayedWords(chunks.slice(0, currentIndex + 1).flat());
  }, [chunks, currentIndex]);

  const showNextChunk = () => {
    if (currentIndex < chunks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  // Wrap tag user message
  const addMessagesTagUser = displayedWords.join(" ");
  const showPrevChunk = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const hasMoreContent = currentIndex < chunks.length - 1;
  // ...............

  // saved message handle
  const SavedHandelFirstSelect = () => {
    setChecked(true);
    setSelectedMessage([...selectedMessage, item]);
  };
  const totalViewCount = item?.originalviews + item?.DummyViews;
  const mediaToShow = showAllMedia
    ? item?.media
    : item?.media
        ?.filter(
          (item) =>
            item?.MimeType?.startsWith("image") ||
            item?.MimeType?.startsWith("video")
        )
        .slice(0, 4);

  let allItems = item?.media?.filter(
    (mediaItem) =>
      mediaItem?.MimeType?.startsWith("image") ||
      mediaItem?.MimeType?.startsWith("video")
  );

  // Show media files
  const showMediaFiles = item?.media?.map((media, index) => (
    <MediaFileComponent
      key={index}
      media={media}
      baseUrlMedia={baseUrlMedia}
      checkGlobalPermission={checkGlobalPermission}
    />
  ));

  const newTimeChat = new Date(item?.CreatedAt).toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  useEffect(() => {
    // Define the callback function to handle the response from the "tagUserList" event
    const handleTagUserList = (response) => {
      setTagUsers(response?.data?.data);
      settagUserImgUrl(response?.data);
      setTagUsersComment(response?.data?.data);
    };

    let body = {
      GroupChannelID: item?.GroupChannelID,
      UserID: localStorage.getItem("gtfconnectid"),
    };

    // Emit the "tagUserList" event when the "item" dependency changes
    emmitToSocket("tagUserListAll", body, handleTagUserList);

    // Clean up the event listener when the component unmounts or "item" changes
    return () => {
      socket.off("tagUserListAll", handleTagUserList);
    };
  }, [socket, item]);

 // Scroll to pinned message
  useEffect(() => {
    if (item && searchMessage) {
      if (item?.GroupChatID === searchMessage?.GroupChatID) {
        const element = myRef?.current;
        focusElement(element)
        element?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => setSearchMessage(null), 3000);
      }
    }
  }, [item, searchMessage]);

  // Scroll to bottom click group/channel list
  useEffect(() => {
    if (liRef && liRef.current) {
      setTimeout(() => {
        liRef.current &&
          liRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }, 100);
    }
  }, [item, liRef]);

  const showLastComment =
    item?.commentData?.length &&
    item?.commentData[item?.commentData?.length - 1];

  const openCommentHandlePopup = (item) => {
    ShowComment(item);
    setCommentAllowDiscussionPermission(item);
    setAllowDisscussionUpdatePermission(item);
  };

  // Gc reactEmoji
  let gcReactEmoji =
    item?.like[0]?.isLike === 1 ? item?.like[0]?.reaction?.EmojiCode : "";

  // findTextHyperlink
  // const findTextHyperlink = /(https?:\/\/[^\s]+)/g;

  // const convertToLinks = () => {
  //   return item?.Message.split(findTextHyperlink).map((part, index) => {
  //     if (index % 2 === 1) {
  //       const url = part;
  //       return (
  //         <a key={index} href={url} target="_blank" rel="noopener noreferrer">
  //       {url}
  //         </a>
  //       );
  //     } else {
  //       // This part is regular text
  //       return part;
  //     }
  //   });
  // };



  return (
    <>
      {Type === "group" && (
        <li
          key={item.id}
          className={
            item?.UserID == connectid ? `massage_send ` : `massage_recived`
          }
          onContextMenu={(event) => handleContextMenu(event, item)}
          onMouseDown={handleChangeSingleMessage}
          onWheel={handleMouseScroll}
          // ref={ liRef}
          ref={item?.GroupChatID === searchMessage?.GroupChatID ? myRef : liRef}
        >
          {(item?.UserID == connectid) == `massage_send` ? (
            <div className="massage_inner" id="hello">
              <div className="massageer_name ">
                {name ? (
                  <figure className="user_im">
                    <img
                      src={
                        item?.user?.ProfileImage == null
                          ? user1
                          : `${baseUrlMedia?.baseUrl}${item?.user?.ProfileImage}`
                      }
                      alt="img"
                    />
                  </figure>
                ) : null}
                {name ? (
                  <strong className="orange">
                    {item?.UserID == connectid
                      ? "You"
                      : item?.dummyUserData !== null
                      ? `${item?.dummyUserData?.Firstname} ${
                          item?.dummyUserData?.Lastname === null
                            ? ""
                            : item?.dummyUserData?.Lastname
                        }`
                      : ` ${item.user?.Firstname} ${
                          item.user?.Lastname === null
                            ? ""
                            : item.user?.Lastname
                        } `}
                  </strong>
                ) : null}
              </div>
              <div className="massage_content">
                <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>

                <div className="time_se">
                  <span>
                    {`${totalViewCount} `}
                    <img src={viewIcon} alt="viewIcon" />
                  </span>
                  <small className="text-black">
                    {moment(newTimeChat).add("hours").format("hh:mm A")}
                  </small>
                </div>
              </div>
              <div className="massage_comments">
                {item?.commentData?.length > 0 && (
                  <h2>
                    Comments (
                    {item?.commentData?.length === undefined
                      ? 0
                      : item?.commentData?.length}
                    )
                  </h2>
                )}
              </div>
              <div className="replay_btn_po">
                {userRoleSlugSettingPermission === "super-admin" ||
                gcSettingPermissionData?.full_permission == 1 ||
                gcPermissionSetting?.RestrictSharingContent == 1 ? (
                  <a
                    data-bs-toggle={
                      userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      gcPermissionSetting?.RestrictSharingContent == 1
                        ? "modal"
                        : ""
                    }
                    data-bs-target={
                      userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      gcPermissionSetting?.RestrictSharingContent == 1
                        ? "#Forward"
                        : ""
                    }
                    onClick={() => setSelectedMessage([item])}
                  >
                    <img src={replyIcon} alt="replay" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div
              className={`massage_inner chat_live hello${
                userOpenComment || userOpenSetting || userOpenProfile
                  ? "width_calc"
                  : "" || item?.GroupChatID === searchMessage?.GroupChatID
                  ? " blinking-background"
                  : ""
              }`}
            >
              {showCheckbox && (
                <input
                  type="checkbox"
                  name="select"
                  value={checked}
                  checked={checked}
                  className="select_message_design"
                  onChange={() => {
                    handleSelectbox(item);
                  }}
                />
              )}
              {item?.UserID == connectid ? (
                ""
              ) : (
                <div
                  className="massageer_name massage_content openright-bar"
                  data-apply_classname="open_profile_sidebar"
                >
                  {name ? (
                    <figure
                      className="user_im"
                      onClick={() => {
                        if (item?.dummyUserData !== null) {
                          // Handle the case when dummyUserData is not null
                        } else {
                          // Dispatch actions and update state
                          dispatch(
                            GroupChannelUserBlockMemberID(item?.GCMemberID)
                          );
                          dispatch(
                            GCUserInfoMediaApi({
                              GCMemberID: item?.GCMemberID,
                              getGroupChannelId,
                            })
                          );
                          setUserOpenProfile(!userOpenProfile);

                          // You can use if statements for more complex conditional behavior
                          if (userOpenProfile) {
                            setUserOpenSetting(false);
                            setUserOpenComment(false);
                          }
                        }
                      }}
                    >
                      <img
                        src={
                          item?.dummyUserData === null
                            ? item?.user?.ProfileImage == null
                              ? user1
                              : `${baseUrlMedia?.baseUrl}${item?.user?.ProfileImage}`
                            : item?.dummyUserData?.ProfilePic == null
                            ? user1
                            : `${baseUrlMedia?.dummyUserBaseUrl}${item?.dummyUserData?.ProfilePic}`
                        }
                        alt="img"
                      />
                    </figure>
                  ) : null}
                  {name ? (
                    <strong className="orange">
                      {item?.UserID == connectid
                        ? ""
                        : item?.dummyUserData
                        ? `${item?.dummyUserData?.Firstname} ${
                            item?.dummyUserData?.Lastname === null
                              ? ""
                              : item?.dummyUserData?.Lastname
                          }`
                        : ` ${item.user?.Firstname} ${
                            item.user?.Lastname === null
                              ? ""
                              : item.user?.Lastname
                          }  `}
                    </strong>
                  ) : null}
                </div>
              )}

              {/* *************************************** quote message *********************************************** */}
              {item?.UserID == connectid ? (
                <div className="massage_content">
                  {item?.GroupChatRefID !== null ? (
                    <div className="massage_inner massage_inner_unsetborder massage-quote-inner">
                      <div className="massage_content">
                        <div
                          className="massage_quote_text"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleFindeMessage(item?.quote)}
                        >
                          <div className="quote_content">
                            <span>
                              <img src={quoteIcon} alt="quoteIcon" />
                            </span>
                            <div className="quote_text">
                              <h2>Quote</h2>
                              <div className="imgcontent-group">
                                <div
                                  className={`goup-row row vodeo_row_play_pause ${
                                    item?.quote?.media?.length == 1
                                      ? "no_column"
                                      : ""
                                  }`}
                                >
                                  <MediaChatComponent
                                    mediaToShow={item?.quote?.media}
                                    allItems={item?.quote?.media}
                                    baseUrlMedia={baseUrlMedia}
                                    checkGlobalPermission={
                                      checkGlobalPermission
                                    }
                                    videoPlay={videoPlay}
                                    dispatch={dispatch}
                                    showPreviewImage={showPreviewImage}
                                    setGallary={setGallary}
                                    setShowGallary={setShowGallary}
                                  />
                                </div>
                              </div>
                              <p>
                                {convertToLinksAndHighlightUsernames(
                                  item?.quote?.Message?.slice(0, 200)
                                )}
                                {item?.quote?.Message?.length > 200
                                  ? "..."
                                  : ""}
                              </p>
                              <div className="time_se">
                                <small className="time-text">
                                  {item?.quote?.user?.UserID === connectid
                                    ? "You , "
                                    : item?.quote?.dummyUserData === null
                                    ? ` ${item?.quote?.user?.Firstname} ${
                                        item?.quote?.user?.Lastname === null
                                          ? ""
                                          : item?.quote?.user?.Lastname
                                      } `
                                    : ` ${
                                        item?.quote?.dummyUserData?.Firstname
                                      } ${
                                        item?.quote?.dummyUserData?.Lastname ===
                                        null
                                          ? ""
                                          : item?.quote?.dummyUserData?.Lastname
                                      } , `}
                                  {moment(item?.quote?.CreatedAt)
                                    .add("hours")
                                    .format("hh:mm A")}
                                </small>
                              </div>
                            </div>
                          </div>
                          <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>
                          <div className="time_se">
                            <small className="time-text"></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <p>
                          {/* {highlightUsernames(addMessagesTagUser)} */}
                          {/* {convertToLinks(item?.Message)} */}
                          {convertToLinksAndHighlightUsernames(addMessagesTagUser)}

                          {hasMoreContent && "..."}
                          {currentIndex < chunks.length - 1 && (
                            <span
                              onClick={showNextChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read More
                            </span>
                          )}
                          {currentIndex > 0 && (
                            <span
                              onClick={showPrevChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read Previous
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="imgcontent-group">
                        <div>{highlightUsernames(item?.Message)}</div>
                        <div
                          className={`goup-row row vodeo_row_play_pause ${
                            item?.media?.length == 1 ? "no_column" : ""
                          }`}
                        >
                          <MediaChatComponent
                            mediaToShow={mediaToShow}
                            allItems={allItems}
                            baseUrlMedia={baseUrlMedia}
                            checkGlobalPermission={checkGlobalPermission}
                            videoPlay={videoPlay}
                            dispatch={dispatch}
                            showPreviewImage={showPreviewImage}
                            setGallary={setGallary}
                            setShowGallary={setShowGallary}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {showContent && (
                    <p>
                      {item?.Message.slice(500)}
                      <span
                        style={{
                          paddingLeft: "10px",
                          color: "green",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setShowContent(!showContent)}
                      >
                        Read Less
                      </span>
                    </p>
                  )}

                  {/* {item?.UserID == connectid ? (
                      <>
                        <div className="reply_comments_sc_send_message">
                          <a
                          
                            data-bs-toggle={
                              userRoleSlugSettingPermission === "super-admin" ||
                              gcPermissionSetting?.RestrictSharingContent == 1
                                ? "modal"
                                : ""
                            }
                            data-bs-target={
                              userRoleSlugSettingPermission === "super-admin" ||
                              gcPermissionSetting?.RestrictSharingContent == 1
                                ? "#Forward"
                                : ""
                            }
                          >
                            <img src={sendMessageReply} alt="replay" />
                          </a>
                        </div>
                      </>
                    ) : 
                      <div className="time_se">
                        <span>
                          {`${totalViewCount} `}
                          <img src={viewIcon} alt="viewIcon" />
                        </span>
                        <small className="text-black">
                          {moment(newTimeChat).add("hours").format("hh:mm A")}
                        </small>
                      </div>
                    } */}
                </div>
              ) : (
                <div className="massage_content ">
                  {/* *************************************** quote message *********************************************** */}
                  {item?.GroupChatRefID !== null ? (
                    <div className="massage_inner massage_inner_unsetborder massage-quote-inner">
                      <div className="massage_content">
                        <div
                          className="massage_quote_text"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleFindeMessage(item?.quote)}
                        >
                          <div className="quote_content">
                            <span>
                              <img src={quoteIcon} alt="quoteIcon" />
                            </span>
                            <div className="quote_text">
                              <h2>Quote</h2>
                              <div className="imgcontent-group">
                                <div
                                  className={`goup-row row vodeo_row_play_pause ${
                                    item?.quote?.media?.length == 1
                                      ? "no_column"
                                      : ""
                                  }`}
                                >
                                  <MediaChatComponent
                                    mediaToShow={item?.quote?.media}
                                    allItems={item?.quote?.media}
                                    baseUrlMedia={baseUrlMedia}
                                    checkGlobalPermission={
                                      checkGlobalPermission
                                    }
                                    videoPlay={videoPlay}
                                    dispatch={dispatch}
                                    showPreviewImage={showPreviewImage}
                                    setGallary={setGallary}
                                    setShowGallary={setShowGallary}
                                  />
                                </div>
                              </div>
                              <p>
                                {convertToLinksAndHighlightUsernames(
                                  item?.quote?.Message?.slice(0, 200)
                                )}
                                {item?.quote?.Message?.length > 200
                                  ? "..."
                                  : ""}
                              </p>
                              <div className="time_se">
                                <small className="time-text">
                                  {item?.quote?.user?.UserID === connectid
                                    ? "You , "
                                    : item?.quote?.dummyUserData === null
                                    ? ` ${item?.quote?.user?.Firstname} ${
                                        item?.quote?.user?.Lastname === null
                                          ? ""
                                          : item?.quote?.user?.Lastname
                                      } `
                                    : ` ${
                                        item?.quote?.dummyUserData?.Firstname
                                      } ${
                                        item?.quote?.dummyUserData?.Lastname ===
                                        null
                                          ? ""
                                          : item?.quote?.dummyUserData?.Lastname
                                      } , `}
                                  {moment(item?.quote?.CreatedAt)
                                    .add("hours")
                                    .format("hh:mm A")}
                                </small>
                              </div>
                            </div>
                          </div>
                          <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>
                          <div className="time_se">
                            <small className="time-text"></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <p>
                          {convertToLinksAndHighlightUsernames(addMessagesTagUser)}
                          {hasMoreContent && "..."}
                          {currentIndex < chunks.length - 1 && (
                            <span
                              onClick={showNextChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read More
                            </span>
                          )}
                          {currentIndex > 0 && (
                            <span
                              onClick={showPrevChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read Previous
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="imgcontent-group">
                        <div
                          className={`goup-row row vodeo_row_play_pause ${
                            item?.media?.length == 1 ? "no_column" : ""
                          }`}
                        >
                          <MediaChatComponent
                            mediaToShow={mediaToShow}
                            allItems={allItems}
                            baseUrlMedia={baseUrlMedia}
                            checkGlobalPermission={checkGlobalPermission}
                            videoPlay={videoPlay}
                            dispatch={dispatch}
                            showPreviewImage={showPreviewImage}
                            setGallary={setGallary}
                            setShowGallary={setShowGallary}
                          />
                        </div>
                        <div className="media_filegroup_list">
                          {showMediaFiles}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="massage_content">
                <div className="media_filegroup_list">{showMediaFiles}</div>
                {item?.UserID == connectid ? (
                  <>
                    <div className="time_se">
                      <span>
                        {`${totalViewCount} `}
                        <img src={viewIcon} alt="viewIcon" />
                      </span>
                      <small className="text-black">
                        {item?.dummyUserData && (
                          <>
                            {" "}
                            <span className="dummy_user_name">
                              {`${item?.dummyUserData?.Firstname} ${
                                item?.dummyUserData?.Lastname === null
                                  ? ""
                                  : item?.dummyUserData?.Lastname
                              }`}{" "}
                            </span>{" "}
                            <strong
                              style={{ position: "relative", left: "-10px" }}
                            >
                              /
                            </strong>{" "}
                          </>
                        )}
                        {moment(newTimeChat).add("hours").format("hh:mm A")}
                      </small>
                      {/* <span className="duble-tick">
                          <img src={doubleTick} alt="doubleTick" />
                        </span> */}
                    </div>
                    {userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      (gcPermissionSetting?.RestrictSharingContent == 1 && (
                        <div className="reply_comments_sc_send_message">
                          <a
                            data-bs-toggle={
                              userRoleSlugSettingPermission === "super-admin" ||
                              gcSettingPermissionData?.full_permission == 1 ||
                              gcPermissionSetting?.RestrictSharingContent == 1
                                ? "modal"
                                : ""
                            }
                            data-bs-target={
                              userRoleSlugSettingPermission === "super-admin" ||
                              gcSettingPermissionData?.full_permission == 1 ||
                              gcPermissionSetting?.RestrictSharingContent == 1
                                ? "#Forward"
                                : ""
                            }
                            onClick={() => setSelectedMessage([item])}
                          >
                            <img src={sendMessageReply} alt="replay" />
                          </a>
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="time_se">
                    <span>
                      {`${totalViewCount} `}
                      <img src={viewIcon} alt="viewIcon" />
                    </span>
                    <small className="text-black">
                      {moment(newTimeChat).add("hours").format("hh:mm A")}
                    </small>
                  </div>
                )}

                {/* {item?.UserID == connectid ? (
                    ""
                  ) : ( */}
                <>
                  <div className="massage_comments">
                    {item?.commentData?.length > 0 && (
                      <h2>
                        Comments (
                        {item?.commentData?.length === undefined
                          ? 0
                          : item?.commentData?.length}
                        )
                      </h2>
                    )}
                    {item?.commentData?.length > 0 && (
                      <div className="massage_comments_content">
                        <div className="comments_img">
                          <figure>
                            <img
                              src={
                                showLastComment?.dummyUserData !== null
                                  ? showLastComment?.dummyUserData
                                      ?.ProfilePic === null
                                    ? user1
                                    : `${baseUrlMedia?.dummyUserBaseUrl}${showLastComment?.dummyUserData?.ProfilePic}`
                                  : showLastComment?.user?.ProfileImage === null
                                  ? user1
                                  : `${baseUrlMedia?.baseUrl}${showLastComment?.user?.ProfileImage}`
                              }
                              alt="img"
                            />
                          </figure>
                        </div>
                        <div className="commment_te">
                          <strong>
                            {showLastComment?.dummyUserData === null
                              ? `${showLastComment?.user?.Firstname} ${
                                  showLastComment?.user?.Lastname === null
                                    ? ""
                                    : showLastComment?.user?.Lastname
                                }`
                              : `${showLastComment?.dummyUserData?.Firstname} ${
                                  showLastComment?.dummyUserData?.Lastname ===
                                  null
                                    ? ""
                                    : showLastComment?.dummyUserData?.Lastname
                                }`}
                            <span>
                              {moment(showLastComment?.CreatedAt)
                                .add("hours")
                                .format("hh:mm A")}
                            </span>
                          </strong>
                          <p>
                            {/* {showLastComment?.comment} */}
                            {convertToLinksAndHighlightUsernames(
                              showLastComment?.comment?.slice(0, 200)
                            )}
                            {showLastComment?.comment?.length > 200
                              ? "..."
                              : ""}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="comments_footer">
                      {(gcPermissionSetting?.AllowDiscussion == 1 && item?.AllowDiscussion == 1) && <div
                        className="reply_comments_sc"
                        onClick={() => {
                          openCommentHandlePopup(item);
                        }}
                      >
                        <a>
                          <img src={replyLeft} alt="replay" /> Reply
                        </a>
                      </div>}
                      <div className="comments_share">
                        <a className="like_link">
                          {`Your Reaction ${gcReactEmoji} `}
                        </a>
                      </div>
                      <div className="emoji_group">
                        {item?.threeEmoji &&
                          item?.threeEmoji?.map((react, index) => (
                            <span key={index}>
                              {react?.reactionDetail?.EmojiCode}
                            </span>
                          ))}
                        {item?.reactionscount > 0 && (
                          <em>{item?.reactionscount}</em>
                        )}
                      </div>
                    </div>
                  </div>
                  {item?.UserID == connectid
                    ? ""
                    : userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      (gcPermissionSetting?.RestrictSharingContent == 1 && (
                        <div className="replay_btn_po">
                          {userRoleSlugSettingPermission === "super-admin" ||
                          gcSettingPermissionData?.full_permission == 1 ||
                          gcPermissionSetting?.RestrictSharingContent == 1 ? (
                            <a
                              data-bs-toggle={
                                userRoleSlugSettingPermission ===
                                  "super-admin" ||
                                gcSettingPermissionData?.full_permission == 1 ||
                                gcPermissionSetting?.RestrictSharingContent == 1
                                  ? "modal"
                                  : ""
                              }
                              data-bs-target={
                                userRoleSlugSettingPermission ===
                                  "super-admin" ||
                                gcSettingPermissionData?.full_permission == 1 ||
                                gcPermissionSetting?.RestrictSharingContent == 1
                                  ? "#Forward"
                                  : ""
                              }
                              onClick={() => setSelectedMessage([item])}
                            >
                              <img src={replyIcon} alt="replay" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                </>
                {/* )} */}
              </div>
            </div>
          )}
          <div ref={endRef}></div>
        </li>
      )}

      {Type === "channel" && (
        <li
          key={item.id}
          className={`massage_recived`}
          // className={
          //   item.UserID == connectid ? `massage_send` : `massage_recived`
          // }
          onContextMenu={(event) => handleContextMenu(event, item)}
          onMouseDown={handleChangeSingleMessage}
          onWheel={handleMouseScroll}
          ref={item?.GroupChatID === searchMessage?.GroupChatID ? myRef : liRef}
        >
          {(item?.UserID == connectid) == `massage_send` ? (
            <div className="massage_inner" id="hello">
              <div className="massageer_name ">
                {/* {name ? ( */}
                <figure className="user_im">
                  <img
                    src={
                      item?.user?.ProfileImage === null
                        ? user1
                        : `${baseUrlMedia?.baseUrl}${item?.user?.ProfileImage}`
                    }
                    alt="img"
                  />
                </figure>
                {/* ) : null} */}

                {name ? (
                  <strong className="orange">
                    {item?.UserID == connectid
                      ? "You"
                      : gcPermissionSetting?.SignedMsg === 1 ||
                        userRoleSlugSettingPermission === "super-admin" ||
                        gcSettingPermissionData?.full_permission == 1
                      ? `${groupChannelDetails?.gc_info?.Name}/${
                          item.user?.Firstname
                        } ${
                          item.user?.Lastname === null
                            ? ""
                            : item.user?.Lastname
                        } `
                      : `${groupChannelDetails?.gc_info?.Name}`}
                  </strong>
                ) : null}
              </div>
              <div className="massage_content">
                <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>
                <div className="time_se">
                  <span>
                    {`${totalViewCount} `}
                    <img src={viewIcon} alt="viewIcon" />
                  </span>
                  <small className="text-black ">
                    {moment(newTimeChat).add("hours").format("hh:mm A")}
                  </small>
                </div>
              </div>
              <div className="massage_comments">
                {item?.commentData?.length > 0 && (
                  <h2>
                    Comments (
                    {item?.commentData?.length === undefined
                      ? 0
                      : item?.commentData?.length}
                    )
                  </h2>
                )}

                {item?.commentData?.length > 0 && (
                  <div className="massage_comments_content">
                    <div className="comments_img">
                      <figure>
                        <img
                          src={
                            showLastComment?.dummyUserData !== null
                              ? showLastComment?.dummyUserData?.ProfilePic ===
                                null
                                ? user1
                                : `${baseUrlMedia?.dummyUserBaseUrl}${showLastComment?.dummyUserData?.ProfilePic}`
                              : showLastComment?.user?.ProfileImage === null
                              ? user1
                              : `${baseUrlMedia?.baseUrl}${showLastComment?.user?.ProfileImage}`
                          }
                          alt="img"
                        />
                      </figure>
                    </div>

                    <div className="commment_te">
                      <strong>
                        {`${showLastComment?.user?.Firstname} ${
                          showLastComment?.user?.Lastname === null
                            ? ""
                            : showLastComment?.user?.Lastname
                        }`}
                        <span>
                          {moment(showLastComment?.CreatedAt)
                            .add("hours")
                            .format("hh:mm A")}
                        </span>
                      </strong>
                      {/* <p>{showLastComment?.comment}</p> */}
                      <p>
                            {/* {showLastComment?.comment} */}
                            {convertToLinksAndHighlightUsernames(
                              showLastComment?.comment?.slice(0, 200)
                            )}
                            {showLastComment?.comment?.length > 200
                              ? "..."
                              : ""}
                          </p>
                    </div>
                  </div>
                )}
                {/* <div className="comments_footer">
                    <div className="reply_comments_sc">
                      <a
                        onClick={() => {
                          openCommentHandlePopup(item);
                        }}
                      >
                        <img src={replyLeft} alt="replay" /> Reply
                      </a>
                    </div>
                    <div className="comments_share">
                      <a className="like_link">
                        <img src={like} alt="like" />
                        60
                      </a>
                      <a className="hart_link">
                        <img src={heart} alt="hart" />
                        45
                      </a>
                    </div>
                  </div> */}
              </div>
              <div className="replay_btn_po">
                {userRoleSlugSettingPermission === "super-admin" ||
                gcSettingPermissionData?.full_permission == 1 ||
                gcPermissionSetting?.RestrictSharingContent == 1 ? (
                  <a
                    data-bs-toggle={
                      userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      gcPermissionSetting?.RestrictSharingContent == 1
                        ? "modal"
                        : ""
                    }
                    data-bs-target={
                      userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      gcPermissionSetting?.RestrictSharingContent == 1
                        ? "#Forward"
                        : ""
                    }
                    onClick={() => setSelectedMessage([item])}
                  >
                    <img src={replyIcon} alt="replay" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div
              className={`massage_inner chat_live ${
                userOpenComment || userOpenSetting || userOpenProfile
                  ? "width_calc"
                  : "" || item?.GroupChatID === searchMessage?.GroupChatID
                  ? " blinking-background"
                  : ""
              }`}
            >
              {showCheckbox && (
                <input
                  type="checkbox"
                  name="select"
                  value={checked}
                  checked={checked}
                  className="select_message_design"
                  onChange={() => {
                    handleSelectbox(item);
                  }}
                />
              )}

              <div
                className="massageer_name massage_content openright-bar"
                data-apply_classname="open_profile_sidebar"
              >
                {/* {name ? ( */}

                <figure
                  className="user_im"
                  onClick={() => {
                    dispatch(GroupChannelUserBlockMemberID(item?.GCMemberID));
                    dispatch(
                      GCUserInfoMediaApi({
                        GCMemberID: item?.GCMemberID,
                        getGroupChannelId,
                      })
                    );
                    setUserOpenProfile(!userOpenProfile) ||
                      (userOpenProfile && setUserOpenSetting(false)) ||
                      setUserOpenComment(false);
                  }}
                >
                  <img
                    src={
                      item?.dummyUserData === null
                        ? item?.user?.ProfileImage == null
                          ? user1
                          : `${baseUrlMedia?.baseUrl}${item?.user?.ProfileImage}`
                        : item?.dummyUserData?.ProfilePic == null
                        ? user1
                        : `${baseUrlMedia?.dummyUserBaseUrl}${item?.dummyUserData?.ProfilePic}`
                    }
                    alt="img"
                  />
                </figure>
                {/* ) : null} */}
                <strong className="orange">
                  {
                    // item?.UserID == connectid
                    //   ? ""
                    //   :
                    gcPermissionSetting?.SignedMsg === 1 ||
                    userRoleSlugSettingPermission === "super-admin" ||
                    gcSettingPermissionData?.full_permission == 1
                      ? `${groupChannelDetails?.gc_info?.Name}/${
                          item.user?.Firstname
                        } ${
                          item.user?.Lastname === null
                            ? ""
                            : item.user?.Lastname
                        } `
                      : `${groupChannelDetails?.gc_info?.Name}`
                  }
                </strong>
              </div>
              {item?.UserID == connectid ? (
                <div className="massage_content">
                  {/* *************************************** quote message *********************************************** */}
                  {item?.GroupChatRefID !== null ? (
                    <div className="massage_inner massage_inner_unsetborder massage-quote-inner">
                      <div className="massage_content">
                        <div
                          className="massage_quote_text"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleFindeMessage(item?.quote)}
                        >
                          <div className="quote_content">
                            <span>
                              <img src={quoteIcon} alt="quoteIcon" />
                            </span>
                            <div className="quote_text">
                              <h2>Quote</h2>
                              <div className="imgcontent-group">
                                <div
                                  className={`goup-row row vodeo_row_play_pause ${
                                    item?.quote?.media?.length == 1
                                      ? "no_column"
                                      : ""
                                  }`}
                                >
                                  <MediaChatComponent
                                    mediaToShow={item?.quote?.media}
                                    allItems={item?.quote?.media}
                                    baseUrlMedia={baseUrlMedia}
                                    checkGlobalPermission={
                                      checkGlobalPermission
                                    }
                                    videoPlay={videoPlay}
                                    dispatch={dispatch}
                                    showPreviewImage={showPreviewImage}
                                    setGallary={setGallary}
                                    setShowGallary={setShowGallary}
                                  />
                                </div>
                              </div>
                              <p>
                                {convertToLinksAndHighlightUsernames(
                                  item?.quote?.Message?.slice(0, 200)
                                )}
                                {item?.quote?.Message?.length > 200
                                  ? "..."
                                  : ""}
                              </p>
                              <div className="time_se">
                                <small className="time-text">
                                  {item?.quote?.user?.UserID === connectid
                                    ? "You , "
                                    : ` ${item?.quote?.user?.Firstname} ${
                                        item?.quote?.user?.Lastname === null
                                          ? ""
                                          : item?.quote?.user?.Lastname
                                      } , `}
                                  {moment(item?.quote?.CreatedAt)
                                    .add("hours")
                                    .format("hh:mm A")}
                                </small>
                              </div>
                            </div>
                          </div>
                          <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>
                          <div className="time_se">
                            <span>
                              {`${totalViewCount} `}
                              <img src={viewIcon} alt="viewIcon" />
                            </span>
                            <small className="time-text"></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <p>
                          {/* {highlightUsernames(addMessagesTagUser)} */}
                          <pre style={{wordWrap:"break-word", whiteSpace:"pre-line"}}>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</pre>
                          {hasMoreContent && "..."}
                          {currentIndex < chunks.length - 1 && (
                            <span
                              onClick={showNextChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read More
                            </span>
                          )}
                          {currentIndex > 0 && (
                            <span
                              onClick={showPrevChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read Previous
                            </span>
                          )}
                        </p>
                      </div>
                      {showContent && (
                        <p>
                          {item?.Message.slice(500)}
                          <span
                            style={{
                              paddingLeft: "10px",
                              color: "green",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => setShowContent(!showContent)}
                          >
                            Read Less
                          </span>
                        </p>
                      )}
                      <div className="imgcontent-group">
                        <div
                          className={`goup-row row vodeo_row_play_pause ${
                            item?.media?.length == 1 ? "no_column" : ""
                          }`}
                        >
                          <MediaChatComponent
                            mediaToShow={mediaToShow}
                            allItems={allItems}
                            baseUrlMedia={baseUrlMedia}
                            checkGlobalPermission={checkGlobalPermission}
                            videoPlay={videoPlay}
                            dispatch={dispatch}
                            showPreviewImage={showPreviewImage}
                            setGallary={setGallary}
                            setShowGallary={setShowGallary}
                          />
                        </div>
                        <div className="media_filegroup_list">
                          {showMediaFiles}
                        </div>
                      </div>
                    </>
                  )}
                  {showContent && (
                    <p>
                      {item?.Message.slice(500)}
                      <span
                        style={{
                          paddingLeft: "10px",
                          color: "green",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => setShowContent(!showContent)}
                      >
                        Read Less
                      </span>
                    </p>
                  )}

                  {item?.UserID == connectid ? (
                    <>
                      <div className="time_se">
                        <span>
                          {`${totalViewCount} `}
                          <img src={viewIcon} alt="viewIcon" />
                        </span>
                        <small className="text-black">
                          {moment(newTimeChat).add("hours").format("hh:mm A")}
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="time_se">
                      <span>
                        {`${totalViewCount} `}
                        <img src={viewIcon} alt="viewIcon" />
                      </span>
                      <small className="text-black">
                        {moment(newTimeChat).add("hours").format("hh:mm A")}
                      </small>
                    </div>
                  )}
                </div>
              ) : (
                <div className="massage_content">
                  {/* *************************************** quote message *********************************************** */}
                  {item?.GroupChatRefID !== null ? (
                    <div className="massage_inner massage_inner_unsetborder massage-quote-inner">
                      <div className="massage_content">
                        <div
                          className="massage_quote_text"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleFindeMessage(item?.quote)}
                        >
                          <div className="quote_content">
                            <span>
                              <img src={quoteIcon} alt="quoteIcon" />
                            </span>
                            <div className="quote_text">
                              <h2>Quote</h2>
                              <div className="imgcontent-group">
                                <div
                                  className={`goup-row row vodeo_row_play_pause ${
                                    item?.quote?.media?.length == 1
                                      ? "no_column"
                                      : ""
                                  }`}
                                >
                                  <MediaChatComponent
                                    mediaToShow={item?.quote?.media}
                                    allItems={item?.quote?.media}
                                    baseUrlMedia={baseUrlMedia}
                                    checkGlobalPermission={
                                      checkGlobalPermission
                                    }
                                    videoPlay={videoPlay}
                                    dispatch={dispatch}
                                    showPreviewImage={showPreviewImage}
                                    setGallary={setGallary}
                                    setShowGallary={setShowGallary}
                                  />
                                </div>
                              </div>
                              <p>
                                {" "}
                                {convertToLinksAndHighlightUsernames(
                                  item?.quote?.Message?.slice(0, 200)
                                )}
                                {item?.quote?.Message?.length > 200
                                  ? "..."
                                  : ""}
                              </p>
                              <div className="time_se">
                                <small className="time-text">
                                  {item?.quote?.user?.UserID === connectid
                                    ? "You , "
                                    : ` ${item?.quote?.user?.Firstname} ${
                                        item?.quote?.user?.Lastname === null
                                          ? ""
                                          : item?.quote?.user?.Lastname
                                      } , `}
                                  {moment(item?.quote?.CreatedAt)
                                    .add("hours")
                                    .format("hh:mm A")}
                                </small>
                              </div>
                            </div>
                          </div>
                          <p>{convertToLinksAndHighlightUsernames(addMessagesTagUser)}</p>
                          <div className="time_se">
                            <small className="time-text"></small>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <p>
                          {convertToLinksAndHighlightUsernames(addMessagesTagUser)}
                          {hasMoreContent && "..."}
                          {currentIndex < chunks.length - 1 && (
                            <span
                              onClick={showNextChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read More
                            </span>
                          )}
                          {currentIndex > 0 && (
                            <span
                              onClick={showPrevChunk}
                              style={{
                                paddingLeft: "10px",
                                color: "green",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Read Previous
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="imgcontent-group">
                        <div
                          className={`goup-row row vodeo_row_play_pause ${
                            item?.media?.length == 1 ? "no_column" : ""
                          }`}
                        >
                          <MediaChatComponent
                            mediaToShow={mediaToShow}
                            allItems={allItems}
                            baseUrlMedia={baseUrlMedia}
                            checkGlobalPermission={checkGlobalPermission}
                            videoPlay={videoPlay}
                            dispatch={dispatch}
                            showPreviewImage={showPreviewImage}
                            setGallary={setGallary}
                            setShowGallary={setShowGallary}
                          />
                        </div>
                        <div className="media_filegroup_list">
                          {showMediaFiles}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="time_se">
                    <span>
                      {`${totalViewCount} `}
                      <img
                        src={viewIcon}
                        alt="viewIcon"
                        onMouseOver={() =>
                          userRoleSlugSettingPermission === "super-admin" ||
                          (gcSettingPermissionData?.full_permission == 1 &&
                            setShowDummyOriginalView(true))
                        }
                        onMouseLeave={() => setShowDummyOriginalView(false)}
                      />
                    </span>
                    <small className="text-black hello">
                      {moment(newTimeChat).add("hours").format("hh:mm A")}
                    </small>
                  </div>
                </div>
              )}
              {showDummyOriginalView && (
                <div className="reactions-vc-col channelview_total_count">
                  <ul>
                    <li>
                      <div className="reactions-click show_channel_count_popup">
                        <img src={viewIcon} alt="viewIcon" />
                        <p>
                          Origianl view :<strong> {item?.originalviews}</strong>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="reactions-click show_channel_count_popup">
                        <img src={viewIcon} alt="viewIcon" />
                        <p>
                          Manipulated view :
                          <strong> {item?.DummyViews} </strong>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              <>
                <div className="massage_comments">
                  {item?.commentData?.length > 0 && (
                    <h2>
                      Comments (
                      {item?.commentData?.length === undefined
                        ? 0
                        : item?.commentData?.length}
                      )
                    </h2>
                  )}

                  {item?.commentData?.length > 0 && (
                    <div className="massage_comments_content">
                      <div className="comments_img">
                        <figure>
                          <img
                            src={
                              showLastComment?.dummyUserData !== null
                                ? showLastComment?.dummyUserData?.ProfilePic ===
                                  null
                                  ? user1
                                  : `${baseUrlMedia?.dummyUserBaseUrl}${showLastComment?.dummyUserData?.ProfilePic}`
                                : showLastComment?.user?.ProfileImage === null
                                ? user1
                                : `${baseUrlMedia?.baseUrl}${showLastComment?.user?.ProfileImage}`
                            }
                            alt="img"
                          />
                        </figure>
                      </div>
                      <div className="commment_te">
                        <strong>
                          {`${showLastComment?.user?.Firstname} ${
                            showLastComment?.user?.Lastname === null
                              ? ""
                              : showLastComment?.user?.Lastname
                          }`}
                          <span>
                            {moment(showLastComment?.CreatedAt)
                              .add("hours")
                              .format("hh:mm A")}
                          </span>
                        </strong>
                        {/* <p>{showLastComment?.comment}</p> */}
                        <p>
                            {/* {showLastComment?.comment} */}
                            {convertToLinksAndHighlightUsernames(
                              showLastComment?.comment?.slice(0, 200)
                            )}
                            {showLastComment?.comment?.length > 200
                              ? "..."
                              : ""}
                          </p>
                      </div>
                    </div>
                  )}
                  <div className="comments_footer">
                    {(gcPermissionSetting?.AllowDiscussion == 1) && <div
                      className="reply_comments_sc"
                      onClick={() => {
                        openCommentHandlePopup(item);
                      }}
                    >
                      <a>
                        <img src={replyLeft} alt="replay" /> Reply
                      </a>
                    </div>}

                    <div className="comments_share">
                      <a className="like_link">
                        {`Your Reaction ${gcReactEmoji}`}
                      </a>
                    </div>
                    <div className="emoji_group">
                      {item?.threeEmoji?.map((react, index) => (
                        <span key={index}>
                          {react?.reactionDetail?.EmojiCode}
                        </span>
                      ))}
                      {item?.reactionscount > 0 && (
                        <em>{item?.reactionscount}</em>
                      )}
                    </div>
                  </div>
                </div>
                <div className="replay_btn_po">
                  {userRoleSlugSettingPermission === "super-admin" ||
                  gcSettingPermissionData?.full_permission == 1 ||
                  gcPermissionSetting?.RestrictSharingContent == 1 ? (
                    <a
                      data-bs-toggle={
                        userRoleSlugSettingPermission === "super-admin" ||
                        gcSettingPermissionData?.full_permission == 1 ||
                        gcPermissionSetting?.RestrictSharingContent == 1
                          ? "modal"
                          : ""
                      }
                      data-bs-target={
                        userRoleSlugSettingPermission === "super-admin" ||
                        gcSettingPermissionData?.full_permission == 1 ||
                        gcPermissionSetting?.RestrictSharingContent == 1
                          ? "#Forward"
                          : ""
                      }
                      onClick={() => setSelectedMessage([item])}
                    >
                      <img src={replyIcon} alt="replay" />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </div>
          )}
          <div ref={endRef}></div>
        </li>
      )}
    </>
  );
};

export default React.memo(SingleMessage);
