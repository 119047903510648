import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatContent from "../../Component/chatContent";
import ChatLeft from "../../Component/chatLeft";
import Sidebar from "../../Component/sidebar";
import {
  getchannelmemberId,
  getGroupChannelId,
  initialShow,
  lastMessage,
  lastMessageData,
  UpdatedList,
} from "../../reducer/GetChat";
import { socket } from "../../store/SocketIo";
import { Bars } from "react-loader-spinner";
import { notInAnyGroup } from "../../reducer/Reaction";
import { setInHome } from "../../reducer/DetailsChannelGroup";
import {
  chatList,
  mentorDetails,
  pinMessageList,
} from "../../reducer/MentorReducer";

const Group = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [PinMessageState, setPinMesssageState] = useState(false);
  const [savedMessageState, setSavedMesssageState] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [updateReactionListed, setUpdateReactionListed] = useState([]);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [checkSubscriptionPlan, setCheckSubscriptionPlan] = useState(false);
  const [noGroupMessage, setNoGroupMessage] = useState(
    "You are not Subscribed in any group."
  );
  const UserID = localStorage.getItem("UserID");
  const chatLeftShowList = useSelector(
    (state) => state?.GetChat?.upDatedlistData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    socket.emit(
      "auth-user",
      { userId: UserID, filter: "group" },
      (response) => {
        dispatch(setInHome(false));
        const dataa = response?.data?.gcData?.map(
          (data) => data?.group?.message?.[0]?.Message
        );

        if (response?.success === true) {
          setLoading(false);
          dispatch(getchannelmemberId(response?.data?.gcData));
          dispatch(getGroupChannelId(response?.data?.gcData?.GroupChannelID));
          dispatch(lastMessage(dataa));
          dispatch(initialShow(false));
          dispatch(lastMessageData(dataa));
          dispatch(UpdatedList(response?.data));
        } else if (
          response?.success === true &&
          response?.data?.gcData?.length == 0
        ) {
          setLoading(false);
          dispatch(notInAnyGroup());
        }
      }
    );
    dispatch(mentorDetails(null));
    dispatch(chatList([]));
    dispatch(pinMessageList([]));
  }, [socket]);

  return (
    <>
      {loading ? (
        <Bars
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperClass="loader_style"
          visible={true}
        />
      ) : (
        <>
          <div className="d-flex main_body_inner">
            <Sidebar
              savedMessageState={savedMessageState}
              setSavedMesssageState={setSavedMesssageState}
            />
            <ChatLeft
              title="Group"
              show={show}
              setShow={setShow}
              setCheckSubscriptionPlan={setCheckSubscriptionPlan}
              checkSubscriptionPlan={checkSubscriptionPlan}
              page={page}
              chatLeftShowList={chatLeftShowList}
              noGroupMessage={noGroupMessage}
              setPinMesssageState={setPinMesssageState}
              PinMessageState={PinMessageState}
              showCheckbox={showCheckbox}
              setShowCheckbox={setShowCheckbox}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
              setUpdateReactionListed={setUpdateReactionListed}
              updateReactionListed={updateReactionListed}
              savedMessageState={savedMessageState}
              setSavedMesssageState={setSavedMesssageState}
            />
            <ChatContent
              show={show}
              setShow={setShow}
              setPage={setPage}
              page={page}
              checkSubscriptionPlan={checkSubscriptionPlan}
              setPinMesssageState={setPinMesssageState}
              PinMessageState={PinMessageState}
              showCheckbox={showCheckbox}
              setShowCheckbox={setShowCheckbox}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
              setUpdateReactionListed={setUpdateReactionListed}
              updateReactionListed={updateReactionListed}
              savedMessageState={savedMessageState}
              setSavedMesssageState={setSavedMesssageState}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Group;
