import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialUserState = {
  otpVerification: "",
  status: STATUSES.IDLE,
};

const otpVerification = createSlice({
  name: "otpVerification",
  initialState: initialUserState,

  reducers: {
    otpVerificationData(state, action) {
      state.otpVerification = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { otpVerificationData, setStatus } = otpVerification.actions;
export default otpVerification.reducer;

// otp verification
export const fetchOtpverification = (payload) => {
  const { email, otp, navigate, setLoading } = payload;
  const formData = new FormData();
  formData.append("email", email);
  formData.append("otp", otp);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      setLoading(true);
      baseUrlAuth.post("/verify-email-otp", formData).then((res) => {
        if (res?.data?.status === 200) {
          navigate("/login");
          toast.success(res?.data?.message);
          dispatch(setStatus(STATUSES.IDLE));
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log("Otp Verification error:", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
