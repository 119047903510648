import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect } from "react";
import { useState } from "react";

import { ClipLoader } from "react-spinners";
const GallerySlider = ({
  showGallary,
  setGallary,
  setShowGallary,
  gallary,
  baseUrlMedia,
  stage,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#2fb463",
  };
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },

      400: {
        items: 1,
      },

      600: {
        items: 1,
      },

      700: {
        items: 1,
      },

      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    if (gallary?.length > 0) {
      setShowLoader(true);
    }
  }, [gallary]);

  return (
    <div className={`light_modalpopup ${showGallary ? "open-lightmodal" : ""}`}>
      <div className="light_modalcontent">
        <button
          className="close-popup"
          onClick={() => {
            setGallary([]);
            setShowLoader(false);
            setShowGallary(false);
          }}
        >
          x
        </button>

        {/* Gallery Popup */}

        <div className="light_modalbody">
          <OwlCarousel className="owl-theme" loop nav {...options}>
            {showLoader ? (
              <ClipLoader color="#2fb463" cssOverride={override} />
            ) : (
              gallary?.map((media, index) => {
                if (media?.MimeType?.startsWith("image")) {
                  return (
                    <div className="item" key={index}>
                      <img
                        alt={media?.name}
                        src={`${
                          stage === "save" || stage === "mentor"
                            ? baseUrlMedia
                            : baseUrlMedia?.mediaUrl
                        }${media?.StoragePath}${media?.FileName}`}
                        effect="blur" // Applying a blur effect on the lazy-loaded image
                      />
                    </div>
                  );
                } else if (media?.MimeType?.startsWith("video")) {
                  return (
                    <div className="item" key={index}>
                      <video
                        className="video_chatlist_video"
                        src={`${
                          stage === "save" || stage === "mentor"
                            ? baseUrlMedia
                            : baseUrlMedia?.mediaUrl
                        }${media?.StoragePath}${media?.FileName}`}
                        poster={`${
                          stage === "save" || stage === "mentor"
                            ? baseUrlMedia
                            : baseUrlMedia?.mediaUrl
                        }${media?.StoragePath}${media?.Thumbnail}`}
                        controls // Adding controls to the video player
                      />
                    </div>
                  );
                } else {
                  return null; // Ignore other types of media
                }
              })
            )}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default React.memo(GallerySlider);
