import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import {
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";
import MaintenanceModeDailog from "../../common/MaintenanceModeDailog";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  userProfileUpdatedetails: "",
  status: STATUSES.IDLE,
};
export const UpdateProfileReducer = createSlice({
  name: "UpdateProfileReducer",
  initialState,
  reducers: {
    userUpdateProfileDetails: (state, action) => {
      state.userProfileUpdatedetails = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { userUpdateProfileDetails, setStatus } =
  UpdateProfileReducer.actions;

export default UpdateProfileReducer.reducer;

export const getUserUpdateProfileDetails = () => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlEdit.get("user/info").then((response) => {
        if (response?.status === 200) {

          if (response?.data?.status == 401) {
            tokenExpireCheckStatus(false, response?.data?.message);
          } else if (response?.data?.status === 503) {
            MaintenanceModeDailog({message : response?.data?.message})
          }
          // store user permission
          const storeGCPermissionValue =
            response?.data?.data?.user_permission?.reduce(
              (accumulator, item) => {
                accumulator[item.permission?.Name] = item.CustomValue;
                return accumulator;
              },
              {}
            );

          // store user role permission
          const userRolePermissionCheck =
            response?.data?.data?.user_role_info?.primary?.role?.role_permissions?.reduce(
              (accumulator, item) => {
                accumulator[item.permission?.Name] = item.PermissionValue;
                return accumulator;
              },
              {}
            );

          //  Check user role permission and user permission and store data
          const resultObject = { ...storeGCPermissionValue }; // Start with a copy of the second object
          for (const key in userRolePermissionCheck) {
            if (!resultObject.hasOwnProperty(key)) {
              resultObject[key] = userRolePermissionCheck[key];
            }
          }

          // Store GC permission value
          localStorage.setItem(
            "GCPermissonCustmValue",
            JSON.stringify(resultObject === undefined ? 0 : resultObject)
          );

          // store local storage in user role
          localStorage.setItem(
            "user_role_slug",
            response?.data?.data?.user_role_info?.primary?.role?.Slug
          );
          dispatch(userUpdateProfileDetails(response?.data));
          dispatch(setStatus(STATUSES.IDLE));
        } else if (response?.status === 401) {
          tokenExpireCheckStatus(false, response?.data?.message);
        }
      });
    } catch (error) {
      if (error.response) {
        console.log("error.response.data", error.response.data);
        console.log("error.response.status", error.response.status);
        console.log("error.response.headers", error.response.headers);
      } else if (error.request) {
        console.log("error.request", error.request);
      } else {
        console.log("error.message", error.message);
      }
      console.log("error.config", error.config);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
