import { createSlice } from "@reduxjs/toolkit";
import { globalLocalStrogeToken } from "../../Component/GlobalLocalStorage";
import { baseUrlEdit, baseUrlMain } from "../../store/axios";
import { toast } from "react-toastify";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const MentorList = createSlice({
  name: "mentors",
  initialState: {
    mentors: [],
    details: null,
    mentorChatList: [],
    mentorPinMessages: [],
    batchOrCourseList: [],
    mediaURL: "",
    mentorUsersList: [],
    isLoading: false,
    typing: null,
    error: null,
    ConversationID: "",
    mentorProfilePicUrl: "",
    storeMenoteChatInitial: "",
    checkUserRole: false,
    userRoleMentor: "",
    status: STATUSES.IDLE,
  },
  reducers: {
    mentorList: (state, action) => {
      state.mentors = action.payload;
    },
    storeUserRoleMentorList: (state, action) => {
      state.userRoleMentor = action.payload;
    },
    mentorDetails: (state, action) => {
      state.details = action.payload;
    },
    prevMentorChats: (state, action) => {
      state.mentorChatList = [
        ...action.payload?.reverse(),
        ...state?.mentorChatList,
      ];
    },
    chatList: (state, action) => {
      state.mentorChatList =
        action?.payload?.length > 0 ? action.payload.reverse() : [];
    },
    pinMessageList: (state, action) => {
      state.mentorPinMessages = action.payload;
    },
    setTypingStatus: (state, action) => {
      state.typing = action.payload;
    },
    setMediaURL: (state, action) => {
      state.mediaURL = action.payload;
    },
    setMentorBatchOrCourseList: (state, action) => {
      state.batchOrCourseList = action.payload;
    },
    setMentorUsersList: (state, action) => {
      state.mentorUsersList = action.payload;
    },
    setConversationID: (state, action) => {
      state.ConversationID = action.payload;
    },
    storeProfilePicUrl: (state, action) => {
      state.mentorProfilePicUrl = action.payload;
    },
    storeMenoteChatInitialData: (state, action) => {
      state.storeMenoteChatInitial = action.payload;
    },
    checkUserRoleAddCondition: (state, action) => {
      state.checkUserRole = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

// fetchMentorlist
export const fetchMentors = () => async (dispatch) => {
  dispatch(setStatus(STATUSES.LOADING));

  try {
    const response = await baseUrlEdit.get(
      `/gc-chat/forward-list?per_page=20&current_page=1`
    );

    dispatch(mentorList(response?.data?.data?.list));
  } catch (error) {
    console.error("Error fetching mentors:", error);
    dispatch(setStatus(STATUSES.ERROR));
  }
};

export const fetchMentorBatchOrCrouses = (data) => async (dispatch) => {
  dispatch(setStatus(STATUSES.LOADING));

  try {
    const response = await baseUrlEdit.get(
      `mentor/allocations?per_page=10&current_page=1&search=&allocation_type=${data}`,
      {
        headers: {
          Authorization: `Bearer ${globalLocalStrogeToken()}`,
        },
      }
    );
    if (response?.status === 200) {
      dispatch(setMentorBatchOrCourseList(response?.data?.data?.list));
      dispatch(setStatus(STATUSES.IDLE));
    }
  } catch (error) {
    console.error("Error fetching mentors:", error);
    dispatch(setStatus(STATUSES.ERROR));
  }
};

//fetch mentor user list by course or batch
export const fetchMentorUsersList =
  ({ type, sub_type, value }) =>
  async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));

    try {
      const response = await baseUrlEdit.get(
        `global_search?per_page=100&current_page=1&search=&type=${
          type || "All"
        }&sub_type=${sub_type || ""}&sub_type_value=${value || ""}`
      );
      if (response?.status === 200) {
        dispatch(setMentorUsersList(response?.data?.data?.list));
        dispatch(setStatus(STATUSES.IDLE));
      }
    } catch (error) {
      console.error("Error fetching mentors:", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };

export const uploadMentorMeida = async ({
  ConversationID,
  files,
  audioChunks,
}) => {
  try {
    let formData = new FormData();
    formData.append("ConversationID", ConversationID);
    formData.append("SenderId", localStorage?.getItem("gtfconnectid"));
    audioChunks
      ? formData.append("files", audioChunks, "voice.m4a")
      : files?.forEach((file) => formData.append("files", file));
    const response = await baseUrlMain.post("uploadMentorFile", formData, {
      headers: {
        Authorization: `Bearer ${globalLocalStrogeToken()}`,
      },
    });
    if (response?.data?.status === 400) {
      toast.error(response?.data?.message)
    }
    if (response) {
      const mentorchatmediaids = response?.data?.data?.map(
        (item) => item?.individualchatmediaid
      );
      const commaSeparatedString = mentorchatmediaids?.join(",");
      return commaSeparatedString;
    }
  } catch (error) {
    console.error("Error uploading mentor media:", error);
    throw error;
  }
};

export const {
  mentorList,
  setMentorBatchOrCourseList,
  storeUserRoleMentorList,
  mentorDetails,
  chatList,
  pinMessageList,
  setTypingStatus,
  setMediaURL,
  prevMentorChats,
  setMentorUsersList,
  setConversationID,
  storeProfilePicUrl,
  storeMenoteChatInitialData,
  checkUserRoleAddCondition,
  setStatus,
} = MentorList.actions;

export default MentorList.reducer;
