import { useState } from "react";
import { useSelector } from "react-redux";

export const TagUser = () => {
  const [tagUsersComment, setTagUsersComment] = useState([]);
  const [filteredUsers, setFilterdUsers] = useState(tagUsersComment);
  const [showTagUsers, setShowTagUsers] = useState(false);
  const [tagUserImgUrl, settagUserImgUrl] = useState();
  const [commentData, setCommentData] = useState();

  const mentDetails = useSelector((state) => state?.MentorList?.details);
  
  const filterUserList = (inputValue) => {
    return tagUsersComment?.filter(
      (user) =>
        user?.Firstname?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        user?.Lastname?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const handleChangeComment = (data) => {
    const inputValue = data?.target?.value;
    setCommentData(data.target.value);
    if (!inputValue) {
      setFilterdUsers(tagUsersComment);
      setShowTagUsers(false);
    } else if (!mentDetails && inputValue?.includes("@")) {
      const searchTerm = inputValue
        ?.slice(inputValue?.lastIndexOf("@") + 1)
        ?.trim(); // Extract search term after '@' character
      const updatedList = filterUserList(searchTerm);
      setFilterdUsers(updatedList);
      setShowTagUsers(true);
    } else {
      setShowTagUsers(false);
    }
    // setCommentData(data);
    setCommentData(inputValue);
  };

  const handleUserClick = (user) => {
    setFilterdUsers(tagUsersComment);
    const userFullName = `~${user?.Firstname} ${
      user?.Lastname === null ? "" : user?.Lastname
    }~`;

    // Check if the user is already in the text
    if (commentData?.includes(userFullName)) {
      // User is already added in the text, return without making any changes
      return;
    }
    const updatedText = commentData?.replace(/@\S+\s?/g, ""); // Remove existing user tags
    let finalText = updatedText?.trim();
    if (finalText) {
      finalText += `${userFullName}`;
    } else {
      finalText = `${userFullName}`;
    }
    finalText = finalText?.replace(/@/g, ""); // Remove any remaining "@" characters

    setCommentData(finalText.trim());
    setShowTagUsers(false);
    setTimeout(() => {
      setCommentData((prevText) => prevText + " "); // Append a space at the end
      // input.current.focus();
    }, 0);
  };

  const formatUserMentions = (message) => {
    if (message === "") {
      return;
    }
    const parts = message?.split(/~(.*?)~/g); // Split the message by tildes to get parts

    const formattedParts = parts?.map((part, index) => {
      if (index % 2 === 1) {
        // Odd-indexed parts (i.e., the username parts) should be processed
        const nameParts = part?.trim()?.split(" ");
        const Lastname = nameParts?.pop();
        const Firstname = nameParts?.join(" ");
        const user = tagUsersComment?.find((u) =>
          u?.Firstname?.toLowerCase() === ""
            ? u?.Lastname?.toLowerCase()
            : (u?.Firstname?.toLowerCase() === u?.Firstname?.toLowerCase()) ===
              ""
            ? u?.Lastname?.toLowerCase()
            : u?.Firstname?.toLowerCase() &&
              (u?.Lastname ||
                u?.Lastname?.toLowerCase() === Lastname?.toLowerCase())
        );
        return user
          ? `@{${Firstname === "" ? "" : Firstname} ${Lastname}|${
              user?.UserID
            }|${user?.IsDummyUser ? 1 : 0}|${user?.DummyUserID}}`
          : `~${part}~`;
      } else {
        // Even-indexed parts (i.e., the message parts) should be returned as they are
        return part;
      }
    });

    return formattedParts?.join("");
  };
  return {
    commentData,setCommentData,
      tagUsersComment,
      setTagUsersComment,
      filteredUsers,
      setFilterdUsers,
      showTagUsers,
      setShowTagUsers,
      tagUserImgUrl,
      settagUserImgUrl,
      filterUserList,
      handleChangeComment,
      handleUserClick,
      formatUserMentions,
    };
};
