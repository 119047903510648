import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { toast } from "react-toastify";
import { globalLocalStrogeToken, tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";

const initialState = {
  GCBlock: [],
  GcBlockUserMemberID: "",
  GCBlockUserList: [],
  GCUnBlockUserList: [],
  globalSettingBlockList: [],
  globalSettingBlockListUnblockMember: [],
};
export const GCBlockUser = createSlice({
  name: "GCBlockUser",
  initialState,
  reducers: {
    GroupChannelUserBlock: (state, action) => {
      state.GCBlock = action.payload;
    },
    GroupChannelUserBlockMemberID: (state, action) => {
      state.GcBlockUserMemberID = action.payload;
    },
    GroupChannelBlockMemberList: (state, action) => {
      state.GCBlockUserList = action.payload;
    },
    GroupChannelUnBlockMemberList: (state, action) => {
      state.GCUnBlockUserList = action.payload;
    },
    globalSettingBlockListData: (state, action) => {
      state.globalSettingBlockList = action.payload;
    },
    globalBlockListUnblockMember: (state, action) => {
      state.globalSettingBlockListUnblockMember = action.payload;
    },
  },
});
export const {
  GroupChannelUserBlock,
  GroupChannelUserBlockMemberID,
  GroupChannelBlockMemberList,
  GroupChannelUnBlockMemberList,
  globalSettingBlockListData,
  globalBlockListUnblockMember,
} = GCBlockUser.actions;
export default GCBlockUser.reducer;
export const updateMemberStatus = (payload) => {
  const { GCuserBlockMemberID, getGroupChannelId, setUserBlockStatusUpdate } =
    payload;
  const formData = new URLSearchParams();
  formData.append("Status", "Blocked");
  formData.append("GCMemberID", GCuserBlockMemberID);
  formData.append("_method", "patch");
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .post(
          `group_channels/${getGroupChannelId}/update-member-status`,
          formData,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(GroupChannelUserBlock(res?.data));
            toast.success(res?.data?.message);
            setUserBlockStatusUpdate && setUserBlockStatusUpdate(true);
          } else {
            toast.error(res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};

export const GCBlockMemberList = (payload) => {
  const { getGroupChannelId } = payload;
  return async (dispatch) => {
    try {
      baseUrlEdit
        .get(`group_channels/${getGroupChannelId || localStorage.getItem("GroupChannelID")}/blocked-member-list`)
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(GroupChannelBlockMemberList(res?.data));
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};

export const updateUnblockMemberStatus = (payload) => {
  const { GCuserBlockMemberID, getGroupChannelId } = payload;
  const formData = new URLSearchParams();
  formData.append("Status", "Active");
  formData.append("GCMemberID", GCuserBlockMemberID);
  formData.append("_method", "patch");
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .post(
          `group_channels/${getGroupChannelId}/update-member-status`,
          formData,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(GCBlockMemberList({ getGroupChannelId }));
            dispatch(GroupChannelUnBlockMemberList(res?.data));
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};

export const globalSettingBlockList = (payload) => {
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(`user/blocked-users?search=${payload}`, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(globalSettingBlockListData(res?.data));
          }   else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
            }
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const globalBlockListUnblockMemberStatus = (payload) => {
  const { GCMemberID, GroupChannelID, setUserBlockStatusUpdate } = payload;
  const formData = new URLSearchParams();
  formData.append("Status", "Active");
  formData.append("GCMemberID", GCMemberID);
  formData.append("_method", "patch");
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .post(
          `group_channels/${GroupChannelID}/update-member-status`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(globalBlockListUnblockMember(res?.data));
            toast.success(res?.data?.message);

            setUserBlockStatusUpdate && setUserBlockStatusUpdate(false);

            try {
              baseUrlEdit
                .get(`user/blocked-users`, {
                  headers: {
                    Authorization: `Bearer ${globalLocalStrogeToken()}`,
                    "content-type": "application/json",
                  },
                })
                .then((res) => {
                  if (res?.data?.status === 200) {
                    dispatch(globalSettingBlockListData(res?.data));
                  }else if (res?.data?.status === 401) {
                    tokenExpireCheckStatus(false, res?.data?.message);
                    }
                });
            } catch (error) {
              console.log(error);
            }
          }   else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
            }
        });
    } catch (error) {
      console.log(error);
    }
  };
};
