import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { globalLocalStrogeToken, tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";

const initialState = {
  GetGCUserInfoMedia: [],
};
export const GCUserInfoMedia = createSlice({
  name: "GCUserInfoMedia",
  initialState,
  reducers: {
    GCUserInfoUserData: (state, action) => {
      state.GetGCUserInfoMedia = action.payload;
    },
  },
});

export const { GCUserInfoUserData } = GCUserInfoMedia.actions;
export default GCUserInfoMedia.reducer;

export const GCUserInfoMediaApi = (payload) => {
  const {GCMemberID,getGroupChannelId} =payload;
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(`group_channels/${getGroupChannelId}/user-info-media?gc_member_id=${GCMemberID}`,{
            headers:{
                Authorization : `Bearer ${globalLocalStrogeToken()}`
            }
        })
        .then((res) => {
            if(res?.data?.status===200){
                dispatch(GCUserInfoUserData(res?.data?.data))
            }  else if (res?.data?.status === 401) {
              tokenExpireCheckStatus(false, res?.data?.message);
              }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};
