import React from 'react'
import { confirmAlert } from 'react-confirm-alert'

const MaintenanceModeDailog = ({message}) => {
  const  handleMaintenanceMode = () => {
    localStorage.clear();
    window.location.href = '/login';
  }
  return (
    <>
      {confirmAlert({
        title: "Maintenance Mode",
        message,
        overlayClassName: "confirm_box_savedmessage_delete",
        buttons: [
          {
            label: "Ok",
            onClick: handleMaintenanceMode,
          }
        ],
      })}
    </>
  )
}

export default MaintenanceModeDailog
