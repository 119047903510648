import { confirmAlert } from "react-confirm-alert";
import { messaging } from "../firebase";
import { getFirebaseToken, getLocalDeviceToken, globalLocalStrogeToken } from "./GlobalLocalStorage";
import { toast } from "react-toastify";
import axios from "axios";

export const BrowserPermission = (device_info) => {
  confirmAlert({
    title: "Allow Permission",
    message: "Allow Browser Permission for Notification.",
    overlayClassName: "confirm_box_savedmessage_delete",
    buttons: [
      {
        label: "Ok",
        onClick: () => {            
            Notification?.requestPermission().then(async(permission) => {
            if (permission === "granted") {
              // get firebase token
              await messaging.getToken().then((token) => {
                localStorage.setItem("firebaseToken", token);
                const firebasetoken = getFirebaseToken();
                if( device_info?.IsDeviceTokenSaved == false && firebasetoken){
                  try {
                    const headerAPI = {
                      DeviceToken: getFirebaseToken() || getLocalDeviceToken() || null,
                      DeviceType: "Browser",
                    }
                    deviceTokenAPI(process.env.REACT_APP_BASE_URL_EDIT, headerAPI)
                      .post("user/device-token/save")
                      .then((res) => {
                        toast.success(res?.data?.message);
                        try {
                          deviceTokenAPI(process.env.REACT_APP_BASE_URL_EDIT, headerAPI).get("user/info").then((response) => {                           
                            if (response?.status === 200) {
                              localStorage.setItem('device_token_status', response?.data?.data?.device_info?.Browser?.IsDeviceTokenSaved)
                              localStorage.setItem('device_token', response?.data?.data?.device_info?.Browser?.DeviceToken)
                            }
                        })                    
                        } catch (error) {
                          console.log(error);                        
                        }         
                      })
                      .catch((error) => console.log(error));
                  } catch (error) {
                    console.log(error);
                  }
                }
              })
            }
          });                  
        },
      },
      {
        label: "Cancel",
        onClick: () => {}
      }
    ],
  });
}

const deviceTokenAPI = (baseURL, headers) => {
  const instance = axios?.create({
    baseURL,
    headers,
  });

  instance.interceptors.request.use((config) => {
    const token = globalLocalStrogeToken(); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return instance;
};