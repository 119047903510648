import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { setMentorBatchOrCourseList } from "../MentorReducer";
export const STATUSES = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
});
const InstructorList = createSlice({
    name: "instructor",
    initialState: {
        status: STATUSES.IDLE,
    },
    reducers: {
        setStatus(state, action) {
            state.status = action.payload;
        },
    }
})

export const { setStatus } = InstructorList.actions;
export default InstructorList.reducer;

export const fetchInstructorListData =
    (payload) =>
        async (dispatch) => {
            dispatch(setStatus(STATUSES.LOADING));

            try {
                const response = await baseUrlEdit.get(
                    `instructor/allocations?per_page=10&current_page=1&search=&allocation_type=${payload}`);
                if (response?.status === 200) {
                    dispatch(setMentorBatchOrCourseList(response?.data?.data?.list));
                    dispatch(setStatus(STATUSES.IDLE));
                }
            } catch (error) {
                console.error("Error fetching mentors:", error);
                dispatch(setStatus(STATUSES.ERROR));
            }
        };