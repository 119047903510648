import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
const GCTotalVideoPlayPause = ({
  media,
  baseUrlMedia,
  videoPlay,
  index,
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState();
  const handlePlayPause = () => {
    if (videoRef?.current?.paused) {
      setCurrentPlayingVideo(videoRef.current.src);
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef?.current?.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    $("#myModal").on("shown.bs.modal", function () {
      $("#video1")[0].play();
    });
    $("#myModal").on("hidden.bs.modal", function () {
      $("#video1")[0].pause();
    });
  }, []);
  return (
    <li>
      <video
        ref={videoRef}
        className="gc_video_play_pause hello"
        src={`${baseUrlMedia?.mediaUrl}${media?.StoragePath}${media?.FileName}`}
        poster={`${baseUrlMedia?.mediaUrl}${media?.StoragePath}${media?.Thumbnail}`}
        style={{ height: "100%" }}
        onClick={() => handleVideoClick(index)}
      />
      <div
        onClick={() => {
          handlePlayPause(index);
        }}
        data-toggle="modal"
        data-target="#myModal"
        className="video_play_icon"
      >
        <img src={videoPlay} alt="play" />
      </div>
      {currentPlayingVideo && (
        <div
          className="modal fade video_show_popup"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          // onClick={() => setCurrentPlayingVideo("")}

        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header pb-1 pt-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setCurrentPlayingVideo("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                <video
                  controls
                  autoPlay
                  id="video1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <source src={currentPlayingVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default GCTotalVideoPlayPause;
