import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  checkPyamentStatus,
  Description,
  FullChat,
  GCChatlistData,
  GCSettingShowModeCheckMessage,
  getGroupChannelId,
  GroupHeading,
  initialShow,
  SubscriptionCount,
  UpdatedList,
} from "../../reducer/GetChat";

import { socket } from "../../store/SocketIo";
import { allPinMessages, allPinMessagess } from "../../reducer/PinMessage";

import { student_support, user1, wowImg } from "../../Images/images";
import { getSelectedEmojiList } from "../../reducer/GCReaction";
import {
  DetailsChannelGroupApi,
  groupChannelDetailsData,
} from "../../reducer/DetailsChannelGroup";
import { compareWithToday, emmitToSocket, highlightUsernames, sortChatMessages } from "../../common";
import {
  chatList,
  checkUserRoleAddCondition,
  fetchMentorBatchOrCrouses,
  fetchMentorUsersList,
  mentorDetails,
  pinMessageList,
  setConversationID,
  setMediaURL,
  setMentorBatchOrCourseList,
  storeMenoteChatInitialData,
  storeProfilePicUrl,
  storeUserRoleMentorList,
} from "../../reducer/MentorReducer";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import SubscriptionPlanShowPopup from "./SubscriptionPlanShowPopup";
import PaymentStatus from "../../common/PaymentStatus";
import { fetchInstructorListData } from "../../reducer/Instructor";
import {
  setShowGlobalSearchProductPopup,
  setSubscriptionPlanShowPopupData,
  setShowGlobalSearchSubscriptionPopup,
} from "../../reducer/notificationReducer";
import { updateChatBoxData } from "../../reducer/CurrentChatBoxInfo";
const ChatLeft = ({
  title,
  setShow,
  chatLeftShowList,
  noGroupMessage,
  setShowCheckbox,
  setSelectedMessage,
  setPinMesssageState,
  setUpdateReactionListed,
  exclusive,
  stage,
  setPage,
  setSavedMesssageState,
}) => {
  const [groupSearch, setGroupSearch] = useState("");
  const [globalSearchList, setGlobalSearchList] = useState([]);
  const [searchListData, setSearchListData] = useState("");
  const [showExclusivePopup, setShowExclusivePopup] = useState("");
  const [showFullList, setShowFullList] = useState(false);
  const [selectedTagIndex, setSelectedTagIndex] = useState(null);
  const [type, setType] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showStudentSupportList, setShowStudentSupportList] = useState(false);
  const [showBatch, setShowBatch] = useState(false);
  // Global search state
  const [storeGlobalSearchSubscriptionPlanData, setStoreSubscriptionPlanData] =
    useState([]);
  // const [
  //   showGlobalSearchSubscriptionPopup,
  //   setShowGlobalSearchSubscriptionPopup,
  // ] = useState(false);
  const [paymentStatusShowPopup, setPaymentStatusShowPopup] = useState(false);
  const [homeSecMentorAddClass, setHomeSecMentorAddClass] = useState(false);
  // const [showGlobalSearchProductPopup, setShowGlobalSearchProductPopup] =
  //   useState(false);

  const {
    showGlobalSearchProductPopup,
    SubscriptionPlanShowPopupData,
    showGlobalSearchSubscriptionPopup,
  } = useSelector((state) => state?.notificationReducer);
  // const [SubscriptionPlanShowPopupData, setSubscriptionPlanShowPopupData] =
  //   useState({});
  const [addClassInMentorList, setaddClassInMentorList] = useState();

  const [courseItem, setCourseItem] = useState(null);
  const [setSubscriptionCheckSubscriptionPlanValue] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectid = localStorage.getItem("gtfconnectid");
  const UserID = localStorage.getItem("UserID");
  const roleID = localStorage.getItem("roleID");
  const paymentGroupChannelID = localStorage.getItem("GroupChannelID");

  const userIsTyping = useSelector((state) => state?.GetChat?.userIsTyping);
  const emptyMessage = useSelector((state) => state?.Reaction?.emptyMessage);
  const realTimeUpdateLastMessage = useSelector(
    (state) => state?.GetChat?.Chats
  );
  // Mentor sec profile pic url
  const mentorProfilePicUrl = useSelector(
    (state) => state?.MentorList?.mentorProfilePicUrl
  );
  // MentorChatList
  const mentorChats = useSelector((state) => state?.MentorList?.mentorChatList);

  // Instructor list check status
  const instructorCheckStatus = useSelector((state) => state?.Instructor);

  // Global search filter list
  const globalSearchFilterList = useSelector((state) => state?.MentorList);
  // mentor list check status
  const mentorCheckStatus = useSelector((state) => state?.MentorList);

  const user = useSelector((state) => state?.UserData);
  const lastUserMessageRecevied =
    realTimeUpdateLastMessage?.length &&
    realTimeUpdateLastMessage[realTimeUpdateLastMessage?.length - 1];
  // for home tab
  const inHome = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.inHome
  );

  const mentDetails = useSelector((state) => state?.MentorList?.details);

  // student support getting data

  const studentSupportList = useSelector(
    (state) => state?.MentorList?.mentorProfilePicUrl
  );
  const mentorBatchOrCourseList = useSelector(
    (state) => state?.MentorList?.batchOrCourseList
  );
  const mentorUserList = useSelector(
    (state) => state?.MentorList?.mentorUsersList
  );
  const { typing } = useSelector((state) => state?.MentorList);
  const channelGroupID = useSelector((state) => state?.GetChat?.channelgroupid);

  useEffect(() => {
    showExclusivePopup?.gc_subscription_plan &&
      showExclusivePopup?.gc_subscription_plan?.forEach((i) =>
        setSubscriptionCheckSubscriptionPlanValue(i?.subscription_plan)
      );
  }, [showExclusivePopup]);

  // Payament check status
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paymentStatus = urlSearchParams.get("payment_status");


  // Payment status
  useEffect(() => {
    if (paymentStatus === "success") {
      setPaymentStatusShowPopup(true);

      dispatch(checkPyamentStatus("success"));
    } else if (paymentStatus === "failure") {
      setPaymentStatusShowPopup(true);
      dispatch(checkPyamentStatus("failure"));
    }
  }, []);
  // const [GCID, setGCID] = useState(null);
  // useEffect(() => {
  //   const storedGCID = localStorage.getItem("GCID");
  //   try {
  //     const parsedGCID = storedGCID ? JSON.parse(storedGCID) : null;
  //     setGCID(parsedGCID);
  //   } catch (error) {
  //     console.error('Error parsing JSON:', error);
  //     setGCID(null);
  //   }

  // }, [GCID]);

  const GCID = JSON.parse(localStorage.getItem("GCID"));

  useEffect(() => {
    localStorage.removeItem("GCID");
  }, []);

  //for handle group and channel
  const selectedChat = (list) => {
    localStorage.setItem("GCID", list?.GroupChannelID);
    dispatch(mentorDetails(null));
    dispatch(chatList([]));
    dispatch(pinMessageList([]));
    dispatch(checkUserRoleAddCondition(false));
    dispatch(getGroupChannelId(list));
    if (
      ( list?.AccessType === "public" &&
        list?.gc_member === null &&
        list?.primary_subscription_plan?.subscription_plan?.SubscriptionType === "product" 
      ) || list?.ShowPopupInfo === 1
    ) {
      // setShowGlobalSearchProductPopup(true);
      dispatch(setShowGlobalSearchProductPopup(true));
      setShow(false);
    } else {
      dispatch(DetailsChannelGroupApi(list?.GroupChannelID)).then((res) => {
        if (
          // res?.gc_subscription_plan[0]?.subscription_plan?.PaymentTerm ===
          //   "free" ||
          res?.gc_member_info !== null ||
          paymentStatus === "success"
        ) {
          socket.emit(
            "chatList",
            {
              userId: connectid,
              GroupChannelID: res?.gc_member_info?.GroupChannelID,
              page: 1,
            },
            (response) => {
              dispatch(FullChat(response?.data?.chatData?.rows));
              dispatch(SubscriptionCount(response?.data?.subscriptionCount));
              dispatch(GCChatlistData(response?.data));
              dispatch(updateChatBoxData({
                GroupChannelID: response?.data?.chatData?.rows[0]?.GroupChannelID
              }))
            }
          );
          socket.emit(
            "userLastMessageTime",
            {
              GCMemberID: list?.GCMemberID,
              GroupChannelID: list?.GroupChannelID,
              UserID: connectid,
            },
            (response) => {
              dispatch(GCSettingShowModeCheckMessage(response?.data));
            }
          );
        // read / unread count update
        socket.emit(
          "readMessage",
          {
            GroupChatID: list?.group?.message?.[0]?.GroupChatID || list?.GroupChatID,
            GCMemberID: list?.GCMemberID || list?.GCMemberID,
            GroupChannelID: list?.GroupChannelID || list?.GroupChannelID,
            UserID: (connectid),
          },
          (res) => {
            console.log("Read message response data =>", res)
          }
        );
                if (list?.group?.Type === "group") {
                  socket.emit(
                    "auth-user",
                    { userId: UserID, filter: "group" },
                    (res) => {
                      dispatch(UpdatedList(res?.data));
                    }
                  );
                } else if (list?.group?.Type === "channel") {
                  socket.emit(
                    "auth-user",
                    { userId: UserID, filter: "channel" },
                    (res) => {
                      dispatch(UpdatedList(res?.data));
                    }
                  );
                } else if (
                  list?.ChatType === "gc" ||
                  list?.ChatType === "mentor"
                ) {
                  socket.emit("auth-user", { userId: UserID }, (res) => {
                    dispatch(UpdatedList(res?.data));
                  });
                }

          setShow(true);
          dispatch(initialShow(true));
          dispatch(
            allPinMessagess(list?.GroupChannelID || paymentGroupChannelID)
          );
          dispatch(
            getSelectedEmojiList(list?.GroupChannelID || paymentGroupChannelID)
          );
          setShowCheckbox(false);
          setSelectedMessage([]);
          setUpdateReactionListed([]);
          setPinMesssageState(false);
          setSavedMesssageState(false);
        } else {
          dispatch(setSubscriptionPlanShowPopupData(res));
          dispatch(setShowGlobalSearchSubscriptionPopup(true));
          setShow(false);
        }
      });
    }
    localStorage.setItem(
      "paymentGCType",
      list?.group?.Type || list?.groupDetail?.Type || list?.Type
    );
    localStorage.setItem(
      "GroupChannelID",
      list?.group?.GroupChannelID || list?.GroupChannelID
    );
    localStorage.setItem(
      "GroupChannelIDName",
      list?.group?.Name || list?.groupDetail?.Name || list?.Name
    );

    setStoreSubscriptionPlanData(list);
    setPaymentStatusShowPopup(false);
  };
  //initiate mentor conversaion
  const initiateMentorConversation = (details) => {
    let body = {
      UserID: localStorage?.getItem("gtfconnectid"),
      ChatRecipientID: details?.ConnectUserID || details?.UserID,
    };
    emmitToSocket("initiateMentorChat", body, (res) => {
      setaddClassInMentorList(res?.data?.UserData?.GTFUserID);
      if (res?.success) {
        fetchMentorChats(res?.data, "initiate");
        emmitToSocket(
          "auth-user",
          { userId: UserID, filter: "mentor" },
          (res) => {
            dispatch(storeProfilePicUrl(res?.data));
          }
        );
      }
    });
  };
  //fetch mentor's chat
  const fetchMentorChats = (data, type) => {
    localStorage.removeItem("GCID");
    // dispatch(storeUserRoleMentorList(data))
    if (data?.SenderId === mentDetails?.UserID || data?.ChatInitiatedByID === mentDetails?.UserID) {
      setHomeSecMentorAddClass(true);
    }
    dispatch(checkUserRoleAddCondition(true));
    // store role
    dispatch(storeMenoteChatInitialData(data));
    const { ConversationID } = data;
    let body = {
      ConversationID,
    };
    // Getting mentor list
    emmitToSocket("mentorChatList", body, (res) => {
      if (res.success) {
        dispatch(updateChatBoxData({
          ConversationID: res?.data?.dataMentor[0]?.ConversationID
        }))
        dispatch(Description(""));
        dispatch(GroupHeading(""));
        dispatch(FullChat([]));
        dispatch(SubscriptionCount(""));
        dispatch(getGroupChannelId(""));
        dispatch(GCChatlistData(""));
        dispatch(groupChannelDetailsData(""));
        dispatch(allPinMessages([]));
        emmitToSocket(
          "allPinMentorMessage",
          { ConversationID, UserID: localStorage?.getItem("gtfconnectid") },
          (res) => {
            dispatch(pinMessageList(res?.data?.data?.message));
          }
        );

        // mentor section home and mentor data dispatch and read/unread count
        if (type === "home") {
          // dispatch home recent tab mentor data
          dispatch(
            mentorDetails(
              connectid !== data?.SenderId
                ? data?.recipientData
                : data?.senderData
            )
          );

          // read/unread count home recent tab for mentor
          emmitToSocket(
            "readMentorMessage",
            {
              ConversationID: ConversationID,
              UserID: connectid,
              RoleID: roleID,
            },
            (res) => {
              if (res.success) {
                emmitToSocket("auth-user", { userId: UserID }, (res) => {
                  dispatch(UpdatedList(res?.data));
                });
              }
            }
          );
        } else {
          // dispatch mentor section mentor data
          dispatch(
            mentorDetails(
                 data?.ChatInitiatedByID == connectid
                  ? data?.ChatRecipientData
                  : data?.ChatInitiatedUserData
            )
          );

          // read/unread message for mentor section
          emmitToSocket(
            "readMentorMessage",
            {
              ConversationID: ConversationID,
              UserID: connectid,
              RoleID: roleID,
            },
            (res) => {
              if (res.success) {
                emmitToSocket(
                  "auth-user",
                  { userId: UserID, filter: "mentor" },
                  (res) => {
                    dispatch(UpdatedList(res?.data?.gcData));
                  }
                );
              }
            }
          );
        }

        // Dispatch other mentor/home data
        dispatch(setConversationID(ConversationID));
        dispatch(chatList(res?.data?.dataMentor));
        dispatch(setMediaURL(res?.data?.mediaUrl));
      }
      setShow(true);
      // setPage(1);
    });

    setPinMesssageState(false);
    setSavedMesssageState(false);
  };

  let arr = chatLeftShowList?.gcData?.map((item) =>
    parseInt(item.unreadmsgcount)
  );

  let sum = 0;
  for (let i = 0; i < arr?.length; i++) {
    sum += arr[i];
  }

  useEffect(() => {
    if (mentorUserList?.length > 0) {
      setGlobalSearchList(mentorUserList);
    }
  }, [mentorUserList]);

  // Handle Global search
  const handleSearch = (e) => {
    if (e.target.value !== "") {
      const filterMentorList = mentorUserList?.filter((item) =>
        item?.Type === "group" ||
        item?.Type === "channel" 
          ? item?.Name?.toLowerCase()?.includes(e.target.value.toLowerCase())
          : (`${item?.Firstname} ${item?.Lastname}`)?.trim()?.toLowerCase()?.includes(e.target.value.toLowerCase())
      );
      setGlobalSearchList(filterMentorList);
    } else {
      setGlobalSearchList(mentorUserList);
    }
    setSearchListData(e.target.value);
  };
  // get mentor current URL
  const currentURL = window.location.href;
  const urlParts = currentURL.split("/");
  const mentorPart = urlParts[urlParts.length - 1];
  const userDetailsRoleInfo = localStorage.getItem("user_role_slug");

  // Mentorlist sortling according to latest message
  const filterMentorListAccordingLastMsg =
    (mentorPart === "mentor" || mentorPart === "users") &&
    chatLeftShowList?.filter((item) => {
      const senderName =
        `${item?.ChatInitiatedUserData?.Firstname} ${item?.ChatInitiatedUserData?.Lastname}`.toLowerCase();
      const recipientName =
        `${item?.ChatRecipientData?.Firstname} ${item?.ChatRecipientData?.Lastname}`.toLowerCase();
      if (
        item?.message?.[0]?.SenderId === item?.ChatInitiatedUserData?.UserID
      ) {
        return recipientName.includes(groupSearch.toLowerCase());
      } else if (item?.ChatInitiatedUserData) {
        return senderName.includes(groupSearch.toLowerCase());
      }
      return false; // Handle the case when none of the conditions match
    });

  (mentorPart === "mentor" || mentorPart === "users") &&
    filterMentorListAccordingLastMsg?.sort((a, b) => {
      const aLatestMessageTime = a?.message?.[0]?.CreatedAt
        ? new Date(a?.message?.[0].CreatedAt)
        : null;
      const bLatestMessageTime = b?.message?.[0]?.CreatedAt
        ? new Date(b?.message?.[0].CreatedAt)
        : null;

      if (!aLatestMessageTime && !bLatestMessageTime) {
        return 0;
      } else if (!aLatestMessageTime) {
        return 1;
      } else if (!bLatestMessageTime) {
        return -1;
      } else {
        return bLatestMessageTime - aLatestMessageTime;
      }
    });
  // .
  // handle when mentor list no message then no show userlist
  const filteredMentorInitialChatData =
    filterMentorListAccordingLastMsg &&
    filterMentorListAccordingLastMsg?.filter(
      (item) => item?.message?.length > 0
    );
  //Group/Channel sortlist according to latest message
  let filterData = chatLeftShowList?.gcData?.filter((item) =>
    item?.group?.Name?.toLowerCase().includes(groupSearch.toLowerCase())
  );

  filterData = sortChatMessages(filterData)

  // filterData?.sort((a, b) => {
  //   const aLatestMessageTime =
  //     a.group.message.length >= 0
  //       ? new Date(a.group.message[0]?.CreatedAt)
  //       : null;
  //   const bLatestMessageTime =
  //     b.group.message.length >= 0
  //       ? new Date(b.group.message[0]?.CreatedAt)
  //       : null;
  //   // If any group has no messages, assume they have the earliest timestamp
  //   if (!aLatestMessageTime && bLatestMessageTime) return -1;
  //   if (aLatestMessageTime && !bLatestMessageTime) return 1;
  //   // Sort in descending order
  //   return bLatestMessageTime - aLatestMessageTime;
  // });

  // recent tab sorting logic here according to lastest message
  const filterAndSortChatData = () => {
    const filteredChatData = chatLeftShowList?.getRecentTab?.filter((item) => {
      if (item.ChatType === "gc") {
        return item.chatData?.CreatedAt ?? null;
      } else {
        return item.individualChat?.CreatedAt ?? null;
      }
    });
    const sortedChatData =
      filteredChatData &&
      filteredChatData.sort((a, b) => {
        const aLatestMessageTime =
          a.ChatType === "gc"
            ? new Date(a.chatData.CreatedAt)
            : new Date(a.individualChat.CreatedAt);
        const bLatestMessageTime =
          b.ChatType === "gc"
            ? new Date(b.chatData.CreatedAt)
            : new Date(b.individualChat.CreatedAt);
        return bLatestMessageTime - aLatestMessageTime;
      });

    return sortedChatData;
  };

  const combinedChatData = filterAndSortChatData();
  // Home section read/unread count
  const homeTabReadUnreadCount = combinedChatData?.reduce(
    (acc, count) => acc + count?.unreadmsgcount,
    0
  );

  // **************************** Exclusive list function *****************************
  const handleShowMore = () => {
    setShowFullList(true);
  };

  const handleShowLess = () => {
    setShowFullList(false);
  };

  const handleTagClick = (index, type, data) => {
    setSelectedTagIndex(index);
    setType(type);
    setCourseItem(data);
  };

  // handle course batch
  const handleCourseOrBatchSearch = () => {
    dispatch(
      fetchMentorUsersList({
        type: (stage === "mentor" || stage === "users") ? "Mentor" : "User",
        sub_type: type,
        value: courseItem?.CourseID || courseItem?.BatchID,
      })
    );
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#2fb463",
  };
  const overrideSearchModule = {
    display: "block",
    margin: "0 auto",
    borderColor: "#2fb463",
    top: "35%",
    position: "absolute",
    left: "40%",
  };

  function handleBatchHide() {
    setShowBatch(false);
  }

  const totalItemCount = exclusive?.exclusiveGcList?.data?.list?.length || 0;
  const visibleItemCount = showFullList ? totalItemCount : 3;

  return (
    <div
      className="tab-pane fade show active"
      style={{ display: "block" }}
      id="mentors-pane"
      role="tabpanel"
      aria-labelledby="mentors-tab"
      tabIndex={0}
    >
      <div className="user-chat-area">
        <div className="user_chatlist">
          <div className="chatlist-bar">
            <div className="chatlist-inner">
              <div className="chatlist-header">
                <div className="chatlist-title hello">
                  <h2>{title}</h2>
                  {/* For read/unread count in home tab */}
                  {inHome && homeTabReadUnreadCount > 0 && (
                    <span>{`${homeTabReadUnreadCount} Unread`}</span>
                  )}
                  {sum > 0 ? (
                    (!inHome && sum > 0) || (stage === "mentor" || stage === "users") ? (
                      emptyMessage !== true ? (
                        <span>
                          {sum ||
                            (chatLeftShowList?.length > 0 &&
                              chatLeftShowList?.reduce(
                                (acc, item) =>
                                  acc + (stage === "mentor" || stage === "users")
                                    ? item?.chatinitiatedbycount
                                    : item?.chatrecipientcount,
                                0
                              ))}
                          {` Unread`}
                        </span>
                      ) : null
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="chatlist-searchbar  ">
                  <div
                    className="group"
                    onClick={() => {
                      dispatch(fetchMentorUsersList({}));
                      setShowSearch(true);
                    }}
                  >
                    <input
                      type="button"
                      className="form-control search-modalbar"
                      value="Search..."
                    />
                  </div>
                  <div
                    className={`modal fade search-barhome-section ${
                      showSearch ? "show" : ""
                    }`}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header-topbar">
                          <h2>Search </h2>
                          <button
                            className="close close_searchhome"
                            onClick={() => setShowSearch(false)}
                          >
                            Close
                          </button>
                        </div>
                        <div className="modal-header-row">
                          <div className="modalsearch-input">
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search.."
                              value={searchListData}
                              onChange={handleSearch}
                            />
                          </div>
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                            style={{overflow:"hidden", overflowX:"auto"}}
                          >
                            <li
                              className="nav-item"
                              role="presentation"
                              onClick={handleBatchHide}
                            >
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#allgroupchannel"
                                onClick={() =>
                                  dispatch(
                                    fetchMentorUsersList({
                                      type: "All",
                                      sub_type: "",
                                      value: "",
                                    })
                                  )
                                }
                              >
                                All
                              </a>
                            </li>
                            <li
                              className="nav-item"
                              role="presentation"
                              onClick={handleBatchHide}
                            >
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#group"
                                onClick={() =>
                                  dispatch(
                                    fetchMentorUsersList({
                                      type: "GC",
                                      sub_type: "group",
                                      value: "",
                                    })
                                  )
                                }
                              >
                                Group
                              </a>
                            </li>
                            <li
                              className="nav-item"
                              role="presentation"
                              onClick={handleBatchHide}
                            >
                              <a
                                data-toggle="tab"
                                href="#channel"
                                className="nav-link"
                                onClick={() =>
                                  dispatch(
                                    fetchMentorUsersList({
                                      type: "GC",
                                      sub_type: "channel",
                                      value: "",
                                    })
                                  )
                                }
                              >
                                Channel
                              </a>
                            </li>

                            {user?.userRole?.primary?.role?.Name === "User" ||
                            user?.userRole?.primary?.role?.Name ===
                              "Super Admin" ? (
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#mentor"
                                  onClick={() =>
                                    dispatch(
                                      fetchMentorUsersList({
                                        type: "Mentor",
                                        sub_type: "",
                                        value: "",
                                      })
                                    )
                                  }
                                >
                                  Mentor
                                </a>
                              </li>
                            ) : null}

                            {user?.userRole?.primary?.role?.Name === "Mentor" ||
                            user?.userRole?.primary?.role?.Name ===
                              "Instructor" ||
                            user?.userRole?.primary?.role?.Name ===
                              "Super Admin" ? (
                              <>
                                <li
                                  className="nav-item dropdown"
                                  role="presentation"
                                >
                                  <a
                                    className="nav-link dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Students By
                                  </a>
                                  <div className="dropdown-menu">
                                    <Link
                                      className="dropdown-item"
                                      data-toggle="tab"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        user?.userRole?.primary?.role?.Name ===
                                        "Instructor"
                                          ? dispatch(
                                              fetchInstructorListData("batch")
                                            )
                                          : dispatch(
                                              fetchMentorBatchOrCrouses("batch")
                                            );
                                      }}
                                    >
                                      Batch
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      data-toggle="tab"
                                      data-bs-toggle={"modal"}
                                      data-bs-target={"#exampleModal"}
                                      onClick={() => {
                                        user?.userRole?.primary?.role?.Name ===
                                        "Instructor"
                                          ? dispatch(
                                              fetchInstructorListData("course")
                                            )
                                          : dispatch(
                                              fetchMentorBatchOrCrouses(
                                                "course"
                                              )
                                            );
                                      }}
                                    >
                                      Course
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      data-toggle="tab"
                                      onClick={() => {
                                        dispatch(
                                          fetchMentorUsersList({
                                            type: "User",
                                          })
                                        );
                                        setShowBatch(true);
                                      }}
                                    >
                                      Direct Student
                                    </Link>
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                        <div className="modal-body">
                          <div className="tab-content">
                            <div
                              id="allgroupchannel"
                              className="tab-pane fade show active"
                              style={{ display: showBatch ? "none" : "" }}
                            >
                              <div className="searchlist-group">
                                <ul>
                                  {globalSearchFilterList?.status ===
                                  "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={overrideSearchModule}
                                    />
                                  ) : globalSearchList?.length > 0 ? (
                                    globalSearchList &&
                                    globalSearchList?.map((list, index) => {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            list?.Type === "group" ||
                                            list?.Type === "channel"
                                              ? list?.GroupChannelID !==
                                                channelGroupID
                                                ? selectedChat(list)
                                                : ""
                                              : list?.IsConnectUser === false
                                              ? toast.error(
                                                  "Not a Connect User!!"
                                                )
                                              : initiateMentorConversation(list)
                                          }
                                          className={
                                            addClassInMentorList === list?.ID &&
                                            list?.GroupChannelID ===
                                              channelGroupID
                                              ? "GCsearchactive_class"
                                              : ""
                                          }
                                        >
                                          <div
                                            className="searchlist-g-blog"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  list?.Type === "group" ||
                                                  list?.Type === "channel"
                                                    ? list?.ProfileImage ===
                                                      null
                                                      ? user1
                                                      : list?.ProfileImage
                                                    : list?.ProfileThumbnail.includes(
                                                        "jpg"
                                                      )
                                                    ? list?.ProfileThumbnail
                                                    : user1
                                                }
                                                alt=""
                                              />
                                            </figure>
                                            <div className="searchlist-g-content">
                                              <strong>
                                                {(list?.Type === "group" || list?.Type === "channel") && list?.Name
                                                  ? list?.Name
                                                  : <>
                                                    {list?.Firstname ? list?.Firstname : ""}
                                                    {list?.Lastname ? " " + list?.Lastname : "" }
                                                    {!list?.Firstname && !list?.Lastname ? "No Name" : "" }
                                                  </>
                                                  }

                                              </strong>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <p className="no_result_search">
                                      No result Found!
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div
                              id="group"
                              className="tab-pane fade showuser_media"
                              style={{ display: showBatch ? "none" : "" }}
                            >
                              <div className="searchlist-group">
                                <ul>
                                  {globalSearchFilterList?.status ===
                                  "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={overrideSearchModule}
                                    />
                                  ) : globalSearchList?.length > 0 ? (
                                    globalSearchList &&
                                    globalSearchList?.map((list, index) => {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            list?.GroupChannelID !==
                                            channelGroupID
                                              ? selectedChat(list)
                                              : ""
                                          }
                                        >
                                          <div
                                            className="searchlist-g-blog"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  list?.ProfileImage === null
                                                    ? user1
                                                    : list?.ProfileImage
                                                }
                                                alt=""
                                              />
                                            </figure>
                                            <div className="searchlist-g-content">
                                              <strong>
                                                {list?.Name ? list?.Name : "No Name"}
                                              </strong>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <p className="no_result_search">
                                      No result Found!
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              id="channel"
                              className="tab-pane fade showuser_media"
                              style={{ display: showBatch ? "none" : "" }}
                            >
                              <div className="searchlist-group">
                                <ul>
                                  {globalSearchFilterList?.status ===
                                  "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={overrideSearchModule}
                                    />
                                  ) : globalSearchList?.length > 0 ? (
                                    globalSearchList &&
                                    globalSearchList?.map((list, index) => {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            list?.GroupChannelID !==
                                            channelGroupID
                                              ? selectedChat(list)
                                              : ""
                                          }
                                        >
                                          <div
                                            className="searchlist-g-blog"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  list?.ProfileImage === null
                                                    ? user1
                                                    : list?.ProfileImage
                                                }
                                                alt=""
                                              />
                                            </figure>
                                            <div className="searchlist-g-content">
                                              <strong>
                                                {list?.Name ? list?.Name : "No Name"}
                                              </strong>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <p className="no_result_search">
                                      No result Found!
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div
                              id="batch"
                              className={`tab-pane fade showuser_media ${
                                showBatch ? "active show" : ""
                              }`}
                            >
                              <div className="searchlist-group">
                                <ul>
                                  {globalSearchFilterList?.status ===
                                  "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={overrideSearchModule}
                                    />
                                  ) : globalSearchList?.length > 0 ? (
                                    globalSearchList?.map((user, index) => {
                                      return (
                                        <li key={index}>
                                          <div
                                            className={
                                              addClassInMentorList === user?.ID
                                                ? "searchlist-g-blog GCsearchactive_class"
                                                : "searchlist-g-blog"
                                            }
                                            style={{
                                              cursor: "pointer",
                                              padding: "15px 20px",
                                            }}
                                            onClick={() => {
                                              user?.ConnectUserID === null &&
                                                toast.error(
                                                  "Not a Connect User!!"
                                                );
                                              initiateMentorConversation(user);
                                            }}
                                          >
                                            <figure>
                                              <img
                                                src={
                                                  user?.ProfileImage &&
                                                  (user.ProfileImage.includes(
                                                    "jpeg"
                                                  ) ||
                                                    user.ProfileImage.includes(
                                                      "jpg"
                                                    ) ||
                                                    user.ProfileImage.includes(
                                                      "png"
                                                    ))
                                                    ? user.ProfileImage
                                                    : user1
                                                }
                                                alt=""
                                              />
                                            </figure>
                                            <div className="searchlist-g-content">
                                              <strong>
                                                {user?.Firstname ? user?.Firstname : ""}
                                                {user?.Lastname ? " " + user?.Lastname : "" }
                                                {!user?.Firstname && !user?.Lastname ? "No Name" : "" }
                                              </strong>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  ) : (
                                    <p className="no_result_search">
                                      No result Found!
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div
                              id="mentor"
                              className="tab-pane fade showuser_media"
                            >
                              <div className="searchlist-group">
                                <ul>
                                  {/* Search module mentor list */}
                                  {globalSearchFilterList?.status ===
                                  "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={overrideSearchModule}
                                    />
                                  ) : globalSearchList?.length > 0 ? (
                                    globalSearchList?.map((mentor, index) => (
                                      <li
                                        key={index}
                                        onClick={() => {
                                          mentor?.IsConnectUser === false &&
                                            toast.error("Not a Connect User!!");
                                          initiateMentorConversation(mentor);
                                        }}
                                      >
                                        <div
                                          className="searchlist-g-blog"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <figure>
                                            <img
                                              src={
                                                mentor?.ProfileImage &&
                                                (mentor.ProfileImage.includes(
                                                  "jpeg"
                                                ) ||
                                                  mentor.ProfileImage.includes(
                                                    "jpg"
                                                  ) ||
                                                  mentor.ProfileImage.includes(
                                                    "png"
                                                  ))
                                                  ? mentor.ProfileImage
                                                  : user1
                                              }
                                              alt=""
                                            />
                                          </figure>
                                          <div className="searchlist-g-content">
                                            <strong>
                                              {mentor?.Firstname ? mentor?.Firstname : ""}
                                              {mentor?.Lastname ? " " + mentor?.Lastname : "" }
                                              {!mentor?.Firstname && !mentor?.Lastname ? "No Name" : "" }
                                            </strong>
                                          </div>
                                        </div>
                                      </li>
                                    ))
                                  ) : (
                                    <p className="no_result_search">
                                      No result Found!
                                    </p>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* course batch modal */}
                        <div
                          className={`modal search-bottom-Channelmodal fade`}
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h2>Select Course/Batch</h2>
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    dispatch(setMentorBatchOrCourseList([]));
                                    setType("");
                                    // setCloseEnd(true);
                                    setCourseItem(null);
                                    setSelectedTagIndex(null);
                                  }}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="search-Channelmodal-list">
                                  {mentorBatchOrCourseList?.length > 0 ? (
                                    mentorBatchOrCourseList?.map(
                                      (batchOrCourse, index) => (
                                        <Link
                                          key={index}
                                          onClick={() =>
                                            handleTagClick(
                                              index,
                                              batchOrCourse?.CourseName
                                                ? "course"
                                                : "batch",
                                              batchOrCourse
                                            )
                                          }
                                          style={{
                                            background:
                                              selectedTagIndex === index
                                                ? "#2fb463"
                                                : "none",
                                          }}
                                        >
                                          {batchOrCourse?.CourseName ||
                                            batchOrCourse?.BatchID}
                                        </Link>
                                      )
                                    )
                                  ) : instructorCheckStatus?.status ===
                                      "loading" ||
                                    mentorCheckStatus?.status === "loading" ? (
                                    <ClipLoader
                                      color="#2fb463"
                                      cssOverride={override}
                                    />
                                  ) : (
                                    <p
                                      style={{ color: "#fff", margin: "auto" }}
                                    >
                                      No record found !
                                    </p>
                                  )}
                                </div>

                                <div className="search-channel-button">
                                  <Link
                                    data-toggle="tab"
                                    data-bs-dismiss="modal"
                                    className="btn btn-info"
                                    onClick={() => {
                                      if (type !== "" && courseItem !== null) {
                                        handleCourseOrBatchSearch();
                                        setShowBatch(true);
                                        dispatch(
                                          setMentorBatchOrCourseList([])
                                        );
                                        setType("");

                                        setCourseItem(null);
                                        setSelectedTagIndex(null);
                                      }
                                    }}
                                  >
                                    Search
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {chatLeftShowList?.gcData?.length == 0 &&
              !exclusive?.exclusiveGcList?.data?.list?.length > 0 ? (
                <div className="chatlist-body">
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {noGroupMessage}
                  </p>
                </div>
              ) : (
                // Exclusive Section Begin

                <div className="chatlist-body">
                  {inHome == true &&
                    userDetailsRoleInfo === "user" &&
                    exclusive?.exclusiveGcList?.data?.list?.length > 0 && (
                      <div
                        className={`chatlist_sec exclusive_sec ${
                          totalItemCount > 3 && "recent_tab_Sec"
                        }`}
                      >
                        <h2>Exclusive</h2>
                        <ul>
                          {exclusive?.exclusiveGcList?.data?.list
                            ?.slice(0, visibleItemCount)
                            ?.map((item, index) => {
                              return (
                                <li
                                  className={
                                    item?.GroupChannelID === channelGroupID
                                      ? "active"
                                      : ""
                                  }
                                  key={index}
                                >
                                  <div
                                    className={`user_chatlist_blog`}
                                    onClick={() =>
                                      item?.GroupChannelID !== channelGroupID
                                        ? selectedChat(item)
                                        : ""
                                    }
                                  >
                                    <figure>
                                      <img
                                        src={
                                          item?.ProfileImage === null
                                            ? user1
                                            : `${exclusive?.exclusiveGcList?.data?.gc_profile_base_url}${item?.ProfileImage}`
                                        }
                                        alt="img"
                                      />
                                    </figure>
                                    <div className="user_chatlist_content">
                                      <strong>{item?.Name}</strong>
                                      <p>
                                        {item?.MemberCount}
                                        {` subscribers`}
                                        {/* {item?.MemberCount +
                                            item?.setting?.FakeMemberCount}
                                          {` subscribers`} */}
                                      </p>
                                      {item?.TrendingLabel == null ? (
                                        ""
                                      ) : (
                                        <span className="padding_btn cursor-pointer">
                                          {item?.TrendingLabel}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          {totalItemCount > 3 && (
                            <span
                              className="exclusive_list_btn cursor-pointer"
                              onClick={
                                visibleItemCount === 3
                                  ? handleShowMore
                                  : handleShowLess
                              }
                            >
                              {visibleItemCount === 3
                                ? "Load More"
                                : "Load Less"}
                            </span>
                          )}
                          {totalItemCount > 3 && (
                            <span className="loadmore_btn-border_right"></span>
                          )}
                        </ul>
                      </div>
                    )}

                  <div className="chatlist_sec recent_sec">
                    {inHome ? (
                      <>
                        <h2>Recent</h2>
                        <ul>
                          {combinedChatData?.length > 0 ? (
                            combinedChatData?.map((item, index) => {
                              return (
                                <div key={index}>
                                  {/* Group/Channel list for recent tab*/}
                                  {item?.ChatType === "gc" && (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        item?.GroupChannelID !== GCID
                                          ? selectedChat(item)
                                          : ""
                                      }
                                      id="active"
                                      className={
                                        item?.GroupChannelID === GCID
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div
                                        className="user_chatlist_blog"
                                        data-gc_name="live_market"
                                      >
                                        <figure>
                                          <img
                                            src={
                                              item?.groupDetail
                                                ?.ProfileImage === null
                                                ? user1
                                                : `${chatLeftShowList?.gcImageBasePath}/${item?.groupDetail?.ProfileImage}`
                                            }
                                            alt="img"
                                          />
                                        </figure>
                                        <div className="user_chatlist_content">
                                          <strong>{`${item?.groupDetail?.Name}`}</strong>
                                          <p>
                                            {userIsTyping?.userId !=
                                              connectid &&
                                            userIsTyping?.GroupChannelID ==
                                              item?.GroupChannelID &&
                                            userIsTyping?.type === "start" ? (
                                              <span
                                                style={{ color: "#2fb463" }}
                                              >{`${userIsTyping?.Firstname} is Typing`}</span>
                                            ) : item?.chatData?.Message ===
                                                "" &&
                                              item?.chatData?.ChatType.trim(
                                                ""
                                              ) === "" ? (
                                              <span
                                                style={{ color: "#bb6212" }}
                                              >
                                                No Messages
                                              </span>
                                            ) : item?.chatData?.ChatType ===
                                              "file" ? (
                                              "Media Files"
                                            ) : (
                                              highlightUsernames(
                                                item?.chatData?.Message
                                              )
                                            )}
                                          </p>
                                          <div className="time_mas">
                                            {compareWithToday(item?.chatData?.CreatedAt) === "Today" 
                                            ? <span>
                                              {moment(item?.chatData?.CreatedAt)
                                                .add("hours")
                                                .format("hh:mm A")}
                                            </span>
                                            : <span>
                                              {compareWithToday(item?.chatData?.CreatedAt)}
                                            </span>}
                                            {item?.unreadmsgcount === 0 ||
                                            item?.GroupChannelID ===
                                              channelGroupID ? (
                                              ""
                                            ) : (
                                              <mark>
                                                {item?.unreadmsgcount}
                                              </mark>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                  {/* mentor list for recent tab*/}

                                  {item?.ChatType === "mentor" && (
                                    <li
                                      key={index}
                                      onClick={() => {
                                        fetchMentorChats(
                                          item?.individualChat,
                                          "home"
                                        );
                                      }}
                                      id="active"
                                      className={
                                        item?.ConversationID ===
                                        mentorChats?.[0]?.ConversationID
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div
                                        className="user_chatlist_blog"
                                        data-gc_name="live_market"
                                      >
                                        <figure>
                                          <img
                                            src={
                                              item?.individualChat?.recipientData?.ProfileImage || item?.individualChat?.senderData?.ProfileImage
                                                ? `${ chatLeftShowList?.user_profile_image_url }/${
                                                  connectid == item?.individualChat?.recipientId 
                                                    ? item?.individualChat?.senderData?.ProfileImage
                                                    : item?.individualChat?.recipientData?.ProfileImage
                                                  }`
                                                : user1
                                            }
                                            alt="img"
                                          />
                                        </figure>

                                        <div className="user_chatlist_content">
                                          <strong>
                                            { connectid == item?.individualChat?.SenderId && `${ item?.individualChat?.recipientData?.Firstname} ${ item?.individualChat?.recipientData?.Lastname === null ? "" : item?.individualChat?.recipientData?.Lastname}` }
                                            { connectid == item?.individualChat?.recipientId && `${ item?.individualChat?.senderData?.Firstname} ${ item?.individualChat?.senderData?.Lastname === null ? "" : item?.individualChat?.senderData?.Lastname}`} 
                                          </strong>
                                          <p>
                                            {typing?.userId ===
                                              item?.individualChat?.SenderId ||
                                            typing?.userId ===
                                              item?.individualChat?.senderData
                                                ?.UserID ? (
                                              <span
                                                style={{ color: "#2fb463" }}
                                              >{`${typing?.Firstname} is Typing`}</span>
                                            ) : item?.individualChat
                                                ?.Content === "" &&
                                              item?.individualChat?.ChatType ===
                                                "" ? (
                                              <span
                                                style={{ color: "#bb6212" }}
                                              >
                                                No Messages
                                              </span>
                                            ) : item?.individualChat
                                                ?.ChatType === "file" ? (
                                              "Media Files"
                                            ) : (
                                              highlightUsernames(
                                                item?.individualChat?.Content
                                              )
                                            )}
                                          </p>
                                          <div className="time_mas">
                                            {compareWithToday(item?.individualChat?.CreatedAt) === "Today" 
                                              ? <span>
                                                {moment(item?.individualChat?.CreatedAt)
                                                  .add("hours")
                                                  .format("hh:mm A")}
                                              </span>
                                              : <span>
                                                {compareWithToday(item?.individualChat?.CreatedAt)}
                                              </span>
                                            }

                                            {item?.unreadmsgcount == 0 ||
                                            item?.GroupChannelID ===
                                              channelGroupID ? (
                                              ""
                                            ) : (
                                              <mark>
                                                {item?.unreadmsgcount}
                                              </mark>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <p className="no_result_search">No result Found!</p>
                          )}
                        </ul>
                      </>
                    ) : (
                      <ul>
                        {filterData?.length > 0 ? (
                          filterData?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() =>
                                  item?.GroupChannelID !== channelGroupID
                                    ? selectedChat(item)
                                    : ""
                                }
                                id="active"
                                className={
                                  item?.GroupChannelID === channelGroupID
                                    ? "active"
                                    : ""
                                }
                              >
                                <div
                                  className="user_chatlist_blog"
                                  data-gc_name="live_market"
                                >
                                  <figure>
                                    <img
                                      src={
                                        item?.group?.ProfileImage === null
                                          ? user1
                                          : `${chatLeftShowList?.gcImageBasePath}/${item?.group?.ProfileImage}`
                                      }
                                      alt="img"
                                    />
                                  </figure>
                                  <div className="user_chatlist_content">
                                    <strong>{item?.group?.Name}</strong>
                                    <p>
                                      {userIsTyping?.userId != connectid &&
                                      userIsTyping?.GroupChannelID ==
                                        item?.GroupChannelID &&
                                      userIsTyping?.type === "start" ? (
                                        <span
                                          style={{ color: "#2fb463" }}
                                        >{`${userIsTyping?.Firstname} is Typing`}</span>
                                      ) : item?.group?.message?.length === 0 ? (
                                        <span style={{ color: "#bb6212" }}>
                                          No Messages
                                        </span>
                                      ) : item?.group?.message?.[0]?.Message ===
                                        "" ? (
                                        "Media Files"
                                      ) : (
                                        highlightUsernames(
                                          item?.group?.message?.[0]?.Message
                                        )
                                      )}
                                    </p>
                                    <div className="time_mas">
                                      <span>
                                        {item?.group?.message?.length == 0 ? (
                                          ""
                                        ) : <>
                                          {compareWithToday(item?.GroupChannelID == lastUserMessageRecevied?.GroupChannelID
                                                ? lastUserMessageRecevied?.CreatedAt
                                                : item?.group?.message?.[0]?.CreatedAt) === "Today"
                                          ?<span>
                                            {moment(
                                              item?.GroupChannelID == lastUserMessageRecevied?.GroupChannelID
                                                ? lastUserMessageRecevied?.CreatedAt
                                                : item?.group?.message?.[0]?.CreatedAt
                                            )
                                              .add("hours")
                                              .format("hh:mm A")}
                                          </span>
                                          :<span>
                                            {compareWithToday(item?.GroupChannelID == lastUserMessageRecevied?.GroupChannelID
                                                ? lastUserMessageRecevied?.CreatedAt
                                                : item?.group?.message?.[0]?.CreatedAt)}
                                          </span>
                                        }
                                        </>}
                                      </span>
                                      {item?.unreadmsgcount == 0 ||
                                      item?.GroupChannelID ===
                                        channelGroupID ? (
                                        ""
                                      ) : (
                                        <mark>{item?.unreadmsgcount}</mark>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <p className="no_result_search">No result Found!</p>
                        )}
                      </ul>
                    )}
                  </div>

                  {/* mentor | student section begins here */}
                  {(stage === "mentor" || stage === "users") && (
                    <div className="chatlist_sec recent_sec">
                      {stage === "mentor" && <h2>Mentor</h2>}
                      <div className="mentor_tab_in_sec">
                        <ul className="mentor_sec_student_support">
                          {filteredMentorInitialChatData?.length
                            ? filteredMentorInitialChatData?.map(
                                (mentor, index) => (
                                  <li
                                    className={
                                      mentor?.ConversationID ===
                                      mentorChats?.[0]?.ConversationID
                                        ? "active"
                                        : ""
                                    }
                                    id="active"
                                    key={index}
                                    onClick={() => fetchMentorChats(mentor)}
                                  >
                                    <div
                                      className="user_chatlist_blog"
                                      data-gc_name="live_market"
                                    >
                                      <figure>
                                        <img
                                          src={ mentor?.ChatRecipientData?.ProfileImage === null || mentor?.ChatInitiatedUserData?.ProfileImage === null
                                            ? user1
                                            : `${ mentorProfilePicUrl?.user_profile_image_url }/${connectid == mentor?.ChatInitiatedByID
                                            ? `${mentor?.ChatRecipientData?.ProfileImage}`
                                            : `${mentor?.ChatInitiatedUserData?.ProfileImage}`
                                          }`}
                                          alt="img"
                                        />
                                      </figure>
                                      <div className="user_chatlist_content">
                                        {/* <strong> */}
                                        {/* {stage === "student"
                                        ? `${
                                            mentor?.ChatInitiatedUserData
                                              ?.Firstname
                                          } ${
                                            mentor?.ChatInitiatedUserData
                                              ?.Lastname === null
                                              ? ""
                                              : mentor?.ChatInitiatedUserData
                                                  ?.Lastname
                                          }`
                                        : `${
                                            mentor?.ChatRecipientData?.Firstname
                                          } ${
                                            mentor?.ChatRecipientData
                                              ?.Lastname === null
                                              ? ""
                                              : mentor?.ChatRecipientData
                                                  ?.Lastname
                                          }`}
                                    </strong> */}
                                        <strong>
                                          {connectid ==
                                          mentor?.ChatInitiatedByID
                                            ? `${
                                                mentor?.ChatRecipientData
                                                  ?.Firstname
                                              } ${
                                                mentor?.ChatRecipientData
                                                  ?.Lastname === null
                                                  ? ""
                                                  : mentor?.ChatRecipientData
                                                      ?.Lastname
                                              }`
                                            : `${
                                                mentor?.ChatInitiatedUserData
                                                  ?.Firstname
                                              } ${
                                                mentor?.ChatInitiatedUserData
                                                  ?.Lastname === null
                                                  ? ""
                                                  : mentor
                                                      ?.ChatInitiatedUserData
                                                      ?.Lastname
                                              }`}
                                        </strong>

                                        <p>
                                          {(typing &&
                                            typing?.userId ===
                                              mentor?.ChatInitiatedUserData
                                                ?.UserID) ||
                                          typing?.userId ===
                                            mentor?.ChatRecipientData?.UserID
                                            ? `${typing?.Firstname} is typing`
                                            : mentor?.message[0]?.ChatType ===
                                              "file"
                                            ? "Media"
                                            : highlightUsernames(
                                                mentor?.message[0]?.Content
                                              )}
                                        </p>
                                        <div className="time_mas">
                                          {compareWithToday(mentor?.message[0]?.CreatedAt) === "Today"
                                            ? <span>
                                            {moment(mentor?.message[0]?.CreatedAt)
                                              .add("hours")
                                              .format("hh:mm A")}
                                            </span>
                                            : <span>
                                              {compareWithToday(mentor?.message[0]?.CreatedAt)}
                                            </span>
                                          }
                                          {mentor?.chatinitiatedbycount == 0 && mentor?.chatrecipientcount == 0
                                        ? ("") 
                                        : (
                                            <>
                                              {connectid == mentor?.ChatInitiatedByID && mentor?.chatinitiatedbycount > 0
                                                ? <mark>{mentor?.chatinitiatedbycount}</mark>
                                                : connectid == mentor?.ChatRecipientID && mentor?.chatrecipientcount > 0
                                                ? <mark>{mentor?.chatrecipientcount}</mark>
                                                : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )
                            : (stage === "mentor" || stage === "users") && (
                                <p
                                  style={{
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                >
                                  No Records Found!!
                                </p>
                              )}
                        </ul>
                      </div>
                    </div>
                  )}
                  {/* Student support section start */}
                  {(stage === "mentor" || stage === "users") && (
                    <div
                      className="student_support_section"
                      onClick={() =>
                        mentorProfilePicUrl?.supportUserData?.length > 1
                          ? setShowStudentSupportList(true)
                          : initiateMentorConversation(
                              mentorProfilePicUrl?.supportUserData?.[0]
                            )
                      }
                    >
                      <p>
                        <img src={student_support} alt="student_support" /> Need
                        Help? Contact Student Support.
                      </p>
                    </div>
                  )}

                  {/* *********** */}
                  <div
                    className={`modal fade search-barhome-section ${
                      showStudentSupportList ? "show" : ""
                    }`}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header-topbar">
                          <h2>Student Support </h2>
                          <button
                            className="close close_searchhome"
                            onClick={() => setShowStudentSupportList(false)}
                          >
                            Close
                          </button>
                        </div>

                        <div className="chatlist_sec recent_sec">
                          <div className="mentor_tab_in_sec">
                            <ul>
                              {mentorProfilePicUrl?.supportUserData &&
                                mentorProfilePicUrl?.supportUserData?.map(
                                  (mentor, index) => (
                                    <li
                                      id="active"
                                      key={index}
                                      onClick={() => {
                                        initiateMentorConversation(mentor);
                                      }}
                                    >
                                      <div
                                        className="user_chatlist_blog"
                                        data-gc_name="live_market"
                                      >
                                        <figure>
                                          <img
                                            src={`  ${mentorProfilePicUrl?.user_profile_image_url}${mentor?.ProfileImage}`}
                                            alt="img"
                                          />
                                        </figure>
                                        <div className="user_chatlist_content">
                                          <strong>
                                            {` ${mentor?.Firstname} ${mentor?.Lastname}`}
                                          </strong>
                                          {/*                                           
                                        <p>
                                          {(typing &&
                                            typing?.userId ===
                                              mentor?.ChatInitiatedUserData
                                                ?.UserID) ||
                                          typing?.userId ===
                                            mentor?.ChatRecipientData?.UserID
                                            ? `${typing?.Firstname} is typing`
                                            : mentor?.message[0]?.ChatType ===
                                              "file"
                                            ? "Media"
                                            : highlightUsernames(
                                                mentor?.message[0]?.Content
                                              )}
                                        </p> */}
                                          <div className="time_mas">
                                            {compareWithToday(mentor?.CreatedAt) === "Today"
                                              ? <span>
                                              {moment(mentor?.CreatedAt)
                                                .add("hours")
                                                .format("hh:mm A")}
                                              </span>
                                              : <span>
                                              {compareWithToday(mentor?.CreatedAt)}
                                              </span>
                                            }
                                            {/* {mentor?.chatinitiatedbycount === 0 ||
                                          mentor?.chatrecipientcount === 0 ? (
                                            ""
                                          ) : (
                                            <mark>
                                              {stage === "mentor"
                                                ? mentor?.chatrecipientcount
                                                : mentor?.chatinitiatedbycount}
                                            </mark>
                                          )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //. Student support section start */}
                </div>
              )}

              {/* Chat body Ends */}
            </div>
          </div>
        </div>
      </div>
      {/* Subscription plan show popup */}
      <Modal
        open={showGlobalSearchSubscriptionPopup}
        onClose={() => dispatch(setShowGlobalSearchSubscriptionPopup(false))}
      >
        <SubscriptionPlanShowPopup
          SubscriptionPlanShowPopupData={SubscriptionPlanShowPopupData}
          setShow={setShow}
        />
      </Modal>
      {/* Subscription plan show image popup */}
      <Modal
        open={showGlobalSearchProductPopup}
        // onClose={() => setShowGlobalSearchProductPopup(false)}
        onClose={() => dispatch(setShowGlobalSearchProductPopup(false))}
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{ background: "unset", top: "50px", border: "unset" }}
          >
            <div className="modal-body">
              <Link
                to={`${
                  storeGlobalSearchSubscriptionPlanData?.PopupRedirectUrl ===
                  null
                    ? ""
                    : storeGlobalSearchSubscriptionPlanData?.PopupRedirectUrl
                }`}
              >
                <img
                  src={
                    // Check if PopupInfoImage is null
                    storeGlobalSearchSubscriptionPlanData?.PopupInfoImage ===
                    null
                      ? wowImg
                      : // Check if PopupInfoImage is an absolute URL
                      storeGlobalSearchSubscriptionPlanData?.PopupInfoImage?.startsWith(
                          "http://"
                        ) ||
                        storeGlobalSearchSubscriptionPlanData?.PopupInfoImage?.startsWith(
                          "https://"
                        )
                      ? storeGlobalSearchSubscriptionPlanData?.PopupInfoImage
                      : // Handle relative URL by appending the base URL
                        `${exclusive?.exclusiveGcList?.data?.popup_img_base_url}${storeGlobalSearchSubscriptionPlanData?.PopupInfoImage}`
                  }
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </Modal>

      {/*  payment status show popup */}
      <Modal
        open={paymentStatusShowPopup}
        onClose={() => setPaymentStatusShowPopup(true)}
      >
        {/* <div className="modal-dialog payment_popup">
          <div className="modal-content">
            <div className="modal-body"> */}
              <PaymentStatus
                setShowCheckbox={setShowCheckbox}
                setSelectedMessage={setSelectedMessage}
                setUpdateReactionListed={setUpdateReactionListed}
                setPinMesssageState={setPinMesssageState}
                setSavedMesssageState={setSavedMesssageState}
                // setSubscriptionPlanShowPopupData={
                //   setSubscriptionPlanShowPopupData
                // }
                // setShowGlobalSearchSubscriptionPopup={
                //   setShowGlobalSearchSubscriptionPopup
                // }
                setShow={setShow}
                setPaymentStatusShowPopup={setPaymentStatusShowPopup}
              />
            {/* </div>
          </div>
        </div> */}
      </Modal>
    </div>
  );
};

export default React.memo(ChatLeft);
