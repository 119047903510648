import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../../store/axios";
import { DetailsChannelGroupApi } from "../DetailsChannelGroup";
import {
  globalLocalStrogeToken,
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";
import { socket } from "../../store/SocketIo";
const initialState = {
  GroupChannelSetting: "",
  GCDeleteData: "",
};

export const GcSetting = createSlice({
  name: "GcSetting",
  initialState,

  reducers: {
    updateGcpermission: (state, action) => {
      state.GroupChannelSetting = action.payload;
    },
    GCDeletePermisson: (state, action) => {
      state.GCDeleteData = action.payload;
    },
  },
});
export const { updateGcpermission, GCDeletePermisson } = GcSetting.actions;
export default GcSetting.reducer;
export const updateGcSetting = (payload) => {
  return async (dispatch) => {
    const form = new URLSearchParams();
    form.append("IsNotification", 1);
    form.append("SignedMsg", payload?.channelSignedMsg);
    form.append("EnableReactions", payload?.channelReaction);
    form.append("RestrictSharingContent", payload?.channelSharingContent);
    form.append("AllowDiscussion", payload?.channelDisscussion);
    form.append("EnableManipulateViews", payload?.channelEnableManipulateViews);
    form.append("ChatHistoryIsEnable", payload?.chatHistoryStatus);
    try {
      const url = `${process.env.REACT_APP_BASE_URL_EDIT}group_channels/${payload?.channelGroupID}/settings/update`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${globalLocalStrogeToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: form,
      });
      const updateSetting = await response.json();
      if (updateSetting?.status === 200) {
        dispatch(DetailsChannelGroupApi(payload?.channelGroupID));
        socket.emit("updateSetting", {
          GroupChannelID: payload?.channelGroupID,
        });
        toast.success(updateSetting?.message);
      } else if (updateSetting?.status === 401) {
        tokenExpireCheckStatus(false, updateSetting?.message);
      }
    } catch (error) {
      console.log("update setting error :", error);
    }
  };
};

export const GCDelete = (payload) => {
  const { channelGroupID, setUpdatedGCListAfterDeleteGC } = payload;
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .delete(`group_channels/${channelGroupID}/delete`)
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(GCDeletePermisson(response?.data));
            socket.emit("updateSetting", {
              GroupChannelID: channelGroupID,
            });
            toast.success(response?.data?.message);
            setUpdatedGCListAfterDeleteGC(true);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
};
