import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  app_store,
  google_play,
  loginleftbox_image_1,
  loginleftbox_image_2,
  loginleftbox_image_3,
  loginleftbox_image_4,
  loginleftbox_image_5,
  loginleftbox_image_6,
  paymentLogo,
  vector_icon5,
} from "../../Images/images";
import { useDispatch } from "react-redux";
import { fetchForgotPassword } from "../../reducer/userAuth/forgotPassword";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Protect Routes
  const loginGetData = localStorage.getItem("token");
  useEffect(() => {
    loginGetData && navigate("/");
  }, [loginGetData]);

  // Handle change password
  const ResetLink = (values) => {
    dispatch(
      fetchForgotPassword({ email: values?.email, navigate, setLoading })
    );
  };

  // Validation
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });

  return (
    <>
      <section className="login register">
        <div className="container">
          <div className="row loginrow">
            <div className="col-md-6 loginleft">
              <div className="loginleftbox">
                <div className="loginleftbox_image">
                  <div className="loginleftbox_image_1">
                    <img
                      src={loginleftbox_image_1}
                      alt="loginleftbox_image_1"
                    />
                  </div>
                  <div className="loginleftbox_image_2">
                    <img
                      src={loginleftbox_image_2}
                      alt="loginleftbox_image_2"
                    />
                  </div>
                  <div className="loginleftbox_image_3">
                    <img
                      src={loginleftbox_image_3}
                      alt="loginleftbox_image_3"
                    />
                  </div>
                  <div className="loginleftbox_image_4">
                    <img
                      src={loginleftbox_image_4}
                      alt="loginleftbox_image_4"
                    />
                  </div>
                  <div className="loginleftbox_image_5">
                    <img
                      src={loginleftbox_image_5}
                      alt="loginleftbox_image_5"
                    />
                  </div>
                  <div className="loginleftbox_image_6">
                    <img
                      src={loginleftbox_image_6}
                      alt="loginleftbox_image_6"
                    />
                  </div>
                </div>
                <div className="loginleftbox_app d-flex justify-content-center flex-wrap align-items-center">
                  <Link to="#">
                    <img src={app_store} alt="app-store" />
                  </Link>
                  <Link to="#">
                    <img src={google_play} alt="google-play" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 loginright">
              <div className="loginrightbox">
                <div className="loginrightbox_logo">
                  <Link to="#">
                    <img src={paymentLogo} alt="img" />
                  </Link>
                </div>
                <div className="loginrightbox_form">
                  <h3>Forgot Password</h3>

                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      // same shape as initial values
                      ResetLink(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-input">
                          <Field
                            type="email"
                            className="form-control"
                            placeholder="Enter Registered Email"
                            name="email"
                          />
                          <span className="form-input-icon">
                            <img src={vector_icon5} alt="vector_icon5" />
                          </span>
                          {errors.email && touched.email ? (
                            <div className="text-danger d-flex mt-2">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-submit">
                          <LoadingButton
                            loading={loading}
                            type="submit"
                            className="btn btn-login"
                          >
                            Send Reset Link
                          </LoadingButton>
                        </div>
                        <div className="back-to-login">
                          Back To <Link to="/login">Login</Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
