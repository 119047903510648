import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
  FULLFIll: "idle",
});
const initialUserState = {
  userRegisterData: "",
  findUSData: "",
  selectCountry: [],
  userSelectState: [],
  userSelectCity: [],
  status: STATUSES.IDLE,
};

const userRegister = createSlice({
  name: "userRegister",
  initialState: initialUserState,

  reducers: {
    storeUserRegisterData(state, action) {
      state.userRegisterData = action.payload;
    },
    storeFindUsData(state, action) {
      state.findUSData = action.payload;
    },
    SelectCountryData(state, action) {
      state.selectCountry = action.payload;
    },
    fetchSelectState(state, action) {
      state.userSelectState = action.payload;
    },
    fetchSelectCity(state, action) {
      state.userSelectCity = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const {
  storeUserRegisterData,
  storeFindUsData,
  SelectCountryData,
  fetchSelectState,
  fetchSelectCity,
  setStatus,
} = userRegister.actions;
export default userRegister.reducer;

// Find Us when user registraiton
export const fetchFindUSData = () => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      baseUrlAuth.get("find_us").then((res) => {
        if (res?.data?.status === 200) {
          dispatch(storeFindUsData(res?.data?.data));
          dispatch(setStatus(STATUSES.IDLE));
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (error) {
      console.log("User Registration Find US Error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// user registration
export const handleUserRegistration = (payload) => {
  const {
    FirstName,
    LastName,
    Email,
    Password,
    PhoneCode,
    Phone,
    CountryID,
    StateID,
    CityID,
    findUs,
    navigate,
    setLoading,
  } = payload;
  const formData = new FormData();
  formData.append("FirstName", FirstName);
  formData.append("LastName", LastName);
  formData.append("Email", Email);
  formData.append("Password", Password);
  formData.append("PhoneCode", PhoneCode);
  formData.append("Phone", Phone);
  formData.append("CountryID", CountryID);
  formData.append("StateID", StateID);
  formData.append("CityID", CityID);
  formData.append("find_us", findUs);
  formData.append("IsReferral", 1);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      setLoading(true);
      baseUrlAuth.post("registration", formData).then((res) => {
        if (res?.data?.status === 200) {
          navigate(`/otpverification`, { replace: true });
          toast.success(res?.data?.message);
          localStorage.setItem("email", res?.data?.data?.email);
          dispatch(setStatus(STATUSES.IDLE));
          setLoading(false);
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (error) {
      console.log("User Registration Error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// select Country second step verification
export const fetchCountryData = (payload) => {
  const { setCountryDataStatus } = payload;
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlAuth.get("get_country").then((res) => {
        if (res?.data?.status === 200) {
          dispatch(SelectCountryData(res?.data));
          setCountryDataStatus(true);
        } 
      });
      dispatch(setStatus(STATUSES.FULLFIll));
    } catch (error) {
      console.log("error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// Select State second step verification
export const fetchSelectStates = (payload) => {
  const { CountryID } = payload;
  const formData = new FormData();
  formData.append("CountryID", CountryID);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlAuth.post("get_state", formData).then((res) => {
        if (res?.data?.status === 200) {
          dispatch(fetchSelectState(res?.data));
        } else {
          toast.error(res?.data?.message);
        }
      });
      dispatch(setStatus(STATUSES.IDLE));
    } catch (error) {
      console.log("error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// Select City second step verification
export const fetchSelectCitys = (payload) => {
  const formData = new FormData();
  formData.append("StateID", payload);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlAuth.post("get_city", formData).then((res) => {
        if (res?.data?.status === 200) {
          dispatch(fetchSelectCity(res?.data));
        } else {
          toast.error(res?.data?.error);
        }
      });
      dispatch(setStatus(STATUSES.IDLE));
    } catch (error) {
      console.log("error :", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
