import React, { memo } from "react";
import { useEffect } from "react";
import "./payment.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DetailsChannelGroupApi } from "../reducer/DetailsChannelGroup";
import {
  FullChat,
  GCChatlistData,
  GCSettingShowModeCheckMessage,
  SubscriptionCount,
  UpdatedList,
  getGroupChannelId,
  initialShow,
} from "../reducer/GetChat";
import { allPinMessagess } from "../reducer/PinMessage";
import { getSelectedEmojiList } from "../reducer/GCReaction";
import { socket } from "../store/SocketIo";
import {
  chatList,
  mentorDetails,
  pinMessageList,
} from "../reducer/MentorReducer";
import { getExclusiveGc } from "../reducer/ExcluciveGcReducer";
import {
  setSubscriptionPlanShowPopupData,
  setShowGlobalSearchSubscriptionPopup,
} from "../reducer/notificationReducer";

const PaymentStatus = ({
  setShow,
  setShowCheckbox,
  setSelectedMessage,
  setUpdateReactionListed,
  setPinMesssageState,
  setSavedMesssageState,
  setPaymentStatusShowPopup,
}) => {
  const checkPaymentStatus = useSelector(
    (state) => state?.GetChat?.paymentStatus
  );

  const dispatch = useDispatch();
  // Get userID

  const connectid = localStorage.getItem("gtfconnectid");
  const UserID = localStorage.getItem("UserID");
  const GCType = localStorage.getItem("paymentGCType");
  const GroupChannelIDName = localStorage.getItem("GroupChannelIDName");
  



  const handleStartChat = () => {

    dispatch(mentorDetails(null));
    dispatch(chatList([]));
    dispatch(pinMessageList([]));
    dispatch(getExclusiveGc());
    socket.emit("auth-user", { userId: UserID });
    dispatch(
      DetailsChannelGroupApi(localStorage.getItem("GroupChannelID"))
    ).then((res) => {
      if (checkPaymentStatus === "success") {
        socket.emit(
          "chatList",
          {
            GCMemberID: res?.gc_member_info?.GCMemberID,
            userId: connectid,
            GroupChannelID: res?.gc_member_info?.GroupChannelID,
            page: 1,
          },
          (response) => {
            dispatch(FullChat(response?.data?.chatData?.rows));
            dispatch(SubscriptionCount(response?.data?.subscriptionCount));
            dispatch(GCChatlistData(response?.data));
            dispatch(getGroupChannelId(res?.gc_member_info?.GroupChannelID));
          }
        );
        socket.emit(
          "userLastMessageTime",
          {
            GCMemberID: res?.gc_member_info?.GroupChannelID?.GCMemberID,
            GroupChannelID: res?.gc_member_info?.GroupChannelID,
            UserID: connectid,
          },
          (response) => {
            dispatch(GCSettingShowModeCheckMessage(response?.data));
          }
        );
        socket.emit(
          "readMessage",
          {
            // GroupChatID: list?.group?.message?.[0]?.GroupChatID,
            GCMemberID: res?.gc_member_info?.GroupChannelID?.GCMemberID,
            GroupChannelID: res?.gc_member_info?.GroupChannelID,
            UserID: connectid,
          },
          (res) => {
            if (res?.success) {
              if (GCType === "group") {
                socket.emit(
                  "auth-user",
                  { userId: UserID, filter: "group" },
                  (res) => {
                    dispatch(UpdatedList(res?.data));
                  }
                );
              } else if (GCType === "channel") {
                socket.emit(
                  "auth-user",
                  { userId: UserID, filter: "channel" },
                  (res) => {
                    dispatch(UpdatedList(res?.data));
                  }
                );
              } else {
                socket.emit("auth-user", { userId: UserID }, (res) => {
                  dispatch(UpdatedList(res?.data));
                });
              }
            }
          }
        );
        setShow(true);
        dispatch(initialShow(true));
        dispatch(allPinMessagess(res?.gc_member_info?.GroupChannelID));

        dispatch(getSelectedEmojiList(res?.gc_member_info?.GroupChannelID));
        setShowCheckbox(false);
        setSelectedMessage([]);
        setUpdateReactionListed([]);
        setPinMesssageState(false);
        setSavedMesssageState(false);
      } else {
        dispatch(setSubscriptionPlanShowPopupData(res));
        dispatch(setShowGlobalSearchSubscriptionPopup(true));
        setShow(false);
      }
    });
    setPaymentStatusShowPopup(false);
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const erroMsg = urlSearchParams.get("error_msg");
  const isPending = urlSearchParams.get("status");

  return checkPaymentStatus === "success" ? (
    <div className="successfully_modal layout-popup">
      <div className="modal-layout">
        <div className="layout-content">
          <div className="latout-body">
            <div className="layout-logo">
              <figure>
                <img src="/gtf-logo.svg" alt="gtf-logo" />
              </figure>
            </div>
            <div className="layout-text">
              <h2>{GCType.slice(0,1).toUpperCase() + GCType.slice(1, GCType.length)} Purchased Successfully!</h2>
              <p>
                Thank you for purchasing our <span>“{GroupChannelIDName.slice(0,1).toUpperCase() + GroupChannelIDName.slice(1, GroupChannelIDName.length)}”</span>{" "}
                channel! We truly appreciate your support and trust in our
                services. If you need any assistance, feel free to reach out.
                Enjoy your new channel!
              </p>
              <Link
                to="/"
                role="button"
                onClick={handleStartChat}
                className="payment-button"
              >
                Let’s Start Learning
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    // payment faild
    <div className="successfully_modal layout-popup">
      <div className="modal-layout">
        <div className="layout-content">
          <div className="latout-body">
            <div className="layout-logo">
              <figure>
                <img src="/payment-failed.svg" alt="gtf-logo" />
              </figure>
            </div>
            <div className="layout-text">
              <h2>
                Oops! Payment {isPending === "pending" ? "Pending" : "Failed"}
              </h2>
              <p>
                {erroMsg
                  ? erroMsg
                  : "The Payment was unsuccessful due to an abnormal. Please try again later or another payment method."}
              </p>
              <Link
                to="/"
                role="button"
                onClick={() => setPaymentStatusShowPopup(false)}
                className="payment-button"
              >
                Try Again
              </Link>
              <div className="need-text">
                <Link
                  onClick={() => setPaymentStatusShowPopup(false)}
                  role="button"
                  href="#"
                >
                  Need Help?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PaymentStatus);
