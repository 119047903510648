import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";

const initialState = {
  forgotPasswordDataList: "",
};
export const forgotPassword = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    forgotPasswordData(state, action) {
      state.forgotPasswordDataList = action.payload;
    },
  },
});
export const { forgotPasswordData } = forgotPassword.actions;
export default forgotPassword.reducer;

export const fetchForgotPassword = (payload) => {
  const { email, navigate, setLoading } = payload;
  const formData = new FormData();
  formData.append("Email", email);
  return async () => {
    setLoading(true);
    baseUrlAuth.post("changepassword", formData).then((res) => {
      if (res?.data?.status === 200) {
        navigate("/login");
        toast.success(res?.data?.message);
        setLoading(false);
      } else {
        toast.error(res?.data?.message);
      }
    });
    try {
    } catch (error) {
      console.log("forgot password error :-", error);
    }
  };
};
