import React from "react";
import GCChatlistAudioPlayPause from "../AudioPlayPause/GCChatlistAudioPlayPause";
import { msWorldZip, zipShowChatlist } from "../../Images/images";

const MediaFileComponent = ({ media, baseUrlMedia, stage }) => {
  if (media?.MimeType?.startsWith("audio")) {
    return (
      <>
        <GCChatlistAudioPlayPause
          media={media}
          baseUrlMedia={baseUrlMedia}
          stage={stage}
        />
        {/* Your audio preview code here */}
      </>
    );
  } else if (media?.MimeType?.startsWith("application/msword")) {
    const onButtonClick = () => {
      fetch(
        `${
          stage === "save" || stage === "mentor"
            ? baseUrlMedia
            : baseUrlMedia?.mediaUrl
        }${media?.StoragePath}${media?.FileName}`
      ).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = media?.FileName;
          alink.click();
        });
      });
    };
    return (
      <div className="massage_zip_file" onClick={onButtonClick}>
        <span>
          <img src={msWorldZip} alt="filelogo" />
        </span>
        <div className="zip_title">
          <strong>{media?.FileName}</strong>
          <span>
            {Math.round(media?.FileSize / 100) / 10 > 1000
              ? `${(Math.round(media?.FileSize / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(media?.FileSize / 100) / 10).toFixed(1)} kb`}
          </span>
        </div>
      </div>
    );
  } else if (media?.MimeType?.startsWith("application")) {
    const onButtonClick = () => {
      fetch(
        `${
          stage === "save" || stage === "mentor"
            ? baseUrlMedia
            : baseUrlMedia?.mediaUrl
        }${media?.StoragePath}${media?.FileName}`
      ).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = media?.FileName;
          alink.click();
        });
      });
    };
    return (
      <div className="massage_zip_file" onClick={onButtonClick}>
        <span>
          <img src={zipShowChatlist} alt="filelogo" />
        </span>
        <div className="zip_title">
          <strong>{media?.FileName}</strong>
          <span>
            {Math.round(media?.FileSize / 100) / 10 > 1000
              ? `${(Math.round(media?.FileSize / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(media?.FileSize / 100) / 10).toFixed(1)} kb`}
          </span>
        </div>
      </div>
    );
  }
  // Return null if the media type is not audio or application/msword or application
  return null;
};

export default React.memo(MediaFileComponent);
