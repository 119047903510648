import React from "react";
import ChatContent from "../../Component/chatContent";
import SettingChat from "../../Component/chatLeft/SettingChat";
import Sidebar from "../../Component/sidebar";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserUpdateProfileDetails } from "../../reducer/EditProfileReducer/UpdateProfile";
const Setting = () => {
  const [savedMessageState, setSavedMesssageState] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserUpdateProfileDetails())
  }, [])

  return (
    <div className="d-flex main_body_inner">
      <Sidebar
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <SettingChat
        title="Settings"
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <ChatContent
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
        showCheckbox={showCheckbox}
        setShowCheckbox={setShowCheckbox}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
      />
    </div>
  );
};

export default Setting;
