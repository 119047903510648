import React, { useRef } from "react";
import {
  additionalInfoIcon,
  arrowLeftIcon,
  billingIcon,
  cidyIcon,
  cityIcon,
  codeIcon,
  countryIcon,
  dateInfoIcon,
  generalIcon,
  gstIcon,
  messageMailIcon,
  socialInfoIcon,
  userIcon,
  vector_icon10,
  wowImg,
} from "../../Images/images";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { CountryData } from "../../CountryData";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserEditProfileDetails,
  submitUpdateProfile,
  updateUserProfilePicture,
} from "../../reducer/EditProfileReducer";
import ChatContent from "../../Component/chatContent";
import {
  fetchCountryData,
  fetchFindUSData,
  fetchSelectCitys,
  fetchSelectStates,
} from "../../reducer/userAuth/userRegister";
import { Bars } from "react-loader-spinner";
import { getUserUpdateProfileDetails } from "../../reducer/EditProfileReducer/UpdateProfile";
const EditProfile = ({
  savedMessageState,
  setSavedMesssageState,
  showCheckbox,
  setShowCheckbox,
  selectedMessage,
  setSelectedMessage,
}) => {
  // const [countryDataValue, setCountryDataValue] = useState([]);
  const [countryDataFetched, setCountryDataFetched] = useState(false);
  const [findUsDataFetched, setFindUsDataFetched] = useState(false);
  const [userProfileImageName, setUserProfileImageName] = useState("");
  const [userProfilePicPreview, setUserProfilePicPreview] = useState();
  const getProfileDetails = useSelector(
    (state) => state?.UpdateProfileReducer?.userProfileUpdatedetails?.data
  );

  const [storeStateID, setStoreStateID] = useState(
    getProfileDetails?.profile_info?.Country
  );
  const [countryDataStatus, setCountryDataStatus] = useState(false);
  const [storeCityID, setStoreCityID] = useState(
    getProfileDetails?.profile_info?.State
  );
  const [cityID, setcityID] = useState(getProfileDetails?.profile_info?.City);
  // get redux call api data
  const countyStateCityListData = useSelector(
    (state) => state?.UpdateProfileReducer
  );
  const fetchEditProfileData = useSelector((state) => state?.userRegister);
  const updateProfilePicture = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle Find Us select Box Trigger the API call only once
  const handleFindUsSelect = () => {
    if (!findUsDataFetched) {
      dispatch(fetchFindUSData());
      setFindUsDataFetched(true);
    }
  };

  // handle coutry data
  const handleCountryData = (values) => {
    if (!countryDataFetched) {
      dispatch(fetchCountryData({ setCountryDataStatus }));
      setCountryDataFetched(true);
    }
    countryDataStatus && setStoreStateID(values);
  };

  // fetch state data
  useEffect(() => {
    if (storeStateID) {
      dispatch(fetchSelectStates({ CountryID: storeStateID }));
    }
  }, [storeStateID]);

  // handle city
  const handleCityList = (values) => {
    setStoreCityID(values);
    setCountryDataFetched(true);
  };

  // Get city list
  useEffect(() => {
    if (storeCityID && storeStateID) {
      dispatch(fetchSelectCitys(storeCityID));
    }
  }, [storeCityID]);

  // user info fetch data
  useEffect(() => {
    dispatch(getUserUpdateProfileDetails());
  }, [dispatch]);

  // select update profile picture
  const selectUpdateProfilePicture = (event) => {
    if (!event.target.files[0]?.type?.startsWith("image")) {
      toast.error("The profile image must be an image.");
    } else {
      setUserProfilePicPreview(URL.createObjectURL(event.target.files[0]));
      setUserProfileImageName(event.target.files[0]);
    }
  };

  const handleUpdateProfilePicture = (event) => {
    updateProfilePicture.current.click();
  };

  // update profile handle submit
  const editProfileSubmit = async (values) => {
    dispatch(
      submitUpdateProfile({
        UserID: UserID,
        Email: values?.email,
        Gender: values?.gender,
        FirstName: values?.firstName,
        LastName: values?.lastName,
        Phone: values?.phoneNumber,
        CountryID: storeStateID,
        StateID: values?.stateName,
        // storeCityID,
        CityID: values?.cityName,
        // CityID: cityID,
        Pincode: values?.pinCode,
        DOB: values?.dob,
        Address: values?.address,
        PhoneCode: values?.PhoneCode,
        find_us: values?.find_us,
        navigate,
        token,
      })
    );
    if (userProfileImageName) {
      dispatch(
        updateUserProfilePicture({
          UserID: UserID,
          ProfilePic: userProfileImageName,
        })
      );
    }
  };

  // set initial value
  const initialValue = {
    firstName: getProfileDetails?.profile_info?.Firstname,
    lastName: getProfileDetails?.profile_info?.Lastname,
    email: getProfileDetails?.profile_info?.email,
    gender: getProfileDetails?.profile_info?.Gender,
    dob: getProfileDetails?.profile_info?.DOB,
    phoneNumber: getProfileDetails?.profile_info?.Phone,
    PhoneCode: getProfileDetails?.profile_info?.PhoneCode,
    CountryID: getProfileDetails?.profile_info?.country?.CountryID,
    stateID: getProfileDetails?.profile_info?.state?.StateID,
    cityID: getProfileDetails?.profile_info?.city?.CityID,
    CountryName: getProfileDetails?.profile_info?.country?.name,
    stateName:
      getProfileDetails?.profile_info?.Country === storeStateID
        ? getProfileDetails?.profile_info?.state?.name
        : "",
    cityName:
      getProfileDetails?.profile_info?.Country === storeStateID
        ? getProfileDetails?.profile_info?.Country === storeStateID
        : "",
    address: getProfileDetails?.profile_info?.Address,
    pinCode: getProfileDetails?.profile_info?.Pincode,
    profileImage: getProfileDetails?.profile_info?.ProfileImage,
    find_us: getProfileDetails?.profile_info?.find_us,
  };

  // validation
  const SignupSchemaEditProfile = Yup.object().shape({
    firstName: Yup.string().required("firstName is Required").nullable(),
    lastName: Yup.string().required("lastName is Required").nullable(),
    email: Yup.string().email("Invalid email").required("Email is Required"),
    gender: Yup.string().required("Select Gender is required!").nullable(),
    dob: Yup.string().required("Date of Birth is Required").nullable(),
    address: Yup.string().required("Address is Required").nullable(),
    phoneNumber: Yup.string()
      .min(6, "Min 6 number is Required")
      .max(15, "Too Long!")
      .required("Mobile number is Required"),
    CountryName: Yup.string().required("Country is Required").nullable(),
    stateName: Yup.string().required("State is Required").nullable(),
    cityName: Yup.string().required("City is Required").nullable(),
    pinCode: Yup.string().required("Pin Number is Required").nullable(),
  });
  const token = localStorage.getItem("api_token_gtf");
  const UserID = localStorage.getItem("UserID");

  return (
    <>
      {savedMessageState ? (
        <ChatContent
          savedMessageState={savedMessageState}
          setSavedMesssageState={setSavedMesssageState}
          showCheckbox={showCheckbox}
          setShowCheckbox={setShowCheckbox}
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
        />
      ) : countyStateCityListData?.status === "loading" ? (
        <Bars
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperClass="loader_style"
          visible={true}
          wrapperStyle={{ left: "30%" }}
        />
      ) : (
        <div className="chatbox-area">
          <div className="chatbox-right-bar">
            <div className="account-setting-inner">
              <div className="edit-profile-account update-profile_cmp">
                <div className="edit-header">
                  <strong>
                    <img
                      src={arrowLeftIcon}
                      alt="arrowLeftIcon"
                      className="edit_profile_arrow_back"
                      onClick={() => navigate(-1)}
                    />
                    Edit Profile
                  </strong>
                </div>
                <div className="edit-profile-form">
                  <div className="edit-from-inner">
                    <Formik
                      enableReinitialize={true}
                      initialValues={initialValue}
                      validationSchema={SignupSchemaEditProfile}
                      onSubmit={(values) => {
                        editProfileSubmit(values);
                      }}
                    >
                      {({ errors, touched, handleChange, values }) => (
                        <Form>
                          <div className="update_profile_image update_profile_btn">
                            <img
                              src={
                                userProfilePicPreview
                                  ? userProfilePicPreview
                                  : getProfileDetails?.profile_info
                                      ?.ProfileImage == ""
                                  ? wowImg
                                  : getProfileDetails?.profile_info
                                      ?.ProfileImage
                              }
                              alt="profile Image"
                              className="profile_edit"
                            />
                            <input
                              ref={updateProfilePicture}
                              type="file"
                              onChange={selectUpdateProfilePicture}
                              style={{ display: "none" }}
                            />
                            <button
                              type="button"
                              onClick={handleUpdateProfilePicture}
                            >
                              Update Profile
                            </button>
                          </div>

                          <br />
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <Field
                                      type="text"
                                      className="form-control"
                                      placeholder="First Name"
                                      value={values.firstName}
                                      onChange={handleChange}
                                      name="firstName"
                                    />
                                    <span>
                                      <img src={userIcon} alt="user-icon" />
                                    </span>
                                    {errors.firstName && touched.firstName ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.firstName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <Field
                                      type="text"
                                      className="form-control"
                                      placeholder="Last Name"
                                      name="lastName"
                                      value={values.lastName}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      <img src={userIcon} alt="user-icon" />
                                    </span>
                                    {errors.lastName && touched.lastName ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.lastName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Billing Name(Optional)"
                                  name="billingName"
                                />
                                <span>
                                  <img src={billingIcon} alt="user-icon" />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  name="gender"
                                  onChange={handleChange}
                                >
                                  <>
                                    <option
                                      value={
                                        getProfileDetails?.profile_info
                                          ?.Gender == null
                                          ? ""
                                          : getProfileDetails?.profile_info
                                              ?.Gender
                                      }
                                    >
                                      {getProfileDetails?.profile_info
                                        ?.Gender == null
                                        ? "Select Gender"
                                        : getProfileDetails?.profile_info
                                            ?.Gender}
                                    </option>
                                    <option value="Man">Man</option>
                                    <option value="Woman">Woman</option>
                                    <option value="other">Other</option>
                                  </>
                                </select>
                                <span>
                                  <img src={generalIcon} alt="user-icon" />
                                </span>
                                {errors.gender && touched.gender ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.gender}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Additional Info(Optional)"
                                  name="addinationInfo"
                                />
                                <span>
                                  <img
                                    src={additionalInfoIcon}
                                    alt="user-icon"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Email"
                                  name="email"
                                  value={values.email}
                                  // disabled="disabled"
                                  onChange={handleChange}
                                />
                                <span>
                                  <img src={messageMailIcon} alt="user-icon" />
                                </span>
                                {errors.email && touched.email ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-input">
                                  <select
                                    className="form-control"
                                    name="find_us"
                                    value={values.find_us}
                                    onChange={handleChange}
                                    onClick={handleFindUsSelect} // Trigger the API call only once
                                  >
                                    <option
                                      value={
                                        getProfileDetails?.profile_info?.find_us
                                      }
                                    >
                                      {getProfileDetails?.profile_info?.find_us}
                                    </option>
                                    {fetchEditProfileData?.status ===
                                    "loading" ? (
                                      <option disabled>
                                        <h1>Loading...</h1>
                                      </option>
                                    ) : (
                                      fetchEditProfileData?.findUSData &&
                                      fetchEditProfileData?.findUSData?.map(
                                        (data) => (
                                          <option
                                            value={data?.Name}
                                            key={data?.FindUsID}
                                          >
                                            {data?.Name}
                                          </option>
                                        )
                                      )
                                    )}
                                  </select>
                                  <span className="form-input-icon">
                                    <img
                                      src={vector_icon10}
                                      alt="vector_icon10"
                                    />
                                  </span>
                                </div>
                                <span>
                                  <img src={socialInfoIcon} alt="user-icon" />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="date"
                                  className="form-control date-form"
                                  placeholder="Enter Dath of Birth"
                                  name="dob"
                                  value={values.dob}
                                  onChange={handleChange}
                                />
                                <span>
                                  <img src={dateInfoIcon} alt="user-icon" />
                                </span>
                                {errors.dob && touched.dob ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.dob}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Address"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                />
                                <span>
                                  <img src={cidyIcon} alt="user-icon" />
                                </span>
                                {errors.address && touched.address ? (
                                  <div className="text-danger d-flex mt-2">
                                    {errors.address}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group number-input">
                                <div className="group">
                                  <select
                                    className="num form-control "
                                    style={{ paddingLeft: "20px" }}
                                    name="PhoneCode"
                                    value={values.PhoneCode}
                                    onChange={handleChange}
                                  >
                                    <option value={values.PhoneCode}>
                                      {values.PhoneCode}
                                    </option>
                                    {CountryData?.map((data, index) => (
                                      <option
                                        value={data?.dialCode}
                                        key={index}
                                      >
                                        {` ${data?.dialCode}`}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Phone number"
                                  name="phoneNumber"
                                  value={values?.phoneNumber}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.phoneNumber && touched.phoneNumber ? (
                                <div className="text-danger d-flex mt-2">
                                  {errors.phoneNumber}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <Field
                                      as="select"
                                      className="form-control"
                                      name="CountryName"
                                      value={values.CountryName}
                                      onChange={handleChange}
                                      onClick={() =>
                                        handleCountryData(values.CountryName)
                                      }
                                    >
                                      <option
                                        value={
                                          getProfileDetails?.profile_info
                                            ?.country?.name
                                        }
                                      >
                                        {
                                          getProfileDetails?.profile_info
                                            ?.country?.name
                                        }
                                      </option>
                                      {!countryDataStatus ? (
                                        <option disabled>
                                          <h1>Loading..</h1>
                                        </option>
                                      ) : (
                                        fetchEditProfileData?.selectCountry
                                          ?.data &&
                                        fetchEditProfileData?.selectCountry?.data?.map(
                                          (c) => (
                                            <option value={c?.CountryID}>
                                              {c?.name}
                                            </option>
                                          )
                                        )
                                      )}
                                    </Field>
                                    <span>
                                      <img src={countryIcon} alt="user-icon" />
                                    </span>
                                    {errors.CountryName &&
                                    touched.CountryName ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.CountryName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      name="stateName"
                                      value={values?.stateName}
                                      onChange={handleChange}
                                      onClick={() =>
                                        handleCityList(values.stateName)
                                      }
                                    >
                                      {getProfileDetails?.profile_info
                                        ?.Country === storeStateID ? (
                                        <option value={values?.stateID}>
                                          {
                                            getProfileDetails?.profile_info
                                              ?.state?.name
                                          }
                                        </option>
                                      ) : (
                                        <option value="">Select State</option>
                                      )}
                                      {fetchEditProfileData?.userSelectState
                                        ?.data &&
                                        fetchEditProfileData?.userSelectState?.data?.map(
                                          (state, index) => {
                                            return (
                                              <option
                                                value={state?.StateID}
                                                key={index}
                                              >
                                                {state?.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    <span>
                                      <img src={countryIcon} alt="user-icon" />
                                    </span>
                                    {errors.stateName && touched.stateName ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.stateName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="GST Number(Optional)"
                                  name="getNumber"
                                />
                                <span>
                                  <img src={gstIcon} alt="user-icon" />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      name="cityName"
                                      value={values?.cityName}
                                      onChange={handleChange}
                                      onClick={() => {
                                        setcityID(values?.cityName);
                                      }}
                                    >
                                      {getProfileDetails?.profile_info
                                        ?.Country === storeStateID ? (
                                        <option value={values?.cityID}>
                                          {
                                            getProfileDetails?.profile_info
                                              ?.city?.name
                                          }
                                        </option>
                                      ) : (
                                        <option value="">Select State</option>
                                      )}
                                      {fetchEditProfileData?.userSelectCity
                                        ?.data &&
                                        fetchEditProfileData?.userSelectCity?.data?.map(
                                          (country, index) => {
                                            return (
                                              <option
                                                value={country?.CityID}
                                                key={index}
                                              >
                                                {country?.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    <span>
                                      <img src={cityIcon} alt="user-icon" />
                                    </span>
                                    {errors.cityName && touched.cityName ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.cityName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <Field
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Pin code"
                                      name="pinCode"
                                      value={values?.pinCode}
                                      onChange={handleChange}
                                    />
                                    <span>
                                      <img src={codeIcon} alt="user-icon" />
                                    </span>
                                    {errors.pinCode && touched.pinCode ? (
                                      <div className="text-danger d-flex mt-2">
                                        {errors.pinCode}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 editbutton-col">
                              <div className="form-btn">
                                <button
                                  className="btn btn-profile edit_profile_button"
                                  type="submit"
                                >
                                  Update Profile
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProfile;
