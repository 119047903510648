import axios from "axios";
import { globalLocalStrogeToken, getGlobalAuthToken, getFirebaseToken, getLocalDeviceToken, generateDummyToken } from "../Component/GlobalLocalStorage";

const createAxiosInstance = (baseURL) => {
  const instance = axios?.create({
    baseURL,
    headers: {
      DeviceToken: getFirebaseToken() || getLocalDeviceToken() || null,
      DeviceType: "Browser",
    },
  });

  instance.interceptors.request.use((config) => {
    const token = globalLocalStrogeToken(); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return instance;
};

const createAxiosInstanceAuth = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      DeviceToken:  getFirebaseToken() || getLocalDeviceToken() || null ,
      DeviceType: "Browser",
      FromGTFConnect:1,
    },
  });

  instance.interceptors.request.use((config) => {
    const token = getGlobalAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export const baseUrlAuth = createAxiosInstanceAuth(process.env.REACT_APP_BASE_URL_AUTH);
export const baseUrlMain = createAxiosInstance(process.env.REACT_APP_BASE_URL_MAIN);
export const baseUrlEdit = createAxiosInstance(process.env.REACT_APP_BASE_URL_EDIT);

