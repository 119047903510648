import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import userLogin from "../reducer/userAuth/userLogin";
import GetChat from "../reducer/GetChat";
import GroupChatlistComment from "../reducer/GroupChatListComment";
import PinMessage from "../reducer/PinMessage";
import FileUploaded from "../reducer/FiieUploaded";
import Reaction from "../reducer/Reaction";
import EditProfileReducer from "../reducer/EditProfileReducer";
import UpdateProfileReducer from "../reducer/EditProfileReducer/UpdateProfile";
import EditGroupInfo from "../reducer/EditGroupInfo";
import UpdateChannelGroupReducer from "../reducer/DetailsChannelGroup";
import GcPermissionUpdate from "../reducer/GcPermissionUpdate";
import DummyUserReducer from "../reducer/DummyUserReducer";
import SubscriberReducer from "../reducer/SubscriberReducer";
import GCReactionList from "../reducer/GCReaction";
import SavedMessagess from "../reducer/SavedMessagess";
import MentorList from "../reducer/MentorReducer";
import UserData from "../reducer/UserReducer";
import ExcluciveGcReducer from "../reducer/ExcluciveGcReducer";
import LeaveOrRejoinReducer from "../reducer/LeaveOrRejoinReducer";
import GCUserInfoMedia from "../reducer/GCUserInfoMedia";
import ReportUser from "../reducer/ReportUser";
import GCBlockUser from "../reducer/GCMemberBlockUnblock";
import { globalSettingPermission } from "../reducer/GlobalSetting";
import GCMuteNotification from "../reducer/GCMuteNotification";
import contactUsPageData from "../reducer/ContactUs";
import OtpVerification from "../reducer/userAuth/OtpVerification";
import userRegister from "../reducer/userAuth/userRegister";
import resendOtp from "../reducer/userAuth/resendOtp";
import forgotPassword from "../reducer/userAuth/forgotPassword";
import changePassword from "../reducer/changePassword";
import Instructor from "../reducer/Instructor";
import notificationReducer from "../reducer/notificationReducer";
import MessageReceivedReducer from "../reducer/MessageReceived";
import CurrentChatBoxInfoReducer from "../reducer/CurrentChatBoxInfo";

export const store = configureStore({
  reducer: {
    GetChat: GetChat,
    userLogin: userLogin,
    allPinMessage: PinMessage,
    GroupChatlistComment: GroupChatlistComment,
    FileUploaded: FileUploaded,
    Reaction: Reaction,
    EditProfileReducer: EditProfileReducer,
    UpdateProfileReducer: UpdateProfileReducer,
    EditGroupInfo: EditGroupInfo,
    UpdateChannelGroupReducer: UpdateChannelGroupReducer,
    GcPermissionUpdate: GcPermissionUpdate,
    DummyUserReducer: DummyUserReducer,
    GCReactionList: GCReactionList,
    SubscriberReducer: SubscriberReducer,
    SavedMessagess: SavedMessagess,
    ExcluciveGcReducer: ExcluciveGcReducer,
    LeaveOrRejoinReducer: LeaveOrRejoinReducer,
    GCUserInfoMedia: GCUserInfoMedia,
    GCBlockUser: GCBlockUser,
    ReportUser: ReportUser,
    globalSettingPermission: globalSettingPermission,
    GCMuteNotification: GCMuteNotification,
    MentorList: MentorList,
    UserData: UserData,
    contactUsPageData: contactUsPageData,
    OtpVerification: OtpVerification,
    userRegister: userRegister,
    resendOtp: resendOtp,
    forgotPassword: forgotPassword,
    changePassword: changePassword,
    Instructor: Instructor,
    notificationReducer:notificationReducer,
    messagereceived:MessageReceivedReducer,
    currentChatBoxInfo: CurrentChatBoxInfoReducer

  },
  middleware: [thunk],
});
