import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ConversationID : null, 
  GroupChannelID:null,
  newMessageStatus: false
};

export const MessageReceived = createSlice({
  name: "messagereceived",
  initialState,
  reducers: {
    newMessageReceived: (state, action) => {
      state.ConversationID = action?.payload?.ConversationID;
      state.GroupChannelID = action?.payload?.GroupChannelID;
      state.newMessageStatus = action?.payload?.newMessageStatus;
    },  
  },
});

export const {newMessageReceived} = MessageReceived.actions;

export default MessageReceived.reducer;
