import { baseUrlEdit } from "../store/axios";
import { socket } from "../store/SocketIo";
import moment from "moment";
export const CallAPI = async (url, method = "GET", params = {}, data = {}) => {
  const defaultHeaders = {
    Authorization: `Bearer ${localStorageToken()}`,
  };

  try {
    const response = await baseUrlEdit.request({
      url,
      method,
      params,
      data,
      headers: defaultHeaders,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const localStorageToken = () => {
  let storedToken;
  if (typeof window !== "undefined") {
    storedToken = localStorage.getItem("token");
  }
  if (storedToken) {
    return storedToken;
  } else {
    return null;
  }
};
export const scrollToBottom = (elementRef) => {
  const element = elementRef?.current;
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  }
};

export const getLoginUserId = () => {
  return localStorage.getItem("gtfconnectid ");
};

export const emmitToSocket = (event, data, callback) => {
  socket.emit(event, data, callback);
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const logOutTokenExpire = () => {
  localStorage.clear();
  window.location.href = "/login";
};

// handling tagUser data
export const highlightUsernames = (value) => {
  let newComment = value;
  // Replace the opening tag and username with the span element
  newComment = newComment?.split("@{")?.map((substring, index) => {
    const closingBracketIndex = substring.indexOf("}");
    if (closingBracketIndex !== -1) {
      const username = substring.substring(0, closingBracketIndex);
      return (
        <>
          <span
            style={{
              color: "#2fb463",
              cursor: "pointer",
              fontWeight: "500",
            }}
          >
            {/* {username.replace(/\|\d+/, "  ")} */}
            {" "+ username.split('|').shift().trim() + ' '}

          </span>
          {substring.substring(closingBracketIndex + 1)}
        </>
      );
    } else {
      return substring;
    }
  });

  return newComment;
};

export const extractUsernameAndText = (value) => {
  if (value !== "") {
    const usernameRegex = /@\{(.*?)\}/g; // Global regex to match all occurrences of the username inside @{...}
    const extractedUsernames = []; // To store the extracted usernames
    const modifiedValue = value?.replace(usernameRegex, (match, username) => {
      extractedUsernames.push(username.trim()); // Extract and store the usernames
      return ""; // Replace the username with an empty string
    });

    const usernamesInOrder = extractedUsernames.join(" "); // Concatenate the extracted usernames with spaces
    const finalValue = `${usernamesInOrder.replace(
      /\|\d+/,
      ""
    )} ${modifiedValue?.trim()}`; // Concatenate usernames and the modified message

    return finalValue?.trim(); // Trim any leading or trailing spaces
  } else {
    return value;
  }
};


// Handle hyperlink and tagUser
export const convertToLinksAndHighlightUsernames = (message) => {
  const findTextHyperlink = /(https?:\/\/[^\s]+)/g;
  return message
    ?.split(/(@\{\w+?\})|(https?:\/\/[^\s]+)/g)
    .filter(Boolean) // Remove empty strings from the split result
    .map((part, index) => {
      if (part.startsWith("@{")) {
        // This part is a username, apply highlighting
        const username = part.substring(2, part.length - 1);
        return (
          <>
            <span
              key={index}
              style={{
                color: "#2fb463",
                cursor: "pointer",
                fontWeight: "500",
              }}
            >
              {" " + username.split('|').shift().trim() + ' '}
            </span>
            {part?.split("}")[1].trim()}
          </>
        );
      } else if (findTextHyperlink.test(part)) {
        // This part is a URL, convert it to a hyperlink
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // This part is regular text
        return part;
      }
    });
};

// Chat last time display functionality
export const compareWithToday = (date) => {
  const today = moment().startOf("day");
  const inputDate = moment(date).startOf("day");

  if (inputDate.isSame(today, "day")) {
    return "Today";
  } else if (inputDate.isSame(today.clone().subtract(1, "days"), "day")) {
    return "Yesterday";
  } else {
    return `${inputDate.format("ddd")} , ${inputDate.format("DD MMM")}`;
  }
};

// chat message sortting functionality
export const sortChatMessages = (messages) => {
  // Filter out empty messages
  const nonEmptyMessages = messages?.filter(msg => msg?.group?.message?.length >= 1);
  const emptyMessages = messages?.filter(msg => msg?.group?.message?.length <= 0);

  // Sort non-empty messages by time
  nonEmptyMessages && nonEmptyMessages?.sort((a, b) => {
    const aLatestMessageTime =
      a.group.message.length >= 0
        ? new Date(a.group.message[0]?.CreatedAt)
        : null;
    const bLatestMessageTime =
      b.group.message.length >= 0
        ? new Date(b.group.message[0]?.CreatedAt)
        : null;
    // If any group has no messages, assume they have the earliest timestamp
    if (!aLatestMessageTime && bLatestMessageTime) return -1;
    if (aLatestMessageTime && !bLatestMessageTime) return 1;
    // Sort in descending order
    return bLatestMessageTime - aLatestMessageTime;
  });

  // Concatenate non-empty and empty messages
  return nonEmptyMessages?.concat(emptyMessages);
}