import React, { useRef, useState } from "react";
import { VideoPlay, videoPlay } from "../../Images/images";

function VideoPlayer({
  mentorMediaURL,
  media,
  allItems,
  check,
  count,
  setMentorGalleryData,
  setMentorShowGallery,
}) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="gc_video_icon_play-pause">
      <video
        ref={videoRef}
        className="video_chatlist_video"
        src={`${mentorMediaURL}${media?.StoragePath}${media?.FileName}`}
        poster={`${mentorMediaURL}${media?.StoragePath}${media?.Thumbnail}`}
      />

      <div
        className="video_play_icon"
        onClick={handlePlayPause}
        data-toggle="modal"
        data-target="#myModal"
      >
        <img
          src={isPlaying ? VideoPlay : videoPlay}
          alt={isPlaying ? "pause" : "play"}
        />
      </div>
      {check && (
        <div
          className="image-counter"
          onClick={() => {
            setMentorGalleryData(allItems);
            setMentorShowGallery(true);
          }}
        >
          + {count}
        </div>
      )}
    </div>
  );
}

export default React.memo(VideoPlayer);
