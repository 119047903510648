import moment from "moment-timezone";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  convertToLinksAndHighlightUsernames,
  highlightUsernames,
  scrollToBottom,
} from "../../common";
import {
  doubleTick,
  quoteIcon,
  replyIcon,
  sendMessageReply,
  videoPlay,
} from "../../Images/images";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import GallerySlider from "./GallerySlider";
import VideoPlayer from "./VideoPlayer";
import MediaFileContent from "./MediaFileContent";
import { showPreviewImage } from "../../reducer/GetChat";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import MediaChatComponent from "./MediaChatComponent";

const MentorChats = ({
  chats,
  index,
  addMentorMessageToPin,
  removeMentorMessage,
  unpinMentorMessage,
  unpinAllMentorMessage,
  MentorPinMessageID,
  handleMentorMessageEdit,
  stage,
  handleMentorQuote,
  handleQuoteMessage,
  quote,
  setQuote,
  selectMode,
  setSelectMode,
  selectedMentMessages,
  setSelectedMentMessages,
  setPosition,
  setPositionXX,
  maxX,
  maxY,
  positionXX,
  getLastMessageAddClassNameMentor,
  setAddLastMessageClassMouseRightClickShowPopup,
  addLastMessageClassMouseRightClickShowPopup,
  setPinMesssageState,
  setGallary,
  setShowGallary,
}) => {
  const endRef = useRef();
  const contextMenuRef = useRef(null);
  const myRef = useRef(null);
  const liRef = useRef(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [mentorShowGallery, setMentorShowGallery] = useState(false);
  const [mentorGalleryData, setMentorGalleryData] = useState([]);
  // Dispatch
  const mentorMediaURL = useSelector((state) => state?.MentorList?.mediaURL);
  const checkGlobalPermission = useSelector(
    (state) =>
      state?.UpdateProfileReducer?.userProfileUpdatedetails?.data?.user_setting
  );
  const dispatch = useDispatch();

  let bool =
    JSON.parse(localStorage?.getItem("gtfconnectid")) !==
    chats?.recipientData?.UserID;

  const connectid = localStorage.getItem("gtfconnectid");

  const newTimeChat = new Date(chats?.CreatedAt)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Asia/Kolkata",
    })
    .split("/")
    .join("-");

  useEffect(() => {
    setSelectMode(false);
    scrollToBottom(endRef);
    const handleDocumentClick = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        setShowContextMenu(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleContextMenuAction = (action, chatData, MentorPinMessageID) => {
    // Perform actions based on the selected action and chatData
    switch (action) {
      case "pin":
        addMentorMessageToPin(chatData);
        break;
      case "unpinAll":
        unpinAllMentorMessage(chatData);
        break;
      case "delete":
        removeMentorMessage(chatData);
        setSelectMode(false);
        break;
      case "edit":
        handleMentorMessageEdit(chatData);
        break;
      case "quote":
        handleMentorQuote(chatData);
        break;
      case "select":
        // Entering select mode
        setSelectMode(true);
        setSelectedMentMessages([chatData]); // Select the current message only
        break;
      case "unpin":
        unpinMentorMessage(chatData, MentorPinMessageID);
        break;
      default:
        break;
    }

    // Close the context menu after the action is performed
    setShowContextMenu(false);
  };
  const handleContextMenu = (event, data) => {
    if (
      data?.IndividualChatID ===
      getLastMessageAddClassNameMentor?.IndividualChatID
    ) {
      setAddLastMessageClassMouseRightClickShowPopup(true);
    } else {
      setAddLastMessageClassMouseRightClickShowPopup(false);
    }
    event.preventDefault();
    setShowContextMenu(true);
    const adjustedX = event.clientX > maxX ? maxX : event.clientX;
    const adjustedY = event.clientY > maxY ? maxY : event.clientY;
    setPositionXX({ adjustedX, adjustedY });
    setPosition({ x: event.clientX, y: event.clientY });
  };

  // handle pin message quote message click redirct message
  useEffect(() => {
    if (quote && stage !== "pin") {
      if (quote?.IndividualChatID === chats?.IndividualChatID) {
        const element = myRef?.current;
        element?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => setQuote(null), 3000);
      }
    }
  }, [quote, chats]);

  const handleCheckboxChange = (messageData) => {
    setSelectedMentMessages((prevSelectedMessages) => {
      if (prevSelectedMessages.includes(messageData)) {
        // If the message is already selected, remove it from the selectedMessages array
        return prevSelectedMessages.filter(
          (message) => message !== messageData
        );
      } else {
        // If the message is not selected, add it to the selectedMessages array
        return [...prevSelectedMessages, messageData];
      }
    });
  };

  useEffect(() => {
    // When select mode is exited, clear the selected messages array
    if (!selectMode) {
      setSelectedMentMessages([]);
    }
  }, [selectMode]);

  const mediaToShow = chats?.media
    ?.filter(
      (item) =>
        item?.MimeType?.startsWith("image") ||
        item?.MimeType?.startsWith("video")
    )
    .slice(0, 4);

  let allItems = chats?.media?.filter(
    (mediaItem) =>
      mediaItem?.MimeType?.startsWith("image") ||
      mediaItem?.MimeType?.startsWith("video")
  );

  // Image and Audio Files Mentor
  const showMedia = mediaToShow?.map((media, index) => {
    let check = index === 3 ? true : false;
    let count = allItems?.length;

    if (media?.MimeType?.startsWith("image")) {
      return (
        <div className="images_view" key={index}>
          <a
            href=""
            data-bs-toggle="modal"
            data-bs-target="#imagesmodalimg"
            onClick={() => dispatch(showPreviewImage(media))}
          >
            <img
              width={38}
              height={38}
              // alt={media?.name}
              key={index}
              src={`${mentorMediaURL}${media?.StoragePath}${media?.FileName}`}
            />
          </a>
          {check && (
            <div
              className="image-counter"
              onClick={() => {
                setMentorGalleryData(allItems);
                setMentorShowGallery(true);
              }}
            >
              +{count}
            </div>
          )}
        </div>
      );
    } else if (media?.MimeType?.startsWith("video")) {
      return (
        <VideoPlayer
          key={index}
          mentorMediaURL={mentorMediaURL}
          media={media}
          check={check}
          count={count}
          allItems={allItems}
          setMentorGalleryData={setMentorGalleryData}
          setMentorShowGallery={setMentorShowGallery}
        />
      );
    }
  });

  //Documents files Mentor / Student
  const showMediaFiles = chats?.media?.map((media, index) => (
    <MediaFileContent
      key={index}
      media={media}
      baseUrlMedia={mentorMediaURL}
      stage={"mentor"}
    />
  ));

  //Read Less and More
  const contentAll = chats?.Content?.split(" ");
  const chunkSize = 100;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    const chunksArray = [];
    for (let i = 0; i < contentAll?.length; i += chunkSize) {
      chunksArray.push(contentAll?.slice(i, i + chunkSize));
    }
    setChunks(chunksArray);
  }, [chats]);

  useEffect(() => {
    setDisplayedWords(chunks?.slice(0, currentIndex + 1)?.flat());
  }, [chunks, currentIndex]);

  const showNextChunk = () => {
    if (currentIndex < chunks?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPrevChunk = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const hasMoreContent = currentIndex < chunks?.length - 1;

  const mentorMessage = displayedWords.join(" ");
  // ..

  // handle scrolling to last message

  useEffect(() => {
    if (endRef && endRef.current) {
      setTimeout(() => {
        endRef?.current &&
          endRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
      }, 100);
    }
  }, [chats, endRef]);

  return (
    <div key={index}>
      {stage !== "pin" && (
        <>
          <li
            onContextMenu={(event) => handleContextMenu(event, chats)}
            onWheel={() => setShowContextMenu(false)}
            className={
              bool
                ? "massage_send mentor_massage"
                : "massage_received mentor_massage"
            }
            ref={
              quote && quote?.IndividualChatID === chats?.IndividualChatID
                ? myRef
                : liRef
            }
          >
            <div
              className={`massage_inner chat_live hello ${
                quote && quote?.IndividualChatID === chats?.IndividualChatID
                  ? " blinking-background"
                  : ""
              }`}
            >
              {selectMode && selectedMentMessages?.length > 0 && (
                <input
                  type="checkbox"
                  name="select"
                  checked={selectedMentMessages.includes(chats)}
                  className="select_message_design"
                  onChange={() => {
                    handleCheckboxChange(chats);
                  }}
                />
              )}
              <div className="massage_content">
                {chats?.ChatRefID !== null ? (
                  <div className="massage_quote_text">
                    <div
                      className="quote_content"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleQuoteMessage(chats?.quote)}
                    >
                      <span>
                        <img src={quoteIcon} alt="quoteIcon" />
                      </span>
                      <div className="quote_text">
                        <h2>Quote</h2>
                        <p>
                          {convertToLinksAndHighlightUsernames(
                            chats?.quote?.Content?.slice(0, 200)
                          )}
                          {chats?.quote?.Content?.length > 200 ? "..." : ""}
                        </p>
                        <div className="time_se">
                          <small className="time-text">
                            {chats?.quote?.SenderId ===
                            localStorage?.getItem("gtfconnectid")
                              ? "You , "
                              : ` ${chats?.quote?.senderData?.Firstname} ${chats?.quote?.senderData?.Lastname} , `}
                            {moment(chats?.quote?.CreatedAt)
                              .add("hours")
                              .format("hh:mm A")}
                          </small>
                        </div>
                      </div>
                    </div>
                    <p>{convertToLinksAndHighlightUsernames(chats?.Content)}</p>
                    <div className="time_se">
                      <small className="time-text">
                        {moment(chats?.CreatedAt)
                          .add("hours")
                          .format("hh:mm A")}
                      </small>
                      {/* {bool && (
                        <span className="duble-tick">
                          <img src={doubleTick} alt="doubleTick" />
                        </span>
                      )} */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>
                      {convertToLinksAndHighlightUsernames(mentorMessage)}
                      {hasMoreContent && "..."}
                      {currentIndex < chunks.length - 1 && (
                        <span
                          onClick={showNextChunk}
                          style={{
                            paddingLeft: "10px",
                            color: "green",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Read More
                        </span>
                      )}
                      {currentIndex > 0 && (
                        <span
                          onClick={showPrevChunk}
                          style={{
                            paddingLeft: "10px",
                            color: "green",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Read Previous
                        </span>
                      )}
                    </p>

                    {/* <div className="imgcontent-group">
                      <div
                        className={`goup-row row vodeo_row_play_pause ${
                          chats?.media?.length === 1 ? "no_column" : ""
                        }`}
                      >
                        <MediaChatComponent
                          mediaToShow={mediaToShow}
                          allItems={allItems}
                          baseUrlMedia={mentorMediaURL}
                          checkGlobalPermission={checkGlobalPermission}
                          videoPlay={videoPlay}
                          dispatch={dispatch}
                          showPreviewImage={showPreviewImage}
                          setGallary={setGallary}
                          setShowGallary={setShowGallary}
                        />
                      </div>
                      <div className="media_filegroup_list">
                        {showMediaFiles}
                      </div>
                    </div> */}
                    <div className="imgcontent-group">
                      <div
                        className={`goup-row row vodeo_row_play_pause ${
                          chats?.media?.length === 1 ? "no_column" : ""
                        }`}
                      >
                        {showMedia}
                      </div>
                      <div className="media_filegroup_list">
                        {showMediaFiles}
                      </div>
                    </div>

                    <div className="time_se">
                      <small className="text-black">
                        {moment(chats?.CreatedAt)
                          .add("hours")
                          .format("hh:mm A")}
                      </small>
                      {/* {bool ? (
                        <span className="duble-tick">
                          <img src={doubleTick} alt="doubleTick" />
                        </span>
                      ) : null} */}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${
                  bool ? "reply_comments_sc_send_message" : "replay_btn_po"
                }`}
              >
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#Forward"
                  onClick={() => setSelectedMentMessages([chats])}
                >
                  <img src={bool ? sendMessageReply : replyIcon} alt="replay" />
                </a>
              </div>
            </div>
          </li>
          <div ref={endRef}></div>{" "}
        </>
      )}

      {/* Pin Message Design */}

      {stage === "pin" && (
        <li
          onContextMenu={(event) => handleContextMenu(event, chats)}
          onWheel={() => setShowContextMenu(false)}
          className={"massage_received mentor_massage"}
          ref={endRef}
        >
          <div className={`massage_inner chat_live hello `}>
            <div
              className="massageer_name massage_content openright-bar"
              data-apply_classname="open_profile_sidebar"
            >
              <strong className="orange">
                {connectid === chats?.SenderId
                  ? `${chats?.recipientData?.Firstname} ${
                      chats?.recipientData?.Lastname === null
                        ? ""
                        : chats?.recipientData?.Lastname
                    }`
                  : `${chats?.senderData?.Firstname} ${
                      chats?.senderData?.Lastname === null
                        ? ""
                        : chats?.senderData?.Lastname
                    }`}

                <span className="pin_message_date pl-2">{newTimeChat} </span>
              </strong>
            </div>
            {selectMode && selectedMentMessages?.length > 0 && (
              <input
                type="checkbox"
                name="select"
                checked={selectedMentMessages.includes(chats)}
                className="select_message_design"
                onChange={() => {
                  handleCheckboxChange(chats);
                }}
              />
            )}
            <div className="massage_content">
              <div>
                <p>
                  {convertToLinksAndHighlightUsernames(mentorMessage)}
                  {hasMoreContent && "..."}
                  {currentIndex < chunks.length - 1 && (
                    <span
                      onClick={showNextChunk}
                      style={{
                        paddingLeft: "10px",
                        color: "green",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Read More
                    </span>
                  )}
                  {currentIndex > 0 && (
                    <span
                      onClick={showPrevChunk}
                      style={{
                        paddingLeft: "10px",
                        color: "green",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Read Previous
                    </span>
                  )}
                </p>

                {/* <div className="imgcontent-group">
                  <div
                    className={`goup-row row vodeo_row_play_pause ${
                      chats?.media?.length === 1 ? "no_column" : ""
                    }`}
                  >
                    <MediaChatComponent
                      mediaToShow={mediaToShow}
                      allItems={allItems}
                      baseUrlMedia={mentorMediaURL}
                      checkGlobalPermission={checkGlobalPermission}
                      videoPlay={videoPlay}
                      dispatch={dispatch}
                      showPreviewImage={showPreviewImage}
                      setGallary={setGallary}
                      setShowGallary={setShowGallary}
                    />
                  </div>
                  <div className="media_filegroup_list">{showMediaFiles}</div>
                </div> */}

                <div className="imgcontent-group">
                  <div
                    className={`goup-row row vodeo_row_play_pause ${
                      chats?.media?.length === 1 ? "no_column" : ""
                    }`}
                  >
                    {showMedia}
                  </div>
                  <div className="media_filegroup_list">{showMediaFiles}</div>
                </div>

                <div className="time_se">
                  {/* <small className="text-black">
                      {moment(chats?.CreatedAt).add("hours").format("hh:mm A")}
                    </small> */}
                  <small
                    className="pin_message_goto_main_msg"
                    onClick={() => {
                      setQuote(chats);
                      setPinMesssageState(false);
                    }}
                  >
                    Go To Main Message
                  </small>
                  <small
                    className="pin_message_remove_pin ml-2"
                    onClick={() => {
                      ConfirmationDialog({
                        title: "Unpin Message",
                        message: "Are you sure you want to Unpin this message?",
                        onConfirm: () =>
                          handleContextMenuAction(
                            "unpin",
                            chats,
                            MentorPinMessageID
                          ),
                      });
                    }}
                  >
                    Remove
                  </small>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}
      {/* ************************************************** Pin message End************************************** */}
      {showContextMenu && (
        <div className="rightClickShowPopup rightclick_p">
          <div
            id="contextMenu"
            ref={contextMenuRef}
            className={"rounded popupright-click"}
            role="menu"
            aria-labelledby="dropdownMenu"
            style={{
              position: "fixed",
              left: positionXX?.adjustedX,
              top: addLastMessageClassMouseRightClickShowPopup
                ? "650px"
                : positionXX?.adjustedY,
              padding: "5px",
              zIndex: "99",
              maxWidth: "200px",
            }}
          >
            <div className="innerButtonMouseRightClick">
              {
                stage !== "pin" && (
                  <>
                    <button
                      className="deletePostStyle"
                      onClick={() => handleContextMenuAction("select", chats)}
                    >
                      Select
                    </button>

                    {bool && chats?.media?.length === 0 && (
                      <button
                        className="deletePostStyle"
                        onClick={() => handleContextMenuAction("edit", chats)}
                      >
                        Edit
                      </button>
                    )}

                    <button
                      className="deletePostStyle"
                      onClick={() => {
                        setShowContextMenu(false);
                        toast.success("Message Copied !!");
                        navigator.clipboard.writeText(chats?.Content);
                      }}
                    >
                      Copy
                    </button>

                    <button
                      className="deletePostStyle"
                      onClick={() => handleContextMenuAction("pin", chats)}
                    >
                      Pin Message
                    </button>

                    <button
                      className="deletePostStyle"
                      onClick={() => handleContextMenuAction("quote", chats)}
                    >
                      Quote
                    </button>

                    <button
                      className="deletePostStyle"
                      onClick={() => handleContextMenuAction("delete", chats)}
                    >
                      Delete
                    </button>
                  </>
                )
                // ) : (
                //   <>
                //     <button
                //       className="deletePostStyle"
                //       onClick={() =>
                //         handleContextMenuAction(
                //           "unpin",
                //           chats,
                //           MentorPinMessageID
                //         )
                //       }
                //     >
                //       Un-Pin Message
                //     </button>

                //     <button
                //       className="deletePostStyle"
                //       onClick={() =>{ handleContextMenuAction("unpinAll", chats)}}
                //     >
                //       Un-Pin All
                //     </button>

                //     <button
                //       className="deletePostStyle"
                //       onClick={() => {
                //         setShowContextMenu(false);
                //         toast.success("Message Copied !!");
                //         navigator.clipboard.writeText(chats?.Content);
                //       }}
                //     >
                //       Copy
                //     </button>
                //   </>
                // )
              }
            </div>
          </div>
        </div>
      )}

      <GallerySlider
        setGallary={setMentorGalleryData}
        setShowGallary={setMentorShowGallery}
        gallary={mentorGalleryData}
        showGallary={mentorShowGallery}
        baseUrlMedia={mentorMediaURL}
        stage={"mentor"}
      />
    </div>
  );
};

export default React.memo(MentorChats);
