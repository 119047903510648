import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  accountSetting,
  blocklist,
  dataIcon,
  notifications,
} from "../../Images/images";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateGlobalSetting } from "../../reducer/GlobalSetting";
import {
  globalBlockListUnblockMemberStatus,
  globalSettingBlockList,
} from "../../reducer/GCMemberBlockUnblock";
const SettingChat = ({ title, setSavedMesssageState }) => {
  const getProfileDetails = useSelector((state) => state?.UpdateProfileReducer);

  const [notificationForChat, setNotificationForChat] = useState(0);
  const [autoPlayVIdeo, setAutoPlayVIdeo] = useState(0);
  const [blockShow, setBlockListShow] = useState(false);
  const [showGlobalSetting, setshowGlobalSetting] = useState(true);
  const [searchBlockUser, setSearchBlockUser] = useState("");
  const dispatch = useDispatch();
  const userBlockList = useSelector(
    (state) => state?.GCBlockUser?.globalSettingBlockList
  );
  const notificationForChatValue =
    getProfileDetails?.userProfileUpdatedetails?.data?.user_setting[11]
      ?.SettingValue;

  const autoPlayVIdeoValue =
    getProfileDetails?.userProfileUpdatedetails?.data?.user_setting[6]
      ?.SettingValue;


  useEffect(() => {
    if (notificationForChatValue !== undefined) {
      setNotificationForChat(notificationForChatValue);
    }
  }, [notificationForChatValue]);

  useEffect(() => {
    if (autoPlayVIdeoValue !== undefined) {
      setAutoPlayVIdeo(autoPlayVIdeoValue);
    }
  }, [autoPlayVIdeoValue]);

  return (
    <>
      {showGlobalSetting && (
        <div className="tab-pane fade show">
          <div className="user-chat-area">
            <div className="user_chatlist">
              <div className="chatlist-bar">
                <div className="chatlist-inner">
                  <div className="chatlist-header">
                    <div className="chatlist-title">
                      <h2>{title}</h2>
                    </div>
                  </div>
                  <div className="setting_tab_menu">
                    <ul>
                      <li>
                        <div className="setting_tab_box">
                          <span>
                            <img src={notifications} alt="notifications" />
                          </span>
                          <div className="setting-tab-content">
                            <strong>
                              <Link to="#">Notifications</Link>
                            </strong>
                            <div className="setting-check">
                              <div className="setting-check-tab">
                                <input
                                  type="checkbox"
                                  id="notificationForChat"
                                  className="same-check-setting"
                                  checked={
                                    notificationForChat == 1 ? true : false
                                  }
                                  onChange={() => {
                                    setNotificationForChat(
                                      notificationForChat ? 0 : 1
                                    );
                                    dispatch(
                                      updateGlobalSetting({
                                        SettingID:
                                          getProfileDetails
                                            ?.userProfileUpdatedetails?.data
                                            ?.user_setting[11]?.SettingID,
                                        CustomValue: notificationForChat
                                          ? 0
                                          : 1,
                                      })
                                    );
                                  }}
                                />

                                <label htmlFor="notificationForChat">
                                  <span />
                                  Notifications for chats
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                        <div className="setting_tab_box">
                          <span>
                            <img src={dataIcon} alt="data-icon" />
                          </span>
                          <div className="setting-tab-content">
                            <strong>
                              <Link to="#">Data and Storage</Link>
                            </strong>
                            <div className="setting-check"> */}
                              {/* <div className="setting-check-tab">
                                <input
                                  type="checkbox"
                                  id="autoPlayGIF"
                                  className="same-check-setting"
                                  checked={autoPlayGIF == 1 ? true : false}
                                  onChange={() => {
                                    setAutoPlayGIF(autoPlayGIF ? 0 : 1);
                                    dispatch(
                                      updateGlobalSetting({
                                        SettingID:
                                          getProfileDetails
                                            ?.userProfileUpdatedetails?.data
                                            ?.user_setting[6]?.SettingID,
                                        CustomValue: autoPlayGIF ? 0 : 1,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="autoPlayGIF">
                                  <span />
                                  Autoplay GIF
                                </label>
                              </div> */}
                              {/* <div className="setting-check-tab">
                                <input
                                  type="checkbox"
                                  id="autoPlayVIdeo"
                                  className="same-check-setting"
                                  checked={autoPlayVIdeo == 1 ? true : false}
                                  onChange={() => {
                                    setAutoPlayVIdeo(autoPlayVIdeo ? 0 : 1);
                                    dispatch(
                                      updateGlobalSetting({
                                        SettingID:
                                          getProfileDetails
                                            ?.userProfileUpdatedetails?.data
                                            ?.user_setting[6]?.SettingID,
                                        CustomValue: autoPlayVIdeo ? 0 : 1,
                                      })
                                    );
                                  }}
                                />
                                <label htmlFor="autoPlayVIdeo">
                                  <span />
                                  Autoplay Video
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li> */}
                      <li>
                        <div className="setting_tab_box">
                          <span>
                            <img src={blocklist} alt="block-list" />
                          </span>
                          <div className="setting-tab-content">
                            <strong>
                              <Link
                                to="#"
                                onClick={() => {
                                  setBlockListShow(true);
                                  setshowGlobalSetting(false);
                                  dispatch(
                                    globalSettingBlockList(searchBlockUser)
                                  );
                                }}
                              >
                                Block List
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="setting_tab_box">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M21.9414 8.73135C22.4718 8.73135 22.9805 8.94206 23.3556 9.31713C23.7307 9.69221 23.9414 10.2009 23.9414 10.7313V14.7313C23.9414 15.2618 23.7307 15.7705 23.3556 16.1456C22.9805 16.5206 22.4718 16.7313 21.9414 16.7313H20.8794C20.6356 18.6646 19.6947 20.4425 18.2332 21.7313C16.7716 23.0202 14.89 23.7313 12.9414 23.7313V21.7313C14.5327 21.7313 16.0588 21.0992 17.184 19.974C18.3093 18.8488 18.9414 17.3226 18.9414 15.7313V9.73135C18.9414 8.14005 18.3093 6.61392 17.184 5.48871C16.0588 4.36349 14.5327 3.73135 12.9414 3.73135C11.3501 3.73135 9.82398 4.36349 8.69877 5.48871C7.57355 6.61392 6.94141 8.14005 6.94141 9.73135V16.7313H3.94141C3.41097 16.7313 2.90227 16.5206 2.52719 16.1456C2.15212 15.7705 1.94141 15.2618 1.94141 14.7313V10.7313C1.94141 10.2009 2.15212 9.69221 2.52719 9.31713C2.90227 8.94206 3.41097 8.73135 3.94141 8.73135H5.00341C5.24744 6.79824 6.18849 5.02062 7.64998 3.73202C9.11147 2.44342 10.993 1.73242 12.9414 1.73242C14.8899 1.73242 16.7713 2.44342 18.2328 3.73202C19.6943 5.02062 20.6354 6.79824 20.8794 8.73135H21.9414ZM8.70141 16.5163L9.76141 14.8203C10.7145 15.4174 11.8168 15.7332 12.9414 15.7313C14.066 15.7332 15.1683 15.4174 16.1214 14.8203L17.1814 16.5163C15.9107 17.3125 14.4409 17.7337 12.9414 17.7313C11.4419 17.7337 9.97215 17.3125 8.70141 16.5163Z"
                                fill="#FCC706"
                              />
                            </svg>
                          </span>
                          <div className="setting-tab-content">
                            <strong>
                              <Link
                                to="/contact"
                                onClick={() => setSavedMesssageState(false)}
                              >
                                Contact Us
                              </Link>
                            </strong>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="setting_tab_box">
                          <span>
                            <img src={accountSetting} alt="account-setting" />
                          </span>
                          <div className="setting-tab-content">
                            <strong>
                              <Link to="#">Account Setting</Link>
                            </strong>
                            <div className="account-link">
                              <Link
                                to="/edit-profile"
                                onClick={() => setSavedMesssageState(false)}
                              >
                                Edit Profile
                              </Link>
                              <Link
                                to="/change-password"
                                onClick={() => setSavedMesssageState(false)}
                              >
                                Change Password
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {blockShow && (
        <div className="user-chat-area">
          <div className="user_chatlist">
            <div className="chatlist-bar">
              <div className="chatlist-inner">
                <div className="chatlist-header">
                  <div className="chatlist-title">
                    <h2>Block List</h2>
                    <span
                      className="toggle-setting-close same-close-bar global_setting_blocklist"
                      onClick={() => {
                        setBlockListShow(false);
                        setshowGlobalSetting(true);
                      }}
                    >
                      <i className="fa-solid fa-xmark" />
                    </span>
                  </div>
                  <div className="chatlist-searchbar">
                    <div className="group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchBlockUser}
                        onChange={(e) => setSearchBlockUser(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="chatlist-body">
                  <div className="chatlist_sec recent_sec">
                    <ul className="block-list-unblock">
                      {userBlockList?.data?.list.length > 0 ? (
                        userBlockList?.data?.list?.map((list, index) => {
                          return (
                            <li key={index}>
                              <div className="user_chatlist_blog user_block_list">
                                <figure>
                                  <img src={list?.ProfileImage} />
                                </figure>
                                <div className="user_chatlist_content">
                                  <strong>{`${list?.Firstname} ${list?.Lastname}`}</strong>
                                  <p>{`${list?.groupChannel?.Name.slice(
                                    0,
                                    50
                                  )}`}</p>
                                  <a
                                    className="unlock-btn"
                                    onClick={() =>
                                      dispatch(
                                        globalBlockListUnblockMemberStatus({
                                          GCMemberID: list?.GCMemberID,
                                          GroupChannelID:
                                            list?.groupChannel?.GroupChannelID,
                                        })
                                      )
                                    }
                                  >
                                    Unblock
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <p className="text-white d-flex justify-content-center mt-5">
                          No Block User...
                        </p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SettingChat);
