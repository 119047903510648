import React, { useEffect, useState } from "react";
import ChatContent from "../../Component/chatContent";
import ChatLeft from "../../Component/chatLeft";
import Sidebar from "../../Component/sidebar";
import {
  groupChannelDetailsData,
  setInHome,
} from "../../reducer/DetailsChannelGroup";
import { useDispatch, useSelector } from "react-redux";
import { emmitToSocket } from "../../common";
import { Bars } from "react-loader-spinner";
import {
  Description,
  FullChat,
  GCChatlistData,
  GroupHeading,
  SubscriptionCount,
  UpdatedList,
  getGroupChannelId,
} from "../../reducer/GetChat";
import { allPinMessages } from "../../reducer/PinMessage";
import { storeProfilePicUrl } from "../../reducer/MentorReducer";
const Users = () => {
  const [show, setShow] = useState(false);
  const [PinMessageState, setPinMesssageState] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [updateReactionListed, setUpdateReactionListed] = useState([]);
  const [savedMessageState, setSavedMesssageState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const UserID = localStorage.getItem("UserID");
  const [page, setPage] = useState(1);
  const chatLeftShowList = useSelector(
    (state) => state?.GetChat?.upDatedlistData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    emmitToSocket("auth-user", { userId: UserID, filter: "mentor" }, (res) => {
      dispatch(UpdatedList(res?.data?.gcData));
      dispatch(storeProfilePicUrl(res?.data));
      setLoading(false);
    });
    dispatch(setInHome(false));
    dispatch(Description(""));
    dispatch(GroupHeading(""));
    dispatch(FullChat([]));
    dispatch(SubscriptionCount(""));
    dispatch(getGroupChannelId(""));
    dispatch(GCChatlistData(""));
    dispatch(groupChannelDetailsData(""));
    dispatch(allPinMessages([]));
  }, []);
  return (
    <>
      {loading ? (
        <Bars
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperClass="loader_style"
          visible={true}
        />
      ) : (
        <div className="d-flex main_body_inner">
          <Sidebar
            savedMessageState={savedMessageState}
            setSavedMesssageState={setSavedMesssageState}
          />
          <ChatLeft
            title="Users"
            chatLeftShowList={chatLeftShowList}
            setPinMesssageState={setPinMesssageState}
            show={show}
            setShow={setShow}
            PinMessageState={PinMessageState}
            page={page}
            setPage={setPage}
            stage={"users"}
            showCheckbox={showCheckbox}
            setShowCheckbox={setShowCheckbox}
            selectedMessage={selectedMessage}
            setSelectedMessage={setSelectedMessage}
            setUpdateReactionListed={setUpdateReactionListed}
            updateReactionListed={updateReactionListed}
            savedMessageState={savedMessageState}
            setSavedMesssageState={setSavedMesssageState}
          />
          <ChatContent
            show={show}
            setShow={setShow}
            setPage={setPage}
            page={page}
            setPinMesssageState={setPinMesssageState}
            PinMessageState={PinMessageState}
            showCheckbox={showCheckbox}
            stage={"users"}
            setShowCheckbox={setShowCheckbox}
            selectedMessage={selectedMessage}
            setSelectedMessage={setSelectedMessage}
            setUpdateReactionListed={setUpdateReactionListed}
            updateReactionListed={updateReactionListed}
            savedMessageState={savedMessageState}
            setSavedMesssageState={setSavedMesssageState}
          />
        </div>
      )}
    </>
  );
};

export default Users;
