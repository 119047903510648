import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import {
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";
import { toast } from "react-toastify";
const authToken = localStorage.getItem("api_token_gtf");

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
  FULLFIll: "idle",
});
const initialState = {
  userprofiledetails: "",
  userUpdateProfilePicture: "",
};

// Create Slice
export const EditProfileReducer = createSlice({
  name: "EditProfileReducer",
  initialState,
  reducers: {
    userEditProfileDetails: (state, action) => {
      state.userprofiledetails = action.payload;
    },
    userUpdateProfilePic: (state, action) => {
      state.userUpdateProfilePicture = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const {
  userEditProfileDetails,
  userUpdateProfilePic,
  setStatus,
} = EditProfileReducer.actions;

export default EditProfileReducer.reducer;

// Update profile picture
export const updateUserProfilePicture = (payload) => {
  const { UserID, ProfilePic } = payload;
  const formData = new FormData();
  formData.append("UserID", UserID);
  formData.append("ProfilePic", ProfilePic);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));

    try {
      await baseUrlAuth
        .post(`update_profile_pic`, formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(userUpdateProfilePic(res?.data));
            dispatch(setStatus(STATUSES.LOADING));
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :-", error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// submit update profile
export const submitUpdateProfile = (payload) => {
  const {
    UserID,
    Email,
    Gender,
    FirstName,
    LastName,
    Phone,
    CountryID,
    StateID,
    CityID,
    Pincode,
    DOB,
    Address,
    PhoneCode, find_us,
    navigate,
  } = payload;
  const formData = new FormData();
  formData.append("UserID", UserID);
  formData.append("Email", Email);
  formData.append("Gender", Gender);
  formData.append("FirstName", FirstName);
  formData.append("LastName", LastName);
  formData.append("Phone", Phone);
  formData.append("CountryID", CountryID);
  formData.append("StateID", StateID);
  formData.append("CityID", CityID);
  formData.append("Pincode", Pincode);
  formData.append("DOB", DOB);
  formData.append("Address", Address);
  formData.append("PhoneCode", PhoneCode);
  formData.append("find_us", find_us);
  return async () => {
    try {
      baseUrlAuth
        .post("updateprofile", formData)
        .then((res) => {
          if (res?.data?.status === 200) {
            toast.success(res?.data?.message);
            navigate("/");
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("Update user profile error :", error);
    }
  };
};
