import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  chatlistBg,
  user1,
  searchIcon,
  menuIcon,
  settingI,
  audioIocn,
  zipPreviewImg,
  msWorldZip,
  images5,
  unMuteIcon,
  muteIcon,
} from "../../Images/images";
import ClipLoader from "react-spinners/ClipLoader";
import { useState } from "react";
import { socket } from "../../store/SocketIo";
import { useDispatch, useSelector } from "react-redux";
import "reactjs-popup/dist/index.css";
import {
  addmessage,
  lastMessage,
  whichUserTyping,
  PrevChatData,
  lastMessageData,
  UpdatedList,
  FileUploadedDatas,
  fileUploadedBaseUrl,
  GCSettingShowModeCheckMessage,
  GCShowMediaFiles,
  LikeUpdate,
  chatlistDeletePost,
  FullChat,
  SubscriptionCount,
  GCChatlistData,
  LikeUpdateUserID,
} from "../../reducer/GetChat";
import SingleMessage from "./SingleMessage";
import { toast } from "react-toastify";
import {
  addPinMessage,
  allPinMessagess,
  removePinMessages,
  unPinAllMessages,
} from "../../reducer/PinMessage";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { FileUploadApi, STATUSES } from "../../reducer/FiieUploaded";
import { getEmoji } from "../../reducer/Reaction";
import "react-circular-progressbar/dist/styles.css";
import { Bars } from "react-loader-spinner";
import moment from "moment";

import {
  DetailsChannelGroupApi,
  joinExclusiveGC,
  leaveGroupFucntion,
  reJoinFunction,
} from "../../reducer/DetailsChannelGroup";

import {
  deleteSavedMessageeess,
  fetchGetSavedMessage,
  sendMediaFilesSavedMessages,
  sendSavedMessages,
} from "../../reducer/SavedMessagess";
import { ReactMic } from "react-mic";
import SavedMessage from "./SavedMessage";
import { GCBlockMemberList } from "../../reducer/GCMemberBlockUnblock";
import PinMessages from "./PinMessages";
import { getSubscribers } from "../../reducer/SubscriberReducer";
import { getDummyUser } from "../../reducer/DummyUserReducer";
import { confirmAlert } from "react-confirm-alert";
import { compareWithToday, emmitToSocket } from "../../common";

import { GCMuteNotificationUpdateUserSetting } from "../../reducer/GCMuteNotification";
import GallerySlider from "./GallerySlider";
import {
  chatList,
  pinMessageList,
  prevMentorChats,
  setTypingStatus,
  uploadMentorMeida,
} from "../../reducer/MentorReducer";
import PinMessageTop from "./PinMessageTop";
import MentorChats from "./MentorChats";
import QuoteMessage from "./QuoteMessage";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import { Modal } from "@mui/material";
import SubscriptionPlanShowPopup from "../chatLeft/SubscriptionPlanShowPopup";
import { getGroupChannelType, userID } from "../GlobalLocalStorage";
import { LoadingButton } from "@mui/lab";
import { setShowGlobalSearchSubscriptionPopup } from "../../reducer/notificationReducer";
import BrowserNotification from "../../common/browserNotification";
import { newMessageReceived } from "../../reducer/MessageReceived";
const ChatboxInner = ({
  setShow,
  ShowComment,
  show,
  setPage,
  page,
  stage,
  commentList,
  openComment,
  openSetting,
  setOpenSetting,
  openProfile,
  setOpenProfile,
  setOpenComment,
  setChannelAccess,
  setChannelDisscussion,
  setChannelSignedMsg,
  setChannelEnableManipulateViews,
  setChatHistoryStatus,
  setGroupAccess,
  setChannelSharingContent,
  setPinMesssageState,
  PinMessageState,
  showCheckbox,
  setShowCheckbox,
  selectedMessage,
  setSelectedMessage,
  userOpenComment,
  userOpenSetting,
  userOpenProfile,
  setUserOpenProfile,
  setUserOpenSetting,
  setUserOpenComment,
  setSelectMessageId,
  savedMessageState,
  setSavedMesssageState,
  firstChecked,
  setFirstChecked,
  gcReactionListData,
  searchMessage,
  setSearchMessage,
  selectedMentMessages,
  setSelectedMentMessages,
  selectMode,
  setSelectMode,
  showSearchPopup,
  setShowSearchPopup,
  setCommentAllowDiscussionPermission,
  quote,
  setQuote,
  updatedGCListAfterDeleteGC,
  setShowMenu,
  showMenu,
  setSavedMessageForwordConditon,
  handleFindeMessage,
  setAllowDisscussionUpdatePermission,
  allowDisscussionUpdatePermission,
  setSelectedDummyUser,
  selectedDummyUser,
  setUpdateSettingGC,
  setTagUsersComment,
  settagUserImgUrl,
  tagUserImgUrl,
}) => {
  const UserID = localStorage.getItem("UserID");
  const [showDownArrow, setShowDownArrow] = useState(false);
  const [text, setText] = useState("");
  const contextMenuRef = useRef(null);
  const [quoteMessage, setQuoteMessage] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [reply, setReply] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableSendMesaage, setDisableSendMesaage] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [mediaFilesShow, setMediaFilesShow] = useState(false);
  const [mediaFilesShowChat, setMediaFilesShowChat] = useState(false);
  const [showReactionCountPopup, setShowReactionCountPopup] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showLeaveGCButton, setShowLeaveGCButton] = useState(false);
  const [checked, setChecked] = useState(false);
  const [getPinMessageID, setgetPinMessageID] = useState();
  const [likeItem, setLikeItem] = useState({});
  const connectid = localStorage.getItem("gtfconnectid");
  // get user rolID
  const roleID = localStorage.getItem("roleID");
  const [notification, setNotification] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [editData, setEditData] = useState({});
  const [savedMessgedGetData, setSavedMessgedGetData] = useState({});
  const [tagUsers, setTagUsers] = useState([]);
  const [filteredUsers, setFilterdUsers] = useState(tagUsers);
  const [isTagged, setIsTagged] = useState(false);
  const [mentQuote, setMentQuote] = useState(null);
  const [showmentQuoted, setShowMentQuoted] = useState(false);
  const [isMessageEdit, setIsMessageEdit] = useState(false);
  const [mentorEditData, setMentorEditData] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioChunks, setAudioChunks] = useState(null);
  const [mentorMedia, setMentorMedia] = useState([]);
  const [showTagUsers, setShowTagUsers] = useState(false);
  const [leaveGCState, setLeaveGCState] = useState(false);
  const [showGallary, setShowGallary] = useState(false);
  const [gCLikeUnlike, setGCLikeUnlike] = useState();
  const [dummyUserObj, setDummyUserObj] = useState({});
  const [gallary, setGallary] = useState([]);
  const [emojiList, setEmojijList] = useState([]);
  const [gCSearchMessage, setGCSearchMessage] = useState("");
  const [storeGCUpdateTimeDay, setStoreGCUpdateTimeDay] = useState();
  const [mentorChatGroup, setMentorChatGroup] = useState([]);

  const newMessage = useSelector(state => state?.messagereceived);

  const [
    addLastMessageClassMouseRightClickShowPopup,
    setAddLastMessageClassMouseRightClickShowPopup,
  ] = useState(false);
  // handle detalis api call many times
  const [handleApiCallMade, setHandleApiCallMade] = useState(false);
  // subscription plan show popup
  // const [
  //   showGlobalSearchSubscriptionPopup,
  //   setShowGlobalSearchSubscriptionPopup,
  // ] = useState(false);
  const { showGlobalSearchSubscriptionPopup } = useSelector(
    (state) => state?.notificationReducer
  );
  const chat = useSelector((state) => state?.GetChat?.Chats);
  // ref
  const MessageRef = useRef(null);

  const emojiPickerRef = useRef(null);

  const focusRef = useRef(null);

  // GC info check permission
  const gcSettingPermissionData = JSON.parse(
    localStorage.getItem("GCPermissonCustmValue")
  );
  const userRoleSlugSettingPermission = localStorage.getItem("user_role_slug");
  // get group channel type
  const getCheckGcType = localStorage.getItem("paymentGCType");
  const chatLeftShowList = useSelector(
    (state) => state?.GetChat?.upDatedlistData?.gcData?.[0]?.group?.Type
  );
  const mentorListProfilePicUrl = useSelector(
    (state) => state?.GetChat?.upDatedlistData
  );
  // getLastmessage for group/channel
  const getLastMessageAddClassName =
    chat?.length >= 5 && chat[chat?.length - 1];

  // Subscription plan store days
  const [subscriptionPlanTotalDays, setSubscriptionPlanTotalDays] = useState();

  const Type = useSelector(
    (state) => state?.GetChat?.groupinformation?.group?.Type
  );
  const memberID = useSelector((state) => state?.GetChat?.GCMemberID);
  const groupinformation = useSelector(
    (state) => state?.GetChat?.groupinformation
  );
  const exclusiveSubscribeOfferCheckResponseStatus = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.status
  );

  const groupHeading = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData?.gc_info?.Name
  );
  const ExclusiveSendMsgGCMemberID = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData
  );
  const pinMessage = useSelector(
    (state) => state?.allPinMessage?.allPinMessage
  );
  const fileUploadDataStatus = useSelector(
    (state) => state?.FileUploaded?.status
  );

  const selectedEmojiList = useSelector(
    (state) => state?.GCReactionList?.selectedEmojiList
  );
  const groupChannelDetails = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.groupChannelData
  );
  const muteNotificationResult = useSelector(
    (state) => state?.GCMuteNotification?.GCMuteNotificationData
  );
  const mentDetails = useSelector((state) => state?.MentorList?.details);
  const mentorChats = useSelector((state) => state?.MentorList?.mentorChatList);
  const currentChat = useSelector((state) => state?.currentChatBoxInfo);
  const mentorPinMessage = useSelector(
    (state) => state?.MentorList?.mentorPinMessages
  );
  // fetchSavedMessageDataStatus
  const fetchSavedMessageDataStatus = useSelector(
    (state) => state?.SavedMessagess?.status
  );

  // handle renew subscription plan
  const [showSubscriptionMessage, setShowSubscriptionMessage] = useState();
  useEffect(() => {
    const delay = setTimeout(() => {
      setShowSubscriptionMessage(
        `Your Plan Will Expire In ${subscriptionPlanTotalDays} Days. Please Click for renew`
      );
    }, 500);
    return () => clearTimeout(delay);
  }, [showSubscriptionMessage, subscriptionPlanTotalDays]);

  const GCMuteNotificationData =
    groupChannelDetails?.gc_member_setting?.mute_notification;
  const gcPermissionPinMessage = groupChannelDetails?.gc_permission;
  const gcPermissionSetting = groupChannelDetails?.gc_setting;
  const GroupChannelIDfromapi = groupChannelDetails?.gc_info?.GroupChannelID;
  const GcMemberId = groupChannelDetails?.gc_member_info?.GCMemberID;
  const GcMemberCheckBlockStatus = groupChannelDetails?.gc_member_info?.Status;
  const GcMemberCheckExclusivekStatus = groupChannelDetails;
  const GCType = groupChannelDetails?.gc_info?.Type;
  const GroupChannelID = useSelector((state) => state?.GetChat?.channelgroupid);
  const GCMemberSubscriptionPlan =
    groupChannelDetails?.gc_member_subscription_plan?.isExpired;
  const GCMemberInfo = groupChannelDetails?.gc_member_info;

  const GCSettingSharingContent = groupChannelDetails?.gc_permission?.SlowMode;
  const GCMemberSubscriptionPlanNull =
    groupChannelDetails?.gc_member_subscription_plan;
  const inHome = useSelector(
    (state) => state?.UpdateChannelGroupReducer?.inHome
  );
  const channelAccessType = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_info?.AccessType
  );

  const getGroupChannelId = useSelector(
    (state) => state?.GetChat?.channelgroupid
  );

  // Mentor section profile pic url
  const mentorProfilePicUrl = useSelector(
    (state) => state?.MentorList?.mentorProfilePicUrl
  );

  const mentorCheckUserRole = useSelector(
    (state) => state?.MentorList?.storeMenoteChatInitial
  );
  // check mentor and user role and change chatlist design like hide notification and setting icon
  const checkMentorRole = useSelector(
    (state) => state?.MentorList?.checkUserRole
  );

  const channelAllowDisscussion = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.AllowDiscussion
  );
  const channelSignedMessage = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting?.SignedMsg
  );
  const EnableManipulateViewschannel = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.EnableManipulateViews
  );
  const ChatHistoryStatus = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.ChatHistoryIsEnable
  );
  const sharingContent = useSelector(
    (state) =>
      state?.UpdateChannelGroupReducer?.groupChannelData?.gc_setting
        ?.RestrictSharingContent
  );

  const dummyUsers = useSelector((state) => state?.DummyUserReducer?.dummyUser);
  const GCSettingShowMode = useSelector(
    (state) => state?.GetChat?.GCSetttingShowMode?.data?.CreatedAt
  );
  const fetchSavedMessageData = useSelector(
    (state) => state?.SavedMessagess?.getSavedMessage
  );
  const { userRole } = useSelector((state) => state?.UserData);
  //mentor || student typing
  const { typing } = useSelector((state) => state?.MentorList);
  const chatConversationID = useSelector(
    (state) => state?.MentorList?.ConversationID
  );

  // Group / Channel chatlist media url
  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);

  // Group / Channel chatlist handle date according to day message
  moment.updateLocale("en", {
    weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  });
  const formatChat = (chatData) => {
    const groups = chatData?.reduce((groups, game) => {
      let date = game?.CreatedAt?.split("T")[0];
      const today = moment(new Date()).format("DD/MM/YYYY");
      const yesterday = moment().subtract(1, "day").format("DD/MM/YYYY");
      if (date === today) {
        date = "Today";
      } else if (date === yesterday) {
        date = "Yesterday";
      }
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        messageArray: groups[date],
      };
    });
    return groupArrays;
  };

  //. Subscription plan store days
  const subscriptionEndDate = new Date(
    groupChannelDetails?.gc_member_subscription_plan?.SubscriptionEndDate
  );

  const currentDate = new Date();
  useEffect(() => {
    const timeDifference = subscriptionEndDate - currentDate; // This gives the time difference in milliseconds
    const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    setSubscriptionPlanTotalDays(totalDays);
  }, [subscriptionEndDate]);

  //. Subscription plan store days
  const getLastMessageAddClassNameMentor =
    mentorChats?.length >= 5 && mentorChats[mentorChats?.length - 1];
  useEffect(() => {
    if (chat?.length > 0) {
      setMentorChatGroup([]);
      const groupedChat = formatChat(chat);
      setStoreGCUpdateTimeDay(groupedChat);
    } else {
      setStoreGCUpdateTimeDay([]);
    }
  }, [chat]);

  useEffect(() => {
    if (mentorChats?.length > 0) {
      setStoreGCUpdateTimeDay();
      const groupedChat = formatChat(mentorChats);
      setMentorChatGroup(groupedChat);
    } else {
      setMentorChatGroup([]);
    }
  }, [mentorChats]);

  useEffect(() => {
    chat?.map((item) => setAudioFiles(item?.media));
  }, []);

  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const givenTime = Math.floor(
    new Date(
      GCSettingShowMode === undefined ? 0 : GCSettingShowMode
    ).getTime() / 1000
  ); // Given time in seconds
  const slowModeTimeDifference = currentTime - givenTime; // Difference in seconds
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const closeEmoji = useRef(null);
  const updatedFiles = useRef(null);
  const hiddenFileInput = useRef();
  const emojiref = useRef();
  const input = useRef(null);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#2fb463",
  };
  const [positionXX, setPositionXX] = useState();
  const maxX = window.innerWidth - contextMenuRef.current?.offsetWidth;
  const maxY = window.innerHeight - contextMenuRef.current?.offsetHeight;

  const newTimeChat = chat?.map((item) =>
    new Date(item?.CreatedAt).toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
    })
  );

  // Mentor all pin message list update

  // useEffect(() => {
  //   mentorPinMessage?.allPin?.length > 0
  //     ? localStorage.setItem("mentorAllPinMessageList", false)
  //     : localStorage.setItem("mentorAllPinMessageList", true);
  // }, [mentorPinMessage?.allPin]);

  // hanlde GC desscussion
  const handleGCDisscussion = (data) => {
    socket.emit("allowPostDiscussion", {
      UserID: data?.UserID,
      GroupChannelID: data?.GroupChannelID,
      GroupChatID: data?.GroupChatID,
      isAdmin: userRoleSlugSettingPermission == "super-admin" ? 1 : 0,
      AllowDiscussion: data?.AllowDiscussion == 1 ? 0 : 1,
    });
    setShowMenu(false);
  };

  // update allowDisscussion data
  useEffect(() => {
    socket.on("allowDiscussionStatus", (res) => {
      setAllowDisscussionUpdatePermission(
        res?.data?.allowDiscussionStatus?.chatDetail
      );
      socket.emit(
        "chatList",
        {
          userId: res?.data?.allowDiscussionStatus?.chatDetail?.UserID,
          GroupChannelID:
            res?.data?.allowDiscussionStatus?.chatDetail?.GroupChannelID,
          page: page,
        },
        (response) => {
          dispatch(FullChat(response?.data?.chatData?.rows));
        }
      );
    });
  }, []);

  // Group/Channel chatlist text copy
  async function handleCopy(text) {
    if( text?.Message?.length > 0 ) {
      try {
        await navigator.clipboard.writeText(text?.Message);
        toast.success("Message Copied !!")
      } catch (error) {
        toast.error("Message Not Copied !!");
      }
    }  else {
      toast.error("No Text Message !!");
    }      
  }

  // Saved message in chatlist Text copy
  function handleSavedMessageCopyText(text) {
    toast.success("Message Copied !!");
    navigator.clipboard.writeText(text?.Content);
  }

  // handle quote message
  const handleQuote = (item) => {
    setQuoteMessage(item);
    setReply(true);
    input.current.focus();
  };

  useEffect(() => {
    if (openSetting) {
      dispatch(
        getSubscribers({
          subspage: 1,
          channelGroupID: groupinformation?.GroupChannelID,
        })
      );
    }
  }, [openSetting]);

  // send like/unlike reaction module
  useEffect(() => {
    socket.emit("like", {
      GCMemberID: groupinformation?.GCMemberID,
      GroupChannelID: groupinformation?.GroupChannelID,
      UserID: connectid,
      isLike: gCLikeUnlike,
    });
  }, [gCLikeUnlike]);

  // Handle searching chat
  useEffect(() => {
    socket.emit("searchingChat", {
      searchKey: gCSearchMessage,
      GroupChannelID: groupinformation?.GroupChannelID,
      userId: connectid,
    });
  }, [gCSearchMessage]);

  // Store notification
  const storeMuteNotificationValue = (value) => {
    if (GCMuteNotificationData?.Value === 1) {
      dispatch(
        GCMuteNotificationUpdateUserSetting({
          SettingID: GCMuteNotificationData?.SettingID,
          CustomValue: 0,
          GCMemberID: GCMuteNotificationData?.GCMemberID,
          SubValue: 0,
          setShowNotificationPopup,
        })
      );
    } else {
      dispatch(
        GCMuteNotificationUpdateUserSetting({
          SettingID: GCMuteNotificationData?.SettingID,
          CustomValue: 1,
          GCMemberID: GCMuteNotificationData?.GCMemberID,
          SubValue: value,
          setShowNotificationPopup,
        })
      );
    }
    setNotification(value);
    if (muteNotificationResult?.status === 200) {
      dispatch(DetailsChannelGroupApi(GroupChannelID));
    }
  };

  // Handle Group channel send message keybord key ENTER send message
  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };
  // Handle send message in group and channel and mentor chatlist
  const handleSubmit = async () => {
    if (
      userRoleSlugSettingPermission === "super-admin" ||
      gcSettingPermissionData?.full_permission == 1 ||
      slowModeTimeDifference > GCSettingSharingContent ||
      mentDetails
    ) {
      // Group/Channel send media files
      if (audioChunks && !mentDetails) {
        dispatch(
          FileUploadApi({
            DummyUserID: selectedDummyUser?.DummyUserID,
            GCMemberID: groupinformation?.GCMemberID,
            GroupChannelID: groupinformation?.GroupChannelID,
            audioChunks,
            Message: formatUserMentions(text),
          })
        );
      } else if (selectedFiles?.length > 0 && !mentDetails) {
        dispatch(
          FileUploadApi({
            DummyUserID: selectedDummyUser?.DummyUserID,
            GCMemberID: groupinformation?.GCMemberID,
            GroupChannelID: groupinformation?.GroupChannelID,
            Files: selectedFiles,
            Message: formatUserMentions(text),
          })
        );
      }

      // mentor send audio record fils
      else if (mentDetails && audioChunks) {
        let body = {
          ConversationID: chatConversationID,
          Content: text,
          SenderId: localStorage.getItem("gtfconnectid"),
          recipientId: mentDetails?.UserID,
          MediaIds: "",
          ChatType: "msg",
        };
        console.log("media", body);
        const res = await uploadMentorMeida({
          ConversationID: chatConversationID,
          audioChunks,
        });
        if (res) {
          body = {
            ...body,
            ChatType: "file",
            MediaIds: res,
          };
          emmitToSocket("sendMentorMessage", body);
        }
      } else {
        let data;
        // Handle send message text null
        if (mentorMedia?.length === 0 && text?.trim() === "") {
          toast.error("Please Enter Some Text");
        }
        // Dummy user send message in group
        else if (Object.keys(selectedDummyUser)?.length !== 0) {
          const data = {
            DummyUserID: selectedDummyUser?.DummyUserID,
            UserID: connectid,
            GCMemberID: groupinformation?.GCMemberID,
            GroupChannelID: groupinformation?.GroupChannelID,
            Files: selectedFiles,
            Message: formatUserMentions(text),
          };
          if (reply) {
            data.GroupChatRefID = quoteMessage.GroupChatID;
          }
          socket.emit("sendMessage", data);
        }
        // Mentor module
        else if (mentDetails) {
          let body = {
            ConversationID: chatConversationID,
            Content: text,
            SenderId: localStorage.getItem("gtfconnectid"),
            recipientId: mentDetails?.UserID,
            MediaIds: "",
            ChatType: "msg",
          };

          if (mentorMedia?.length > 0) {
            // setMediaLoading(true);
            const res = await uploadMentorMeida({
              ConversationID: chatConversationID,
              files: mentorMedia,
            });

            if (res) {
              body = {
                ...body,
                ChatType: "file",
                MediaIds: res,
              };
            }
          }

          if (showmentQuoted) {
            body = {
              ...body,
              ChatRefID: mentQuote?.IndividualChatID,
            };
          }

          setShowMentQuoted(false);
          emmitToSocket("sendMentorMessage", body);
          setMentorMedia([]);
          input?.current?.blur();
          // setAudioChunks("")
        } else {
          // Send message in group/Channel
          data = {
            UserID: connectid,
            GCMemberID:
              groupinformation?.GCMemberID ||
              ExclusiveSendMsgGCMemberID?.gc_member_info?.GCMemberID ||
              GCMemberInfo?.GCMemberID,
            GroupChannelID:
              groupinformation?.GroupChannelID ||
              ExclusiveSendMsgGCMemberID?.gc_member_info?.GroupChannelID ||
              localStorage.getItem("GroupChannelID"),
            Message: formatUserMentions(text),
            Files: selectedFiles,
          };

          if (reply) {
            data.GroupChatRefID = quoteMessage.GroupChatID;
          }
        }
        socket.emit("sendMessage", data);
        dispatch(lastMessage(text));
        setReply(false);
        // For typing
        socket.emit("typing", {
          userId: connectid,
          GroupChannelID: GroupChannelID,
          type: "end",
        });

        // setSelectedDummyUser({});
      }
      // user last message updated
      socket.emit(
        "userLastMessageTime",
        {
          GCMemberID: groupinformation?.GCMemberID,
          GroupChannelID: GroupChannelID,
          UserID: connectid,
        },
        (response) => {
          dispatch(GCSettingShowModeCheckMessage(response?.data));
        }
      );
    } else {
      // When admin in chatlist fix send message time
      alert(`Unable to send message for a time duration!`);
      // alert(
      //   `Wait for send message ${
      //     slowModeTimeDifference - GCSettingSharingContent
      //   } Seconds`
      // );
    }

    setQuoteMessage({});
    setShowEmoji(false);
    setText("");
    setSelectedFiles([]);
    setAudioChunks("");
  };

  // show GC media files
  const showGCMediaFiles = () => {
    socket.emit(
      "mediaListing",
      { GroupChannelID: GroupChannelID, filter: "file" },
      (res) => {
        dispatch(GCShowMediaFiles(res?.data));
      }
    );
  };
  // Saved message Handle send message
  const SavedMessageHandleSubmit = () => {
    if (text?.length > 0) {
      dispatch(sendSavedMessages(text));
    } else if (selectedFiles?.length > 0) {
      dispatch(sendMediaFilesSavedMessages(selectedFiles));
    } else {
      toast.error("Please Enter Some Text");
    }
    setText("");
    setSelectedFiles([]);
  };

  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setShowMenu(true);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current) return;
    const container = containerRef.current;
    const dx = e.movementX;
    const dy = e.movementY;
    container.scrollLeft -= dx;
    container.scrollTop -= dy;
    setScrollPos({ x: container.scrollLeft, y: container.scrollTop });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setShowMenu(false);
  };
  // ---------------------------------for get input field value-------------------------------------------
  const filterUserList = (inputValue) => {
    return tagUsers?.filter(
      (user) =>
        user?.Firstname?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        user?.Lastname?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  // Function to split the message by comma and format user mentions
  const formatUserMentions = (message) => {
    if (message === "") {
      return;
    }
    const parts = message?.split(/~(.*?)~/g); // Split the message by tildes to get parts

    const formattedParts = parts?.map((part, index) => {
      if (index % 2 === 1) {
        // Odd-indexed parts (i.e., the username parts) should be processed
        const nameParts = part?.trim()?.split(" ");
        const Lastname = nameParts?.pop();
        const Firstname = nameParts?.join(" ");
        const user = tagUsers?.find((u) =>
          u?.Firstname?.toLowerCase() === ""
            ? u?.Lastname?.toLowerCase()
            : (u?.Firstname?.toLowerCase() === u?.Firstname?.toLowerCase()) ===
              ""
            ? u?.Lastname?.toLowerCase()
            : u?.Firstname?.toLowerCase() &&
              (u?.Lastname ||
                u?.Lastname?.toLowerCase() === Lastname?.toLowerCase())
        );
        return user
          ? `@{${Firstname === "" ? "" : Firstname} ${Lastname}|${
              user?.UserID
            }|${user?.IsDummyUser ? 1 : 0}|${user?.DummyUserID}}`
          : `~${part}~`;
      } else {
        // Even-indexed parts (i.e., the message parts) should be returned as they are
        return part;
      }
    });

    return formattedParts?.join("");
  };

  //mentor stop typing
  function fireMentorStopTyping() {
    let body = {
      userId: localStorage.getItem("gtfconnectid"),
      ConversationID: chatConversationID,
      type: "end",
    };
    emmitToSocket("mentorTyping", body);
  }

  const handlechange = (data) => {
    const inputValue = data?.target?.value;
    if (!inputValue) {
      setFilterdUsers(tagUsers);
      setShowTagUsers(false);
      setIsTagged(false);
    } else if (!mentDetails && inputValue?.includes("@")) {
      const searchTerm = inputValue
        ?.slice(inputValue?.lastIndexOf("@") + 1)
        ?.trim(); // Extract search term after '@' character
      const updatedList = filterUserList(searchTerm);
      setFilterdUsers(updatedList);
      setShowTagUsers(true);
      setIsTagged(true);
    } else {
      setShowTagUsers(false);
      setIsTagged(false);
    }

    // setText(data);

    setText(inputValue);
    let body = mentDetails
      ? {
          userId: localStorage.getItem("gtfconnectid"),
          ConversationID: chatConversationID,
          type: "start",
        }
      : {
          userId: connectid,
          GroupChannelID: GroupChannelID,
          type: "start",
        };
    socket.emit(mentDetails ? "mentorTyping" : "typing", body);
    !mentDetails && typingFunction();
  };

  const handleUserClick = (user) => {
    setFilterdUsers(tagUsers);
    const userFullName = ` ~${user?.Firstname} ${
      user?.Lastname === null ? "" : user?.Lastname
    }~`;

    // Check if the user is already in the text
    if (text?.includes(userFullName)) {
      // User is already added in the text, return without making any changes
      return;
    }
    const updatedText = text?.replace(/@\S+\s?/g, ""); // Remove existing user tags
    let finalText = updatedText?.trim();
    if (finalText) {
      finalText += `${userFullName}`;
    } else {
      finalText = `${userFullName}`;
    }
    finalText = finalText?.replace(/@/g, ""); // Remove any remaining "@" characters

    setText(finalText.trim());
    setShowTagUsers(false);
    setTimeout(() => {
      setText((prevText) => prevText + " "); // Append a space at the end
      input.current.focus();
    }, 0);
  };

  // getLastmessage for PinMessage
  const getLastMessageAddClassNamePinMessage =
    data?.allPinData?.length >= 5 &&
    pinMessage?.data?.allPinData[pinMessage?.data?.allPinData?.length - 1];

  // Chat reation add
  const handleChatLike = (data) => {
    const { GroupChannelID, GroupChatID } = likeItem;
    const { GCMemberID } = memberID?.find(
      (item) => item?.group?.Name === groupHeading
    );
    let isLike =
      likeItem?.like?.[0]?.ReactionID === data?.ReactionID &&
      likeItem?.like?.[0]?.isLike === 1
        ? 0
        : 1;

    const body = {
      GCMemberID,
      GroupChannelID,
      GroupChatID,
      isLike,
      UserID: localStorage.getItem("gtfconnectid"),
      ReactionID: data?.ReactionID,
    };
    emmitToSocket("like", body);
  };

  // handle Group / Channel reaction count
  const handleEmojiCountResponse = (resp) => {
    let newArray = [];
    resp?.data?.forEach((emoji) => {
      const matchingReaction = selectedEmojiList?.find(
        (selectedEmoji) =>
          parseInt(selectedEmoji?.ReactionID) === parseInt(emoji?.ReactionID)
      );
      if (matchingReaction) {
        newArray.push({
          EmojiCode: matchingReaction?.EmojiCode,
          count: emoji?.count,
        });
      }
    });
    setEmojijList(newArray);
  };

  //fetch Reactions count
  const fetchTotalEmojiCount = () => {
    const { GroupChannelID, GroupChatID } = likeItem;
    let body = {
      GroupChannelID,
      GroupChatID,
      UserID: localStorage.getItem("gtfconnectid"),
    };
    emmitToSocket("getEmojiCount", body, handleEmojiCountResponse);
  };

  //mentor pin message event
  const addMentorMessageToPin = (data) => {
    const { ConversationID, IndividualChatID } = data;
    const filteredArray = mentorPinMessage?.allPin?.filter(
      (IndividualChatIDss) => {
        return (
          IndividualChatIDss?.messageData?.IndividualChatID === IndividualChatID
        );
      }
    );
    if (filteredArray?.length > 0) {
      toast.error("This message is already pinned.");
    } else {
      let body = {
        ConversationID,
        IndividualChatID,
        UserID: localStorage?.getItem("gtfconnectid"),
      };
      emmitToSocket("pinMentorMessage", body, (res) => {
        if (res?.success) {
          toast.success(res?.data?.data?.message);
          emmitToSocket(
            "allPinMentorMessage",
            { ConversationID, UserID: localStorage?.getItem("gtfconnectid") },
            (res) => {
              dispatch(pinMessageList(res?.data?.data?.message));
            }
          );
        }
      });
    }
  };

  // mentor unpin message
  const unpinMentorMessage = (data, IndividualChatID) => {
    const { ConversationID } = data;
    let body = {
      ConversationID,
      pinMessageMentorID: IndividualChatID,
      UserID: localStorage?.getItem("gtfconnectid"),
    };
    emmitToSocket("removePinMentorMessage", body, (res) => {
      if (res?.success) {
        toast.success(res?.data?.data?.message);
        emmitToSocket(
          "allPinMentorMessage",
          { ConversationID, UserID: localStorage?.getItem("gtfconnectid") },
          (res) => {
            dispatch(pinMessageList(res?.data?.data?.message));
          }
        );
      }
    });
  };

  // unpin all messages
  const unpinAllMentorMessage = (data) => {
    const { ConversationID } = data;
    let body = {
      ConversationID,
      UserID: localStorage?.getItem("gtfconnectid"),
    };
    emmitToSocket("removeAllPinMentorMessage", body, (res) => {
      if (res?.success) {
        toast.success(res?.data?.data?.message);
        // setPinMesssageState(false);
        setShowMenu(false);
        emmitToSocket(
          "allPinMentorMessage",
          { ConversationID, UserID: localStorage?.getItem("gtfconnectid") },
          (res) => {
            // setPinMesssageState(false);
            dispatch(pinMessageList(res?.data?.data?.message));
          }
        );
      }
    });
  };

  //delete mentor message
  const removeMentorMessage = (data) => {
    confirmAlert({
      title: "Are you sure?",
      message: "You want to delete this message",
      overlayClassName: "confirm_box_savedmessage_delete",
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            const { ConversationID, IndividualChatID } = data;
            let body = {
              ConversationID,
              IndividualChatID,
              UserID: localStorage?.getItem("gtfconnectid"),
            };
            emmitToSocket("deleteMentorMessage", body);
            if (inHome == true) {
              socket.emit("auth-user", { userId: UserID }, (response) => {
                dispatch(UpdatedList(response?.data));
              });
            } else {
              emmitToSocket(
                "auth-user",
                { userId: UserID, filter: "mentor" },
                (res) => {
                  dispatch(UpdatedList(res?.data?.gcData));
                }
              );
            }
            const tempData = selectedMentMessages?.filter((item) => 
            IndividualChatID !== item?.IndividualChatID
          )
          setSelectedMentMessages(tempData);
          if (!tempData || tempData?.length <= 0) setSelectMode(false);
            toast.success("Message Removed!!");
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            // setShowMenu(false);
          },
        },
      ],
    });
  };

  // edit mentor message
  const editMentorMessage = () => {
    const { ConversationID, IndividualChatID } = mentorEditData;
    if (text !== "") {
      let body = {
        ConversationID,
        IndividualChatID,
        Content: text,
        UserID: localStorage?.getItem("gtfconnectid"),
      };

      emmitToSocket("editMentorMessage", body);
      input.current.blur();
    }
  };

  const handleMentorMessageEdit = (data) => {
    setIsMessageEdit(true);
    setText(data?.Content);
    setMentorEditData(data);
  };

  //mentor quote
  const handleMentorQuote = (data) => {
    setShowMentQuoted(true);
    setMentQuote(data);
  };

  const handleQuoteMessage = (data) => {
    let find = mentorChats?.find(
      (item) => item?.IndividualChatID === data?.IndividualChatID
    );
    if (find) {
      setQuote(find);
    }
  };

  //mentor chat listner
  useEffect(() => {
    if (mentDetails) {
      socket.on("receiveMentorMessage", (res) => {
        let body = {
          userId: localStorage.getItem("UserID"),
          filter: "mentor",
        };

        // setMediaLoading(false);
        if (stage === "student") {
          body = {
            ...body,
            roleId: userRole?.primary?.role?.RoleID,
          };
        }
        if (inHome) {
          socket.emit("auth-user", { userId: UserID }, (resp) => {
            dispatch(UpdatedList(resp?.data));
          });
          
          if(res?.data?.sendMessage?.dataMentor?.ConversationID == currentChat?.data?.ConversationID){
            emmitToSocket(
              "readMentorMessage",
              {
                ConversationID:
                  res?.data?.sendMessage?.dataMentor?.ConversationID,
                UserID: connectid,
                RoleID: roleID,
              },
              (res) => {
                if (res.success) {
                  emmitToSocket("auth-user", { userId: UserID }, (res) => {
                    dispatch(UpdatedList(res?.data));
                  });
                }
              }
            );
          }

        } else {
          emmitToSocket("auth-user", body, (resp) => {
            dispatch(UpdatedList(resp?.data?.gcData));
          });

          if(res?.data?.sendMessage?.dataMentor?.ConversationID == currentChat?.data?.ConversationID){
            // console.log("oky2",res?.data?.sendMessage?.dataMentor?.ConversationID, currentChat?.data?.ConversationID)
            emmitToSocket(
              "readMentorMessage",
              {
                ConversationID:
                  res?.data?.sendMessage?.dataMentor?.ConversationID,
                UserID: connectid,
                RoleID: roleID,
              },
              (res) => {
                if (res.success) {
                  emmitToSocket("auth-user", body, (res) => {
                    dispatch(UpdatedList(res?.data?.gcData));
                  });
                }
              }
            );
          }

        }

        dispatch(
          chatList(
            [...mentorChats, res?.data?.sendMessage?.dataMentor].reverse()
          )
        );
      });
      socket.on("deleteMentorMessageUpdate", (res) => {
        const updatedChats = mentorChats?.filter(
          (mentChat) =>
            mentChat?.IndividualChatID !== res?.data?.IndividualChatID
        );

        dispatch(chatList(updatedChats.reverse()));
      });

      //edit mentor and quote message
      socket.on("editMentorMessageUpdate", (res) => {
        let body = {
          userId: localStorage.getItem("UserID"),
          filter: "mentor",
        };
        if (stage === "student") {
          body = {
            ...body,
            roleId: userRole?.primary?.role?.RoleID,
          };
        }
        let foundIndex = mentorChats?.findIndex(
          (item) =>
            item?.IndividualChatID ===
            res?.message?.dataMentor?.IndividualChatID
        );
        let foundQuoteIndex = mentorChats?.findIndex(
          (check) =>
            check?.quote?.IndividualChatID ===
            res?.message?.dataMentor?.IndividualChatID
        );
        let newArr = [...mentorChats];
        newArr[foundIndex] = {
          ...newArr[foundIndex],
          Content: res?.message?.dataMentor?.Content,
        };

        if (foundQuoteIndex >= 0) {
          newArr[foundQuoteIndex] = {
            ...newArr[foundQuoteIndex],
            quote: {
              ...newArr[foundQuoteIndex].quote,
              Content: res?.message?.dataMentor?.Content,
            },
          };
        }
        dispatch(chatList(newArr.reverse()));
        toast.success("Message Updated !!");
        setText("");
        setIsMessageEdit(false);
        setMentorEditData(null);
        emmitToSocket("auth-user", body, (resp) => {
          dispatch(UpdatedList(resp?.data?.gcData));
        });
      });

      //mentor typing listner
      socket.on("mentorUserTyping", (res) => {
        res?.type === "start" &&
        res?.userId !== localStorage?.getItem("gtfconnectid")
          ? dispatch(setTypingStatus(res))
          : dispatch(setTypingStatus(null));
      });

      return () => {
        socket.off("receiveMentorMessage");
        socket.off("deleteMentorMessageUpdate");
        socket.off("editMentorMessageUpdate");
        socket.off("mentorUserTyping");
      };
    }
  }, [socket, mentorChats]);

  // Subscription count upudate form admin panel
  // reJoin Group / Channel
  const reJoinGBtn = () => {
    socket.emit("updateSubscriptionCount", {
      GroupChannelID: GroupChannelID,
      GCMemberID: GcMemberId,
      UserID: connectid,
    });
    dispatch(
      reJoinFunction({
        GcMemberId: GcMemberId,
        GroupChannelID: GroupChannelID,
      })
    );
  };

  // Update subscription count
  useEffect(() => {
    const handleSubscriberCount = (res) => {
      if (res?.data?.message === "update Subscription count") {
        setUpdateSettingGC(true);
        dispatch(DetailsChannelGroupApi(GroupChannelID));
      }
    };
    socket.on("getSubscriberCount", handleSubscriberCount);
    return () => {
      socket.off("getSubscriberCount", handleSubscriberCount);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("getGcSetting", (response) => {
      if (
        response?.data?.GroupChannelID ==
        JSON.parse(localStorage.getItem("GroupChannelID"))
      ) {
        setHandleApiCallMade(true);
        handleApiCallMade &&
          dispatch(DetailsChannelGroupApi(response?.data?.GroupChannelID));
      }
      setTimeout(() => {
        if (updatedGCListAfterDeleteGC) {
          if (inHome == true) {
            socket.emit("auth-user", { userId: UserID }, (response) => {
              dispatch(UpdatedList(response?.data));
              setShow(false);
            });
          } else if (inHome == false && chatLeftShowList === "group") {
            socket.emit(
              "auth-user",
              { userId: UserID, filter: "group" },
              (response) => {
                dispatch(UpdatedList(response?.data));
                setShow(false);
              }
            );
          } else if (inHome == false && chatLeftShowList === "channel") {
            socket.emit(
              "auth-user",
              { userId: UserID, filter: "channel" },
              (response) => {
                dispatch(UpdatedList(response?.data));
                setShow(false);
              }
            );
          }
        }
      }, 1000);
    });
  }, [socket, updatedGCListAfterDeleteGC, handleApiCallMade]);

  // handle add emoji in Group / Channel
  const addEmoji = (e) => {
    if (text === undefined) {
      setText(e.native);
    } else {
      setText(text + e.native);
    }
  };
  useEffect(() => {
    if (!selectedFile) {
      setText(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setText(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFiles.length > 10) {
      setMediaFilesShow(true);
      setMediaFilesShowChat(true);
    } else if (selectedFiles.length <= 10) {
      setMediaFilesShow(false);
    }
  }, [selectedFiles]);

  // --------------------------------------for received message start------------------------------------
  useEffect(() => {
    socket.on("messageReceived", (resp) => {
      // if(resp?.saveMsg?.getData?.GroupChannelID !== groupChannelDetails?.gc_info?.GroupChannelID && groupChannelDetails?.gc_member_info?.Status == "Active"){
      // BrowserNotification({
      //   title:"New Message Received", 
      //   body:`${resp?.saveMsg?.getData?.user?.Firstname || 'unknown'} : ${resp?.saveMsg?.getData?.Message}`
      // })
      // }
      const checkMessageReceivedGCID =
        resp?.saveMsg?.getData?.GroupChannelID ===
        JSON.parse(localStorage.getItem("GroupChannelID"));
      if (checkMessageReceivedGCID) {
        dispatch(addmessage(resp?.saveMsg?.getData));
        dispatch(lastMessage(resp?.saveMsg?.getData?.Message));
        dispatch(lastMessageData(resp?.saveMsg?.getData));
        dispatch(FileUploadedDatas(resp?.saveMsg?.getData));
        dispatch(fileUploadedBaseUrl(resp?.saveMsg?.baseUrl));
      }
      if (inHome == true) {
        socket.emit("auth-user", { userId: UserID }, (response) => {
          dispatch(UpdatedList(response?.data));
        });
      } else if (inHome == false && chatLeftShowList === "group") {
        socket.emit(
          "auth-user",
          { userId: UserID, filter: "group" },
          (response) => {
            dispatch(UpdatedList(response?.data));
          }
        );
      } else if (inHome == false && chatLeftShowList === "channel") {
        socket.emit(
          "auth-user",
          { userId: UserID, filter: "channel" },
          (response) => {
            dispatch(UpdatedList(response?.data));
          }
        );
      }

      //  for last message update
      socket.emit(
        "userLastMessageTime",
        {
          GCMemberID: groupinformation?.GCMemberID,
          GroupChannelID: GroupChannelID,
          UserID: connectid,
        },
        (response) => {
          dispatch(GCSettingShowModeCheckMessage(response?.data));
        }
      );

      // for read/unread count update
      // inHome &&
      if(resp?.saveMsg?.getData?.GroupChannelID == currentChat?.data?.GroupChannelID){
        socket.emit(
          "readMessage",
          {
            GroupChatID: resp?.saveMsg?.getData?.GroupChatID,
            GCMemberID: GcMemberId,
            GroupChannelID: resp?.saveMsg?.getData?.GroupChannelID,
            UserID: connectid,
          },
          (res) => {
            if (res?.success) {
              socket.emit("auth-user", { userId: UserID }, (res) => {
                dispatch(UpdatedList(res?.data));
              });
            }
          }
        );
      }
    });

    // for like update
    socket.on("likeUpdate", (data) => {
      let foundIndex = chat?.findIndex(
        (item) => item?.GroupChatID === data?.data?.chatDetail?.GroupChatID
      );

      if (foundIndex >= 0) {
        let newArr = [...chat];
        const updateReaction = data?.data?.chatDetail?.like?.some(
          (item) =>
            item?.UserID === Number(localStorage.getItem("gtfconnectid"))
        );
        if (updateReaction) {
          newArr[foundIndex] = { ...data?.data?.chatDetail };
        } else {
          newArr[foundIndex] = {
            ...newArr[foundIndex],
            threeEmoji: data?.data?.chatDetail?.threeEmoji,
          };
        }
        dispatch(LikeUpdate(newArr));
      }
    });

    socket.on("postDeleteData", (res) => {
      dispatch(chatlistDeletePost(res));
      if (inHome == true) {
        socket.emit("auth-user", { userId: UserID }, (response) => {
          dispatch(UpdatedList(response?.data));
        });
      } else if (inHome == false && chatLeftShowList === "group") {
        socket.emit(
          "auth-user",
          { userId: UserID, filter: "group" },
          (response) => {
            dispatch(UpdatedList(response?.data));
          }
        );
      } else if (inHome == false && chatLeftShowList === "channel") {
        socket.emit(
          "auth-user",
          { userId: UserID, filter: "channel" },
          (response) => {
            dispatch(UpdatedList(response?.data));
          }
        );
      }
    });

    return () => {
      socket.off("messageReceived");
      socket.off("likeUpdate");
      socket.off("auth-user");
      socket.off("likeUpdate");
      socket.off("postDeleteData");
    };
  }, [chat, text, socket, chatLeftShowList]);

  useEffect(() => {
    let body = null
    let pathName = window.location.pathname;
    
    if (pathName === "/mentor") (body = { userId: UserID, filter: "mentor" })
    else if (pathName === "/users") (body = { userId: UserID, filter: "mentor" })
    else if (pathName === "/group") (body = { userId: UserID, filter: "group" })
    else if (pathName === "/channel") (body = { userId: UserID, filter: "channel" })
    else if (inHome == true) (body = { userId: UserID })

    if(!!body) {
      socket.emit(
        "auth-user",
         body,
        (response) => {
          if(body?.filter  === "mentor") {
            dispatch(UpdatedList(response?.data?.gcData));
          } else {
            dispatch(UpdatedList(response?.data));
          }
        }
      );
      dispatch(newMessageReceived({
        ...newMessage,
        newMessageStatus: false
      }));
    }

  },[newMessage])

  // ---------------------------------------received message end-----------------------------------------

  const typingFunction = () => {
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength) {
        socket.emit("typing", {
          userId: connectid,
          GroupChannelID: GroupChannelID,
          type: "end",
        });
      }
    }, timerLength);
  };

  // ----------------------------------------------------userTyping EventListener start-------------------------
  useEffect(() => {
    socket.on("userTyping", (resp) => {
      dispatch(whichUserTyping(resp));
    });
    return () => {
      socket.off("userTyping");
    };
  }, [socket]);

  // ----------------------------------------------------userTyping EventListener End-------------------------

  // Handle emoji mart popup
  const handleOutSideClickEmojiMart = (e) => {
    if (
      showEmoji &&
      emojiPickerRef?.current &&
      !emojiPickerRef?.current?.contains(e.target)
    ) {
      setShowEmoji(false);
      // setReply(false);
      setShowTagUsers(false);
    }
  };

  // Handle emoji mart popup
  useEffect(() => {
    const popupElement = emojiPickerRef.current;
    const handlePopupClick = (event) => {
      event.stopPropagation();
    };
    document.addEventListener("mousedown", handleOutSideClickEmojiMart);
    if (popupElement) {
      document.addEventListener("click", handlePopupClick);
    }

    return () => {
      document.removeEventListener("click", handleOutSideClickEmojiMart);
      if (popupElement) {
        popupElement.removeEventListener("mousedown", handlePopupClick);
      }
    };
  }, [showEmoji]);

  // handle scroll to bottom chatlist
  const scrollToBottom = () => {
    if (MessageRef.current) {
      const scrollHeight = MessageRef.current.scrollHeight;
      MessageRef.current.scrollTo({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  };

  // Check handle scroll
  const handleScroll = () => {
    if (MessageRef.current) {
      const scrollBottomThreshold = 50; // You can adjust this threshold
      const isAtBottom =
        MessageRef.current.scrollTop >=
        MessageRef.current.scrollHeight -
          MessageRef.current.clientHeight -
          scrollBottomThreshold;

      setShowDownArrow(!isAtBottom);
    }
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    if (MessageRef.current) {
      MessageRef.current.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (MessageRef.current) {
        MessageRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (showMenu) {
      dispatch(getEmoji());
    }
  }, [dispatch]);
  //  ----------------------------------------- file upload function   start------------------------------------

  const handleClick = (event) => {
    hiddenFileInput.current.click();
    setShowEmoji(false);
  };

  useEffect(() => {
    if (savedMessageState === true) {
      dispatch(fetchGetSavedMessage());
    }
  }, [savedMessageState]);

  //  ----------------------------------------- file upload function   end------------------------------------
  // Get prvious chatlist
  const handleScrollBar = (e) => {
    let element = e.target;
    if (!mentDetails) {
      if (element?.scrollTop === 0) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);

        let newPage = page + 1;
        setPage(newPage);

        socket.emit(
          "chatList",
          {
            userId: connectid,
            GroupChannelID: groupinformation?.GroupChannelID,
            page: newPage,
          },
          (response) => {
            dispatch(PrevChatData(response?.data?.chatData?.rows));
            // setShowDownArrow(true);
          }
        );
      }
    } else {
      if (element?.scrollTop === 0) {
        setLoading(true);
        let newPage = page + 1;
        setPage(newPage);
        emmitToSocket(
          "mentorChatList",
          { ConversationID: chatConversationID, page: newPage },
          (res) => {
            if (res?.data?.dataMentor?.length > 0) {
              setTimeout(() => {
                setLoading(false);
                dispatch(prevMentorChats(res?.data?.dataMentor));
              }, 500);
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        setLoading(false);
      }
    }
    if (MessageRef.current) {
      const scrollTop = MessageRef.current.scrollTop;
      const scrollHeight = MessageRef.current.scrollHeight;
      const clientHeight = MessageRef.current.clientHeight;

      setShowDownArrow(scrollTop > 0);
      if (scrollTop + clientHeight >= scrollHeight) {
        setShowDownArrow(false);
      }
    }
  };

  const handleScrollBarSavedMessage = (e) => {
    let element = e.target;
    if (element.scrollTop === 0) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);

      let newPage = page + 1;
      setPage(newPage);

      dispatch(sendSavedMessages(page));
    }
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 10;
    if (fetchSavedMessageData?.length <= 4) {
      setShowDownArrow(false);
    } else if (bottom) {
      setShowDownArrow(false);
    } else {
      setShowDownArrow(true);
    }
  };

  const handelFirstSelect = (item) => {
    setFirstChecked(true);
    setSelectedMessage([...selectedMessage, item]);
  };

  const pinMessageHandle = (item) => {
    dispatch(addPinMessage(item));
    dispatch(allPinMessagess(item?.GroupChannelID));
  };

  const showPinMessage = () => {
    dispatch(allPinMessagess(GroupChannelID));
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    !mentDetails
      ? setSelectedFiles(selectedFilesArray)
      : setMentorMedia(selectedFilesArray);
    input?.current?.focus();
    // FOR BUG IN CHROME
    event.target.value = "";
  };

  // .. handle remove file
  const handleRemoveFile = (file) => {
    const uploadedFiles = mentDetails ? mentorMedia : selectedFiles;
    const filtered = uploadedFiles?.filter((i) => i.name !== file.name);
    URL.revokeObjectURL(file);
    mentDetails ? setMentorMedia(filtered) : setSelectedFiles([...filtered]);
  };
  const renderFilePreview = (file) => {
    const iconProps = {
      alt: file?.name,
      src: URL.createObjectURL(file),
    };
    const sizeLabel = `${
      Math.round(file.size / 100) / 10 > 1000
        ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
        : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`
    }`;

    return (
      <>
        {file?.type?.startsWith("image") ? (
          <img {...iconProps} />
        ) : file?.type?.startsWith("video") ? (
          <video {...iconProps} />
        ) : file?.type?.startsWith("application/msword") ? (
          <>
            <img src={msWorldZip} alt="zipPreviewImg" />
            <span className="file-size" variant="body2">
              {sizeLabel}
            </span>
          </>
        ) : file?.type?.startsWith("audio") ? (
          <>
            <img src={audioIocn} alt="audioIocn" />
            <span className="file-size" variant="body2">
              {sizeLabel}
            </span>
          </>
        ) : file?.type?.startsWith("application") ? (
          <>
            <img src={zipPreviewImg} alt="zipPreviewImg" />
            <span className="file-size" variant="body2">
              {sizeLabel}
            </span>
          </>
        ) : null}
      </>
    );
  };

  // Dummy User get firstname and last name  only get first character
  const getFirstCharacter =
    Object.keys(selectedDummyUser)?.length === 0
      ? "U"
      : `${selectedDummyUser?.Firstname?.charAt(
          0
        )}${selectedDummyUser?.Lastname?.charAt(0)}`;

  //. handle saved message delete message
  const handleSavedMessageDeleteMsg = async () => {
    setShowMenu(false);
    dispatch(
      deleteSavedMessageeess({
        savedMessgedGetData: savedMessgedGetData,
        setShowMenu,
      })
    );
  };

  // . handle group/channel delete message
  const handleDeleteGCMessage = async (data) => {
    const tempData = selectedMessage?.filter((item) => 
      data?.GroupChatID !== item?.GroupChatID
    )
    setSelectedMessage(tempData);
    if (!tempData || tempData?.length <= 0) setShowCheckbox(false);
    socket.emit("postDelete", {
      UserID: data?.UserID,
      GroupChatID: data?.GroupChatID,
      GroupChannelID: data?.GroupChannelID,
      GCMemberID: data?.GCMemberID,
    });

    toast.success("Message deleted successfully..");
  };

  // media file list when upload
  let filesToMap = mentDetails ? mentorMedia : selectedFiles;
  const fileList = filesToMap?.map((file) => (
    <div key={file.name} className="images-file">
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>
      </div>
      <span
        className="close_upload_icon"
        onClick={() => handleRemoveFile(file)}
      >
        &#10006;
      </span>
    </div>
  ));

  // audio Recording
  const toggleRecording = async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
      if (stream) {
        setIsPlaying((prevState) => !prevState);
      }
      // ... rest of your recording logic
    } catch (error) {
      toast.error("Recording Device not found!");
      setIsPlaying(false);
    }
  };

  const onRecordingComplete = (recordedBlob) => {
    setAudioChunks(recordedBlob.blob);
  };

  const handleRemoveAudioRecorder = () => {
    setAudioChunks(null)
  }

  // *********************************************** Saved message ********************************************************************************
  if (savedMessageState === true) {
    return (
      <div className="chatbox-inner chat_live">
        <div className="chatbox-header pinheader">
          <div
            className="user-name-bar openright-bar"
            data-apply_classname="open_profile_sidebar"
          >
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => {
                setSavedMesssageState(false);
                setShowCheckbox(false);
                setSelectedMessage([]);
              }}
            />

            <div className="name-content">
              <strong>Saved Messages</strong>
            </div>
          </div>
        </div>

        {/* <div className="chatbox-body" onScroll={handleScrollBarSavedMessage}> */}
        <div
          className="chatbox-body"
          onScroll={handleScrollBar}
          ref={MessageRef}
        >
          <ul className="active">
            {loading && fetchSavedMessageData?.length > 10 && (
              <ClipLoader color="#2fb463" cssOverride={override} />
            )}
            {mediaFilesShow && mediaFilesShowChat && (
              <div className="mediafilepopup">
                <p className="error innerpopup">
                  <h6>Too many files selected</h6>
                  <br />
                  <span className="fileuploaded_popup">
                    You have picked too many files. Limit is .
                  </span>{" "}
                  <br />
                  <span
                    className="selectedmediafilebutton"
                    onClick={() => {
                      setMediaFilesShow(false);
                      setSelectedFiles([]);
                    }}
                  >
                    OK
                  </span>
                </p>
              </div>
            )}
            {fetchSavedMessageData &&
              fetchSavedMessageData?.map((item, index) => {
                const newTimeChat = new Date(item?.CreatedAt).toLocaleString(
                  "en-US",
                  {
                    timeZone: "Asia/Kolkata",
                  }
                );
                return (
                  <SavedMessage
                    key={index}
                    newTimeChat={newTimeChat}
                    index={index}
                    setSavedMessgedGetData={setSavedMessgedGetData}
                    item={item}
                    setShowMenu={setShowMenu}
                    setPosition={setPosition}
                    showCheckbox={showCheckbox}
                    setShowGallary={setShowGallary}
                    setGallary={setGallary}
                    setSelectedMessage={setSelectedMessage}
                    selectedMessage={selectedMessage}
                    setFirstChecked={setFirstChecked}
                    setShowCheckbox={setShowCheckbox}
                    setPositionXX={setPositionXX}
                    maxY={maxY}
                    maxX={maxX}
                  />
                );
              })}
            {showMenu && (
              <div className="rightClickShowPopup">
                <div
                  id="contextMenu"
                  className="rounded popupright-click"
                  role="menu"
                  aria-labelledby="dropdownMenu"
                  style={{
                    position: "fixed",
                    left: positionXX?.adjustedX,
                    top: positionXX?.adjustedY,
                    padding: "5px",
                    zIndex: "99",
                    maxWidth: "200px",
                  }}
                  ref={contextMenuRef}
                >
                  <div className="innerButtonMouseRightClick">
                    <button
                      className="deletePostStyle"
                      data-bs-toggle="modal"
                      data-bs-target="#Forward"
                      onClick={() => {
                        setShowMenu(false);
                        setSavedMessageForwordConditon(true);
                      }}
                    >
                      Forward
                    </button>
                    <button
                      className="deletePostStyle"
                      onClick={() => {
                        savedMessgedGetData?.SavedHandelFirstSelect(
                          savedMessgedGetData
                        );
                        setShowCheckbox(true);
                        setShowMenu(false);
                        setSavedMessageForwordConditon(true);
                      }}
                    >
                      Select
                    </button>
                    <button
                      className="deletePostStyle"
                      // data-bs-toggle="modal"
                      // data-bs-target="#Copy"
                      onClick={() => {
                        handleSavedMessageCopyText(savedMessgedGetData?.item);
                        setShowMenu(false);
                      }}
                    >
                      Copy
                    </button>

                    <button
                      className="deletePostStyle"
                      onClick={() => {
                        ConfirmationDialog({
                          title: "Delete Message",
                          message:
                            "Are you sure you want to delete this message?",
                          onConfirm: handleSavedMessageDeleteMsg,
                        });
                        setShowMenu(false);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </ul>
          {fetchSavedMessageDataStatus === "idle" && showDownArrow && (
            <button onClick={scrollToBottom} className="downArrowButton">
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          )}
        </div>
        {/* </div> */}
        <div className="chatbox-footer" ref={updatedFiles}>
          <div className="footer-type" ref={emojiPickerRef}>
            {showEmoji && (
              <Picker data={data} onEmojiSelect={(e) => addEmoji(e)} />
            )}
            {selectedFiles?.length <= 10 && (
              <div
                className={`images d-flex ${
                  !selectedFiles?.length > 0 ? "images-d-none" : ""
                }`}
                id="showFilesData"
              >
                {fileList}
                {selectedFiles?.length > 0 && (
                  <div
                    className="type-attach extra_added_file"
                    onClick={handleClick}
                  >
                    <span>&#43;</span>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      multiple
                      // style={{ display: "none" }}
                    />
                  </div>
                )}
              </div>
            )}
            {showCheckbox ? (
              <div className="select-flex">
                <div className="select_popup">
                  <span>{`${selectedMessage?.length}  Message Selected `}</span>
                  <button data-bs-toggle="modal" data-bs-target="#Forward">
                    Forward
                  </button>
                </div>
              </div>
            ) : (
              <div className="type-group">
                <div className="iconchat-bar" ref={closeEmoji}>
                  <i
                    className="fa-regular fa-face-smile emojiIcon"
                    onClick={() => {
                      setShowEmoji(!showEmoji);
                    }}
                  ></i>
                </div>
                <input
                  value={text}
                  onChange={handlechange}
                  ref={input}
                  onKeyPress={(e) => {
                    if (e?.code === "Enter") {
                      SavedMessageHandleSubmit();
                    }
                  }}
                  style={{ background: "#f4f8f7", borderRadius: " 15px" }}
                  className="form-control"
                  placeholder="Enter Message"
                ></input>
                <div className="type-attach" onClick={handleClick}>
                  <i className="fa-solid fa-paperclip fileupload" />
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    onChange={onSelectFile}
                    multiple
                    style={{ display: "none" }}
                  />
                </div>
                <button
                  className={
                    selectedFiles.length > 10 ? "disable_button" : "send"
                  }
                  type="button"
                  onClick={SavedMessageHandleSubmit}
                  disabled={selectedFiles.length > 10}
                >
                  <i className="fa-solid fa-paper-plane"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else if (show !== true) {
    return exclusiveSubscribeOfferCheckResponseStatus === "loading" ? (
      ""
    ) : (
      <img src={chatlistBg} className="initialImg" />
    );
  }
  // *********************************************** //. Saved message ********************************************************************************
  return (
    <>
      {
        fileUploadDataStatus === STATUSES.LOADING || mediaLoading ? (
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperClass=" loader_styleFileuploaded"
            visible={true}
          />
        ) : PinMessageState ? (
          // *************************************************Pin message Page UI****************************************
          <div
            className={`chatbox-inner chat_live ${
              openComment || openSetting || openProfile || showSearchPopup
                ? "width_calc"
                : ""
            }`}
          >
            <div className="chatbox-header pinheader">
              <div
                className="user-name-bar openright-bar pin_message_header_sec"
                data-apply_classname="open_profile_sidebar"
              >
                <i
                  className="fa-solid fa-arrow-left"
                  onClick={() => {
                    setPinMesssageState(false);
                    setShowCheckbox(false);
                    setSelectedMessage([]);
                  }}
                />

                <div className="name-content pin_message_name_header">
                  <strong>Pinned Messages</strong>
                  {!mentDetails && pinMessage?.data?.allPinData?.length > 0 ? (
                    <strong
                      onClick={() => {
                        ConfirmationDialog({
                          title: "Unpin All Message",
                          message:
                            "Are you sure you want to Unpin All this message?",
                          onConfirm: () =>
                            dispatch(
                              unPinAllMessages({
                                GroupChannelID:
                                  localStorage.getItem("GroupChannelID"),
                                setPinMesssageState,
                              })
                            ),
                        });
                        setShowMenu(false);
                      }}
                    >
                      Remove All
                    </strong>
                  ) : (
                    mentorPinMessage?.allPin?.length > 0 && (
                      <strong
                        onClick={() => {
                          ConfirmationDialog({
                            title: "Unpin All Message",
                            message:
                              "Are you sure you want to Unpin All this message?",
                            onConfirm: () =>
                              unpinAllMentorMessage(
                                mentorPinMessage?.allPin[0]
                              ),
                          });
                        }}
                      >
                        Remove All
                      </strong>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* Chatting in detail start */}
            <div
              className="chatbox-body"
              onScroll={handleScrollBar}
              ref={MessageRef}
            >
              <ul className="active">
                {loading && pinMessage?.data?.allPinData?.length > 10 && (
                  <ClipLoader color="#2fb463" cssOverride={override} />
                )}
                {mentDetails ? (
                  <>
                    {mentorPinMessage?.allPin?.map((chat, index) => {
                      // setStoreMentorData([chat])

                      return (
                        <MentorChats
                          chats={chat?.messageData}
                          key={index}
                          MentorPinMessageID={chat?.MentorPinMessageID}
                          addMentorMessageToPin={addMentorMessageToPin}
                          unpinMentorMessage={unpinMentorMessage}
                          unpinAllMentorMessage={unpinAllMentorMessage}
                          removeMentorMessage={removeMentorMessage}
                          handleMentorQuote={handleMentorQuote}
                          handleQuoteMessage={handleQuoteMessage}
                          setSelectMode={setSelectMode}
                          selectMode={selectMode}
                          selectedMentMessages={selectedMentMessages}
                          setSelectedMentMessages={setSelectedMentMessages}
                          setShowGallary={setShowGallary}
                          setGallary={setGallary}
                          setQuote={setQuote}
                          quote={quote}
                          stage={"pin"}
                          setPosition={setPosition}
                          setPositionXX={setPositionXX}
                          maxY={maxY}
                          maxX={maxX}
                          positionXX={positionXX}
                          getLastMessageAddClassNameMentor={
                            getLastMessageAddClassNameMentor
                          }
                          addLastMessageClassMouseRightClickShowPopup={
                            addLastMessageClassMouseRightClickShowPopup
                          }
                          setAddLastMessageClassMouseRightClickShowPopup={
                            setAddLastMessageClassMouseRightClickShowPopup
                          }
                          setPinMesssageState={setPinMesssageState}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    {pinMessage?.data?.allPinData &&
                      pinMessage?.data?.allPinData?.map((item, index) => {
                        return (
                          <PinMessages
                            key={index}
                            item={item}
                            ShowComment={ShowComment}
                            index={index}
                            setShow={setShow}
                            name={1}
                            handleQuote={handleQuote}
                            reply={reply}
                            commentList={commentList}
                            setShowGallary={setShowGallary}
                            setGallary={setGallary}
                            selectedFiles={selectedFiles}
                            channelAllowDisscussion={channelAllowDisscussion}
                            Type={getGroupChannelType()}
                            selectedMessage={selectedMessage}
                            setSelectedMessage={setSelectedMessage}
                            showCheckbox={showCheckbox}
                            setShowCheckbox={setShowCheckbox}
                            setShowMenu={setShowMenu}
                            setPosition={setPosition}
                            setEditData={setEditData}
                            editData={editData}
                            checked={checked}
                            firstChecked={firstChecked}
                            setFirstChecked={setFirstChecked}
                            setSelectMessageId={setSelectMessageId}
                            setgetPinMessageID={setgetPinMessageID}
                            setUserOpenProfile={setUserOpenProfile}
                            userOpenComment={userOpenComment}
                            setUserOpenSetting={setUserOpenSetting}
                            setUserOpenComment={setUserOpenComment}
                            setPositionXX={setPositionXX}
                            maxY={maxY}
                            maxX={maxX}
                            getLastMessageAddClassNamePinMessage={
                              getLastMessageAddClassNamePinMessage
                            }
                            setAddLastMessageClassMouseRightClickShowPopup={
                              setAddLastMessageClassMouseRightClickShowPopup
                            }
                            handleFindeMessage={handleFindeMessage}
                            setPinMesssageState={setPinMesssageState}
                            setSearchMessage={setSearchMessage}
                          />
                        );
                      })}
                    {/* {showMenu && (
                      <div className="rightClickShowPopup">
                        <div
                          id="contextMenu"
                          className=" rounded popupright-click"
                          role="menu"
                          aria-labelledby="dropdownMenu"
                          style={{
                            position: "fixed",
                            left: positionXX?.adjustedX,
                            top: positionXX?.adjustedY,
                            padding: "5px",
                            zIndex: "99",
                            maxWidth: "200px",
                            background: "#fff",
                          }}
                        >
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              editData?.SavedHandelFirstSelect(editData?.item);
                              setShowCheckbox(true);
                              setShowMenu(false);
                            }}
                          >
                            Select
                          </button>
                          <button
                            className="deletePostStyle"
                            data-bs-toggle="modal"
                            data-bs-target="#Copy"
                            onClick={() => {
                              handleCopy(editData?.item);
                              setShowMenu(false);
                            }}
                          >
                            Copy
                          </button>
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              ConfirmationDialog({
                                title: "Unpin Message",
                                message:
                                  "Are you sure you want to Unpin this message?",
                                onConfirm: () =>
                                  handleDeletePinMessage({
                                    pinmessagesID:
                                      getPinMessageID?.pinmessagesID,
                                    GroupChannelID:
                                      getPinMessageID?.GroupChannelID,
                                  }),
                              });
                              setShowMenu(false);
                            }}
                          >
                            UnPin Message
                          </button>
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              ConfirmationDialog({
                                title: "Unpin All Message",
                                message:
                                  "Are you sure you want to Unpin this message?",
                                onConfirm: () =>
                                unPinAllMessageGC({
                                    GroupChannelID:
                                      getPinMessageID?.GroupChannelID,
                                  }),
                              });
                              setShowMenu(false);
                            }}
                          >
                            UnPin All Message
                          </button>
                        </div>
                      </div>
                    )} */}
                    {showCheckbox && (
                      <div className="select-flex">
                        <div className="select_popup">
                          <span>{`${selectedMessage?.length}  Message Selected `}</span>
                          <button
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#Forward"
                            onClick={() => {
                              setSelectMessageId(selectedMessage);
                            }}
                          >
                            Forward
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </ul>
              {showDownArrow && (
                <button onClick={scrollToBottom} className="downArrowButton">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </button>
              )}
            </div>

            {/* Chatting in detail End */}
          </div>
        ) : (
          // *************************************************/////////Pin message Page UI END****************************************
          <div
            className={`chatbox-inner chat_live ${
              openComment || openSetting || openProfile || showSearchPopup
                ? "width_calc"
                : ""
            }`}
            id="gtf_inner"
            ref={emojiref}
          >
            <div className="chatbox-header">
              <div
                className="user-name-bar openright-bar"
                // data-apply_classname="open_profile_sidebar"
                onClick={showGCMediaFiles}
              >
                <figure>
                  <img
                    src={
                      mentorProfilePicUrl
                        ? mentDetails?.ProfileImage !== null &&
                          mentDetails?.ProfileImage !== undefined
                          ? `${mentorProfilePicUrl?.user_profile_image_url}${mentDetails.ProfileImage}`
                          : groupChannelDetails?.gc_info?.ProfileImage == null
                          ? user1
                          : groupChannelDetails?.gc_info?.ProfileImage
                        : mentDetails?.ProfileImage !== null &&
                          mentDetails?.ProfileImage !== undefined
                        ? `${mentorListProfilePicUrl?.user_profile_image_url}${mentDetails.ProfileImage}`
                        : groupChannelDetails?.gc_info?.ProfileImage == null
                        ? user1
                        : groupChannelDetails?.gc_info?.ProfileImage
                    }
                    alt="logo"
                  />
                </figure>
                {/* GcMemberCheckBlockStatus==="Blocked" ? <div
                  className="name-content"
                > */}
                <div
                  className="name-content"
                  onClick={
                    GcMemberCheckBlockStatus === "Blocked" ||
                    GcMemberCheckBlockStatus === "GCLeft" ||
                    GCMemberInfo === null ||
                    GCMemberSubscriptionPlanNull === null
                      ? null
                      : () => {
                          setOpenProfile(!openProfile) ||
                            (openProfile && setOpenSetting(false)) ||
                            setOpenComment(false);
                        }
                  }
                >
                  <strong>
                    {mentDetails
                      ? `${mentDetails?.Firstname} ${
                          mentDetails?.Lastname === null
                            ? ""
                            : mentDetails?.Lastname
                        }`
                      : groupHeading}
                  </strong>
                  {!mentDetails ? (
                    <>
                      <p>
                        {`${
                          groupChannelDetails?.gc_info?.MemberCount +
                          groupChannelDetails?.gc_setting?.FakeMemberCount
                        } ${
                          getGroupChannelType() === "group"
                            ? "Members"
                            : "Subscribers"
                        } ${
                          localStorage?.getItem("user_role_slug") ===
                          "super-admin"
                            ? `(${groupChannelDetails?.gc_setting?.FakeMemberCount}
                              Manipulated and
                              ${groupChannelDetails?.gc_info?.MemberCount}
                              Original)`
                            : ""
                        }`}
                      </p>
                    </>
                  ) : (
                    <p>
                      {
                        typing
                          ? `${typing?.Firstname} is typing..`
                          : // : stage === "mentor"
                            mentDetails?.usrRole?.role?.Name
                        // : "Student"
                      }
                    </p>
                  )}
                </div>
              </div>
              <div className="chatbox-menubar">
                <ul>
                  <li className="GC_search-toggle-open" id="GCSearchMessage">
                    <Link className="thumbtack-notifiction">
                      <img
                        src={searchIcon}
                        alt="searchIcon"
                        onClick={
                          GcMemberCheckBlockStatus === "Blocked" ||
                          GcMemberCheckBlockStatus === "GCLeft" ||
                          GCMemberInfo === null
                            ? null
                            : () => setShowSearchPopup(!showSearchPopup)
                        }
                      />
                    </Link>
                  </li>
                  {userRoleSlugSettingPermission === "super-admin" ||
                  gcSettingPermissionData?.full_permission == 1 ||
                  gcSettingPermissionData?.gc_setting == 1 ||
                  gcSettingPermissionData?.full_permission == 1
                    ? checkMentorRole !== true && (
                        <li>
                          <Link
                            className="setting-toggle-open"
                            onClick={() => {
                              dispatch(
                                GCBlockMemberList({ getGroupChannelId })
                              );
                              setOpenSetting(!openSetting) ||
                                (openSetting && setOpenComment(false));
                              setOpenProfile(false);
                              // dispatch(
                              //   DetailsChannelGroupApi(GroupChannelIDfromapi)
                              // );

                              setChannelAccess(channelAccessType);
                              setChannelDisscussion(channelAllowDisscussion);
                              setChannelSignedMsg(channelSignedMessage);
                              setChannelEnableManipulateViews(
                                EnableManipulateViewschannel
                              );
                              setChatHistoryStatus(ChatHistoryStatus)
                              setGroupAccess(channelAccessType);
                              setChannelSharingContent(sharingContent);
                            }}
                          >
                            <img src={settingI} alt="setting" />
                          </Link>
                        </li>
                      )
                    : ""}
                  {checkMentorRole !== true ? (
                    <li
                      className="dropdown"
                      onClick={() =>
                        GCMuteNotificationData?.Value !== 1 &&
                        setShowNotificationPopup(!showNotificationPopup)
                      }
                    >
                      <Link
                        className={
                          showNotificationPopup
                            ? "dropdown-toggle show"
                            : "dropdown-toggle"
                        }
                        data-bs-toggle={
                          GcMemberCheckBlockStatus === "Blocked" ||
                          GcMemberCheckBlockStatus === "GCLeft" ||
                          GCMemberInfo === null
                            ? null
                            : "dropdown"
                        }
                        aria-expanded={showNotificationPopup ? true : false}
                      >
                        {GCMuteNotificationData?.Value === 1 ? (
                          <img
                            src={unMuteIcon}
                            alt="well"
                            onClick={storeMuteNotificationValue}
                          />
                        ) : (
                          <img src={muteIcon} alt="well" />
                        )}
                      </Link>
                      {GcMemberCheckBlockStatus === "Blocked" ||
                      GcMemberCheckBlockStatus === "GCLeft" ||
                      GCMemberInfo === null ||
                      checkMentorRole === true ? (
                        ""
                      ) : (
                        <div
                          className={
                            showNotificationPopup
                              ? "dropdown-menu show "
                              : "dropdown-menu"
                          }
                        >
                          <div className="notification-list">
                            <strong>Notification</strong>
                            <div className="notification-check">
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="forever"
                                  name="notifaction"
                                  value="525600"
                                  checked={notification == "525600"}
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="forever">
                                  <span />
                                  Forever
                                </label>
                              </div>
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="three"
                                  name="notifaction"
                                  value="180"
                                  checked={notification == "180"}
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="three">
                                  <span />
                                  For 3 Hour
                                </label>
                              </div>
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="six"
                                  name="notifaction"
                                  value="360"
                                  checked={notification == "360"}
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="six">
                                  <span />
                                  For 6 Hour
                                </label>
                              </div>
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="1day"
                                  name="notifaction"
                                  value="1440"
                                  checked={notification == "1440"}
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="1day">
                                  <span />
                                  For 1 Day
                                </label>
                              </div>
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="3day"
                                  name="notifaction"
                                  value="4320"
                                  checked={notification == "4320"}
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="3day">
                                  <span />
                                  For 3 Day
                                </label>
                              </div>
                              <div className="check-noification-box">
                                <input
                                  type="radio"
                                  className="checknotification"
                                  id="1week"
                                  name="notifaction"
                                  checked={notification == "10080"}
                                  value="10080"
                                  onChange={(e) => {
                                    storeMuteNotificationValue(e.target.value);
                                  }}
                                />
                                <label htmlFor="1week">
                                  <span />
                                  For 1 Week
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="cancel-mute-tab">
                            <div className="links">
                              <Link to="#">Cancel</Link>
                              <Link
                                to="#"
                                onClick={() => {
                                  storeMuteNotificationValue(0);
                                }}
                              >
                                {notification == "mute" ? "Unmute" : "Mute"}
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ) : (
                    ""
                  )}
                  {userRoleSlugSettingPermission === "super-admin" ||
                  gcSettingPermissionData?.full_permission == 1 ? (
                    ""
                  ) : GcMemberCheckBlockStatus === "GCLeft" ||
                    GCMemberInfo === null ||
                    checkMentorRole === true ? (
                    ""
                  ) : (
                    <li
                      className={
                        showLeaveGCButton
                          ? "dropdown setting-drop show"
                          : "dropdown setting-drop"
                      }
                      onClick={() => setShowLeaveGCButton(!showLeaveGCButton)}
                    >
                      <Link
                        data-bs-toggle={
                          GcMemberCheckBlockStatus === "Blocked" ||
                          GcMemberCheckBlockStatus === "GCLeft"
                            ? null
                            : "dropdown"
                        }
                        aria-expanded={showLeaveGCButton ? true : false}
                      >
                        <img src={menuIcon} alt="Menu Icon" />
                      </Link>
                      <div
                        className={
                          showLeaveGCButton
                            ? "dropdown-menu showLeaveGCButton show"
                            : "dropdown-menu"
                        }
                        ref={contextMenuRef}
                      >
                        <Link
                          className="block"
                          onClick={() => setLeaveGCState(true)}
                        >
                          {`Leave ${getGroupChannelType()}`}
                        </Link>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            {/* Chatting in detail start pinn message top component*/}
            {mentDetails ? (
              mentorPinMessage?.allPin?.length > 0 ? (
                <PinMessageTop
                  pinMessage={mentorPinMessage}
                  stage={stage}
                  setQuote={setQuote}
                  GcMemberCheckBlockStatus={GcMemberCheckBlockStatus}
                  GCMemberInfo={GCMemberInfo}
                  GCMemberSubscriptionPlanNull={GCMemberSubscriptionPlanNull}
                  showPinMessage={showPinMessage}
                  setPinMesssageState={setPinMesssageState}
                  setShowMenu={setShowMenu}
                />
              ) : null
            ) : pinMessage?.data?.allPinData?.length > 0 ? (
              <PinMessageTop
                pinMessage={pinMessage}
                stage={stage}
                GcMemberCheckBlockStatus={GcMemberCheckBlockStatus}
                setSearchMessage={setSearchMessage}
                GCMemberInfo={GCMemberInfo}
                GCMemberSubscriptionPlanNull={GCMemberSubscriptionPlanNull}
                showPinMessage={showPinMessage}
                setPinMesssageState={setPinMesssageState}
                setShowMenu={setShowMenu}
              />
            ) : null}

            {/* {searchClose && (
              <div className="search_popup_bar">
                <div className="search_popup-inner">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setGCSearchMessage(e.target.value)}
                  />
                  <div className="search-number">
                    <span>3/5</span>
                  </div>
                  <div className="search-drop">
                    <span>
                      <img src={searchcounterright} alt="searchcounterright" />
                    </span>
                    <span>
                      <img src={searchcountleft} alt="searchcountleft" />
                    </span>
                  </div>

                  <a href="#" className="close-icon">
                    <img
                      src={searchCloseIcon}
                      alt="searchcountleft"
                      onClick={() => setSearchClose(false)}
                    />
                  </a>
                </div>
              </div>
            )} */}
            <div
              className={
                GcMemberCheckBlockStatus === "Blocked" ||
                GCMemberInfo === null ||
                GCMemberSubscriptionPlanNull === null ||
                GcMemberCheckBlockStatus === "GCLeft"
                  ? "chatbox-body opacity-0"
                  : "chatbox-body "
              }
              onScroll={handleScrollBar}
              ref={MessageRef}
            >
              <ul className="active">
                {loading && !mentDetails
                  ? chat?.length > 10
                  : loading &&
                    mentorChats?.length > 20 && (
                      <ClipLoader color="#2fb463" cssOverride={override} />
                    )}
                {mediaFilesShow && mediaFilesShowChat && (
                  <div className="mediafilepopup">
                    <p className="error innerpopup">
                      <h6>Too many files selected</h6>
                      <br />
                      <span className="fileuploaded_popup">
                        You have picked too many files. Limit is 5.
                      </span>{" "}
                      <br />
                      <span
                        className="selectedmediafilebutton"
                        onClick={() => {
                          setMediaFilesShow(false);
                          setSelectedFiles([]);
                        }}
                      >
                        OK
                      </span>
                    </p>
                  </div>
                )}
                {leaveGCState && (
                  <div className="leaveGCpopup">
                    <p className="error innerpopup">
                      <div className="d-flex leaveGcIcon">
                        {" "}
                        <img
                          src={
                            groupChannelDetails?.gc_info?.ProfileImage == null
                              ? user1
                              : groupChannelDetails?.gc_info?.ProfileImage
                          }
                          alt="groupicon"
                        />
                        <h6>Leave Channel ?</h6>
                      </div>
                      <span className="fileuploaded_popup">
                        Are you sure you want to leave
                      </span>{" "}
                      <h6 className="LeaveGcName">{groupHeading}</h6>
                      <div className="selectedmediafilebutton d-flex">
                        <span
                          onClick={() => {
                            dispatch(
                              leaveGroupFucntion({
                                GcMemberId: GcMemberId,
                                GroupChannelID: GroupChannelID,
                              })
                            );
                            setLeaveGCState(false);
                          }}
                        >
                          Leave
                        </span>
                        <span
                          onClick={() => {
                            setLeaveGCState(false);
                          }}
                        >
                          Cancel
                        </span>
                      </div>
                    </p>
                  </div>
                )}
                {storeGCUpdateTimeDay?.map((item, i) => (
                  <div
                    className="top_scroll_show_day_date"
                    key={i}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <span className="show_date_day">
                      {compareWithToday(item?.date)}
                    </span>
                    {item?.messageArray?.map((messageItem, index) => {
                      const prevMessageItem = item?.messageArray?.[index - 1];
                      const currUserID = messageItem?.user?.UserID;
                      const dummyUserID =
                        messageItem?.dummyUserData?.DummyUserID;
                      const prevUserID = prevMessageItem?.user?.UserID;
                      const prevDummyUserID =
                        prevMessageItem?.dummyUserData?.DummyUserID;

                      const isSameUser =
                        currUserID === prevUserID &&
                        dummyUserID === prevDummyUserID;

                        if((storeGCUpdateTimeDay?.length === (i+1)) && (item?.messageArray?.length === (index+1)) && searchMessage?.GroupChatID){
                          focusRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }

                      return (
                        <SingleMessage
                          focusElement={(e) => focusRef.current = e}
                          key={index}
                          name={isSameUser ? 0 : 1}
                          GcMemberCheckBlockStatus={GcMemberCheckBlockStatus}
                          ShowComment={ShowComment}
                          setGCLikeUnlike={setGCLikeUnlike}
                          item={messageItem}
                          index={index}
                          singleMessageData={item?.messageArray}
                          gcReactionListData={gcReactionListData}
                          handleQuote={handleQuote}
                          reply={reply}
                          commentList={commentList}
                          selectedFiles={selectedFiles}
                          setShowGallary={setShowGallary}
                          setGallary={setGallary}
                          channelAllowDisscussion={channelAllowDisscussion}
                          Type={getGroupChannelType()}
                          newTimeChatshowTime={newTimeChat}
                          setEmojijList={setEmojijList}
                          selectedMessage={selectedMessage}
                          setSearchMessage={setSearchMessage}
                          setSelectedMessage={setSelectedMessage}
                          setLikeItem={setLikeItem}
                          showCheckbox={showCheckbox}
                          setShowCheckbox={setShowCheckbox}
                          setShowMenu={setShowMenu}
                          setPosition={setPosition}
                          setEditData={setEditData}
                          selectedEmojiList={selectedEmojiList}
                          setShowReactionCountPopup={setShowReactionCountPopup}
                          checked={checked}
                          groupHeading={groupHeading}
                          searchMessage={searchMessage}
                          firstChecked={firstChecked}
                          setFirstChecked={setFirstChecked}
                          setTagUsers={setTagUsers}
                          setTagUsersComment={setTagUsersComment}
                          userOpenProfile={userOpenProfile}
                          userOpenSetting={userOpenSetting}
                          userOpenComment={userOpenComment}
                          setUserOpenSetting={setUserOpenSetting}
                          setUserOpenProfile={setUserOpenProfile}
                          setUserOpenComment={setUserOpenComment}
                          PinMessageState={PinMessageState}
                          setCommentAllowDiscussionPermission={
                            setCommentAllowDiscussionPermission
                          }
                          setPositionXX={setPositionXX}
                          maxY={maxY}
                          maxX={maxX}
                          getLastMessageAddClassName={
                            getLastMessageAddClassName
                          }
                          setAddLastMessageClassMouseRightClickShowPopup={
                            setAddLastMessageClassMouseRightClickShowPopup
                          }
                          handleFindeMessage={handleFindeMessage}
                          setAllowDisscussionUpdatePermission={
                            setAllowDisscussionUpdatePermission
                          }
                          settagUserImgUrl={settagUserImgUrl}
                          gcPermissionSetting={gcPermissionSetting}
                        />
                      );
                    })}
                  </div>
                ))}

                {/* mentor chat list */}

                {mentorChatGroup?.length > 0
                  ? mentorChatGroup?.map((item, i) => (
                      <div className="top_scroll_show_day_date" key={i}>
                        <span className="show_date_day">
                          {compareWithToday(item?.date)}
                        </span>
                        {item?.messageArray?.map((chat, index) => {
                          return (
                            <MentorChats
                              key={index}
                              chats={chat}
                              addMentorMessageToPin={addMentorMessageToPin}
                              removeMentorMessage={removeMentorMessage}
                              editMentorMessage={editMentorMessage}
                              handleMentorQuote={handleMentorQuote}
                              handleQuoteMessage={handleQuoteMessage}
                              handleMentorMessageEdit={handleMentorMessageEdit}
                              setSelectMode={setSelectMode}
                              selectMode={selectMode}
                              mediaLoading={mediaLoading}
                              selectedMentMessages={selectedMentMessages}
                              setSelectedMentMessages={setSelectedMentMessages}
                              setQuote={setQuote}
                              quote={quote}
                              index={index}
                              setPosition={setPosition}
                              setPositionXX={setPositionXX}
                              maxY={maxY}
                              maxX={maxX}
                              positionXX={positionXX}
                              getLastMessageAddClassNameMentor={
                                getLastMessageAddClassNameMentor
                              }
                              addLastMessageClassMouseRightClickShowPopup={
                                addLastMessageClassMouseRightClickShowPopup
                              }
                              setAddLastMessageClassMouseRightClickShowPopup={
                                setAddLastMessageClassMouseRightClickShowPopup
                              }
                            />
                          );
                        })}
                      </div>
                    ))
                  : null}

                {/* Gallery Popup */}
                <GallerySlider
                  setGallary={setGallary}
                  setShowGallary={setShowGallary}
                  gallary={gallary}
                  showGallary={showGallary}
                  baseUrlMedia={baseUrlMedia}
                />
                <div className="rightClickShowPopup rightclick_p">
                  <div
                    id="contextMenu"
                    ref={contextMenuRef}
                    className={
                      GcMemberCheckBlockStatus === "Blocked" ||
                      GcMemberCheckBlockStatus === "GCLeft" ||
                      GCMemberInfo === null ||
                      GCMemberSubscriptionPlanNull === null
                        ? "d-none"
                        : "rounded popupright-click"
                    }
                    role="menu"
                    aria-labelledby="dropdownMenu"
                    style={addLastMessageClassMouseRightClickShowPopup ? {
                      position: "fixed",
                      left: positionXX?.adjustedX,

                      bottom: "120px",
                      padding: "4px 0",
                      zIndex: "9999",
                      maxWidth: "200px",
                      background: "#fff",
                    } : {
                      position: "fixed",
                      left: positionXX?.adjustedX,
                      top: positionXX?.adjustedY,
                      padding: "4px 0",
                      zIndex: "9999",
                      maxWidth: "200px",
                      background: "#fff",
                    }}
                  >
                    {selectedEmojiList?.length > 0 && !mentDetails ? (
                      <ul
                        id="ulScrollbar"
                        ref={containerRef}
                        style={{
                          overflow: "auto",
                          display: showReactionCountPopup
                            ? "none"
                            : showMenu
                            ? ""
                            : "none",
                        }}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                      >
                        {selectedEmojiList &&
                          selectedEmojiList?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => handleChatLike(item)}
                              >
                                {item?.EmojiCode}
                              </li>
                            );
                          })}
                      </ul>
                    ) : null}

                    {showMenu && !PinMessageState && (
                      <div
                        className="innerButtonMouseRightClick"
                        style={{
                          display: showReactionCountPopup ? "none" : "",
                        }}
                        ref={contextMenuRef}
                      >
                        {userRoleSlugSettingPermission === "super-admin" ||
                        gcPermissionSetting?.RestrictSharingContent == 1 ||
                        gcSettingPermissionData?.full_permission == 1 ? (
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              editData?.SavedHandelFirstSelect(editData?.item);
                              setShowCheckbox(true);
                              setShowMenu(false);
                            }}
                          >
                            Select
                          </button>
                        ) : null}
                        {gcPermissionPinMessage?.SendMessage == 1 ||
                        userRoleSlugSettingPermission === "super-admin" ||
                        (gcSettingPermissionData?.full_permission == 1 &&
                          gcPermissionPinMessage?.SendMedia == 1) ? (
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              handleCopy(editData?.item);
                              setShowMenu(false);
                            }}
                          >
                            Copy
                          </button>
                        ) : null}
                        {gcPermissionPinMessage?.PinMessage == 1 ||
                        gcSettingPermissionData?.bubble_menu_pin_message == 1 ||
                        userRoleSlugSettingPermission == "super-admin" ||
                        gcSettingPermissionData?.full_permission == 1 ||
                        mentDetails ? (
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              pinMessageHandle(editData?.item);
                              setShowMenu(false);
                            }}
                          >
                            Pin Message
                          </button>
                        ) : null}

                        {allowDisscussionUpdatePermission?.AllowDiscussion ==
                          1 ||
                        gcSettingPermissionData?.enable_disable_discussion ==
                          1 ||
                        userRoleSlugSettingPermission === "super-admin" ||
                        gcSettingPermissionData?.full_permission == 1
                          ? editData?.item?.user?.UserID == connectid && (
                              <button
                                className="deletePostStyle"
                                onClick={() =>
                                  handleGCDisscussion(
                                    editData?.item
                                  )
                                }
                              >
                                {allowDisscussionUpdatePermission?.AllowDiscussion ==
                                1
                                  ? "Disabled disscussion"
                                  : "Enabled disscussion"}
                              </button>
                            )
                          : null}
                        {gcPermissionPinMessage?.SendMessage == 1 ||
                        userRoleSlugSettingPermission === "super-admin" ||
                        (gcSettingPermissionData?.full_permission == 1 &&
                          gcPermissionPinMessage?.SendMedia == 1) ||
                        mentDetails ? (
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              handleQuote(editData?.item);
                              setShowMenu(false);
                            }}
                          >
                            Quote
                          </button>
                        ) : null}
                        {editData?.item?.user?.UserID == connectid && (
                          <button
                            className="deletePostStyle"
                            onClick={() => {
                              ConfirmationDialog({
                                title: "Delete Message",
                                message:
                                  "Are you sure you want to delete this message?",
                                onConfirm: () =>
                                  handleDeleteGCMessage(editData?.item),
                              });
                              setShowMenu(false);
                            }}
                          >
                            Delete
                          </button>
                        )}
                        {!mentDetails && (
                          <button className="deletePostStyle">
                            <span
                              onClick={() => {
                                setShowMenu(false);
                                fetchTotalEmojiCount();
                                setShowReactionCountPopup(true);
                              }}
                            >
                              {`${groupChannelDetails?.gc_info?.MemberCount} / ${editData?.item?.lastThreeData?.length} Reacted`}
                            </span>
                            {editData?.item?.lastThreeData?.map(
                              (react, index) => (
                                <span key={index} className="gc_reaction_list">
                                  {react?.reaction?.EmojiCode}
                                </span>
                              )
                            )}
                          </button>
                        )}
                      </div>
                    )}
                    {showReactionCountPopup && (
                      <div className="reactions-vc-col">
                        <h2>
                          Total Reactions -{" "}
                          {emojiList?.reduce(
                            (total, emoji) => total + Number(emoji.count),
                            0
                          )}
                        </h2>
                        <ul>
                          {emojiList?.map((emoji, index) => (
                            <li key={index}>
                              <div className="reactions-click">
                                <span>{emoji?.EmojiCode}</span>
                                <p>{emoji?.count}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </ul>
              {showDownArrow && (
                <button onClick={scrollToBottom} className="downArrowButton">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </button>
              )}

              <div ref={scrollRef} />
            </div>

            {/* Subscription plan remining days */}
            {groupChannelDetails?.gc_member_subscription_upcoming_plan ===
              null &&
              subscriptionPlanTotalDays <= 3 &&
              !mentDetails && (
                <div
                  className="expire_subscription_plan"
                  onClick={() =>
                    dispatch(setShowGlobalSearchSubscriptionPopup(true))
                  }
                >
                  <h6 className="animate-charcter">
                    {showSubscriptionMessage}
                  </h6>
                </div>
              )}
            <Modal
              open={showGlobalSearchSubscriptionPopup}
              onClose={() =>
                dispatch(setShowGlobalSearchSubscriptionPopup(false))
              }
            >
              <SubscriptionPlanShowPopup
                // SubscriptionPlanShowPopupData={groupChannelDetails}
                setShow={setShow}
                IsRenew={subscriptionPlanTotalDays <= 3 ? 1 : 0}
                showGlobalSearchSubscriptionPopup={
                  showGlobalSearchSubscriptionPopup
                }
              />
            </Modal>
            {/* //. Subscription plan remining days */}
            {(GcMemberCheckBlockStatus === "Active" &&
              gcPermissionPinMessage?.SendMessage === 1) ||
            userRoleSlugSettingPermission === "super-admin" ||
            gcSettingPermissionData?.full_permission == 1 ||
            mentDetails ? (
              <div className="chatbox-footer" ref={updatedFiles}>
                {(getGroupChannelType() === "group" ||getGroupChannelType() === "channel") && showTagUsers && (
                  <div className="taguser_list">
                    <div className="taguser_heading">
                      <h2>Suggestions</h2>
                    </div>
                    <ul>
                      {filteredUsers?.map((user, index) => (
                        <li key={index}>
                          <div
                            className="taguser_group"
                            onClick={() => handleUserClick(user)}
                          >
                            <figure>
                              <img
                                src={
                                  user?.IsDummyUser === true
                                    ? user?.ProfileImage
                                      ? `${tagUserImgUrl?.dummyUserBaseUrl}${user?.ProfileImage}`
                                      : images5
                                    : user?.ProfileImage
                                    ? `${tagUserImgUrl?.baseUrl}${user?.ProfileImage}`
                                    : images5
                                }
                                alt="img"
                              />
                            </figure>
                            <strong>
                              <span>{user.Firstname}</span> {user?.Lastname}
                            </strong>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {audioChunks && (
                  <div style={{position:"relative"}}>                    
                    <audio controls>
                      <source src={URL.createObjectURL(audioChunks)} type="audio/mp3" />
                    </audio>
                      <span className="close-icon-audio"
                      onClick={() => handleRemoveAudioRecorder()}
                      >
                        &#10006;
                      </span>
                  </div>
                )}
                <div className="footer-type" ref={emojiPickerRef}>
                  {showEmoji && (
                    <Picker data={data} onEmojiSelect={(e) => addEmoji(e)} />
                  )}
                  {/* Quote message part for channel/group */}
                  {reply && (
                    <QuoteMessage
                      message={quoteMessage}
                      isSenderYou={quoteMessage?.user?.UserID === connectid}
                      setShowQuote={setReply}
                    />
                  )}
                  {/* Quote message part for channel/group */}
                  {showmentQuoted && (
                    <QuoteMessage
                      message={mentQuote}
                      stage={"mentor"}
                      isSenderYou={mentQuote?.SenderId === connectid}
                      setShowQuote={setShowMentQuoted}
                    />
                  )}

                  {/* Media Render for Group / Channel*/}
                  {selectedFiles?.length > 0 && selectedFiles.length <= 10 && (
                    <div
                      className={`images d-flex ${
                        !selectedFiles?.length > 0 ? "images-d-none" : ""
                      }`}
                      id="showFilesData"
                    >
                      {fileList}
                      {selectedFiles?.length > 0 && (
                        <div
                          className="type-attach extra_added_file"
                          onClick={handleClick}
                        >
                          <span>&#43;</span>
                          <input
                            type="file"
                            onChange={onSelectFile}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {/* Media Render for Mentor / Student */}

                  {mentorMedia?.length > 0 && mentorMedia.length <= 10 && (
                    <div
                      className={`images d-flex ${
                        !mentorMedia?.length > 0 ? "images-d-none" : ""
                      }`}
                      id="showFilesData"
                    >
                      {fileList}
                      {mentorMedia?.length > 0 && (
                        <div
                          className="type-attach extra_added_file"
                          onClick={handleClick}
                        >
                          <span>&#43;</span>
                          <input
                            type="file"
                            onChange={onSelectFile}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {selectMode && selectedMentMessages?.length > 0 && (
                    <div className="select-flex">
                      <div className="select_popup">
                        <span>{`${selectedMentMessages?.length}  Message Selected `}</span>
                        <button
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#Forward"
                          // onClick={() => {
                          //   setSelectMessageId(selectedMessage);
                          // }}
                        >
                          Forward
                        </button>
                      </div>
                    </div>
                  )}

                  {showCheckbox ? (
                    <div className="select-flex">
                      <div className="select_popup">
                        <span>{`${selectedMessage?.length}  Message Selected `}</span>
                        <button
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#Forward"
                          onClick={() => {
                            setSelectMessageId(selectedMessage);
                          }}
                        >
                          Forward
                        </button>
                      </div>
                    </div>
                  ) : GcMemberCheckExclusivekStatus?.gc_info?.Status ==
                    "Inactive" ? (
                    <p className="d-flex justify-content-center rejoin_btn">
                      {` This ${getGroupChannelType()} is deactivated , Please contact to admin for
                    Activation.`}
                    </p>
                  ) : (
                    <div className="type-group">
                      {userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      gcSettingPermissionData?.dummy_message == 1
                        ? getGroupChannelType() === "group" &&
                          !mentDetails && (
                            <div>
                              <span
                                className="dummy_user"
                                onClick={() => {
                                  dispatch(getDummyUser(GroupChannelID));
                                  setShowEmoji(false);
                                  // setSelectedDummyUser({});
                                }}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {getFirstCharacter}
                              </span>
                              <div
                                className="dropdown-menu border-0-p"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="notification-list_dummyuser">
                                  <strong>Choose a user</strong>
                                  <div className="notification-check">
                                    {dummyUsers?.map((item, index) => {
                                      return (
                                        <div
                                          className="check-noification-box"
                                          key={index}
                                        >
                                          <input
                                            type="radio"
                                            className="checknotification"
                                            id={item?.Lastname}
                                            name="dummyuser"
                                            // onChange={() => setDummyUserObj(item)}
                                            onChange={() =>
                                              setSelectedDummyUser(item)
                                            }
                                            checked={selectedDummyUser === item}
                                          />
                                          <label htmlFor={item?.Lastname}>
                                            <span />
                                            {item?.Firstname} {item?.Lastname}
                                          </label>
                                        </div>
                                      );
                                    })}
                                    <div className="check-noification-box">
                                      <input
                                        type="radio"
                                        className="checknotification"
                                        id="Unmute"
                                        name="dummyuser"
                                        onChange={() =>
                                          setSelectedDummyUser({})
                                        }
                                      />
                                      <label htmlFor="Unmute">
                                        <span />
                                        Unmute
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        : null}
                      <div className="iconchat-bar" ref={closeEmoji}>
                        <i
                          className="fa-regular fa-face-smile emojiIcon"
                          onClick={() => {
                            setShowEmoji(!showEmoji);
                          }}
                        ></i>
                      </div>
                      <textarea
                        rows="1"
                        style={{
                          background: "#f4f8f7",
                          borderRadius: " 15px",
                          overflow: "hidden",
                        }}
                        className="form-control"
                        placeholder="Enter Message"
                        value={text}
                        onBlur={() => fireMentorStopTyping()}
                        onChange={handlechange}
                        ref={input}
                        onKeyDown={handleTextareaKeyDown}
                        onKeyPress={(e) => {
                          if (e?.code === "Enter") {
                            isMessageEdit
                              ? editMentorMessage()
                              : handleSubmit();
                          }
                        }}
                      />
                      <ReactMic
                        record={isPlaying}
                        onStop={onRecordingComplete}
                        mimeType="audio/mp3"
                        className={"voice_recorder"}
                      />
                      <div className="type-attach" onClick={toggleRecording}>
                        {isPlaying ? (
                          <i className={"fa-solid fa-record-vinyl"}></i>
                        ) : (
                          <i className={"fa-solid fa-microphone"}></i>
                        )}
                      </div>
                      {gcPermissionPinMessage?.SendMedia == 1 ||
                      userRoleSlugSettingPermission === "super-admin" ||
                      gcSettingPermissionData?.full_permission == 1 ||
                      mentDetails ? (
                        <div className="type-attach" onClick={handleClick}>
                          <i className="fa-solid fa-paperclip fileupload" />
                          <input
                            ref={hiddenFileInput}
                            type="file"
                            onChange={onSelectFile}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      ) : null}
                      <button
                        className={
                          selectedFiles.length > 10 ? "disable_button" : "send"
                        }
                        type="button"
                        onClick={
                          isMessageEdit ? editMentorMessage : handleSubmit
                        }
                        disabled={selectedFiles.length > 10}
                      >
                        <i className="fa-solid fa-paper-plane"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : GcMemberCheckBlockStatus === "Blocked" &&
              GcMemberCheckBlockStatus === "Blocked" ? (
              <p className="d-flex justify-content-center rejoin_btn">
                You Are Blocked Please Connect to Admin
              </p>
            ) : GcMemberCheckExclusivekStatus?.gc_member_info === null &&
              GcMemberCheckExclusivekStatus?.gc_info?.Status !== "Inactive" &&
              GcMemberCheckBlockStatus !== "GCLeft" ? (
              <p
                className="d-flex justify-content-center rejoin_btn"
                onClick={() => {
                  dispatch(
                    joinExclusiveGC({
                      GCSubscriptionPlanID:
                        groupChannelDetails?.gc_subscription_plan[0]
                          ?.GCSubscriptionPlanID,
                      GroupChannelIDfromapi,
                    })
                  );
                }}
              >
                {`Join ${getGroupChannelType()}`}
              </p>
            ) : GcMemberCheckExclusivekStatus?.gc_member_info !== undefined &&
              GCMemberSubscriptionPlan === 0 &&
              GcMemberCheckBlockStatus === "GCLeft" &&
              GcMemberCheckExclusivekStatus?.gc_member_info !== null ? (
              <p
                className="d-flex justify-content-center rejoin_btn"
                onClick={reJoinGBtn}
              >
                {`Rejoin ${getGroupChannelType()}`}
              </p>
            ) : getGroupChannelType() !== "channel" ? (
              <p className="d-flex justify-content-center rejoin_btn">
                You do not have permission to send messages.
              </p>
            ) : null}
          </div>
        )

        // **************************************************/////////Normal Chatting List *******************************
      }
    </>
  );
};

export default React.memo(ChatboxInner);
