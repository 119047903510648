import { createSlice } from "@reduxjs/toolkit";
import { baseUrlMain } from "../../store/axios";
import { tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";

const initialState = {
  emojiList: [],
  emptyMessage: false,
};

export const Reaction = createSlice({
  name: "Reaction",
  initialState,
  reducers: {
    emojis: (state, action) => {
      state.emojiList = action.payload;
    },
    notInAnyGroup: (state, action) => {
      state.emptyMessage = true;
    },
  },
});

export const { emojis, notInAnyGroup } = Reaction.actions;

export default Reaction.reducer;

export const getEmoji = () => {
  return async (dispatch) => {
    try {
      await baseUrlMain.get("emoji").then((response) => {
        if (response?.data?.status === 200) {
          dispatch(emojis(response?.data?.data));
        }  else if (response?.data?.status === 401) {
          tokenExpireCheckStatus(false, response?.data?.message);
          }
      });
    } catch (error) {
      console.log("error", error);
    }
  };
};
