import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showGlobalSearchProductPopup:false,
    SubscriptionPlanShowPopupData:{},
    showChatlist:false,
    showGlobalSearchSubscriptionPopup:false
}

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState,
    reducers:{
        setShowGlobalSearchProductPopup:(state,action)=>{
            state.showGlobalSearchProductPopup = action.payload
        },
        setSubscriptionPlanShowPopupData:(state,action)=>{
           
            state.SubscriptionPlanShowPopupData = action.payload
        },
        setShowChatlist:(state,action)=>{
            state.showChatlist = action.payload
        },
        setShowGlobalSearchSubscriptionPopup:(state,action)=>{
            state.showGlobalSearchSubscriptionPopup = action.payload
        }
    }
})

export const {setShowGlobalSearchProductPopup,setSubscriptionPlanShowPopupData,setShowChatlist,setShowGlobalSearchSubscriptionPopup} = notificationSlice.actions;
export default notificationSlice.reducer;