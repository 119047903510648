import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
import { toast } from "react-toastify";
import { getGlobalAuthToken } from "../../Component/GlobalLocalStorage";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialUserState = {
  storeChangePassword: "",
  status: STATUSES.IDLE,
};

const changePassword = createSlice({
  name: "changePassword",
  initialState: initialUserState,

  reducers: {
    changePasswordData(state, action) {
      state.storeChangePassword = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { changePasswordData, setStatus } = changePassword.actions;
export default changePassword.reducer;

// Change password
export const fetchChangePasswordData = (payload) => {
  const {
    UserID,
    CurrentPassword,
    NewPassword,
    NewConfirmPassword,
    setLoading,
    navigate,
  } = payload;
  const formData = new FormData();
  formData.append("UserID", UserID);
  formData.append("CurrentPassword", CurrentPassword);
  formData.append("NewPassword", NewPassword);
  formData.append("NewConfirmPassword", NewConfirmPassword);
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    setLoading(true);
    try {
      baseUrlAuth
        .post("resetpassword", formData)
        .then((res) => {
          if (res?.data?.status === 200) {
            toast.success(res?.data?.message);
            navigate("/setting");
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((error) => console.log(error),  setLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
};
