import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import {
  globalLocalStrogeToken,
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";
const initialState = {
  dummyUser: [],
};
export const DummyUserReducer = createSlice({
  name: "DummyUserReducer",
  initialState,
  reducers: {
    dummyuserList: (state, action) => {
      state.dummyUser = action.payload?.list;
    },
  },
});
export const { dummyuserList } = DummyUserReducer.actions;
export default DummyUserReducer.reducer;

export const getDummyUser = (channelGroupID) => {
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(`group_channels/${channelGroupID}/dummy_user`)
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(dummyuserList(response?.data?.data));
          } else if (response?.data?.status === 401) {
            tokenExpireCheckStatus(false, response?.data?.message);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
};
