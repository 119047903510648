import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../store/axios";
import { tokenExpireCheckStatus } from "../Component/GlobalLocalStorage";
import { FullChat, GCChatlistData, SubscriptionCount } from "./GetChat";
import { socket } from "../store/SocketIo";
import { getExclusiveGc } from "./ExcluciveGcReducer";
import { setShowGlobalSearchSubscriptionPopup, setSubscriptionPlanShowPopupData } from "./notificationReducer";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  groupChannelData: [],
  exclusiveGroupChannelData: [],
  inHome: "",
  leaveGroup: [],
  exclusiveJoinGCData: "",
  reJoinUser: "",
  exclusiveJoinGCStatusData: false,
  status: STATUSES.IDLE,
};
export const DetailsChannelGroup = createSlice({
  name: "DetailsChannelGroup",
  initialState,
  reducers: {
    groupChannelDetailsData: (state, action) => {
      state.groupChannelData = action.payload;
    },
    exclusiveGroupChannelDetailsData: (state, action) => {
      state.exclusiveGroupChannelData = action.payload;
    },
    setInHome: (state, action) => {
      state.inHome = action.payload;
    },
    leaveGc: (state, action) => {
      state.leaveGroup = action.payload;
    },
    reJoin: (state, action) => {
      state.leaveGroup = action.payload;
    },
    exclusiveJoinGC: (state, action) => {
      state.exclusiveJoinGCData = action.payload;
    },
    exclusiveJoinGCStatus: (state, action) => {
      state.exclusiveJoinGCStatusData = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});
export const {
  groupChannelDetailsData,
  exclusiveGroupChannelDetailsData,
  setInHome,
  leaveGc,
  reJoin,
  setStatus,
  exclusiveJoinGC,
  exclusiveJoinGCStatus,
} = DetailsChannelGroup.actions;
export default DetailsChannelGroup.reducer;

export const DetailsChannelGroupApi = (payload) => {
  return async (dispatch) => {
    // dispatch(setStatus(STATUSES.LOADING));
    try {
      let res = await baseUrlEdit.get(`/group_channels/${payload}/details`);
      if (res) {
        if (res?.data?.status === 200) {
          dispatch(groupChannelDetailsData(res?.data?.data));
          dispatch(exclusiveJoinGCStatus(true));
          dispatch(exclusiveGroupChannelDetailsData(res?.data?.data));
          dispatch(setSubscriptionPlanShowPopupData(res?.data?.data));
          setTimeout(() => {
            dispatch(setStatus(STATUSES.IDLE));
          }, 1000);
          return res?.data?.data;
        } else if (res?.data?.status === 401) {
          tokenExpireCheckStatus(false, res?.data?.message);
        }
      }
    } catch (error) {
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

// Leave Group and Channel
export const leaveGroupFucntion = (payload) => {
  const { GcMemberId, GroupChannelID } = payload;
  return async (dispatch) => {
    const form = new FormData();
    form.append("_method", "patch");
    try {
      await baseUrlEdit
        .post(`gc_member/${GcMemberId}/leave_gc`, form)
        .then((res) => {
          if (res?.status === 200) {
            dispatch(DetailsChannelGroupApi(GroupChannelID)).then((res) => {
              socket.emit("updateSubscriptionCount", {
                GroupChannelID: res?.gc_member_info?.GroupChannelID,
                GCMemberID: res?.gc_member_info?.GCMemberID,
                UserID: res?.gc_member_info?.UserID,
              });
            });
            dispatch(leaveGc(res.data));
            toast.success(res.data?.message);
            // dispatch(DetailsChannelGroupApi(GroupChannelID));
          } else {
            toast.success(res.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
};

// ReJoin Group / Channel
export const reJoinFunction = (payload) => {
  const form = new FormData();
  form.append("_method", "patch");
  const { GcMemberId, GroupChannelID } = payload;
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .post(`gc_member/${GcMemberId}/rejoin_gc`, form)
        .then((res) => {
          if (res.data.status === 200) {
            dispatch(reJoin(res.data.data));
            toast.success(res.data?.message);
            try {
              baseUrlEdit
                .get(`/group_channels/${GroupChannelID}/details`)
                .then((res) => {
                  if (res?.data?.status === 200) {
                    dispatch(groupChannelDetailsData(res?.data?.data));
                    dispatch(setStatus(STATUSES.IDLE));
                  } else if (res?.data?.status === 401) {
                    tokenExpireCheckStatus(false, res?.data?.message);
                  }
                });
            } catch (error) {
              console.log("error", error);
              dispatch(setStatus(STATUSES.ERROR));
            }
          } else {
            toast.error(res?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
};

// ******************************************* Exclusive section ReJoinGC API //.*************************************************
// Exclusive sec join First time Group/Channel
export const joinExclusiveGC = (payload) => {
  const { GCSubscriptionPlanID, GroupChannelID, setShow, IsRenew } = payload;
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    const formData = new FormData();
    formData.append("GCSubscriptionPlanID", GCSubscriptionPlanID);
    IsRenew && formData.append("IsRenew", IsRenew);
    !IsRenew && formData.append("IsRenew", 0);
    // form.append("PaymentGateway", "");
    // form.append("GatewayPaymentID", "");
    // form.append("GatewayOrderID", "");
    // form.append("PaymentMethod", "");
    // form.append("GatewayStatus", "");
    // form.append("TotalPrice", "");
    // form.append("PaymentDate", "");
    // form.append("PaymentStatus", "");
    try {
      await baseUrlEdit.post(`group_channels/join_gc`, formData).then((res) => {
        if (res?.status === 200) {
          dispatch(exclusiveJoinGC(res.data));
          dispatch(getExclusiveGc());
          setShow(true);
          dispatch(setShowGlobalSearchSubscriptionPopup(false));
          dispatch(DetailsChannelGroupApi(GroupChannelID)).then((res) => {
            socket.emit("updateSubscriptionCount", {
              GroupChannelID: res?.gc_member_info?.GroupChannelID,
              GCMemberID: res?.gc_member_info?.GCMemberID,
              UserID: res?.gc_member_info?.UserID,
            });
          });
          socket.emit("auth-user", {
            userId: localStorage.getItem("UserID"),
          });
          socket.emit(
            "chatList",
            {
              userId: localStorage.getItem("gtfconnectid"),
              GroupChannelID: localStorage.getItem("GroupChannelID"),
              page: 1,
            },
            (response) => {
              dispatch(FullChat(response?.data?.chatData?.rows));
              dispatch(SubscriptionCount(response?.data?.subscriptionCount));
              dispatch(GCChatlistData(response?.data));
            }
          );

          toast.success(res.data?.message);
        } else {
          toast.error(res.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
