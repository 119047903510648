import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ConfirmationDialog = ({ title, message, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <div>
      {confirmAlert({
        title: title,
        message: message,
        overlayClassName: "confirm_box_savedmessage_delete",
        buttons: [
          {
            label: "Ok",
            onClick: handleConfirm,
          },
          {
            label: "Cancel",
            onClick: () => {}, // Empty click handler
          },
        ],
      })}
    </div>
  );
};

export default ConfirmationDialog;
