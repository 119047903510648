import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';

const Test = ({ placeholder }) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const handleButtonClick = () => {
    // Access the input value and perform any desired action
    console.log("Input value:", content);
  };

  const config = {
    toolbar: false,
    enableDragAndDropFileToEditor: false,
    showPlaceholder: placeholder ? true : false,
    statusbar:false,
    // enter: 'br',
    // disablePlugins: ['source'], 
  };

  const handleBlur = (newContent) => {
    // setContent(newContent);
    // if (editor.current && editor.current.editor) {
    //   editor.current.editor.focus();
    // }
  };

  const handleFocus = () => {
    // Manually set the content when the editor gains focus
    editor.current.editor.setContent(content);
  };

  return (
    <div>
      {/* <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={(newContent) => setContent(newContent)}
      /> */}

<JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={newContent => {}}
		/>
      <button onClick={handleButtonClick}>Get Input Value</button>
    </div>
  );
};

export default Test;
