import React from "react";
import SettingChat from "../../Component/chatLeft/SettingChat";
import Sidebar from "../../Component/sidebar";
import { arrowLeftIcon, changePassword } from "../../Images/images";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ChatContent from "../../Component/chatContent";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { fetchChangePasswordData } from "../../reducer/changePassword";
const ChangePassword = () => {
  const [savedMessageState, setSavedMesssageState] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserID = localStorage.getItem("UserID");

  // update Password handle
  const updatePassword = async (values) => {
    dispatch(
      fetchChangePasswordData({
        UserID: UserID,
        CurrentPassword: values.currentPassword,
        NewPassword: values.newPassword,
        NewConfirmPassword: values.confirmPassword,
        navigate,
        setLoading,
      })
    );
  };

  // change password validation
  const SignupSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is Required"),
    newPassword: Yup.string().required("New Password is Required"),
    confirmPassword: Yup.string()
      .required("Please Re-enter new password")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
  });

  return (
    <div className="d-flex main_body_inner">
      <Sidebar
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />
      <SettingChat
        title="Settings"
        savedMessageState={savedMessageState}
        setSavedMesssageState={setSavedMesssageState}
      />

      {savedMessageState ? (
        <ChatContent
          savedMessageState={savedMessageState}
          setSavedMesssageState={setSavedMesssageState}
          showCheckbox={showCheckbox}
          setShowCheckbox={setShowCheckbox}
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
        />
      ) : (
        <div className="chatbox-area">
          <div className="chatbox-right-bar">
            <div className="account-setting-inner">
              <div className="changepassword-blog">
                <div className="change-password-header">
                <strong>
                  {/* <img
                    src={arrowLeftIcon}
                    alt="arrowLeftIcon"
                    className="change_password_back_arrow"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  /> */}
                  Change Password
                </strong>
                </div>
                <div className="change-password-inner">
                  <div className="change-password-form">
                    <h2>Change Password</h2>
                    <Formik
                      initialValues={{
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                      }}
                      validationSchema={SignupSchema}
                      onSubmit={(values) => {
                        updatePassword(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="form-group">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Current Password"
                              name="currentPassword"
                            />
                            <span>
                              <img src={changePassword} alt="user-icon" />
                            </span>
                            {errors.currentPassword &&
                              touched.currentPassword ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.currentPassword}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="New Password"
                              name="newPassword"
                            />
                            <span>
                              <img src={changePassword} alt="user-icon" />
                            </span>
                            {errors.newPassword && touched.newPassword ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.newPassword}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <Field
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                            />
                            <span>
                              <img src={changePassword} alt="user-icon" />
                            </span>
                            {errors.confirmPassword &&
                              touched.confirmPassword ? (
                              <div className="text-danger d-flex mt-2">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-btn text-center">
                            <LoadingButton
                              loading={loading}
                              type="submit"
                              className="btn btn-profile edit_profile_button"
                            >
                              Update Password
                            </LoadingButton>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
