import React from "react";
import Moment from "react-moment";
import moment from "moment";
import { quoteimgGreen } from "../../Images/images";
import { highlightUsernames } from "../../common";

const QuoteMessage = ({ message, isSenderYou, stage, setShowQuote }) => {
  return (
    <ul className="active">
      <li className="messageReceived quoateMessage">
        <div className="quotestyleone">
          <span>
            <img src={quoteimgGreen} alt="quoteimg" />
          </span>
        </div>
        <div className="quotestylesecond">
          <span>Quote</span>
          <p>{stage === "mentor" ?message?.Content=== "" &&message?.media?.length >0 ? "Media Files" : message?.Content : message?.Message === "" && message?.media?.length > 0 ?" Media Files": highlightUsernames(message.Message)}</p>
          <p>
            {isSenderYou
              ? "You ,"
              : stage === "mentor"
              ? ` ${message.senderData?.Firstname} ${message.senderData?.Lastname=== null ? "" :message.senderData?.Lastname} , `
              : message?.dummyUserData === null ? `${message.user?.Firstname} ${message.user?.Lastname === null ? "":message.user?.Lastname} `: `${message.dummyUserData?.Firstname} ${message.dummyUserData?.Lastname === null ?"":message.dummyUserData?.Lastname} ,`}
            <small>
              {moment(message?.CreatedAt).format("DD-MM-YYYY ")}
              <Moment fromNow>
                {moment(message?.CreatedAt).add(1, "hours")}
              </Moment>
            </small>
          </p>
        </div>
        <i className="fa-solid fa-xmark" onClick={() => setShowQuote(false)} />
      </li>
    </ul>
  );
};

export default React.memo(QuoteMessage);
