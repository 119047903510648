import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";
const GCChatlistVideoPlayPause = ({
  media,
  baseUrlMedia,
  checkGlobalPermission,
  videoPlay,
  index,
  bool,
  setShowGallary,
  setGallary,
  count,
  allItems,
  stage,
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState();
  const mentDetails = useSelector((state) => state?.MentorList?.details);
  const handlePlayPause = () => {
    if (videoRef?.current?.paused) {
      setCurrentPlayingVideo(videoRef.current.src);
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef?.current?.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    $("#myModal").on("shown.bs.modal", function () {
      $("#video1")[0].play();
    });
    $("#myModal").on("hidden.bs.modal", function () {
      $("#video1")[0].pause();
    });
  }, []);

  return (
    <>
      <div className="gc_video_icon_play-pause">
        {/* {checkGlobalPermission?.[6]?.SettingValue == 1 ? (
          <video
            className="video_chatlist_video"
            src={`${stage === "save" ? baseUrlMedia :mentDetails ? baseUrlMedia: baseUrlMedia?.mediaUrl}${
              media?.StoragePath
            }${media?.FileName}`}
            autoPlay={true}
          />
        ) : ( */}
          <video
            ref={videoRef}
            className="video_chatlist_video"
            src={`${stage === "save" ? baseUrlMedia :mentDetails ? baseUrlMedia: baseUrlMedia?.mediaUrl}${
              media?.StoragePath
            }${media?.FileName}`}
            // poster={`${
            //   stage === "save"
            //     ? `${savedMessageMediaFilesBaseUrl?.thumbnail_url}/${media?.Thumbnail}`
            //     : baseUrlMedia?.mediaUrl
            // }${media?.StoragePath}${media?.Thumbnail}`}
            onClick={() => handleVideoClick(index)}
          />
        {/* )} */}

        <div
          className="video_play_icon"
          onClick={() => {
            handlePlayPause(index);
          }}
          data-toggle="modal"
          data-target="#myModal"
        >
          {/* {checkGlobalPermission?.[6]?.SettingValue == 1 ? (
            ""
          ) : ( */}
            <img src={videoPlay} alt="play" />
          {/* )} */}
        </div>
        {bool && (
          <div
            className="image-counter"
            onClick={() => {
              setGallary(allItems);
              setShowGallary(true);
            }}
          >
            + {count}
          </div>
        )}
      </div>
      {currentPlayingVideo && (
        <div
          className="modal fade video_show_popup"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          // onClick={() => setCurrentPlayingVideo("")}
        >
          <div className="modal-dialog videoshowpopup_center" role="document">
            <div className="modal-content">
              <div className="modal-header pb-1 pt-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setCurrentPlayingVideo("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pt-0">
                <video
                  controls
                  autoPlay
                  id="video1"
                  style={{ width: "100%", height: "500px" }}
                >
                  <source src={currentPlayingVideo} type="video/mp4" controls />
                </video>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GCChatlistVideoPlayPause;
