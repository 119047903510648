import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { globalLocalStrogeToken, tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";
const initialState = {
  subscriberList: [],
};

export const SubscriberReducer = createSlice({
  name: "SubscriberReducer",
  initialState,
  reducers: {
    Subscribers: (state, action) => {
      state.subscriberList = action.payload;
    },
    // Subscribers: (state, action) => {
    //   state.subscriberList =
    //     action?.payload?.from == 1
    //       ? action.payload.list
    //       : [...state.subscriberList, ...action.payload?.list];
    // },
  },
});
export const { Subscribers } = SubscriberReducer.actions;
export default SubscriberReducer.reducer;

export const getSubscribers = (payload) => {
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(
          `group_channels/${payload.channelGroupID}/subscribers?page=${payload?.subspage}&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === 200) {
            dispatch(Subscribers(response?.data?.data));
          }  else if (response?.data?.status === 401) {
            tokenExpireCheckStatus(false, response?.data?.message);
            }
        });
    } catch (error) {
      console.log(error);
    }
  };
};
