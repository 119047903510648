import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../../store/axios";
import { socket } from "../../store/SocketIo";
import { DetailsChannelGroupApi } from "../DetailsChannelGroup";
import { UpdatedList } from "../GetChat";
import { globalLocalStrogeToken } from "../../Component/GlobalLocalStorage";

const tokenEditProfile = localStorage.getItem("token");
const UserID = localStorage.getItem("UserID");

const initialState = {
  groupinfo: "",
};

export const EditGroupInfo = createSlice({
  name: "EditGroupInfo",
  initialState,

  reducers: {
    updateGroupChannelInfo: (state, action) => {
      state.groupinfo = action.payload;
    },
  },
});
// const CLASS_NAME_OPEN = "modal-open";
export const { updateGroupChannelInfo } = EditGroupInfo.actions;

export default EditGroupInfo.reducer;
export const updateGroupInfo = (payload) => {
  const {
    channelName,
    Type,
    setOpenProfile,
    inHome,
    chatLeftShowList,
    isSetting,
  } = payload;

  return async (dispatch) => {
    const form = new FormData();
    form.append("Name", payload?.channelName);
    form.append("_method", "patch");
    form.append("Description", payload?.Description);
    form.append("Type", payload?.Type);
    form.append("AccessType", payload?.channelAccessType);
    if (payload?.imageName) {
      form.append("ProfileImage", payload?.imageName);
    }

    try {
      await baseUrlEdit
        .post(`group_channels/${payload?.channelGroupID}/update`, form, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            setOpenProfile(false);
            if (!isSetting) {
              if (inHome) {
                let foundIndex = chatLeftShowList?.getRecentTab?.findIndex(
                  (item) =>
                    item?.ChatType === "gc" &&
                    item?.GroupChannelID === payload?.channelGroupID
                );
                let newArr = [...chatLeftShowList?.getRecentTab];
                if (foundIndex !== -1) {
                  newArr[foundIndex] = {
                    ...newArr[foundIndex],
                    groupDetail: {
                      ...newArr[foundIndex]?.groupDetail,
                      Name: channelName,
                    },
                  };
                }
                let updatedChatLeft = {
                  ...chatLeftShowList,
                  getRecentTab: newArr,
                };

                dispatch(UpdatedList(updatedChatLeft));
              } else {
                let foundIndex = chatLeftShowList?.gcData?.findIndex(
                  (item) => item?.GroupChannelID === payload?.channelGroupID
                );
                let newArr = [...chatLeftShowList?.gcData];
                if (foundIndex !== -1) {
                  newArr[foundIndex] = {
                    ...newArr[foundIndex],
                    group: {
                      ...newArr[foundIndex]?.group,
                      Name: channelName,
                    },
                  };
                }
                let updatedChatLeft = {
                  ...chatLeftShowList,
                  gcData: newArr,
                };

                dispatch(UpdatedList(updatedChatLeft));
              }
            }
            // if (inHome == true) {
            //   socket.emit("auth-user", { userId: UserID }, (response) => {
            //     console.log("getting", response);
            //     dispatch(UpdatedList(response?.data));
            //   });
            // } else {
            //   socket.emit(
            //     "auth-user",
            //     { userId: UserID, filter: `${Type}` },
            //     (response) => {
            //       dispatch(UpdatedList(response?.data));
            //     }
            //   );
            // }

            toast.success(`${Type} details updated successfully`);
            dispatch(DetailsChannelGroupApi(payload?.channelGroupID));
          } else if (
            response?.data?.status == 400 &&
            channelName?.length > 100
          ) {
            toast.error(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
};
