import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Comment: [],
  commentView: "",
  commentEdit: "",
  commentDelete: "",
};

export const GroupChatListComment = createSlice({
  name: "comment",
  initialState,
  reducers: {
    FullChat: (state, action) => {
      state.Chats = action.payload;
    },
  },
});

export const { FullChat } = GroupChatListComment.actions;

export default GroupChatListComment.reducer;
