import React from "react";
import { extractUsernameAndText, highlightUsernames } from "../../common";
import { pinGreenIcon, user1 } from "../../Images/images";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const PinMessageTop = ({
  pinMessage,
  GcMemberCheckBlockStatus,
  GCMemberInfo,
  GCMemberSubscriptionPlanNull,
  showPinMessage,
  setPinMesssageState,
  setShowMenu,
  stage,
  setQuote,
  setSearchMessage,
}) => {
  const mentDetails = useSelector((state) => state?.MentorList?.details);
  // Group channel chatlist url
  const baseUrlMedia = useSelector((state) => state?.GetChat?.chatlistData);
  // Mentor list url
  const mentorMediaURL = useSelector((state) => state?.MentorList);
  return (
    <div
      className={
        GcMemberCheckBlockStatus === "Blocked" ||
        GcMemberCheckBlockStatus === "GCLeft" ||
        GCMemberInfo === null ||
        GCMemberSubscriptionPlanNull === null
          ? "d-none"
          : "pin_bar"
      }
      onClick={() => {
        !mentDetails && showPinMessage();
        mentDetails
          ? setQuote(pinMessage?.allPin[0]?.messageData)
          : setSearchMessage(pinMessage?.data?.allPinData[0]?.chat);
        !mentDetails && setShowMenu(false);
      }}
    >
      {!mentDetails
        ? pinMessage?.data?.allPinData &&
          pinMessage?.data?.allPinData?.length > 0 && (
            <div className="pin-bar-inner">
              <figure>
                <img
                  src={
                    pinMessage?.data?.allPinData?.[0]?.chat?.user?.ProfileImage
                    ?`${baseUrlMedia?.baseUrl}/${pinMessage?.data?.allPinData?.[0]?.chat?.user?.ProfileImage}`
                    : pinMessage?.data?.allPinData?.[0]?.chat?.dummyUserData
                      ? `${pinMessage?.data?.dummyUserBaseUrl}/${pinMessage?.data?.allPinData?.[0]?.chat?.dummyUserData?.ProfilePic}`
                      : user1
                  }
                  alt="pinMessageimage"
                />
              </figure>
              <div className="pin-bar-content">
                <strong>Pinned Message</strong>
                <p>
                  {pinMessage?.data?.allPinData[0]?.chat?.Message === ""
                    ? "Media Files"
                    : highlightUsernames(
                        pinMessage?.data?.allPinData[0]?.chat?.Message
                      )}
                </p>
                <Link
                  className="pin-link"
                  onClick={() => setPinMesssageState(true)}
                >
                  <img src={pinGreenIcon} alt="pinIcon" />
                  <span>{pinMessage?.data?.allPinData?.length}</span>
                </Link>
              </div>
            </div>
          )
        : pinMessage?.allPin?.length > 0 && (
            <div className="pin-bar-inner">
              <figure>
                <img
                  src={`${
                    (pinMessage?.allPin[0]?.messageData?.SenderId !==
                      pinMessage?.allPin[0]?.messageData?.senderData
                        ?.ProfileImage) ===
                    null
                      ? user1
                      : pinMessage?.allPin[0]?.messageData?.senderData
                      ? `${pinMessage?.profile_image_url}${pinMessage?.allPin[0]?.messageData?.senderData?.ProfileImage}`
                      : pinMessage?.allPin[0]?.messageData?.recipientData
                          ?.ProfileImage === null
                      ? user1
                      : `${pinMessage?.profile_image_url}${pinMessage?.allPin[0]?.messageData?.recipientData?.ProfileImage}`
                  }`}
                  alt="pinMessageimage"
                />
              </figure>
              <div className="pin-bar-content">
                <strong>Pinned Message</strong>
                <p>
                  {pinMessage?.allPin[0]?.messageData?.ChatType === "file"
                    ? "Media"
                    :highlightUsernames(pinMessage?.allPin[0]?.messageData?.Content)}
                </p>
                <Link
                  className="pin-link"
                  onClick={() => setPinMesssageState(true)}
                >
                  <img src={pinGreenIcon} alt="pinIcon" />
                  <span>{pinMessage?.allPin?.length}</span>
                </Link>
              </div>
            </div>
          )}
    </div>
  );
};

export default React.memo(PinMessageTop);
