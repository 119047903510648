import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { toast } from "react-toastify";
import {
  globalLocalStrogeToken,
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  totalReactionList: [],
  updateReaction: [],
  selectedEmojiList: [],
  status: STATUSES.IDLE,
};

export const GcReactionData = createSlice({
  name: "GCReaction",
  initialState,
  reducers: {
    GcReactionList: (state, action) => {
      state.totalReactionList =
        action.payload.list[0].ReactionID === 1
          ? action.payload.list
          : [...state.totalReactionList, ...action.payload.list];
    },
    UpdateReactionList: (state, action) => {
      state.updateReaction = action.payload;
    },
    selectedEmoji: (state, action) => {
      state.selectedEmojiList = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { GcReactionList, updateReaction, selectedEmoji, setStatus } =
  GcReactionData.actions;
export default GcReactionData.reducer;
export const GetTotalGCReaction = (payload) => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      await baseUrlEdit
        .get(
          `group_channels/${payload?.channelGroupID}/reactions?is_active=0&page=${payload?.gcReactionListData}&per_page=25`,
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(GcReactionList(res?.data?.data));
            dispatch(setStatus(STATUSES.IDLE));
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

export const UpdateReactionList = (payload) => {
  const { updateReactionListed, channelGroupID } = payload;
  console.log(
    "updateReactionListed",
    updateReactionListed,
    "channelGroupID",
    channelGroupID
  );

  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    const form = new FormData();
    let arr = [];
    for (let i = 0; i < updateReactionListed?.length; i++) {
      arr.push(updateReactionListed[i]?.ReactionID);
    }
    form["ReactionID"] = arr;
    try {
      await baseUrlEdit
        .patch(
          `group_channels/${channelGroupID}/reaction/update`,
          { ReactionID: arr },
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // dispatch(
            //   GetTotalGCReaction({
            //     gcReactionListData: 1,
            //     channelGroupID: channelGroupID,
            //   })
            // );
            dispatch(getSelectedEmojiList(channelGroupID));
            dispatch(setStatus(STATUSES.IDLE));
            toast.success("Reactions Updated Successfully");
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};

export const getSelectedEmojiList = (payload) => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));
    try {
      baseUrlEdit
        .get(
          `group_channels/${payload}/reactions?is_active=1&page=1&per_page=200`,
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(
              selectedEmoji([
                res?.data?.data?.like_reaction,
                ...res?.data?.data?.list,
              ])
            );
            dispatch(setStatus(STATUSES.IDLE));
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(setStatus(STATUSES.ERROR));
    }
  };
};
