import { createSlice } from "@reduxjs/toolkit";
import { globalLocalStrogeToken } from "../../Component/GlobalLocalStorage";
import { toast } from "react-toastify";

const initialState = {
  GCMuteNotificationData: "",
};
export const GCMuteNotification = createSlice({
  name: "GCMuteNotification",
  initialState,

  reducers: {
    muteNotificationRequest: (state, action) => {
      state.GCMuteNotificationData = action.payload;
    },
  },
});
export const { muteNotificationRequest } = GCMuteNotification.actions;
export default GCMuteNotification.reducer;

// Mute /unmute notification
export const GCMuteNotificationUpdateUserSetting = (payload) => {
  const{SettingID,CustomValue,GCMemberID,SubValue,setShowNotificationPopup}=payload;
  const muteNotificationData = new URLSearchParams();
  muteNotificationData.append("SettingID", SettingID);
  muteNotificationData.append("Value", CustomValue);
  muteNotificationData.append("GCMemberID", GCMemberID);
  muteNotificationData.append("SubValue", SubValue);
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_EDIT}group_channels/member_settings/update`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${globalLocalStrogeToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: muteNotificationData,
      });
      const muteNotificationResult = await response.json();
      if(muteNotificationResult?.status===200){
        dispatch(muteNotificationRequest(muteNotificationResult))
        setShowNotificationPopup(false)
        toast.success(muteNotificationResult?.message)
        return muteNotificationResult;
      }else{
        toast.error(muteNotificationResult?.message)

      }
    } catch (error) {
      console.log("error :", error);
    }
  };
};
