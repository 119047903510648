import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../../store/axios";
import { globalLocalStrogeToken, tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";
const initialState = {
  globalSetting: [],
  globalSettingBlockList: [],
};
export const globalSettingPermission = createSlice({
  name: "globalSettingPermission",
  initialState,
  reducers: {
    getGlobalSettingData: (state, action) => {
      state.globalSetting = action.payload;
    },
    globalSettingBlockListData: (state, action) => {
      state.globalSettingBlockList = action.payload;
    },
  },
});

export const { getGlobalSettingData, globalSettingBlockListData } =
  globalSettingPermission.actions;

export default globalSettingPermission.reducer;

export const updateGlobalSetting = (payload) => {
  const { SettingID, CustomValue } = payload;
  const formData = new URLSearchParams();
  formData.append("SettingID", SettingID);
  formData.append("CustomValue", CustomValue);
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_EDIT}user/update_user_setting`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${globalLocalStrogeToken()}`,
        },
        body: formData,
      });
      const res = await response.json();
      if (res?.status === 200) {
        dispatch(getGlobalSettingData(res));
        toast.success(res?.message);
      } else if (res?.status === 401) {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const globalSettingBlockList = () => {
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(`user/blocked-users`)
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(globalSettingBlockListData(res?.data));
          } else {
            toast.success(res?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
};

