import { createSlice } from "@reduxjs/toolkit";
import { baseUrlAuth } from "../../store/axios";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  contactUsData: "",
  status: STATUSES.IDLE,
};
export const contactUsPageData = createSlice({
  name: "contactUsPageData",
  initialState,
  reducers: {
    contactUsPageDatas: (state, action) => {
      state.contactUsData = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { contactUsPageDatas, setStatus } = contactUsPageData.actions;
export default contactUsPageData.reducer;

export const contactUsPageFetchData = () => {
  return async (dispatch) => {
    dispatch(setStatus(STATUSES.LOADING));

    try {
      baseUrlAuth.get("contact_us").then((res) => {
        if (res?.data?.status === 200) {
          dispatch(contactUsPageDatas(res?.data?.data));
          dispatch(setStatus(STATUSES.IDLE));
        } else {
          dispatch(setStatus(STATUSES.ERROR));
        }
      });
    } catch (error) {
      console.log("ContactUs page error : ", error);
    }
  };
};
