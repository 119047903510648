import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import Mentor from "./pages/mentor";
import Student from "./pages/student";
import Group from "./pages/group";
import Channel from "./pages/channel";
import Setting from "./pages/setting";
import LogOut from "./pages/logout";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import OtpVerification from "./pages/Auth/OtpVerification";
import ProtectRoutes from "./pages/ProtectRoutes/ProtectRoutes";
import { ToastContainer } from "react-toastify";
import EditProfile from "./pages/editProfile";
import ChangePassword from "./pages/changePassword";
import Header from "./Component/Header/Header";
import Test from "./Test";
import { useEffect } from "react";
import { baseUrlEdit } from "./store/axios";
import { globalLocalStrogeToken } from "./Component/GlobalLocalStorage";
import { useDispatch } from "react-redux";
import { setUser, setUserRole } from "./reducer/UserReducer";
import { messaging } from "./firebase";

// import "firebase/compat/messaging";
import ContactUs from "./pages/contactUs";
import { logOutTokenExpire } from "./common";
import NotFound from "./pages/Not Found";
import { fetchMentors } from "./reducer/MentorReducer";
import { socket } from "./store/SocketIo";
import { confirmAlert } from "react-confirm-alert";
import { getUserUpdateProfileDetails } from "./reducer/EditProfileReducer/UpdateProfile";
import firebase from "firebase/compat/app"; // Update this import
import "firebase/compat/messaging"; // Update this import
import NotificationRoute from "./pages/notification";
import Users from "./pages/user";
import MaintenanceModeDailog from "./common/MaintenanceModeDailog";
import { newMessageReceived } from "./reducer/MessageReceived";


function App() {
  const dispatch = useDispatch();
  // Generate url for notification
  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       messaging.getToken().then((token) => {
  //         localStorage.setItem("firebaseToken", token);
  //         // Send the token to your server for sending notifications
  //       });
  //     }
  //   });
  // }, []);

  useEffect(() => {
    // const messaging = firebase.messaging();
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("Notification permission granted.");
        messaging.getToken().then((token) => {
          localStorage.setItem("firebaseToken", token);
          // Send the token to your server for sending notifications
        });
      }
    });
  }, [messaging]);

  // useEffect(() => {
  //   if (Notification.permission !== 'granted') {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === 'granted') {
  //         console.log('Notification permission granted.');
  //         messaging.getToken().then((token) => {
  //           console.log('Checking Token', token);
  //           localStorage.setItem('firebaseToken', token);
  //           // Send the token to your server for sending notifications
  //         });
  //       }
  //     });
  //   }

  //   else {
  //     // Permission is already granted
  //     // Proceed with getting the token
  //     messaging.getToken().then((token) => {
  //       console.log('Checking Token', token);
  //       localStorage.setItem('firebaseToken', token);
  //       // Send the token to your server for sending notifications
  //     });
  //   }
  // },[]);

  // Service worker for notification
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        // .register("/service-worker.js")
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }

  // ************************************************
  // useEffect(()=>{
  // console.log("hellocheckingdata",messaging)

  // },[messaging])
  // //.*********************************************************

  // Verify JWt Token
  const verifyJWT = async () => {
    try {
      const res = await baseUrlEdit.post(
        "token/verify",
        {},
        {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        }
      );
      if (res) {
        if (res?.data?.status === 401) {
          logOutTokenExpire();
        } else if (res?.data?.status === 503) {
          MaintenanceModeDailog({message : res?.data?.message})
        }else {
          dispatch(setUser(res?.data?.data?.user));
          dispatch(setUserRole(res?.data?.data?.user_role));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (globalLocalStrogeToken()) {
      verifyJWT();
      dispatch(fetchMentors());
    }
  }, []);

  // when block user by admin
  useEffect(() => {
    socket.on("getUserSetting", (res) => {
      if (res?.data?.type === "getPermissionUpdated") {
        dispatch(getUserUpdateProfileDetails());
      } else if (res?.data?.type === "getUserBlocked") {
        confirmAlert({
          title: "Your Account Has Been Blocked Please Connect to Admin",
          overlayClassName: "confirm_box_logout",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                localStorage.removeItem("token");
                localStorage.removeItem("api_token_gtf");
                window.location.replace("/login");
              },
            },
          ],
        });
      }
    });
  }, []);

  useEffect(() => {
    socket.on("messageCountIncrease", (res) => {
      if(res?.ConversationID) {
        dispatch(newMessageReceived({ConversationID : res?.ConversationID, newMessageStatus: true}))
      } else if(res?.GroupChannelID) {
        dispatch(newMessageReceived({GroupChannelID : res?.GroupChannelID, newMessageStatus: true}))
      }
    })
  },[socket])

  return (
    <BrowserRouter>
      {/* <Header /> */}
      {/* <div className="d-flex"> */}

      <Routes basename={"gtf-connect/"}>
        <Route exact path="/" element={<ProtectRoutes Cmp={Home} />} />
        <Route
          exact
          path="/notification"
          element={<ProtectRoutes Cmp={NotificationRoute} />}
        />
        <Route exact path="/mentor" element={<ProtectRoutes Cmp={Mentor} />} />
        <Route exact path="/users" element={<ProtectRoutes Cmp={Users} />} />
        <Route
          exact
          path="/contact"
          element={<ProtectRoutes Cmp={ContactUs} />}
        />
        <Route
          exact
          path="/student"
          element={<ProtectRoutes Cmp={Student} />}
        />
        <Route exact path="/group" element={<ProtectRoutes Cmp={Group} />} />
        <Route
          exact
          path="/channel"
          element={<ProtectRoutes Cmp={Channel} />}
        />
        <Route path="/setting" element={<ProtectRoutes Cmp={Setting} />} />
        <Route exact path="/logout" element={<ProtectRoutes Cmp={LogOut} />} />
        <Route
          exact
          path="/edit-profile"
          element={<ProtectRoutes Cmp={EditProfile} />}
        />
        <Route
          path="/change-password"
          element={<ProtectRoutes Cmp={ChangePassword} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forget-password" element={<ForgetPassword />} />
        <Route exact path="/otpverification" element={<OtpVerification />} />
        <Route exact path="/saved-message" element={<Home />} />
        <Route exact path="/testing" element={<Test />} />
        <Route path="/*" element={<ProtectRoutes Cmp={NotFound} />} />
      </Routes>
      {/* <ChatContent /> */}
      {/* </div> */}
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
