import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { user1 } from "../../Images/images";
import { useState } from "react";
import { joinExclusiveGC } from "../../reducer/DetailsChannelGroup";
import { useDispatch, useSelector } from "react-redux";

const SubscriptionPlanShowPopup = ({
  // SubscriptionPlanShowPopupData,
  setShow,
  IsRenew,
  showGlobalSearchSubscriptionPopup,
}) => {

  const [tabIndex, setTabIndex] = useState(0);
  const userId = localStorage.getItem("gtfconnectid");
  const { SubscriptionPlanShowPopupData }  = useSelector((state)=>state?.notificationReducer);
  const dispatch = useDispatch();
  const globalSearchShowSubscription =
    SubscriptionPlanShowPopupData?.gc_subscription_plan?.map((data, index) => {
      const valueInDays = parseInt(
        data?.subscription_plan?.term_duration?.ValueInDays
      );
      return (
        <>
          {valueInDays === 30 && <Tab>1 Month</Tab>}
          {valueInDays === 60 && <Tab>2 Months</Tab>}
          {valueInDays === 90 && <Tab>3 Months</Tab>}
          {valueInDays === 180 && <Tab>6 Months</Tab>}
          {valueInDays > 180 && <Tab>Unlimited</Tab>}
        </>
      );
    });
  const globalSearchShowPanelSubscription =
    SubscriptionPlanShowPopupData?.gc_subscription_plan?.map((data, index) => {
      const valueInDays = parseInt(
        data?.subscription_plan?.term_duration?.ValueInDays
      );
      return (
        <TabPanel key={index}>
          <div className="subscribe-modal-user">
            <div className="subscribe-img">
              <img
                src={
                  data?.subscription_plan?.ProfileImage == null
                    ? user1
                    : `${SubscriptionPlanShowPopupData?.subscription_plan_profile_base_url}${data?.subscription_plan?.ProfileImage}`
                }
                alt={SubscriptionPlanShowPopupData?.gc_info?.Name}
                className="w-100"
              />
            </div>
            <div className="subscribe-right-content">
              <h2>{data?.subscription_plan?.Name}</h2>
              <span>
                {SubscriptionPlanShowPopupData?.gc_info?.MemberCount}
                subscribers
              </span>
              <p>Get access to membership parks</p>
            </div>
          </div>
          <div className="subscribe-benefits">
            <h2>Benefits</h2>
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: data?.subscription_plan?.Benefit,
                }}
              ></li>
            </ul>
            <h2>Plan Description</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.subscription_plan?.Description,
              }}
            />
          </div>
          <div className="subscribe-modal-footer">
            {data?.subscription_plan?.TotalPrice == 0 ? (
              <Link
                className="btn btn-inline"
                onClick={() => {
                  dispatch(
                    joinExclusiveGC({
                      GCSubscriptionPlanID: data?.GCSubscriptionPlanID,
                      GroupChannelID: data?.GroupChannelID,
                      setShow: setShow,
                      IsRenew: IsRenew,
                    })
                  );
                }}
              >
                Free Join Now
              </Link>
            ) : (
              <Link
                className="btn btn-inline"
                to={
                  showGlobalSearchSubscriptionPopup
                    ? process.env.REACT_APP_PAYMENT_URL+`app/checkout/user/${userId}/renew?item=gc_subscription_plan&item_id=${data?.GCSubscriptionPlanID}&device=web`
                    : process.env.REACT_APP_PAYMENT_URL+`app/checkout/user/${userId}?item=gc_subscription_plan&item_id=${data?.GCSubscriptionPlanID}&device=web`
                }
              >
                {`${"Subscribe At "}${data?.subscription_plan?.TotalPrice} / ${
                  valueInDays === 30
                    ? "1 Month"
                    : valueInDays === 60
                    ? "2 Months"
                    : valueInDays === 90
                    ? "3 Months"
                    : valueInDays === 180
                    ? "6 Months"
                    : "Unlimited"
                }`}
              </Link>
            )}
          </div>
        </TabPanel>
      );
    });

  return (
    <div className="modal-dialog popup_width_sub">
      <div className="modal-content">
        <div className="modal-body p-0">
          <div className="subscribe-modal-view">
            <div className="subscribe-modal-inner_exclusive">
              <div className="subscribe-modal-content">
                <div className="subscribe-modal-header">
                  <h2>Membership</h2>
                </div>
                <Tabs
                  className="subscribe-modal-body"
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList className="subscribe-tab">
                    {globalSearchShowSubscription}
                  </TabList>
                  {globalSearchShowPanelSubscription}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SubscriptionPlanShowPopup);
