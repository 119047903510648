import { createSlice } from "@reduxjs/toolkit";
import { baseUrlEdit } from "../../store/axios";
import { toast } from "react-toastify";
import {
  globalLocalStrogeToken,
  tokenExpireCheckStatus,
} from "../../Component/GlobalLocalStorage";

const initialState = {
  reportUserList: [],
  addReportUser: [],
  GetGCUserInfoMedia: [],
};
export const reportUser = createSlice({
  name: "reportUser",
  initialState,
  reducers: {
    userReportData: (state, action) => {
      state.reportUserList = action.payload;
    },
    userAddReportData: (state, action) => {
      state.addReportUser = action.payload;
    },
    GCUserInfoUserData: (state, action) => {
      state.GetGCUserInfoMedia = action.payload;
    },
  },
});

export const { userReportData, userAddReportData, GCUserInfoUserData } =
  reportUser.actions;
export default reportUser.reducer;

export const userReportListData = () => {
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(`group_channels/report-reasons?page=1&per_page=2`, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(userReportData(res?.data));
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};

export const userAddReport = (payload) => {
  const { getGroupChannelId, GCMemberIDUserReport, reportReasonID } = payload;
  const formData = new FormData();
  formData.append("ReportReasonID", reportReasonID);
  formData.append("GCMemberID", GCMemberIDUserReport);
  formData.append("GroupChannelID", getGroupChannelId);
  formData.append("UserReportID", "");
  console.log("payload", payload);
  return async (dispatch) => {
    try {
      await baseUrlEdit
        .post(`group_channels/add-report`, formData, {
          headers: {
            Authorization: `Bearer ${globalLocalStrogeToken()}`,
          },
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(userAddReportData(res?.data));
            toast.success(res?.data?.message);
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};

export const reportUserGCUserInfoMediaApi = (payload) => {
  const { GCMemberID, getGroupChannelId, setReportUserUpdateStatus } = payload;

  return async (dispatch) => {
    try {
      await baseUrlEdit
        .get(
          `group_channels/${getGroupChannelId}/user-info-media?gc_member_id=${GCMemberID}`,
          {
            headers: {
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            dispatch(GCUserInfoUserData(res?.data?.data));
            setReportUserUpdateStatus(true);
          } else if (res?.data?.status === 401) {
            tokenExpireCheckStatus(false, res?.data?.message);
          }
        });
    } catch (error) {
      console.log("error :", error);
    }
  };
};
