import { createSlice } from "@reduxjs/toolkit";
import { type } from "@testing-library/user-event/dist/type";
import { toast } from "react-toastify";
import { baseUrlEdit } from "../../store/axios";
import { DetailsChannelGroupApi } from "../DetailsChannelGroup";
import { globalLocalStrogeToken, tokenExpireCheckStatus } from "../../Component/GlobalLocalStorage";
import { socket } from "../../store/SocketIo";

const tokenEditProfile = localStorage.getItem("token");
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
const initialState = {
  permission: "",
  statusGCSettingUpdateLoader: false,
};

export const GcPermissionUpdate = createSlice({
  name: "GcPermissionUpdate",
  initialState,

  reducers: {
    updateGcpermission: (state, action) => {
      state.permission = action.payload;
    },
    setStatus(state, action) {
      state.statusGCSettingUpdateLoader = action.payload;
    },
  },
});

export const { updateGcpermission,setStatus } = GcPermissionUpdate.actions;

export default GcPermissionUpdate.reducer;
export const updateGroupChannelPermission = (payload) => {
  return async (dispatch) => {
    const form = new FormData();
    form.append(
      "SendMessage",
      payload?.channelSendMedia == 1 ? 1 : payload?.channelSendMessage
    );
    form.append("SendMedia", payload?.channelSendMedia);
    form.append("SendStickerGIF", payload?.channelSendStickerGIF);
    form.append("EmbedLinks", payload?.channelEmbedLinks);
    form.append("PinMessage", payload?.channelPinMessage);
    form.append("SlowMode", payload?.channelSlowMode);
    form.append("_method", "patch");
    try {
      await baseUrlEdit
        .post(
          `group_channels/${payload?.channelGroupID}/permission/update`,
          form,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${globalLocalStrogeToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.status === 200) {
            // toast.success(`permission updated successfully`);
            socket.emit("updateSetting", {
              GroupChannelID: payload?.channelGroupID,
            });
            dispatch(setStatus(true))
            dispatch(DetailsChannelGroupApi(payload?.channelGroupID));
            setTimeout(()=>dispatch(setStatus(false)),500)
          }  else if (response?.data?.status === 401) {
            tokenExpireCheckStatus(false, response?.data?.message);
            }
        });
    } catch (error) {
      console.log("error", error);
    }
  };
};
