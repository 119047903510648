import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  app_store,
  google_play,
  hide_icon,
  loginleftbox_image_1,
  loginleftbox_image_2,
  loginleftbox_image_3,
  loginleftbox_image_4,
  loginleftbox_image_5,
  loginleftbox_image_6,
  paymentLogo,
  vector_icon1,
  vector_icon2,
  vector_icon3,
} from "../../Images/images";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { fetchUserLogins } from "../../reducer/userAuth/userLogin";
const Login = () => {
  const [hidepassword, setHidePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCrediancial, setUserCrediancial] = useState("");
  const [userCrediancialStatus, setUserCrediancialStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const otherDeviceLoginMessage = useSelector(
    (state) => state?.userLogin?.otherDeviceLogInMessage
  );

  // Protect Routes
  const loginGetData = localStorage.getItem("token");
  useEffect(() => {
    loginGetData && navigate("/");
  }, [loginGetData]);

  // Handle user LogIn
  const UserLogin = (values) => {
    setUserCrediancial(values);
    dispatch(
      fetchUserLogins({
        Email: values.enterEmail,
        Password: values.password,
        navigate,
        setLoading,
        otherDeviceLogOut: "check",
        setUserCrediancialStatus,
      })
    );
  };

  // Other Device Login Show Alret Popup
  useEffect(() => {
    if (userCrediancialStatus) {
      otherDeviceLoginMessage?.status === 401 &&
        confirmAlert({
          message: otherDeviceLoginMessage?.message,
          overlayClassName: "confirm_box_other_device_login",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                dispatch(
                  fetchUserLogins({
                    Email: userCrediancial.enterEmail,
                    Password: userCrediancial.password,
                    navigate,
                    setLoading,
                    otherDeviceLogOut: 1,
                  })
                );
              },
            },
            {
              label: "No",
              onClick: () => {
                dispatch(
                  fetchUserLogins({
                    Email: userCrediancial.enterEmail,
                    Password: userCrediancial.password,
                    navigate,
                    setLoading,
                    otherDeviceLogOut: 0,
                  })
                );
              },
            },
          ],
        });
    }
  }, [userCrediancialStatus]);

  // Visible Password
  const VisiblePassword = () => {
    setHidePassword(!hidepassword);
    const PasswordId = document.getElementById("password");
    if (PasswordId.type === "password") {
      return (PasswordId.type = "text");
    } else {
      return (PasswordId.type = "password");
    }
  };

  // Login Validation
  const SignupSchema = Yup.object().shape({
    enterEmail: Yup.string().required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  });
  return (
    <>
      {/* Start Login */}
      <section className="login">
        <div className="container">
          <div className="row loginrow">
            <div className="col-md-6 loginleft">
              <div className="loginleftbox">
                <div className="loginleftbox_image">
                  <div className="loginleftbox_image_1">
                    <img
                      src={loginleftbox_image_1}
                      alt="loginleftbox_image_1"
                    />
                  </div>
                  <div className="loginleftbox_image_2">
                    <img
                      src={loginleftbox_image_2}
                      alt="loginleftbox_image_2"
                    />
                  </div>
                  <div className="loginleftbox_image_3">
                    <img
                      src={loginleftbox_image_3}
                      alt="loginleftbox_image_3"
                    />
                  </div>
                  <div className="loginleftbox_image_4">
                    <img
                      src={loginleftbox_image_4}
                      alt="loginleftbox_image_4"
                    />
                  </div>
                  <div className="loginleftbox_image_5">
                    <img
                      src={loginleftbox_image_5}
                      alt="loginleftbox_image_5"
                    />
                  </div>
                  <div className="loginleftbox_image_6">
                    <img
                      src={loginleftbox_image_6}
                      alt="loginleftbox_image_6"
                    />
                  </div>
                </div>
                <div className="loginleftbox_app d-flex justify-content-center flex-wrap align-items-center">
                  <Link to="#">
                    <img src={app_store} alt="app-store" />
                  </Link>
                  <Link to="#">
                    <img src={google_play} alt="google-play" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 loginright">
              <div className="loginrightbox">
                <div className="loginrightbox_logo">
                  <Link to="#">
                    <img src={paymentLogo} alt="img" />
                  </Link>
                </div>
                <div className="loginrightbox_form">
                  <h3>Login</h3>
                  <Formik
                    initialValues={{
                      enterEmail: "",
                      password: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                      UserLogin(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="form-input">
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            name="enterEmail"
                          />
                          <span className="form-input-icon">
                            <img src={vector_icon1} alt="vector_icon1" />
                          </span>
                          {errors.enterEmail && touched.enterEmail ? (
                            <div className="text-danger d-flex mt-2">
                              {errors.enterEmail}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-input m-0">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            id="password"
                            name="password"
                          />
                          <span className="form-input-icon">
                            <img src={vector_icon2} alt="vector_icon2" />
                          </span>
                          <span className="form-input-eye">
                            {!hidepassword ? (
                              <img
                                className="oneye"
                                src={vector_icon3}
                                alt="vector_icon3"
                                onClick={VisiblePassword}
                              />
                            ) : (
                              <img
                                className="oneye"
                                src={hide_icon}
                                alt="hide_icon"
                                onClick={VisiblePassword}
                              />
                            )}
                          </span>
                          {errors.password && touched.password ? (
                            <div className="text-danger d-flex mt-2">
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-resend d-flex justify-content-between flex-wrap">
                          <Link
                            to="/forget-password"
                            className="d-flex justify-content-end"
                          >
                            Forgot Password
                          </Link>
                        </div>
                        <div className="form-submit">
                          <LoadingButton
                            loading={loading}
                            type="submit"
                            className="btn btn-login"
                          >
                            Login
                          </LoadingButton>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <div className="loginrightbox_newuser_new">
                    <span>
                      if you are new <Link to="/register">Register</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Login */}
    </>
  );
};

export default Login;
